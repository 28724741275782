import { useAppDispatch, useAppSelector } from 'src/store';
import { useState, useEffect } from 'react';
import { Button, Col, Row, Image } from 'react-bootstrap';
import BookingNow from '../components/Booking';
import ExitConfirmation from '../components/ExitConfirmation';
import PropertyInfo from '../components/PropertyInfo';
import ServiceDetail from '../components/ServiceDetail';
import SecurityCard from './SecurityCard';
import { addSelectedServices, calculateDistance, editButtonPropertyInfo, getAllBookingServices, getFirstGalleryItems, getPhotographer, setRedirection, setSelectedBookingService, setShowListingPrice, setShowSquareFeet } from 'src/features/services/booking.slice';
import BookingItem from './BookingItem';
import { useParams } from 'react-router-dom';
import { setPropertyInfo } from 'src/features/services/booking.slice';
import SuccessPopup from 'src/components/SuccessPopup';
import ErrorPopup from 'src/components/ErrorPopup';
import Checkout from 'src/components/Checkout';
import { trackBookingAnalytics } from 'src/features/analytics/analytics.slice';
import DocumentMeta from 'react-document-meta';
import { isPricingMethodology } from 'src/components/Booking/BookingUtils';
import { delayTime } from 'src/components/ToastMessage';
import { getProviderSubscription } from 'src/features/services/booking.slice';
import { Box, Skeleton } from '@mui/material';
import { enableDarkMode, enableLightMode } from 'src/features/theme/theme.slice';
import { getBranding } from 'src/utils/branding';
const Booking = () => {
  let { uuid } = useParams();
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(true);
  const [recallGallery, setRecallGallery] = useState(false);
  const [showServiceDetail, setShowServiceDetail] = useState(false);
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const [showPropertyInfo, setShowPropertyInfo] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [showFullView, setShowFullView] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const { firstGalleryItems, photographer, selectedBookingService, redirect, editPropertyInfo, addButtonDisable, selectedServices, bookingServices, bookingServicesCount, isLoading, bookingError, calendarError, propertyInfoFilled, address, square_feet, listing_price } =
  useAppSelector((state) => state.bookingServices);
  const [value, setValue] = useState(null)
  const [showSuccessPopup, SetShowSuccessPopup] = useState(false)
  const [showCheckout, setShowCheckout] = useState(false);
  const [slotDetail, setSlotDetail] = useState(null);
  const { subscriptionFeatures } = useAppSelector((state) => state.auth);
  const branding_logo = getBranding('oppy_word_mark');
  useEffect(() => {
    if (!propertyInfoFilled) {
      dispatch(getAllBookingServices({uuid}));
    }
  }, [propertyInfoFilled, address, square_feet, listing_price]);

  useEffect(() => {
    if(bookingServices.length != 0){
      if(isPricingMethodology(bookingServices, 'square_footage') || isPricingMethodology(bookingServices, 'tiered_sqft_fee')){
        dispatch(setShowSquareFeet(true))
      }
      if(isPricingMethodology(bookingServices, 'tiered_price_fee')){dispatch(setShowListingPrice(true))}
    }
  }, [bookingServices]);

  useEffect(() => {
    if (!subscriptionFeatures) {
      dispatch(getProviderSubscription({uuid}))
    }
  }, [])

  useEffect(() => {
    dispatch(enableLightMode())
    return () => {
      dispatch(enableDarkMode())
    }
  }, [])

  const handleCloseServiceList = () => {
    setShow(false);
    setShowExitConfirmation(true);
  };

  const closeServiceListModal = () => {
    setShow(false);
  };

  const handleKeepBooking = () => {
    closeExitConfirmation();
    setShow(true);
  };

  const closeExitConfirmation = () => {
    setShowExitConfirmation(false);
  };

  const closeServiceView = () => {
    setShow(true);
    setShowFullView(false);
  };

  const handleErrorPopup = (source, service = selectedBookingService) => {

    dispatch(setRedirection({item:  service, source: source}))

    setShowErrorPopup(true);
    handlePropertyInfo()
    setTimeout(() => setShowErrorPopup(false), delayTime);
  };

  const handlePropertyInfo = () => {
    setShow(false);
    setShowServiceDetail(false)
    setShowFullView(false)
    setShowPropertyInfo(true);
  };

  const closePropertyInfo = () => {
    setShowPropertyInfo(false);
    if (editPropertyInfo === 'Checkout') {
      setShowCheckout(true)
      dispatch(editButtonPropertyInfo(''));
    }
    else if (redirect && redirect.source === "Service Detail" ){
      setShowServiceDetail(true)
    }
    else if (redirect && redirect.source === "Full Gallery View" ){
      setShowFullView(true)
    }
    else {
      setShow(true);
    }
    dispatch(setRedirection(null))
  };

  const handleSubmitPropertyInfo = (data) => {
    dispatch(calculateDistance({address: value.label, uuid: uuid})).unwrap().then(() => {
      const info = {...data, address: value.label, uuid}
      dispatch(setPropertyInfo(info));
      dispatch(getAllBookingServices(info)).unwrap().then(() => {
        setRecallGallery(!recallGallery)
        dispatch(trackBookingAnalytics({
          action_name: "user_saved_property_info",
          payload: {
            description: "user filled property info",
            address: value.label,
            square_feet: data.square_feet,
            listing_price: data.listing_price,
            uuid: uuid
          }
        }))
        setShowPropertyInfo(false);
        if (redirect?.item){
          dispatch(addSelectedServices(redirect.item))
        }
        if (editPropertyInfo === 'Checkout') {
          setShowBookingModal(true)
          dispatch(editButtonPropertyInfo(''));
        }
        else if (redirect && redirect.source === "Service Detail" ){
          setShowServiceDetail(true)
        }
        else if (redirect && redirect.source === "Full Gallery View" ){
          setShowFullView(true)
        }
        else {
          SetShowSuccessPopup(true)
          setTimeout(() => SetShowSuccessPopup(false), delayTime);
          setShow(true);
        }
        dispatch(setRedirection(null))
      })
    })
  };

  const handleBooking = () => {
    dispatch(trackBookingAnalytics({
      action_name: "book_now_clicked",
      payload: {
        services_chosen: selectedServices,
        address: address,
        square_feet: square_feet,
        listing_price: listing_price,
        description: "user initiated booking",
        uuid: uuid
      }
    }))
    setShow(false);
    setShowBookingModal(true);
  };

  const handleCloseBooking = () => {
    setShow(true);
    setShowBookingModal(false);
  };

  const handleCheckout = () => {
    setShow(false);
  };

  const handleCloseCheckout = () => {
    setShowCheckout(false);
    setShowBookingModal(true);
  };

  const handleCloseModal = () => {
    setShowCheckout(false);
    handleCloseBooking();
    closeServiceListModal();
  };

  const handleShowPropertyInfo = () => {
    dispatch(editButtonPropertyInfo('Checkout'));
    setShowPropertyInfo(true);
    setShowCheckout(false);
  }

  useEffect(()=>{
    dispatch(getPhotographer({uuid}))
  }, [])

  const meta = {
    title: `Book ${photographer ? photographer?.name : "now"}!`,
    description: photographer?.bio,
    meta: {
      charSet: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      },
      itemProp: {
        name: `Book ${photographer?.name}! Powered by Oppy.pro`,
        description: photographer?.bio,
      },
      property: {
        'og:title': `Book ${photographer ? photographer?.name : "now"}!`,
        'og:description': photographer?.bio,
      }
    },
    auto: {
      ograph: true
    }
  }

  return (
    <>
      <DocumentMeta {...meta}/>
      <ServiceDetail
        addButtonDisable={addButtonDisable}
        closeServiceView={closeServiceView}
        showServiceDetail={showServiceDetail}
        propertyInfoFilled={propertyInfoFilled}
        selectedServices={selectedServices}
        handleErrorPopup={handleErrorPopup}
        uuid={uuid}
        showFullView={showFullView}
        setShowFullView={setShowFullView}
        setShowServiceDetail={setShowServiceDetail}
      />

      <ExitConfirmation
        closeExitConfirmation={closeExitConfirmation}
        showExitConfirmation={showExitConfirmation}
        handleKeepBooking={handleKeepBooking}
      />

      <PropertyInfo
        closePropertyInfo={closePropertyInfo}
        showPropertyInfo={showPropertyInfo}
        onSubmit={handleSubmitPropertyInfo}
        value={value}
        setValue={setValue}
        showErrorPopup={showErrorPopup}
      />

      <BookingNow
        onClose={handleCloseBooking}
        show={showBookingModal}
        handleBooking={handleCheckout}
        showBooking={handleBooking}
        closeBooking={() => setShowBookingModal(false)}
        setShowBookingModal={setShowBookingModal}
        setShowCheckout={setShowCheckout}
        setSlotDetail={setSlotDetail}
      />

      <Checkout
        show={showCheckout}
        onClose={handleCloseCheckout}
        showConfirmation={handleCloseModal}
        slotDetail={slotDetail}
        selectedServices={selectedServices}
        uuid={uuid}
        handleShowPropertyInfo={handleShowPropertyInfo}
      />
      <div className='booking-wrapper'>
        <div className="card booking-card">
          <div className='card-header'>
            <SecurityCard />
            <div className='card-subtitle'>
              <h3>Book Services</h3>
              {(bookingServicesCount > 0) &&
                <span className="property-info-button" onClick={handlePropertyInfo} >
                  {propertyInfoFilled
                    ? 'Modify property info'
                    : 'Enter property info for prices & times'}
                </span>
              }
            </div>
          </div>

          <div className="card-body">
            {showSuccessPopup && <SuccessPopup message="Property Info saved successfully"/>}
            {bookingError && <ErrorPopup message={bookingError}/>}
            {bookingServicesCount === 0 &&
              <Row className='justify-content-center'>
                <Col>
                  <Image
                    className="mx-auto d-block mt-5 p-5"
                    src="/icon/servicesEmpty.svg"
                    fluid
                  />
                  <p className="text-center mt-3">
                    {!!calendarError ? calendarError : "No Services..."}
                  </p>
                </Col>
              </Row>
            }
            {!(bookingServicesCount > 0) && !(bookingServicesCount === 0) &&
              <Row className='services-container'>
                  <Box className="service-card">
                    <Skeleton variant="rectangular" className="card-img-top" />
                    <Box className='card-body'>
                      <Skeleton animation="wave" width="60%" height={75}/>
                      <Skeleton width="40%"/>
                      <Skeleton width="60%" />
                    </Box>
                  </Box>
                  <Box className="service-card">
                    <Skeleton variant="rectangular" className="card-img-top" />
                    <Box className='card-body'>
                      <Skeleton animation="wave" width="60%" height={75}/>
                      <Skeleton width="40%"/>
                      <Skeleton width="60%" />
                    </Box>
                  </Box>
                  <Box className="service-card">
                    <Skeleton variant="rectangular" className="card-img-top" />
                    <Box className='card-body'>
                      <Skeleton animation="wave" width="60%" height={75}/>
                      <Skeleton width="40%"/>
                      <Skeleton width="60%" />
                    </Box>
                  </Box>
              </Row>
            }
            <Row className='services-container'>
              {bookingServices.map((service, index) => (
                <BookingItem
                  addButtonDisable={addButtonDisable}
                  bookingInfo={service}
                  setShow={setShow}
                  recallGallery={recallGallery}
                  setShowFullView={setShowFullView}
                  propertyInfoFilled={propertyInfoFilled}
                  selectedServices={selectedServices}
                  handleErrorPopup={handleErrorPopup}
                  key={index}
                />
              ))}
            </Row>
          </div> {/*card-body*/}
        </div> {/* //card */}
      </div>{/* //booking-wrapper */}
      <div className="booking-footer-wrapper">
        <div className="booking-footer-container">
          <div className="d-flex remark-label fs-12 flex-grow-1 align-items-center">
            <span>Powered by </span>
            <img src={branding_logo} alt="Oppy.pro icon" style={{ maxHeight: '30px', maxWidth: '35px' }} />
          </div>
          <Button
            title={!selectedServices.length ? "Please add any service" : ""}
            variant="primary"
            onClick={handleBooking}
            disabled={!selectedServices.length}
            style={{pointerEvents: 'auto', opacity:`${!selectedServices.length ? ".5" : "1" }`}}
          >
            {!selectedServices.length ? "No services chosen" : "Choose Date"}
          </Button>
        </div>
      </div> {/*card-footer*/}
    </>
  );
};

export default Booking;
