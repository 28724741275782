import { Dispatch } from 'react';

const analyticsMiddleware =
  ({ getState }) =>
  (next: Dispatch<any>) =>
  (action: any) => {
    next(action);

    //TODO orchestrate the dispatch of events and add the async call to analytics
    // console.log(
    //   'handle analytics based on the action ',
    //   getState(),
    //   next,
    //   action
    // );
  };

export default analyticsMiddleware;
