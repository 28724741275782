import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  FormHelperText,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Chip,
} from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';

interface CronSchedulerProps {
  url: string;
  currentSchedule: string;
  onSave: (schedule: string) => void;
  onClose: () => void;
}

// Helper function to convert local hour to UTC hour
const localHourToUtcHour = (localHour: number): number => {
  const now = new Date();
  now.setHours(localHour, 0, 0, 0); // Set the time to the selected hour
  const utcHour = now.getUTCHours();
  return utcHour;
};

// Helper function to generate cron expression
const generateCronExpression = (hour: string, day: string, type: string) => {
  const minute = '0'; // Default minute
  let cronHour = hour;
  let dayOfWeek = '*';
  let dayOfMonth = '*';
  let month = '*';

  if (type === 'Weekly') {
    dayOfWeek = day;
  } else if (type === 'Monthly') {
    dayOfMonth = day;
  }

  return `${minute} ${cronHour} ${dayOfMonth} ${month} ${dayOfWeek}`;
};

// CronScheduler component with time zone adjustments
const CronScheduler: React.FC<CronSchedulerProps> = ({
  url,
  currentSchedule,
  onSave,
  onClose,
}) => {
  const [scheduleType, setScheduleType] = useState<'Daily' | 'Weekly' | 'Monthly'>('Daily');
  const [hour, setHour] = useState('0');
  const [dayOfWeek, setDayOfWeek] = useState('1'); // Monday
  const [dayOfMonth, setDayOfMonth] = useState('1');
  const [cronExpression, setCronExpression] = useState('0 0 * * *');

  // Initialize state from currentSchedule
  useEffect(() => {
    if (currentSchedule) {
      const parts = currentSchedule.split(' ');
      if (parts.length === 5) {
        const minute = parts[0];
        const hourPart = parts[1];
        const dayOfMonthPart = parts[2];
        const monthPart = parts[3];
        const dayOfWeekPart = parts[4];

        // Convert UTC hour back to local hour for display
        const utcHour = parseInt(hourPart);
        const date = new Date();
        date.setUTCHours(utcHour, 0, 0, 0);
        const localHour = date.getHours();

        setHour(localHour.toString());
        setDayOfMonth(dayOfMonthPart);
        setDayOfWeek(dayOfWeekPart);

        if (dayOfMonthPart !== '*') {
          setScheduleType('Monthly');
        } else if (dayOfWeekPart !== '*') {
          setScheduleType('Weekly');
        } else {
          setScheduleType('Daily');
        }
      }
    }
  }, [currentSchedule]);

  // Update cron expression whenever inputs change
  useEffect(() => {
    // Convert local hour to UTC hour
    const localHour = parseInt(hour); // User's selected local hour
    const utcHour = localHourToUtcHour(localHour);

    // Adjust the hour in the cron expression to use UTC hour
    let cron = generateCronExpression(
      utcHour.toString(),
      scheduleType === 'Weekly' ? dayOfWeek : dayOfMonth,
      scheduleType
    );
    setCronExpression(cron);
  }, [hour, dayOfWeek, dayOfMonth, scheduleType]);

  const handleSave = () => {
    onSave(cronExpression);
  };

  // Generate hour options in 12-hour format
  const hourOptions = Array.from({ length: 24 }, (_, i) => {
    const hourValue = i.toString();
    const ampmHour = i % 12 === 0 ? 12 : i % 12;
    const ampm = i < 12 ? 'AM' : 'PM';
    return { value: hourValue, label: `${ampmHour} ${ampm}` };
  });

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <ScheduleIcon sx={{ mr: 1 }} />
          Edit Schedule
        </Box>
      </DialogTitle>
      <DialogContent>
        {/* Display URL */}
        <Typography variant="body2" color="textSecondary" sx={{ wordBreak: 'break-all', mb: 2 }}>
          {url}
        </Typography>
        {/* Schedule Type Selection */}
        <Typography variant="subtitle1">Select Schedule Type:</Typography>
        <ToggleButtonGroup
          color="primary"
          value={scheduleType}
          exclusive
          onChange={(e, value) => value && setScheduleType(value)}
          sx={{ mb: 2 }}
        >
          <ToggleButton value="Daily">Daily</ToggleButton>
          <ToggleButton value="Weekly">Weekly</ToggleButton>
          <ToggleButton value="Monthly">Monthly</ToggleButton>
        </ToggleButtonGroup>
        {/* Schedule Details */}
        <Grid container spacing={2}>
          {/* Hour Selection */}
          <Grid item xs={12}>
            <Typography variant="subtitle1">Select Time:</Typography>
            <ToggleButtonGroup
              color="primary"
              value={hour}
              exclusive
              onChange={(e, value) => value != null && setHour(value)}
              sx={{ flexWrap: 'wrap', mb: 2 }}
            >
              {hourOptions.map((option) => (
                <ToggleButton key={option.value} value={option.value}>
                  {option.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <FormHelperText>Select the time you want the schedule to run</FormHelperText>
          </Grid>
          {/* Day Selection */}
          {scheduleType === 'Weekly' && (
            <Grid item xs={12}>
              <Typography variant="subtitle1">Select Day of Week:</Typography>
              <ToggleButtonGroup
                color="primary"
                value={dayOfWeek}
                exclusive
                onChange={(e, value) => value != null && setDayOfWeek(value)}
                sx={{ mb: 2 }}
              >
                <ToggleButton value="1">Mon</ToggleButton>
                <ToggleButton value="2">Tue</ToggleButton>
                <ToggleButton value="3">Wed</ToggleButton>
                <ToggleButton value="4">Thu</ToggleButton>
                <ToggleButton value="5">Fri</ToggleButton>
                <ToggleButton value="6">Sat</ToggleButton>
                <ToggleButton value="0">Sun</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          )}
          {scheduleType === 'Monthly' && (
            <Grid item xs={12}>
              <Typography variant="subtitle1">Select Day of Month:</Typography>
              <ToggleButtonGroup
                color="primary"
                value={dayOfMonth}
                exclusive
                onChange={(e, value) => value != null && setDayOfMonth(value)}
                sx={{ flexWrap: 'wrap', mb: 2 }}
              >
                {[...Array(31)].map((_, i) => (
                  <ToggleButton key={i + 1} value={(i + 1).toString()}>
                    {i + 1}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
          )}
        </Grid>
        {/* Schedule Summary */}
        <Box mt={2}>
          <Typography variant="subtitle1">Schedule Summary:</Typography>
          <Chip
            label={
              scheduleType === 'Daily'
                ? `Every day at ${
                    hourOptions.find((opt) => opt.value === hour)?.label || `${hour}:00`
                  }`
                : scheduleType === 'Weekly'
                ? `Every ${
                    [
                      'Sunday',
                      'Monday',
                      'Tuesday',
                      'Wednesday',
                      'Thursday',
                      'Friday',
                      'Saturday',
                    ][parseInt(dayOfWeek)]
                  } at ${
                    hourOptions.find((opt) => opt.value === hour)?.label || `${hour}:00`
                  }`
                : `Day ${dayOfMonth} of every month at ${
                    hourOptions.find((opt) => opt.value === hour)?.label || `${hour}:00`
                  }`
            }
            variant="outlined"
          />
        </Box>
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
          Note: The schedule is based on your local time zone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CronScheduler; 
