const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
];
const SCOPES = 'openid https://www.googleapis.com/auth/calendar';
const CLIENT_ID = process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID;
// If you're using Google+ javascript button, then you have to use 'postmessage' instead of the actual URI
// Ref: https://stackoverflow.com/a/18990247
const REDIRECT_URI = 'postmessage';

const config = {
  client_id: CLIENT_ID,
  discoveryDocs: DISCOVERY_DOCS,
  redirect_uri: REDIRECT_URI,
  cookie_policy: 'single_host_origin',
  scope: SCOPES,
  access_type: 'offline',
  response_type: 'code',
  prompt: '',
};

class GoogleCalendar {
  private static instance: GoogleCalendar;
  gapi: null | any;
  isSignIn: boolean;
  AuthInstance: null | any;

  private constructor() {
    this.gapi = null;
    this.isSignIn = false;
    this.handleClientLoad();
  }

  public static getInstance(): GoogleCalendar {
    if (!GoogleCalendar.instance) {
      GoogleCalendar.instance = new GoogleCalendar();
    }

    return GoogleCalendar.instance;
  }

  updateSigninStatus = (data) => {
    this.isSignIn = true;
  };

  grantOfflineAccess = () => {
    if (this.AuthInstance) {
      return this.AuthInstance.grantOfflineAccess({
        prompt: 'select_account',
      });
    } else {
      return Promise.reject(new Error('Error: gapi not loaded'));
    }
  };

  signIn = () => {
    if (this.gapi) {
      return this.AuthInstance.signIn();
    } else {
      return Promise.reject(new Error('Error: gapi not loaded'));
    }
  };

  signOut = () => {
    if (this.AuthInstance) {
      this.AuthInstance.signOut();
    }
  };

  initClient = () => {
    this.gapi = window['gapi'];
    this.gapi.client.init(config).then(() => {
      this.AuthInstance = this.gapi.auth2.getAuthInstance();
      if (this.AuthInstance) {
        this.AuthInstance.isSignedIn.listen(this.updateSigninStatus);
      }
    }).catch(error => console.error('Error initializing Google API client:', error));
  };

  handleClientLoad() {
    this.gapi = window['gapi'];
    const script = document.createElement('script');
    script.src = 'https://apis.google.com/js/api.js';
    document.body.appendChild(script);
    script.onload = () => {
      window['gapi'].load('client:auth2', this.initClient);
    };
  }
}

export default GoogleCalendar;
