import Swal from 'sweetalert2'
import { useEffect, useState } from 'react';
import httpClient from 'src/libs/axios';

type SentNotification = {
  success: boolean,
  message: string,
};
type PreviousNotificationsResponse = PreviousNotificationsNotification[];
type PreviousNotificationsNotification = {
  ReceivedAt: string,
}

const NotifyAboutUpdatesButton = ({galleriableType, galleriableId}) => {
  const [notifications, setNotifications] = useState(null)

  const getNotifications = () => {
    const url = `${process.env.REACT_APP_API_URL}/notifications/${galleriableType}/${galleriableId}`
    httpClient.get<PreviousNotificationsResponse>(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(({ data }) => {
      setNotifications(data)
    })
  }

  useEffect(() => {
    getNotifications()
  }, [galleriableType, galleriableId]);

  const notifyClicked = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary swal-button',
        cancelButton: 'btn btn-default swal-button'
      },
      buttonsStyling: false
    })

    console.warn(notifications)
    const receivedAt = notifications !== null && notifications.length > 0 && notifications[0]?.ReceivedAt
    swalWithBootstrapButtons.fire({
      title: 'Send notification',
      text: receivedAt ? `The last notification was sent on ${receivedAt}` : 'This is the first time a notification will be sent.',
      input: 'textarea',
      confirmButtonText: 'Send now',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: (message) => {
        const url = `${process.env.REACT_APP_API_URL}/notifications/${galleriableType}/${galleriableId}`
        return httpClient.post<SentNotification>(url, {message,})
          .then(({ data }) => {
            if (!data.success) {
              throw new Error(data.message)
            }
          })
          .catch(error => {
            Swal.showValidationMessage(
              `Request failed: ${error}`
            )
          })
      },
    }).then((result) => {
      if (!result.isConfirmed) {
        return
      }

      Swal.fire('Notification sent!', 'Your message was sent to the booker!', 'success').then(() => getNotifications())
    })
  }

  if (notifications === null) {
    // return;
  }

  return (
    <button className="btn btn-primary btn-sm" onClick={notifyClicked}>
      Notify about changes
    </button>
  );
}
export default NotifyAboutUpdatesButton;
