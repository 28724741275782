import { Form, FormControl, InputGroup } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

const SquareFootage = ({ showError, setFieldValue, handleChange, values, touched, errors }) => {
  return (
    <>
      <Form.Group>
        <Form.Label
          htmlFor="service_pricing_attributes_amount"
          className="fw-medium fs-14 mb-1 required"
        >
          Charge Per Square Foot
        </Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text>$</InputGroup.Text>
          <NumberFormat
            thousandSeparator={true}
            className={`form-control ${ showError && !!errors.service_pricing_attributes?.amount ? "is-invalid" : ""}`}
            aria-label="Amount (to the nearest dollar)"
            id="service_pricing_attributes_amount"
            name="service_pricing_attributes.amount"
            allowNegative={false}
            value={values.service_pricing_attributes.amount}
            onValueChange={(e) => {setFieldValue("service_pricing_attributes.amount",e.value)}}
          />
          <InputGroup.Text>USD</InputGroup.Text>
          <Form.Control.Feedback type="invalid">
            <>{errors?.service_pricing_attributes?.amount}</>
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      <Form.Group>
        <Form.Label
          htmlFor="minimun_service_pricing"
          className="fw-medium fs-14 mb-1"
        >
          Your Minimun Charge Per Project
        </Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text>$</InputGroup.Text>
          <NumberFormat
            thousandSeparator={true}
            className={`form-control ${ showError && !!errors.service_pricing_attributes?.min_price ? "is-invalid" : ""}`}
            aria-label="Amount (to the nearest dollar)"
            id="minimun_service_pricing"
            name="service_pricing_attributes.min_price"
            allowNegative={false}
            value={values.service_pricing_attributes.min_price}
            onValueChange={(e) => {setFieldValue("service_pricing_attributes.min_price",e.value)}}
          />
          <InputGroup.Text>USD</InputGroup.Text>
          <Form.Control.Feedback type="invalid">
            <>{errors?.service_pricing_attributes?.min_price}</>
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      <Form.Group>
        <Form.Label
          htmlFor="minimun_service_pricing"
          className="fw-medium fs-14 mb-1"
        >
          Your Maximum Charge Per Project
        </Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text>$</InputGroup.Text>
          <NumberFormat
            thousandSeparator={true}
            className={`form-control ${ showError && !!errors.service_pricing_attributes?.max_price ? "is-invalid" : ""}`}
            aria-label="Amount (to the nearest dollar)"
            id="maximun_service_pricing"
            name="service_pricing_attributes.max_price"
            allowNegative={false}
            value={values.service_pricing_attributes.max_price}
            onValueChange={(e) => {setFieldValue("service_pricing_attributes.max_price",e.value)}}
          />
          <InputGroup.Text>USD</InputGroup.Text>
          <Form.Control.Feedback type="invalid">
            <>{errors?.service_pricing_attributes?.max_price}</>
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </>
  );
};

export default SquareFootage;
