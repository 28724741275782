import { Button, Col, Row, Image } from 'react-bootstrap';

interface Props {
  onClick: () => void;
}

const AddService = ({ onClick }: Props) => {
  return (
    <Row className="">
      <Col>
        <Image
          className="mx-auto d-block mt-5 p-5 "
          src="icon/servicesEmpty.svg"
          fluid
        />
        <p className="text-center mt-3">
          No services yet! Add your first service and you'll be one step closer to your first booking!
        </p>
        <div className="d-grid d-md-block text-center">
          <Button
            className="mt-3"
            variant="primary"
            type="submit"
            onClick={onClick}
          >
            <Image className="me-2" src="icon/plus.svg" />
            Add Service
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default AddService;
