import React, { useState } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import Header from '../Header';
import SideBar from '../SideBar';

const TravelPreferences = ({ screenWidth }) => {
  const [menu, setMenu] = useState(false);

  return (
    <>
      <Header screenWidth={screenWidth} setMenu={setMenu} />
      {menu ? (
        <div className="row">
          <SideBar activePage={'travel'} />
        </div>
      ) : (
        <Container fluid>
          <div className="row">
            <SideBar
              className="col"
              screenWidth={screenWidth}
              activePage={'travel'}
            />
            <Navbar
              expand="lg"
              variant="light"
              bg="light"
              className="col px-lg-5"
            >
              <Container className="px-lg-5 mx-lg-5 ">
                <Container className="text-start px-lg-5 mx-lg-5">
                  <form>
                    <div className="">
                      <div className="pt-4 pb-3 fw-medium fs-48">
                        Travel Preferences
                      </div>
                      <div className="fw-medium fs-24">Preparation</div>
                      <p className="text-black-50 fs-12">
                        Consider the time you need to prepare before an
                        appointment.
                      </p>
                      <h6 className="fs-14 fw-medium">
                        Fixed Travel Adjustment
                      </h6>
                      <div className="input-group mb-3 ">
                        <input
                          type="text"
                          className="form-control ht-48"
                          aria-describedby="basic-addon2"
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text ht-48"
                            id="basic-addon2"
                          >
                            minutes
                          </span>
                        </div>
                      </div>
                      <div className="fw-medium fs-24">Distances</div>
                      <p className="text-black-50 fs-12">
                        Tell us how far you are willing to travel from your home
                        address, and how much you charge for longer distances.
                        We use historical traffic data to make your schedule
                        more efficient.
                      </p>
                      <h6 className="fs-14  fw-medium required">
                        Maximum Distance
                      </h6>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control ht-48"
                          aria-describedby="basic-addon2"
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text ht-48"
                            id="basic-addon2"
                          >
                            miles
                          </span>
                        </div>
                      </div>
                      <p className="text-black-50 fs-12">
                        Maximum distance willing to travel even if paid extra
                        (Straight line distance “As the crow flies”)
                      </p>
                      <div className="row mx-1">
                        <img
                          className="border rounded mb-3 p-0"
                          src="./images/TravelMap.png"
                          alt=""
                        />
                      </div>
                      <div className="row">
                        <div className="col-sm-10">
                          <h6 className="fs-14 fw-medium ">
                            Add A Fee For Drives Over A Certain Length?
                          </h6>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gridRadios"
                              id="gridRadios1"
                              value="option1"
                            />
                            <label className="form-check-label fs-14">No</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gridRadios"
                              id="gridRadios2"
                              value="option2"
                            />
                            <label className="form-check-label fs-14">
                              Yes
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row pt-3 pb-3">
                        <div className="col-12 col-lg-6">
                          <h6 className="fs-14 fw-medium required">
                            Travel Time Included In Service Fees
                          </h6>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control ht-48"
                              aria-describedby="basic-addon2"
                            />
                            <div className="input-group-append ht-48">
                              <span
                                className="input-group-text "
                                id="basic-addon2"
                              >
                                minutes
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <h6 className="fs-14 fw-medium required">
                            Extra Change Above Travel Time Included
                          </h6>
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text">$</span>
                            </div>
                            <input
                              type="text"
                              className="form-control ht-48"
                              aria-label="Amount (to the nearest dollar)"
                            />
                            <div className="input-group-append ht-48">
                              <span className="input-group-text">USD</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-top row justify-content-end mx-1">
                        <button
                          type="button"
                          className="col-xl-2 order-xl-2 mt-3 ml-3 btn btn-primary btn-sm"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="col-xl-2 order-xl-1 mt-3 ml-3 btn btn-sm btn-light"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </Container>
              </Container>
            </Navbar>
          </div>
        </Container>
      )}
    </>
  );
};

export default TravelPreferences;
