import React from 'react';
import { Box, Typography, Avatar, Grid } from '@mui/material';

// Team members for the first row
const teamMembers = [
  {
    name: 'Alex Gustafson',
    role: 'Founder & CEO',
    highlights: [
      '25+ years in software development and web applications',
      'Founder of Remark Visions, a successful proptech marketplace',
      'Holds multiple software patents in innovative technology solutions',
    ],
    tenure: 'Founded Remark Visions in 2011, over 25k listings serviced and counting',
    image: 'https://res.cloudinary.com/kw/image/upload/v1728517411/oppy/1675806115067.jpg', // Updated Alex's headshot
  },
  {
    name: 'Marci James',
    role: 'Fractional CMO',
    highlights: [
      '20+ years in real estate marketing and branding',
      'Former Director of Marketing at Realtor.com',
      'Thought leader and speaker in social media strategies',
    ],
    image: 'https://res.cloudinary.com/kw/image/upload/v1728517323/oppy/1691957514228.jpg', // Updated Marci's headshot
  },
  {
    name: 'Rudolf Hattenkofer',
    role: 'Fractional CTO',
    highlights: [
      '15+ years in software architecture and leadership',
      'CTO at sell & pick GmbH, overseeing data pipeline architectures',
      'Expert in data engineering, machine learning, and cloud computing',
    ],
    tenure: 'Working with Remark Visions team for over 12 years',
    image: 'https://res.cloudinary.com/kw/image/upload/v1728517323/oppy/1680107319755.jpg', // Updated Rudolf's headshot
  },
];

// Second-row team members
const secondRowMembers = [
  // {
  //   name: 'Eleni Sommerschield',
  //   role: 'Fractional COO',
  //   highlights: [
  //     '14+ years as COO at Wise Agent, a leading real estate CRM',
  //     'Implemented robust operational and financial procedures',
  //     'B.Sc. in Computer Science from DePaul University',
  //   ],
  //   image: 'https://res.cloudinary.com/kw/image/upload/v1728517323/oppy/1554789341340.jpg', // Eleni's photo
  // },
  {
    name: '',
    role: 'Incoming COO',
    highlights: [
      'Almost 15 years as COO at a leading real estate CRM',
      'B.Sc. in Computer Science',
    ],
    image: '', // Eleni's photo
  },
  {
    name: 'Rachel Baca',
    role: 'Client Support Lead',
    highlights: [
      'Nearly 10 years at Remark Visions in client support',
      'Skilled in client relations and problem-solving',
      'Veteran with a strong commitment to service and excellence',
    ],
    tenure: 'Working with Remark Visions team for nearly 10 years',
    image: 'https://res.cloudinary.com/kw/image/upload/v1728517390/oppy/rachel_baca_headshot.jpg', // Updated Rachel's headshot
  },
  {
    name: 'Sierra Martin',
    role: 'Technical Onboarding & Retention Engineer',
    highlights: [
      'Built ML models for data redaction at American Express',
      'Research interned at NASA on self-supervised solar image models',
      'Contributed to Mars rover "Helios" as drivetrain programmer',
    ],
    image: 'https://res.cloudinary.com/kw/image/upload/v1728589170/oppy/1000007464.jpg',
  }
];

const TeamSlide: React.FC = () => {
  return (
    <Box sx={{ height: '100%', padding: 4, color: 'white', textAlign: 'center' }}>
      <Typography variant="h2" sx={{ mb: 6 }}>Our Team</Typography>

      {/* First Row */}
      <Grid container spacing={6} justifyContent="center">
        {teamMembers.map((member, index) => {
          console.log(`Rendering ${member.name} with image URL: ${member.image}`);
          return (
            <Grid item xs={12} sm={4} key={index}>
              <Box>
                <Avatar
                  src={member.image}
                  alt={member.name}
                  sx={{ width: 140, height: 140, margin: '0 auto', mb: 3 }}
                />
                <Typography variant="h4">{member.name}</Typography>
                <Typography variant="h6" sx={{ fontStyle: 'italic', mb: 2 }}>{member.role}</Typography>
                {member.highlights.map((point, idx) => (
                  <Typography key={idx} variant="body1" sx={{ mt: 1 }}>
                    {point}
                  </Typography>
                ))}
                {member.tenure && (
                  <Typography variant="body1" sx={{ mt: 2, color: '#4CAF50' }}>
                    {member.tenure}
                  </Typography>
                )}
              </Box>
            </Grid>
          );
        })}
      </Grid>

      {/* Second Row */}
      <Grid container spacing={6} justifyContent="center" sx={{ mt: 6 }}>
        {secondRowMembers.map((member, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Box>
              <Avatar
                src={member.image}
                alt={member.name}
                sx={{ width: 140, height: 140, margin: '0 auto', mb: 2 }}
              />
              <Typography variant="h5">{member.name}</Typography>
              <Typography variant="subtitle1" sx={{ fontStyle: 'italic', mb: 2 }}>{member.role}</Typography>
              {member.highlights.map((point, idx) => (
                <Typography key={idx} variant="body1" sx={{ mt: 1 }}>
                  {point}
                </Typography>
              ))}
              {member.tenure && (
                <Typography variant="body1" sx={{ mt: 2, color: '#4CAF50' }}>
                  {member.tenure}
                </Typography>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TeamSlide;
