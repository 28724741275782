import { Modal } from 'react-bootstrap';

const LightBox = ({ showView, closeView, child }) => {

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      fullscreen={true}
      show={showView}
      contentClassName="px-lg-3 w-100 bg-black text-white px-lg-0 px-md-0"
      onHide={closeView}
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="bg-dark text-white"
      >
      </Modal.Header>
      <Modal.Body className="pt-0">
        {child}
      </Modal.Body>
    </Modal>
  );
};


export default LightBox;
