import React, { useState } from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';

const SecurityCard = () => {
  const [closeState, setCloseState] = useState(false);

  const toggleState = () => {
    setCloseState(!closeState);
  };
  const popover = (
    <Popover
      id="popover-basic"
      className="bg-dark remove-arrow"
      // positionLeft={200}
      // positionTop={50}
    >
      <Popover.Body className="text-white">
        <div className="float-end btn" onClick={toggleState}>
          <img src="/icon/cross-white.png" alt="" className="me-2" />
        </div>
        <br />
        <div className="text-start">
          <h6>
            <img src="/icon/lock-white.png" alt="" className="me-2" /> Security
          </h6>
          <p>
            Your info will be transferred securely over a 2048-bit encrypted
            connection.
          </p>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <div>
      <OverlayTrigger
        trigger="click"
        placement="bottom-start"
        overlay={popover}
        rootCloseEvent="click"
        show={closeState}
      >
        <span className="bg-secure d-flex fw-medium btn" onClick={toggleState}>
          <img src="/icon/lock.svg" alt="" className="me-2" />
          Secure
          <img src="/icon/down.svg" alt="" className="ms-2" />
        </span>
      </OverlayTrigger>
    </div>
  );
};

export default SecurityCard;
