import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenDialog } from '../../../features/theme/theme.slice';
import ContactsUploader from './ContactsUploader';
import { useAppSelector } from '../../../store'; 

const ContactsUploaderDialog: React.FC = () => {
  const dispatch = useDispatch();
  const openDialog = useAppSelector((state) => state.theme.openDialog);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    console.log('openDialog', openDialog);
    if (openDialog === 'ContactsUploaderDialog') {
      console.log('openDialog', openDialog);
      setOpen(true);
    }
  }, [dispatch, openDialog]);

  const handleClose = () => {
    dispatch(setOpenDialog(''));
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Upload Contacts
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ContactsUploader />
      </DialogContent>
    </Dialog>
  );
};

export default ContactsUploaderDialog;
