// import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { getBranding } from 'src/utils/branding';
const Header = ({ screenWidth, setMenu }) => {
  const threshold = 750;
  const [iconHeight, setIconHeight] = useState(40);
  const [iconWidth, setIconWidth] = useState(40);
  const branding_logo = getBranding('logo');
  useEffect(() => {
    if (screenWidth && screenWidth < threshold) {
      setIconHeight(24);
      setIconWidth(24);
    } else {
      setIconHeight(40);
      setIconWidth(40);
      setMenu(false);
    }
  }, [screenWidth, setMenu]);
  return (
    <Card>
      <Container fluid>
        <Row>
          <Col className="text-start px-4 py-3">
            <img
              src={branding_logo}
              className="h-100"
              alt=""
              height={iconHeight}
              width={iconWidth}
            />
          </Col>
          <Col className="text-end  px-4 py-3">
            {!screenWidth || screenWidth >= threshold ? (
              <div>
                <div className="d-none d-sm-block me-3">
                  <img
                    src="./images/Ellipse4.png"
                    alt=""
                    className="h-50"
                    height={iconHeight}
                    width={iconWidth}
                  />
                  <span className="text-blue-added ml-2">
                    Art Vandelay
                    <img src="./icon/down.svg" alt="" className="ms-2" />
                  </span>
                </div>
                <div className="px-3 d-sm-none"></div>
              </div>
            ) : (
              <div>
              {/*
                <MenuOutlinedIcon onClick={() => setMenu((prev) => !prev)} />
              */}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Card>
  );
};

export default Header;
