import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAppDispatch } from 'src/store';
import { loginWithSms } from 'src/features/auth/auth.slice';
import { useNavigate } from 'react-router-dom';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import { getOnboardingPath } from 'src/libs/utils';
import { PhoneVerification } from 'src/components/PhoneVerification';
import { Box } from '@mui/system';

const PhoneLogin = ({smsLogin, setSmsLogin}) => {
  const [loading, setLoading] = useState<boolean>(false);
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  const login = (phoneNumber, token) => {
    dispatch(loginWithSms({ phone_token: token }))
      .unwrap()
      .then(() => {
        setLoading(false)
        dispatch(trackAnalytics({ action_name: "user_logged_in", payload: { description: `user logged in with phone number ${phoneNumber}` } }))
        navigate('/channels')
      })
      .catch((error) => {
        setLoading(false)
      });
  };

  return (
    <>
      <PhoneVerification
        onPhoneVerification={(phoneNumber, token, isExistingUser) => login(phoneNumber, token)}
        existingUser={true}
      />
      <Box sx={{ mb: 5}}/>
    </>
  );
};

export default PhoneLogin;
