import { httpClient } from 'src/libs';  

const API_URL = `${process.env.REACT_APP_BASE_URL}/api/v3/private/notes`;

const getNotes = (params: any) => {
  console.log('Fetching notes with params:', params);
  return httpClient.get(API_URL, { params });
};

const createNote = (noteData: any) => {
  console.log('Creating note:', noteData);
  return httpClient.post(API_URL, noteData);
};

const updateNote = (noteId: string, noteData: any) => {
  console.log(`Updating note ${noteId}:`, noteData);
  return httpClient.put(`${API_URL}/${noteId}`, noteData);
};

const deleteNote = (noteId: string) => {
  console.log(`Deleting note ${noteId}`);
  return httpClient.delete(`${API_URL}/${noteId}`);
};

export default {
  getNotes,
  createNote,
  updateNote,
  deleteNote,
}; 
