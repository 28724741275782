import { useAppDispatch } from 'src/store';
import { delayTime } from 'src/components/ToastMessage';
import { deletePhotographerServiceImage, setgalleryItems, setServiceImages } from 'src/features/services/photographer.slice';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Col, Image, Row } from 'react-bootstrap';
import GalleryItemUploader from './GalleryItemUploader';
import GalleryItem from './GalleryItem';

const GalleryItemDragAndDrop = ({ setErrorMessage, chunkSize, currentGalleryItems, serviceId, enableDnd, rows, currentService }) => {
  const dispatch = useAppDispatch();

  const setError = () => {
    setErrorMessage("Enable Drag & Drop")
    setTimeout(() => {
    setErrorMessage("")
    }, delayTime)
  }

  const reorder = (e) => {
    let source_row = parseInt(/\d+$/.exec(e.source.droppableId)[0])
    let destination_row = parseInt(/\d+$/.exec(e.destination.droppableId)[0])
    let source_index = ((source_row - 1) * chunkSize) + e.source.index
    let destination_index = ((destination_row - 1) * chunkSize) + e.destination.index

    let tempImages = [...currentGalleryItems]
    const currentImages = [...currentGalleryItems]
    let element = tempImages[source_index];
    tempImages.splice(source_index, 1);
    if (source_row < destination_row)
      tempImages.splice(destination_index - 1, 0, element);
    else
      tempImages.splice(destination_index, 0, element);

    let order = []
    for (let i = 0; i < tempImages.length; i++) order.push({ id: tempImages[i].id, order:  i+1 });

    return [order, currentImages, tempImages]
  }

  const handleImageDelete = async (photoId) => {
    let tempImages = currentGalleryItems.filter((image) => image.id != photoId)
    const currentImages = [...currentGalleryItems]

    dispatch(setServiceImages(tempImages))
    dispatch(deletePhotographerServiceImage({serviceId, photoId}))
      .unwrap().catch(() => dispatch(setServiceImages(currentImages)))
  }

  const handleDragEnd = (e) => {
    if(!enableDnd) {
      setError()
      return
    }
    if(!e.destination){ return }

    const [order, currentImages, tempImages] = reorder(e);

    dispatch(setgalleryItems({serviceId ,order}))
      .unwrap().catch(() => dispatch(setServiceImages(currentImages)))
    dispatch(setServiceImages(tempImages))
  };

  return (
    <Row style={{ position: 'relative' }}>
      <DragDropContext onDragEnd={(results) => {
        handleDragEnd(results)
      }}>
        {rows.map((chunk, key)=> (
          <Droppable droppableId={`droppable-${key+1}`} direction="horizontal" key={key}>
            {
              (provider) => (
              <Row ref={provider.innerRef} {...provider.droppableProps}>
                {chunk.map((image, index)=> (
                  <Draggable disableInteractiveElementBlocking key={`image-${image.id}`} draggableId={`image-${image.id}`} index={index}>
                    {
                      (provider) => (
                      <Col sm={6} md={4} className="mt-3"  ref={provider.innerRef} {...provider.draggableProps} {...provider.dragHandleProps}>
                        <div className="d-flex item gallery-list h-100">
                          <GalleryItem item={image} />
                          <div>
                            {image.id > 0 && <div
                              onClick={() => handleImageDelete(image.id)}
                              className="delete-image"
                            >
                              <Image
                                src="/icon/addImageIcon.svg"
                                className="text-blue-added"
                              />
                            </div>}
                            {image.id > 0 && image.itemable_type === "EmbedCode" && <div className='drag-n-drop-icon'>
                              <Image
                                src="/icon/enableDnDIcon.svg"
                                className="text-blue-added"
                              />
                            </div>}
                          </div>
                        </div>
                      </Col>
                      )
                    }
                  </Draggable>
                ))}
              {provider.placeholder}
              </Row>
              )
            }
          </Droppable>
        ))}
      </DragDropContext>
      <Col sm={6} md={4} lg={4} className="mt-3">
        <GalleryItemUploader
          currentService={currentService}
          currentGalleryItems={currentGalleryItems}
          serviceId={serviceId}
        />
      </Col>
    </Row>
  );
};

export default GalleryItemDragAndDrop;
