import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Container, Navbar, Badge } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { trackBookingAnalytics } from 'src/features/analytics/analytics.slice';
import { finalizeInvoice, getBooking, getBookingSettings, getInvoices, sendCancelationEmail, getProviderSubscription } from 'src/features/services/booking.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import ErrorPopup from '../ErrorPopup';
import CancelBookingConfirmation from '../ExitConfirmation/CancelBookingConfirmation';
import Loading from '../Loading';
import SuccessPopup from '../SuccessPopup';
import DocumentMeta from 'react-document-meta';
import CustomMap from './CustomMap';
import useAuth from 'src/hooks/useAuth';
import GalleryV2 from '../GalleryV2/GalleryV2';
import SuggestedServices from '../BookingConfirmation/SuggestedServices';
import { enableDarkMode, enableLightMode } from 'src/features/theme/theme.slice';
import Markdown from 'markdown-to-jsx';
import { getBranding } from 'src/utils/branding';
const BookingConfirmation = ({}) => {
  const { isLoggedIn } = useAuth();
  const { user } = useAppSelector((state) => state.user);
  const bgColor = {"Scheduled": "bg-green", "Pending": "bg-yellow", "Cancelled": "bg-red", "Finished": "bg-green", "No-Show": "bg-red", "Rescheduled": "bg-yellow"}
  const [emailConfirmationMessage, setEmailConfirmationMessage] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const strftime = require('strftime')
  const { isLoading, booking, bookingSettings, bookingError, invoices } = useAppSelector((state) => state.bookingServices);
  const showInMapClicked = (formatted_address) => {
    window.open(`https://maps.google.com?q=${formatted_address}` );
  };
  const [coppiedUrl, setCoppiedUrl] = useState(false)
  const { subscriptionFeatures } = useAppSelector((state) => state.bookingServices);
  const branding_logo = getBranding('logo');
  const currentUrl = window.location.href
  const dispatch = useAppDispatch();
  let { uuid } = useParams();

  useEffect(() => {
    dispatch(enableLightMode())
    return () => {
      dispatch(enableDarkMode())
    }
  }, [])

  const copyUrl = (text) => {
    navigator.clipboard.writeText(text)
    setCoppiedUrl(true)
    setTimeout(() => {
      setCoppiedUrl(false)
    }, 3000);
  }

  const sendFinalizeInvoice = () => {
    dispatch(finalizeInvoice({
      booking_uuid: booking.uuid
    })).unwrap().then(() => window.location.reload())
  }

  useEffect(() => {
    dispatch(getBooking(uuid)).unwrap()
      .then((response)=> {
        dispatch(getBookingSettings(response.photographer.uuid));
        if( user && !response.paid && response.photographer.uuid === user.uuid ){
          dispatch(getInvoices({ booking_uuid: uuid }));
        }
        if (!subscriptionFeatures){
          dispatch(getProviderSubscription({uuid: response.photographer.uuid}));
        }
      });
  }, []);

  const isPaid = !!booking?.paid;

  const handleCancelBooking = () => {
    dispatch(sendCancelationEmail(uuid)).unwrap().then((response) => {
      dispatch(trackBookingAnalytics({action_name: "cancel_booking_initiated", payload: { description: `user initiated cancel booking with uuid ${uuid}`, booking_uuid: uuid}}))
      setEmailConfirmationMessage(response.message)
    })
    setShowCancelConfirmation(false);
  }

  if(bookingError && !booking){
    return <p className='text-danger text-center'>{bookingError}</p>
  }

  if(!booking){
    return <Loading/>
  }

  const cancelBooking = () => {
    setShowCancelConfirmation(true)
  }

  const handleGoBack = () => {
    setShowCancelConfirmation(false);
  };

  const service_names_string = () => {
    let services_name = []
    booking?.services.map((service) => (services_name.push(service?.name)))

    return services_name.join(', ')
  };

  const meta = {
    title: `Booking: ${booking?.formatted_address}`,
    description: `${booking?.photographer?.first_name} ${booking?.photographer?.last_name} is looking forward to seeing you on ${strftime("%m/%d @ %H:%M",new Date(booking?.chosen_time))}. Easily view, manage and share your appointment for ${service_names_string()} here! Booking automation by Oppy.pro`,
    meta: {
      itemProp: {
        name: `Booking: ${booking?.formatted_address}`,
        description: `${booking?.photographer?.first_name} ${booking?.photographer?.last_name} is looking forward to seeing you on ${strftime("%m/%d @ %H:%M",new Date(booking?.chosen_time))}. Easily view, manage and share your appointment for ${service_names_string()} here! Booking automation by Oppy.pro`,
        image: booking?.photographer?.photo || {branding_logo},
      },
      property: {
        'og:title': `Booking: ${booking?.formatted_address}`,
        'og:description': `${booking?.photographer?.first_name} ${booking?.photographer?.last_name} is looking forward to seeing you on ${strftime("%m/%d @ %H:%M",new Date(booking?.chosen_time))}. Easily view, manage and share your appointment for ${service_names_string()} here! Booking automation by Oppy.pro`,
        'og:image': booking?.photographer?.photo || {branding_logo},
      }
    },
  }

  const showCollectPaymentButton = () => (
    isLoggedIn &&
      (user.role >= 10 || booking?.photographer?.uuid === user?.uuid) &&
      !isPaid &&
      booking?.payment_collection_type !== 'never_charge' &&
      subscriptionFeatures?.invoicing_paywalls_and_watermarks?.permitted
  );

  const showInvoices = () => (
    isLoggedIn &&
      booking?.photographer?.uuid === user?.uuid &&
      !isPaid &&
      booking?.payment_collection_type !== 'never_charge' &&
      subscriptionFeatures?.invoicing_paywalls_and_watermarks?.permitted
  );

  return (
    <>
      <DocumentMeta {...meta}/>
      {bookingError && bookingError.indexOf("No such invoice") === -1 && <ErrorPopup message={bookingError} />}
      <CancelBookingConfirmation
        showExitConfirmation={showCancelConfirmation}
        handleGoBack={handleGoBack}
        handleCancelBooking={handleCancelBooking}
      />
        {emailConfirmationMessage && <SuccessPopup message={emailConfirmationMessage}/>}
        <div className="container booking-container px-lg-5 bg-light">
          <div className='top-detail-wrapper'>
            <div className="contact-card-item">
              <div className='icon-container'>
                <img
                    src={booking?.photographer?.photo}
                    alt=""
                    className="avatar--round"
                  />
              </div>
              <div className="info-container">
                <span className="fs-16 black-medium text-truncate">
                  {booking?.photographer?.first_name + " " + booking?.photographer?.last_name}
                </span>
                <a className="fs-14 grey" href={`tel:${booking?.photographer?.contact_phone}`}>{booking?.photographer?.contact_phone}</a>
                <a className="fs-14 grey" href={`mailto:${booking?.photographer?.email}?subject=${encodeURIComponent("Booking @ " + booking.formatted_address)}`}>{booking?.photographer?.email}</a>
              </div>
            </div>

            <div className="text-lg-end booking-status-wrapper">
              <div className="mb-1">
                <span className={`${bgColor[booking?.status]} fw-medium rounded-pill`}>
                  <b className="status">.</b> {booking?.status}
                </span>
              </div>
              <span className="mb-1 fw-medium fs-18">Booking {booking?.status}</span>
              <p className="fw-medium-light fs-14 text-muted booking-time-wrapper">
                <span>{booking?.chosen_time}</span>
                <br></br>
                <span>Length: {booking?.estimated_duration} Minutes</span>
              </p>
            </div>
          </div>
          <Container>
          <Row className="booking-cards-wrapper">
            <Card className="border-0 booking-confirmation-card address-details">
              <Row className="mt-2 fs-14 fw-medium">
                <Col className="text-start">
                  <Card.Title>{booking?.formatted_address}</Card.Title>
                  {booking?.area && <p>Square Feet: {booking?.area} {booking?.listing_price ? `• $${booking?.listing_price} Listing Price` : ''}</p>}
                </Col>

                <Col xs lg="5" md="5" className="text-end">
                  <p>
                    <a onClick={() => showInMapClicked(booking?.formatted_address)} href="#">Open in maps</a>
                  </p>
                </Col>
              </Row>
              <div className='map-wrapper' style={{ height: '200px' }}>
                <CustomMap
                    address={booking?.formatted_address}
                  />
              </div>
            </Card>
            { bookingSettings?.confirmation_video_url &&
              <Card className="border-0 booking-confirmation-card" >
                <iframe width="100%" height="315"
                  src={bookingSettings?.confirmation_video_url}
                >
                </iframe>
              </Card>
            }

            {bookingSettings?.confirmation_message &&
                <Card className="border-0 booking-confirmation-card">
                  <Card.Body>
                    <Card.Title className="mb-2 text-muted fs-14 fw-medium">
                      What’s next?
                    </Card.Title>
                    <Card.Text>
                      {bookingSettings?.confirmation_message}
                    </Card.Text>
                  </Card.Body>
                </Card>
            }

              <Card className="border-0 booking-confirmation-card">
                <Card.Body>
                  <Card.Title>
                    Chosen Services:
                  </Card.Title>
                  <div className='services-list'>
                    {booking?.services.map((service, index) => (
                      <div key={index} className="services-list-item">
                        <div className='icon-container'>
                          <img
                              src={service.icon}
                              alt=""
                              className="p-2 small-icon"
                            />
                        </div>
                        <div className='services-list-columns'>
                          <div className='list-left-column'>
                            <div className="title-container">
                              <span className="fs-14 black-medium text-truncate">
                                {service.name}
                              </span>
                              <span className="fs-12 grey">{service.tagline}</span>
                            </div>
                          </div>
                          <div className="list-right-column fs-14 grey">
                            {booking?.estimated_duration && <p>Duration: {booking?.estimated_duration} minutes</p> }
                            <p>Turnaround: {service?.turnaround_time_string}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Card.Subtitle className="mb-2 text-muted">
                    Booking Total: <b className='me-4'>$ {booking?.price}</b>
                    { showCollectPaymentButton() &&
                      <Button disabled={isLoading} variant="primary mb-3 my-2" onClick={sendFinalizeInvoice}>
                        Collect Payment
                      </Button>
                    }
                    { isPaid && <Badge bg='success'>Paid</Badge> }
                  </Card.Subtitle>

                  {showInvoices() && invoices.map((invoice, index) => (
                    <p key={`invoice-${index}`}><a href={invoice.invoice_link} target="_blank" rel="noreferrer">Manage Draft Invoice</a> <Badge bg="info">{invoice.status}</Badge></p>
                  ))}
                </Card.Body>
                {booking?.status === "Scheduled" && !booking.paid &&
                  <>
                    <h6 className='text-muted'>You might like</h6>
                    <Card.Footer>
                      <Col xs={12}>
                        <SuggestedServices />
                      </Col>
                    </Card.Footer>
                  </>
                }
              </Card>
              <Card className="border-0 booking-confirmation-card share-booking-card">
                <Card.Body>
                  <p className="fw-medium fs-18 mb-3">
                    <img src="/icon/share.png" alt="" className="me-2" />
                    Share booking
                  </p>

                  <Card.Text className="bg-light">
                    <div className="fw-medium-light text-muted py-1 rounded-1" style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}} onClick={() => {copyUrl(currentUrl)}}>
                      {coppiedUrl ? (<img src="/icon/clipboard-check-fill.svg" />) : (<img src="/icon/clipboard.svg" />)}
                      Copy Link
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
              {booking?.client_notes &&
                <Card className="border-0 booking-confirmation-card">
                  <Card.Body>
                    <Card.Title className="mb-2 ">
                      Appointment Notes:
                    </Card.Title>
                    <Card.Text className='fs-14'>
                      {booking?.client_notes ? booking?.client_notes : 'No notes'}
                    </Card.Text>
                  </Card.Body>
                </Card>
              }

              {bookingSettings?.cancellation_policy &&
                <Card className="border-0 booking-confirmation-card">
                  <Card.Title className="mb-2 text-muted">
                    Cancellation Policy:
                  </Card.Title>
                  <Card.Text>
                    <Markdown>{bookingSettings?.cancellation_policy}</Markdown>
                  </Card.Text>
                </Card>
              }
              { booking?.status === "Scheduled"? <Button
                className="cancel-button add-btn fw-medium px-3"
                size="sm"
                variant="outline-danger"
                onClick={cancelBooking}
              >
                Cancel Appointment
              </Button> : "" }

          </Row>
          </Container>
        </div>
        {subscriptionFeatures?.storage_management_and_delivery?.permitted && (
         <GalleryV2 galleriableType='Booking' galleriableId={uuid} authorizedToPay={showCollectPaymentButton()} sendFinalizeInvoice={sendFinalizeInvoice} isPaid={ isPaid || booking.payment_collection_type === "never_charge" } /> )
        }
        <div className="fs-14 d-sm-block mt-5 remark-label powered-by-oppy">
          Powered by
          <img src={branding_logo} className="me-2" alt="" />
        </div>
    </>
  );
};

export default BookingConfirmation;
