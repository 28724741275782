import { createAsyncThunk, createSlice, PayloadAction, createEntityAdapter } from '@reduxjs/toolkit';
import { serializeErrorMessage } from 'src/utils';
import ContactsService from 'src/services/contacts.services'; // Assuming there's a service file for contacts
import { enqueueSnackbar } from 'notistack';
import { RootState } from 'src/store';
import { debounce } from 'lodash';

export interface Contact {
  id?: string;
  channel_id?: string;
  name?: string;
  phone_number?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  address?: string;
  notes?: string;
  metadata?: any; // JSON type
  picture_url?: string;
  status?: string;
  preferred_contact_method?: string;
  company_name?: string;
  company_size?: number;
  company_industry?: string;
  company_website?: string;
  company_address?: string;
  home_address?: string;
  vector_db_status?: string;
  tags?: string[];
  limit?: number;
  last_contacted_at_before?: string; // Changed from Date to string
  created_at_before?: string;        // Changed from Date to string
  last_contacted_at_after?: string;  // Changed from Date to string
  created_at_after?: string;         // Changed from Date to string
  loading: boolean;
  error: string | null;
  account_id?: string;
  created_at?: Date;
  updated_at?: Date;
  last_contacted?: Date;
  timezone?: string;
  locality?: string;
  zip?: string;
  state?: string;
  country_code?: string;
  street_address_1?: string;
  street_address_2?: string;
  score?: number;
  stage?: string;
  assigned_to?: string;
  position?: number;
  deal_size?: number;
  shared_notes?: string;
  admin_notes?: string;
}

export const contactsAdapter = createEntityAdapter<Contact>({
  selectId: (contact) => contact.id,
  sortComparer: (a, b) => {
    // Convert date strings to Date objects for comparison
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateA.getTime() - dateB.getTime(); // Sort by most recently updated first
  },
});

const initialState = contactsAdapter.getInitialState({
  contactsLoading: false,
  error: null,
  initialLoad: false,
  totalContacts: 0,
  earliestContactCreatedAtDateTime: new Date().toISOString() // Convert Date to ISO string
});

export const getContacts = createAsyncThunk(
  // gets all contacts with pagination and filters
  'contacts/get',
  async (queryParams: Partial<Contact> = {}, thunkAPI) => {
    try {
      const response = await ContactsService.fetch_contacts(queryParams);
      if (response.data.success) {
        return {
          contacts: response.data.contacts,
          totalContacts: response.data.total_count,
          hasMore: response.data.has_more
        };
      } else {
        return thunkAPI.rejectWithValue('Failed to fetch contacts');
      }
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const getContact = createAsyncThunk(
  // gets a single contact by id
  'contacts/getContact', // Ensure the action type string is unique and correctly used in the reducer
  async (contactId: string, thunkAPI) => {
    try {
      const response = await ContactsService.show(contactId);
      if (response.data.success && response.data.contact) { // Ensure the correct data structure is accessed
        return response.data.contact; // Return a single contact object
      } else {
        return thunkAPI.rejectWithValue('No contacts found');
      }
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const updateContact = createAsyncThunk(
  'contacts/update',
  async (contactData: Partial<Contact>, thunkAPI) => {
    try {
      const response = await ContactsService.update(contactData);
      return response.data.contact;
    } catch (error) {
      enqueueSnackbar('Error updating contact', { variant: 'error' });
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const deleteContact = createAsyncThunk(
  'contacts/delete',
  async (contactId: string, thunkAPI) => {
    try {
      const response = await ContactsService.destroy(contactId);
      return response.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const createContact = createAsyncThunk(
  'contacts/create',
  async (contactData: Partial<Contact>, thunkAPI) => {
    try {
      const response = await ContactsService.create(contactData);
      return response.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)


// Exported function to reorder contact
export const reorderContactState = (id: string, position: number, stage: string) => (dispatch: any) => {
  // Dispatch updateContact with the new position and stage
  dispatch(updateContact({ id, position, stage }));
  console.log(`Reordered contact ${id} to position ${position} in stage ${stage}`);
};

const contactsSlice = createSlice({
  name: 'contacts',
  initialState: initialState,
  reducers: {
    updateContactState: contactsAdapter.upsertOne,
    removeContactState: contactsAdapter.removeOne,
  },
  extraReducers: builder => {
    builder
      .addCase(createContact.fulfilled, (state, action) => {
        contactsAdapter.addOne(state, action.payload);
        state.contactsLoading = false;
        state.error = null;
      })
      .addCase(createContact.pending, (state, action) => {
        state.contactsLoading = true;
        state.error = null;
      })
      .addCase(createContact.rejected, (state, action) => {
        state.contactsLoading = false;
        state.error = action.payload as string;
        enqueueSnackbar('Error creating contact', { variant: 'error' });
      })
      .addCase(getContacts.pending, (state, action) => {
        state.contactsLoading = true;
        state.error = null;
        state.initialLoad = true;
      })
      .addCase(getContacts.fulfilled, (state, action) => {
        // Only update if there are new or changed contacts
        const existingContacts = contactsAdapter.getSelectors().selectAll(state);
        const newContacts = action.payload.contacts;
        const isDifferent = newContacts.some(newContact => {
          return !existingContacts.some(existing => existing.id === newContact.id && existing.updated_at === newContact.updated_at);
        });

        if (isDifferent) {
          const normalizedContacts = newContacts.map(contact => ({
            ...contact,
            name: contact.name ?? '',
            phone_number: contact.phone_number ?? '',
            email: contact.email ?? '',
            first_name: contact.first_name ?? '',
            last_name: contact.last_name ?? '',
          }));
          contactsAdapter.upsertMany(state, normalizedContacts);
          state.totalContacts = action.payload.totalContacts;
          state.earliestContactCreatedAtDateTime = newContacts.reduce((earliest, contact) => {
            const contactDate = new Date(contact.created_at).toISOString();
            return earliest > contactDate ? contactDate : earliest;
          }, state.earliestContactCreatedAtDateTime);
        }
        state.contactsLoading = false;
        state.error = null;
        state.initialLoad = true;
      })
      .addCase(getContacts.rejected, (state, action) => {
        state.contactsLoading = false;
        state.error = action.payload as string;
        state.initialLoad = false;
      })
      .addCase(getContact.fulfilled, (state, action) => {
        const contact = action.payload;
        const normalizedContact = {
          ...contact,
          name: contact.name ?? '',
          phone_number: contact.phone_number ?? '',
          email: contact.email ?? '',
          first_name: contact.first_name ?? '',
          last_name: contact.last_name ?? '',
        };
        contactsAdapter.upsertOne(state, normalizedContact);
        state.contactsLoading = false;
      })
      .addCase(getContact.pending, (state) => {
        state.contactsLoading = true;
        state.error = null;
      })
      .addCase(getContact.rejected, (state, action) => {
        state.contactsLoading = false;
        state.error = action.payload as string;
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        contactsAdapter.upsertOne(state, action.payload);
        state.error = null;
        state.contactsLoading = false;
      })
      .addCase(updateContact.pending, (state, action) => {
        state.contactsLoading = true;
      })
      .addCase(updateContact.rejected, (state, action) => {
        state.contactsLoading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteContact.pending, (state, action) => {
        contactsAdapter.removeOne(state, action.meta.arg); // Remove contact immediately
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        state.error = null;
      })
      .addCase(deleteContact.rejected, (state, action) => {
        const contactId = action.meta.arg as string; // Ensure contactId is a string
        const contact = state.entities[contactId]; // Retrieve the contact from state
        if (contact) {
          contactsAdapter.upsertOne(state, contact); // Add contact back if deletion fails
        }
        state.error = action.payload as string;
        enqueueSnackbar('Error deleting contact', { variant: 'error' });
      })
  },
});

export const {
  updateContactState,
  removeContactState,
} = contactsSlice.actions;
export const { selectById: selectContactById, selectAll: selectAllContacts } = contactsAdapter.getSelectors((state: RootState) => state.contacts);
export default contactsSlice.reducer;
