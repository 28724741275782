export const GreenLeaf = ({ }) => {
  return (
    <svg
      className="svg-icon mini-icon my-auto"
      style={{ color: "green", verticalAlign: "middle", fill: "currentColor", overflow: "hidden" }}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M731.428571 402.285714q0-14.848-10.825143-25.746286t-25.746286-10.825143q-98.304 0-181.686857 28.306286t-148.260571 76.580571-134.582857 125.44q-10.825143 11.995429-10.825143 25.746286 0 14.848 10.825143 25.746286t25.746286 10.825143q13.677714 0 25.746286-10.825143 15.433143-13.677714 42.276571-40.594286t38.253714-37.741714q78.262857-70.875429 153.453714-100.571429t179.126857-29.696q14.848 0 25.746286-10.825143t10.825143-25.746286zM1024 289.133714q0 54.272-11.410286 110.299429-26.258286 128-105.398857 218.843429t-204.288 153.161143q-122.294857 61.732571-250.294857 61.732571-84.553143 0-163.401143-26.843429-8.557714-2.852571-50.322286-23.990857t-54.857143-21.138286q-9.142857 0-22.601143 18.285714t-25.746286 40.009143-29.988571 40.009143-34.304 18.285714q-17.115429 0-29.110857-6.290286t-17.700571-13.677714-15.433143-23.990857q-1.170286-2.267429-3.437714-6.290286t-3.145143-5.705143-1.682286-5.412571-0.877714-7.68q0-19.968 17.700571-41.984t38.838857-37.449143 38.838857-32.036571 17.700571-27.428571q0-2.267429-7.972571-21.723429t-9.142857-25.161143q-5.12-29.110857-5.12-59.465143 0-65.682286 24.868571-125.732571t68.022857-105.398857 97.426286-79.433143 116.589714-54.564571q31.451429-10.313143 82.870857-14.555429t102.546286-5.12 102.034286-3.437714 93.403429-13.677714 64.877714-32.256l16.822857-16.822857t16.822857-16.018286 15.433143-11.410286 20.845714-9.142857 24.868571-2.56q22.308571 0 40.301714 26.258286t27.136 64 13.677714 70.875429 4.534857 54.857143z"
      />
    </svg>
  );
};
