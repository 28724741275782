const getOrdinalSuffix = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) return i + "st";
  if (j == 2 && k != 12) return i + "nd";
  if (j == 3 && k != 13) return i + "rd";
  return i + "th";
};

const today = new Date();
today.setHours(0, 0, 0, 0);

const differenceInDays = (dateTime) => {
  dateTime.setHours(0, 0, 0, 0);

  return (today.getTime() - dateTime.getTime()) / (1000 * 60 * 60 * 24);
}

export const dateTimeFormatter = (dateString, format = 'time_only') => {

  const dateTime = new Date(dateString);
  const time_only = dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).replace(/^0+/, '');

  const day = dateTime.getDate();

  switch (format) {
    case 'time_only':
      return time_only[0] === ':' ? '00' + time_only : time_only

    case 'date_only':
      return dateTime.toLocaleDateString();

    case 'date_time':
      return dateTime.toLocaleString();

    case 'day_month_ordinal_humanized':
      switch (differenceInDays(dateTime)) {
        case -1:
          return 'Tomorrow';
        case 0:
          return 'Today'
        case 1:
          return 'Yesterday'
        default:
          const dayValue = getOrdinalSuffix(dateTime.getDate());
          const month = dateTime.toLocaleString('default', { month: 'short' });

          const day_month_ordinal = `${month} ${dayValue}`;
          return day_month_ordinal;
      }
    case 'day_month_ordinal_humanized_time_period':
      switch (differenceInDays(dateTime)) {
        case -1:
          return `Tomorrow at ${time_only}`;
        case 0:
          return `Today at ${time_only}`;
        case 1:
          return `Yesterday at ${time_only}`;
        default:
          const dayValue = getOrdinalSuffix(dateTime.getDate());
          const month = dateTime.toLocaleString('default', { month: 'short' });

          const day_month_ordinal = `${month} ${dayValue}`;
          return `${day_month_ordinal} at ${time_only}`;
      }
    
    case 'humanized_time_period':
      switch (differenceInDays(dateTime)) {
        case 0:
          return 'Today'
        case 1:
          return 'Yesterday'
        case 2: case 3: case 4: case 5: case 6: case 7:
          return 'Last 7 Days'
        default:
          return 'Older'
      }

    case 'day_month_ordinal':
      let suffix = 'th';
      if (day % 10 === 1 && day !== 11) {
        suffix = 'st';
      } else if (day % 10 === 2 && day !== 12) {
        suffix = 'nd';
      } else if (day % 10 === 3 && day !== 13) {
        suffix = 'rd';
      }

      const formatter = new Intl.DateTimeFormat('en-US', { weekday: 'short' });
      const monthFormatter = new Intl.DateTimeFormat('en-US', { month: 'short' });
      return `${formatter.format(dateTime)}, ${monthFormatter.format(dateTime)} ${day}${suffix}`;

    default:
      return dateTime.toLocaleString();
  }
}
