import { Formik } from "formik";
import { useState } from "react";
import { Form, Button, Modal } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import { updateEmail } from "src/features/user/user.slice";
import { useAppDispatch, useAppSelector } from "src/store";
import { UpdateEmailSchema } from "src/utils/formSchemas";
import ErrorPopup from "../ErrorPopup";
import SuccessPopup from "../SuccessPopup";
import { delayTime } from "../ToastMessage";

const UpdateEmail = () => {
  const dispatch = useAppDispatch();
  const { email_token } = useParams();
  const { userError, userLoading } = useAppSelector((state) => state.user);
  const [successMessage, setSuccessMessage] = useState("")

  const initialValues = {
    email: '',
  };

  const handleUpdateEmail = (data) => {
    dispatch(updateEmail({email_token, email: data.email})).unwrap().then((response) => {
      setSuccessMessage(response.response.message)
    })
  }

  return (
    <>
      <Modal
        show={true}
        fullscreen={'sm-down'}
        keyboard={false}
        centered
        contentClassName="medium-width"
      >
        <Modal.Header>
        {userError && <ErrorPopup message={userError}/>}
        {successMessage && <SuccessPopup message={successMessage}/>}
        </Modal.Header>
        <Modal.Body>
          {!successMessage? <div className="d-flex flex-column align-items-center text-center">
            <p className="fs-4 px-3 fw-medium lh-sm">Add New Email</p>
            <div className='w-100'>
              <Formik
                initialValues={initialValues}
                validationSchema={UpdateEmailSchema}
                onSubmit={handleUpdateEmail}
                enableReinitialize
              >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                resetForm,
                values,
                touched,
                errors,
              }) => {
                return (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group>
                    <Form.Control
                      value={values.email}
                      id="email"
                      name="email"
                      type="text"
                      className="mb-4"
                      onChange={handleChange}
                      isValid={touched.email && !errors.email}
                      isInvalid={touched.email && !!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      <>{errors.email}</>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button
                    className="w-100 mb-3"
                    variant="primary"
                    type="submit"
                    disabled={userLoading || successMessage != ''}
                  >
                    <span className="fw-medium">Save Email</span>
                  </Button>
                </Form>
              )}}
              </Formik>
            </div>
          </div> : <div>
            Your email is updated! Please close this window.
          </div>}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdateEmail;
