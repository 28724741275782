import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export default function TestTimeModal({ showView, closeView }) {
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={showView}
      onHide={closeView}
      centered
      contentClassName="large-width px-4"
      fullscreen={'sm-down'}
    >
      <Modal.Header closeButton className="fs-32 fw-medium">
        Test Time
      </Modal.Header>

      <Modal.Body className="pt-0">
        <div className="fs-14 ">
          Add the property dimension below to test the time of the service withs
          the current settings.
        </div>
        <div className="row pt-5 p-3">
          <div className="col-12 col">
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control ht-48"
                aria-label="Amount (to the nearest dollar)"
                placeholder="Square feet (required)"
              />
            </div>
          </div>
          <div className="col-12 col">
            <Button className="col-12 mb-1 mt-2">Test Time</Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
