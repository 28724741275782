import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import LiveTranscriptionComponent from './LiveTranscriptionComponent';

const LiveTranscriptionDialog = ({ open, onClose, latestTranscriptionChunk }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>Live Transcription</DialogTitle>
      <DialogContent>
        <LiveTranscriptionComponent latestTranscriptionChunk={latestTranscriptionChunk} />
      </DialogContent>
    </Dialog>
  );
};

export default LiveTranscriptionDialog;
