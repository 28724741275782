import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, Select, MenuItem, Switch, TextField, CircularProgress, FormControlLabel, Snackbar, TextareaAutosize, FormGroup, Typography, Card, CardHeader, CardContent, CardMedia, InputAdornment, IconButton, Alert, Tooltip, Chip, Accordion, AccordionDetails, AccordionSummary, Divider, useMediaQuery, useTheme, List, ListItemText, ListItem, ListSubheader, Grid, Box } from '@mui/material';
import getRandomName from './RandomDefaultName';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import { deleteChannel, updateBccAndFallbackPhone, updateChannel } from 'src/features/account/channels.slice';
import { useAppDispatch } from 'src/store';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import { useAppSelector } from 'src/store';
import CalendarDialog from '../CalendarDialog';
import { useNavigate } from 'react-router-dom';
import { checkUserCalendar } from 'src/features/user/user.slice';
import { networkError } from 'src/libs/utils';
import ChannelPhoneValidator from './ChannelPhoneValidator';
import SettingsIcon from '@mui/icons-material/Settings';
import { getBranding } from 'src/utils/branding';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import SchoolIcon from '@mui/icons-material/School';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import UrlInput from './embedKeyAvailabilityChecker';
import { useSnackbar } from 'notistack';
import ChannelSystemMessageDialog from './ChannelSystemMessageEditDialog';
import PagesDialog from './PagesDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import ChannelDeleteDialog from './ChannelDeleteDialog';
import MarkdownEditor from '../MarkdownEditor';


const ChannelEditDialog = ({ open, handleClose, handleBack, channelId }) => {
  const channel = useAppSelector((state) => state.channels.channels.find(channel => channel.id === channelId));
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const [isPhoneVerified, setIsPhoneVerified] = useState(channel.config?.fallback_phone_number ? true : false);
  const [validatedPhoneNumber, setValidatedPhone] = useState(channel.config?.fallback_phone_number || '');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [CalendarDialogOpen, setCalendarDialogOpen] = useState(false);
  const [system_message, setSystemMessage] = useState('');
  const [validatePhoneOpen, setValidatePhoneOpen] = useState(false);
  const [expanded, setExpanded] = useState('');
  const [bookingEnabled, setBookingEnabled] = useState(channel.config?.booking_enabled || false);
  const theme = useTheme();
  const [pages, setPages] = useState((channel.config?.pages && channel.config.pages.length > 0 && channel.config.pages) || [{ tracking_id: '', page_url: '' }]);
  const fullScreen = true;
  const logo = getBranding('oppy_expressions_smiling')
  const [urlValid, setUrlValid] = useState(false);
  const [smsNotificationsEnabled, setSmsNotificationsEnabled] = useState(channel.config?.sms_notifications_enabled || false);
  const { enqueueSnackbar } = useSnackbar();
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [addressRequiredForScheduling, setAddressRequiredForScheduling] = useState(channel.config?.address_required_for_scheduling || false);

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const setDefaultGoals = () => {
    if (channel.config?.goals) {
      if (channel.config.goals.length > 0) {
        return channel.config.goals
      } else {
        return [
          "",
        ]
      }
    } else if (bookingEnabled) {
      return [
        "Get user's name, email, and preferred time for appointment.",
        "Check calendar for availability within user's suggested time range.",
        "Book the appointment at an available time slot or offer alternatives.",
      ]
    } else {
      return [
        "Capture Email, Name & Phone Number",
        "Answer user's questions about your business and ask them questions to qualify them",
        "Share social channels for them to stay connected"
      ]
    }
  }

  useEffect(() => {
    if (open) {
      setLoading(false);
      setError(null);
    }
  }, [open]);

  const [formValues, setFormValues] = useState({
    chatbot_name: (channel.config?.chatbot_name ? channel.config.chatbot_name : getRandomName()),
    chatbot_personality: (channel.config?.chatbot_personality ? channel.config.chatbot_personality : "Professional"),
    use_emojis: (channel.config?.use_emojis ? channel.config.use_emojis : true),
    goals: setDefaultGoals(),
    business_website_url: channel.config?.business_website_url || "",
    knowledge_base: channel.config?.knowledge_base || "",
    system_message: channel.config.system_message || system_message || "",
    fallback_phone_number: (channel.config?.fallback_phone_number),
    sms_notifications_enabled: channel.config?.sms_notifications_enabled || false,
    company_name: channel.config?.company_name || "",
    chatbot_style: channel.config?.chatbot_style || "2",
    embed_key: channel?.embed_key || "",
    flow: channel?.flow || "basic_booking_function_flow"
  });

  useEffect(() => {
    dispatch(trackAnalytics({ action_name: "user_opened_basic_channel_create", payload: { description: `user clicked basic channel create` } }))
  }, [])

  useEffect(() => {
    dispatch(checkUserCalendar()).unwrap().catch((error) => {
      if (error != networkError)
        setBookingEnabled(false)
    })
  }, []);

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleToggle = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.checked });
    dispatch(trackAnalytics({ action_name: "user_toggled_input_basic_channel_create", payload: { description: `user toggled ${e.target.name} to ${e.target.checked}`, target_name: e.target.name, target_value: e.target.value } }))
    console.warn(formValues)
  }

  const handleChangeGoal = (value, index) => {
    let newArr = [...formValues.goals];
    newArr[index] = value;
    setFormValues({ ...formValues, goals: newArr });
  }

  const handleAddGoal = (index) => {
    let newArr = [...formValues.goals];
    newArr.splice(index + 1, 0, "");
    setFormValues({ ...formValues, goals: newArr });
  }

  const handleRemoveGoal = (index) => {
    if (formValues.goals.length === 1) {
      return;
    }
    const newArr = [...formValues.goals];
    newArr.splice(index, 1);
    setFormValues({ ...formValues, goals: newArr });
  }

  const handlePostChannel = () => {
    setLoading(true);
    handleUpdateBasicChatBot();
  };

  const handlePagesUpdate = (updatedPages) => {
    dispatch(trackAnalytics({ action_name: "user_updated_pages", payload: { description: `user updated pages` } }))
    dispatch(updateChannel({ channelData: { id: channel.id, config: { ...channel.config, pages: updatedPages } } }))
    setPages(updatedPages);
  };

  const sanitizedPages = (incomingPages) => {
    let pagesContainingUrl = incomingPages.filter(page => page.page_url !== '');
    let filteredPages = pagesContainingUrl.filter(page => page.tracking_id !== '');
    return filteredPages;
  }

  const handleUpdateBasicChatBot = async () => {

    const config = {
      chatbot_name: formValues.chatbot_name,
      provider_frontend_url: `${process.env.REACT_APP_BASE_URL}`,
      system_message: system_message,
      style: formValues.chatbot_style,
      pages: sanitizedPages(pages),
      chatbot_personality: formValues.chatbot_personality,
      use_emojis: formValues.use_emojis,
      goals: formValues.goals,
      business_website_url: formValues.business_website_url,
      knowledge_base: formValues.knowledge_base || '',
      company_name: formValues.company_name,
      embed_key: formValues.embed_key,
      booking_enabled: (user.google_calendar_connected && bookingEnabled),
      address_required_for_scheduling: addressRequiredForScheduling,
    };

    const data = {
      title: formValues.company_name,
      config: config,
      flow: formValues.flow,
      embed_key: formValues.embed_key,
      id: channel.id,
    };

    console.warn(data, config)
    try {
      await dispatch(updateChannel({ channelData: data }));
      enqueueSnackbar(`Successfully updated ${formValues.chatbot_name}!`, {
        variant: 'success',
        autoHideDuration: 6000
      });
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      setError("Error while updating assistant. Please try again in a moment.");
      enqueueSnackbar(`Error while updating assistant. Please try again in a moment.`, {
        variant: 'error',
        autoHideDuration: 6000
      });
    }
  }

  useEffect(() => {
    if (open && isPhoneVerified && (validatedPhoneNumber !== channel.config?.fallback_phone_number || smsNotificationsEnabled !== channel.config?.sms_notifications_enabled)) {
      dispatch(updateBccAndFallbackPhone({ channelId: channel.id, fallbackPhone: validatedPhoneNumber, smsNotificationsEnabled: smsNotificationsEnabled }));
    }
  }, [open, isPhoneVerified, validatedPhoneNumber, smsNotificationsEnabled]);

  useEffect(() => {
    let system_message = `
#Your Identity: ${formValues.chatbot_name && `You are an assistant named ${formValues.chatbot_name}`} ${formValues.company_name ? `working for ${formValues.company_name}` : "working for a company named Oppy.pro"}

#NEVER SAY YOU ARE AN AI ASSISTANT or A BOT OR ROBOT - Politely change the subject back to your goals!

#Your Personality/Communication Style is: 
  ${formValues.chatbot_personality && `- Very ${formValues.chatbot_personality}`}
  `

    if (formValues.knowledge_base) {
      system_message += `#Knowledge Base: ${formValues.knowledge_base} \n`
    }
    if (formValues.goals.length > 0) {
      system_message += "After you fully understand the details above - YOU MUST Execute the following goals listed in order of importance while naturally conversing with the user: \n\n"

      formValues.goals.forEach((goal, index) => {
        system_message += `#Goal ${index + 1}: ${goal} \n`
      })

      system_message += "\nNow continue executing your goals in your conversation with the user.\n";
    } else {
      system_message += "After you fully understand the details above - YOU MUST continue naturally conversing with the user: \n\n"
    }

    setSystemMessage(system_message);
  }, [formValues]);

  const handlePhoneNumberChange = (value) => {
    if (channel.config?.fallback_phone_number === value.phone) {
      setIsPhoneVerified(true);
    } else {
      setIsPhoneVerified(value.valid || false);
    }

    setFormValues({
      ...formValues,
      fallback_phone_number: value.phone
    });
  };

  const handlePhoneVerification = (isValid) => {
    setIsPhoneVerified(isValid);
    setValidatedPhone(formValues.fallback_phone_number);
  };

  const toggleAddressRequiredForScheduling = () => {
    setAddressRequiredForScheduling(!addressRequiredForScheduling);
  }

  const handleEmbedKeyChange = (val) => {
    setFormValues({ ...formValues, embed_key: val });
  }

  const handleCalendarConnected = () => {
    console.log('calendar connection successful')
    dispatch(trackAnalytics({
      action_name: "user_success_connect_calendar",
      payload: { description: `user success connect calendar` }
    }))
    setBookingEnabled(true)
    setCalendarDialogOpen(false)
  }

  const handleCalendarBack = () => {
    setCalendarDialogOpen(false)
    dispatch(trackAnalytics({
      action_name: "user_exited_connect_calendar",
      payload: { description: `user exited connect calendar` }
    }))
  }

  const toggleCalendarCalendarSwitch = () => {
      if (!user.google_calendar_connected){
        setCalendarDialogOpen(true)
      } else if (user.google_calendar_connected) {
        setBookingEnabled(!bookingEnabled)
      }

    dispatch(trackAnalytics({
      action_name: `user_toggled_connect_calendar_${!bookingEnabled}`,
      payload: { description: `user toggled connect calendar` }
    }))
  }

  const handleSmsToggleSwitch = () => {
    const newSmsNotificationsEnabled = !formValues.sms_notifications_enabled;
    setFormValues({ ...formValues, sms_notifications_enabled: newSmsNotificationsEnabled });
    setSmsNotificationsEnabled(newSmsNotificationsEnabled);
    if (!validatePhoneOpen && !validatedPhoneNumber) {
      setValidatePhoneOpen(true);
    }
    dispatch(trackAnalytics({
      action_name: `user_toggled_sms_notifications_${newSmsNotificationsEnabled}`,
      payload: { description: `user toggled sms notifications` }
    }));
  }

  const handleDeleteChannel = () => {
    setOpenDeleteConfirmation(false);
    setLoading(true);
    dispatch(trackAnalytics({ action_name: "channel_deleted", payload: { description: "user deleted channel" } }))
    dispatch(deleteChannel(channel.id))
      .unwrap()
      .then(() => {
        setLoading(false);
        handleClose();
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  // Memoize options
  const markdownOptions = useMemo(() => ({
    toolbar: [
      'bold',
      'italic',
      'heading',
      '|',
      'quote',
      'unordered-list',
      'ordered-list',
      '|',
      'link',
      '|',
      'preview',
      'side-by-side',
      'fullscreen',
      '|',
      'guide',
    ],
    placeholder: `Do's & Don'ts, FAQs, when I should standby, additional context, personality, writing style, etc.`,
  }), []);

  // Memoize onChange handler
  const handleMarkdownChange = useCallback((value: string) => {
    handleChange({
      target: {
        name: 'knowledge_base',
        value: value,
      },
    });
  }, [handleChange]);

  return (
    <>
      {openDeleteConfirmation && <ChannelDeleteDialog open={openDeleteConfirmation} handleClose={() => setOpenDeleteConfirmation(false)} channel={channel} handleDeleteChannel={handleDeleteChannel} />}
      <Dialog
        open={open}
        fullScreen={fullScreen}
        onClose={handleClose}
        maxWidth="xs"
        key={channelId}
      >
        <DialogTitle sx={{
          backgroundColor: '#000000',
          backgroundImage: `url(${logo})`,
          backgroundPosition: 'right',
          backgroundSize: '20%',
          backgroundRepeat: 'no-repeat',
          paddingTop: 7,
          paddingLeft: 5,
          paddingRight: 10,
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{ color: '#BBB5FF' }} variant='h5' onClick={() => setFormValues({ ...formValues, use_emojis: !formValues.use_emojis })}>
                {formValues.use_emojis ? '👋 Hi' : 'Hi'}{user.first_name && ` ${user.first_name}`},
              </Typography>

              <Tooltip title="Random Name" placement="top">
                <Typography sx={{ color: '#BBB5FF', marginBottom: 4 }} variant='h4' onClick={() => setFormValues({ ...formValues, chatbot_name: getRandomName() })}>
                  I'm {formValues.chatbot_name}!
                  <RefreshIcon color="secondary" sx={{}} />
                </Typography>
              </Tooltip>
              
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  color: "#CEFF1A",
                  '& label': {
                    color: "#4949C8",
                    backgroundColor: "#000000",
                    padding: '3px',
                    borderRadius: 10,
                  },
                }}
              >
                <Typography variant="body1">
                  I'm proud to work for
                </Typography>
                <TextField
                  focused
                  variant="standard"
                  size='small'
                  sx={{
                    marginTop: 1,
                    marginLeft: 1,
                    width: 'fit-content',
                    '& .MuiInputBase-input': {
                      color: "#CEFF1A",
                    },
                  }}
                  label="Company Name"
                  name="company_name"
                  value={formValues.company_name}
                  onChange={handleChange}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <PagesDialog channelId={channel.id} incomingPages={pages} onUpdatePages={handlePagesUpdate} />
                <Typography variant="caption" display="block" textAlign="center" color="white" sx={{ mt: 0 }}>
                  {pages.length} page{pages.length !== 1 ? 's' : ''} imported
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Divider />
        </DialogTitle>
        <DialogContent sx={{ paddingLeft: 1, paddingRight: 1 }}>

          <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ marginTop: 1.5 }} expanded={expanded === 'Personality'} onChange={handleAccordionChange('Personality')}>
            <AccordionSummary expandIcon={<SentimentSatisfiedAltIcon />} aria-controls="personalityd-content" id="personalityd-header">
              <Typography variant='h6'>Assistant Personality</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup sx={{}}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <TextField
                      sx={{}}
                      label="Assistant Name"
                      name="chatbot_name"
                      value={formValues.chatbot_name}
                      onChange={handleChange}
                      variant="standard"
                      InputProps={{
                        endAdornment:
                          <Button sx={{ marginBottom: 1 }} variant="contained" className='d-flex align-items-center' onClick={() => setFormValues({ ...formValues, chatbot_name: getRandomName() })}>
                            <div className="d-flex align-items-center">
                              <RefreshIcon sx={{ paddingLeft: 1 }} />
                              <Typography sx={{ marginLeft: .5, fontSize: 12, paddingRight: 1 }}>Random </Typography>
                            </div>
                          </Button>
                      }} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField variant="standard" sx={{ marginTop: 1 }} label="Company Name" name="company_name" value={formValues.company_name} onChange={handleChange} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControl sx={{ marginTop: 1 }} variant="standard" >
                      <InputLabel>Assistant Personality</InputLabel>
                      <Select label="Assistant Personality" name="chatbot_personality" value={formValues.chatbot_personality} onChange={handleChange}>
                        <MenuItem value="Professional">Professional</MenuItem>
                        <MenuItem value="Friendly">Fun</MenuItem>
                        <MenuItem value="Casual">Casual</MenuItem>
                        <MenuItem value="Business">Business</MenuItem>
                        <MenuItem value="Funny">Funny</MenuItem>
                        <MenuItem value="Sassy">Sassy</MenuItem>
                        <MenuItem value="Caring">Caring</MenuItem>
                        <MenuItem value="Hip">Hip</MenuItem>
                        <MenuItem value="Witty - you are a wise-cracker but always sharp and focused on your goals. You are highly intellegent in your writing ability and need only few words to express yourself while subtlly building deep rapport with the user">Witty</MenuItem>
                        <MenuItem value="Chill AF">Chill AF</MenuItem>
                        <MenuItem value="Sarcastic">Sarcastic</MenuItem>
                        <MenuItem value="Serious">Serious</MenuItem>
                        <MenuItem value="Silly">Silly</MenuItem>      
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <FormControlLabel sx={{ margin: 1 }} control={<Switch checked={formValues.use_emojis} onChange={handleToggle} name="use_emojis" />} label={formValues.use_emojis ? "🙂 Use emojis" : "🤐 Don't use emojis"} />
                  </Grid>
                </Grid>
              </FormGroup>
            </AccordionDetails>
          </Accordion>

          <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ marginTop: 1.5 }} expanded={expanded === 'goals'} onChange={handleAccordionChange('goals')}>
            <AccordionSummary expandIcon={
              <Badge color="success" badgeContent={formValues?.goals?.length}>
                <SportsScoreIcon />
              </Badge>
            } aria-controls="goalsd-content" id="goalsd-header">
              <Typography variant='h6'>Set Goals</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup sx={{}}>
                <FormControl fullWidth variant="standard">
                  <Typography sx={{ marginBottom: 1 }} variant="body2">What are the goals you want {formValues.chatbot_name} to achieve for you?</Typography>
                  {formValues.goals && formValues.goals.map((goal, index) => {
                    return (
                      <TextField
                        key={index}
                        sx={{ marginTop: 1 }}
                        label={`Goal ${index + 1}`}
                        name={`goal_${index + 1}`}
                        value={goal}
                        variant='standard'
                        onChange={event => handleChangeGoal(event.target.value, index)}
                        onBlur={() => {
                          if (goal === '') {
                            handleRemoveGoal(index);
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                onClick={() => {
                                  if (window.confirm('Are you sure you want to delete this goal?')) {
                                    handleRemoveGoal(index)
                                  }
                                }}
                              >
                                <RemoveCircleOutlineIcon />
                              </IconButton>
                              {goal &&
                                <IconButton
                                  onClick={() => handleAddGoal(index)}
                                >
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              }
                            </InputAdornment>
                          ),
                        }}
                      />
                    )
                  })
                  }
                </FormControl>
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ marginTop: 1.5 }} expanded={expanded === 'teach'} onChange={handleAccordionChange('teach')}>
            <AccordionSummary expandIcon={<SchoolIcon />} aria-controls="teachd-content" id="teachd-header">
              <Typography variant='h6'>Teach {formValues.chatbot_name}</Typography>
            </AccordionSummary>

            <AccordionDetails>

              <Typography variant='body2'>
                I'm a fast learner and with a little fine tuning here and there, I won't let you down.
              </Typography>

              <PagesDialog channelId={channel.id} incomingPages={pages} onUpdatePages={handlePagesUpdate} />
              <FormGroup sx={{ mt: 3 }}>
                <FormControl fullWidth variant="outlined">
                  <MarkdownEditor
                    value={formValues.knowledge_base}
                    onChange={handleMarkdownChange}
                    maxLength={1000}
                    options={markdownOptions}
                  />
                </FormControl>
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ marginTop: 1.5 }} expanded={expanded === 'scheduling' || !bookingEnabled} onChange={handleAccordionChange('scheduling')}>
            <AccordionSummary sx={bookingEnabled ? { backgroundColor: '' } : { backgroundColor: '#ff9800' }} expandIcon={
              <Badge color={bookingEnabled ? "success" : "warning"} badgeContent={bookingEnabled ? "✓" : "!"}><CalendarMonthIcon /></Badge>
            } aria-controls="schedulingd-content" id="schedulingd-header">
              <Typography variant='h6' >Scheduling {bookingEnabled ? 'Enabled' : 'disabled connect 🗓️!'}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup sx={{}}>

                <Typography variant='h6'>Connect Your Calendar</Typography>

                {CalendarDialogOpen &&
                  <CalendarDialog
                    show={CalendarDialogOpen}
                    handleGoBack={handleCalendarBack}
                    calendarConnected={() => handleCalendarConnected}
                    fullScreen={fullScreen}
                  />
                }
                <FormControlLabel
                  label={bookingEnabled ? "🗓️ Scheduling Enabled" : "🗓️ Scheduling Disabled"}
                  control={
                    <Switch
                      checked={bookingEnabled}
                      onChange={() =>
                        toggleCalendarCalendarSwitch()
                      }
                      name="calendar_connected"
                    />
                  }
                />
                <FormControlLabel
                  label={addressRequiredForScheduling ? "🗓️ Appointment address REQUIRED" : "🗓️  Appointment address NOT REQUIRED"}
                  control={
                    <Switch
                      checked={addressRequiredForScheduling}
                      onChange={() =>
                        toggleAddressRequiredForScheduling()
                      }
                      name="calendar_connected"
                    />
                  }
                />
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ marginTop: 1.5, width: '100%' }} expanded={expanded === 'notifications'} onChange={handleAccordionChange('notifications')}>
            <AccordionSummary expandIcon={
              <Badge color={formValues.sms_notifications_enabled ? "success" : "default"} badgeContent={formValues.sms_notifications_enabled ? "✓" : "💤"}>
                <NotificationsIcon />
              </Badge>
            } aria-controls="notificationd-content" id="notificationd-header">
              <Typography variant='h6'>Notifications</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup sx={{}}>
                <FormControlLabel sx={{}}
                  control={<Switch checked={formValues.sms_notifications_enabled} onChange={handleSmsToggleSwitch} name="sms_notifications_enabled" />}
                  label={formValues.sms_notifications_enabled ? "🔔 Notify me for each message" : "🔕 Notify me for each message"}
                />
                {(validatePhoneOpen) ?
                  <>
                    {!validatedPhoneNumber && <Alert severity="warning">To enable BCC please validate a phone number</Alert>}
                    <ChannelPhoneValidator value={validatedPhoneNumber} setValue={handlePhoneNumberChange} setIsPhoneVerified={handlePhoneVerification} />
                  </>
                  :
                  <>
                    {validatedPhoneNumber ?
                      <>
                        <Alert severity="success" icon={false}>
                          <Box display="flex" alignItems="center">
                            <Box flexGrow={1}>
                              Notifications
                              {formValues.sms_notifications_enabled ?
                                <strong> ENABLED </strong>
                                :
                                <strong> DISABLED </strong>
                              }
                              for &nbsp;&nbsp;
                              <Chip variant='filled' label={validatedPhoneNumber}
                                onDelete={() => setValidatePhoneOpen(true)}
                                deleteIcon={
                                  <Tooltip title="Edit Phone">
                                    <IconButton color="inherit" size="small" onClick={() => setValidatePhoneOpen(true)}>
                                      <EditIcon sx={{ fontSize: '15px' }} />
                                    </IconButton>
                                  </Tooltip>
                                }
                              />

                            </Box>
                          </Box>
                        </Alert>
                      </>
                      :
                      <Alert severity="warning" onClick={() => setValidatePhoneOpen(true)}>Add phone number for notifications</Alert>
                    }
                  </>
                }
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          <Accordion TransitionProps={{ unmountOnExit: true }} sx={{ marginTop: 1.5 }} expanded={expanded === 'advanced'} onChange={handleAccordionChange('advanced')}>
            <AccordionSummary expandIcon={<SettingsIcon />} aria-controls="advancedd-content" id="advancedd-header">
              <Typography variant='h6'>Advanced</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup sx={{}}>
                <FormControl sx={{ marginTop: 1 }} variant="standard" >
                  <InputLabel>Look</InputLabel>
                  <Select label="Assistant Style" name="chatbot_style" value={formValues.chatbot_style} onChange={handleChange}>
                    <MenuItem value="1">Light Mode</MenuItem>
                    <MenuItem value="2">Dark Mode</MenuItem>
                    <MenuItem value="3">Disco Mode</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="standard" sx={{ mt: 3 }}>
                  <InputLabel id="flow-select-label">Flow Type</InputLabel>
                  <Select
                    labelId="flow-select-label"
                    id="flow-select"
                    value={formValues.flow}
                    label="Flow Type"
                    onChange={(event) => setFormValues({ ...formValues, flow: event.target.value })}
                  >
                    <MenuItem value="basic_booking_function_flow">Standard</MenuItem>
                    <MenuItem value="operator_flow">Operator</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="outlined">
                  <Typography sx={{ margin: 1 }} variant="body2"></Typography>
                  <ChannelSystemMessageDialog
                    channelId={channel.id}
                  />
                </FormControl>
                <FormControl fullWidth variant="outlined">
                  <Typography sx={{ margin: 1 }} variant="body2"></Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button
                      onClick={() => setOpenDeleteConfirmation(true)}
                      disabled={loading}
                      sx={{
                        marginRight: 1,
                        backgroundColor: theme.palette.error.main,
                        color: theme.palette.common.white,
                        textTransform: 'none',
                        maxWidth: '50%'
                      }}
                      variant='contained'
                    >
                      Delete {formValues.chatbot_name}
                      <DeleteIcon
                        color="inherit"
                        style={{ marginLeft: '10px' }}
                      />
                    </Button>
                  </Box>
                </FormControl>
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: '#000000',
            backgroundImage: `url(${logo})`,
            backgroundPosition: 'left',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems='center'>
            <Grid item xs={4} md={6} display={'flex'} alignContent={'flex-start'}>
              <Typography sx={{ color: '#CEFF1A' }} variant="body1">Put me to work already!</Typography>
            </Grid>
            <Grid item xs={8} md={6} display={'flex'} justifyContent='flex-end'>
              <Button variant='contained' color="primary" onClick={handleBack}  >Back</Button>
              <Button onClick={handlePostChannel} variant='contained' color="secondary" disabled={loading}>
                {loading ? <CircularProgress size={24} /> : ""} Save & Continue
              </Button>
            </Grid>
          </Box>
        </DialogActions>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={!!error}
          onClose={() => setError(null)}
          message={error}
          autoHideDuration={6000}
          color="warning"
        />
      </Dialog>
    </>
  );
};

export default ChannelEditDialog;
