let conversationsHotKeysMounted = false;
let currentConversationIndex = 0;

export const conversationsHotKeys = (conversations, setFocusedConversation) => {
  const handleKeyDown = (event) => {
    if (event.key === 'j') {
      currentConversationIndex = (currentConversationIndex + 1) % conversations.length;
      setFocusedConversation(conversations[currentConversationIndex]);
    } else if (event.key === 'k') {
      currentConversationIndex = (currentConversationIndex - 1 + conversations.length) % conversations.length;
      setFocusedConversation(conversations[currentConversationIndex]);
    }
  };

  return {
    mount: () => {
      if (!conversationsHotKeysMounted) {
        document.addEventListener('keydown', handleKeyDown);
        conversationsHotKeysMounted = true;
      }
    },
    unmount: () => {
      if (conversationsHotKeysMounted) {
        document.removeEventListener('keydown', handleKeyDown);
        conversationsHotKeysMounted = false;
      }
    }
  };
};

export const postMessageHotKey = (inputRef, handleSendMessage) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
      event.preventDefault();
      if (inputRef.current && inputRef.current.value) {
        handleSendMessage(inputRef.current.value);
      }
    }
  };

  return {
    mount: () => {
      document.addEventListener('keydown', handleKeyDown);
    },
    unmount: () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  };
};
