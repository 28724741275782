import React, { useState } from 'react';
import { httpClient } from 'src/libs';
import { API_ROUTES } from 'src/constants/routes';
import { useAppSelector, useAppDispatch } from 'src/store';
import FollowUpSettingsForm from '../FollowUpSettingsForm';
import { FollowUpSettings } from 'src/types/followUpSettings';
import { updateConversation } from 'src/features/account/conversations.slice';

interface ConversationFollowUpSettingsProps {
  conversationId: string;
}

const DEFAULT_PROMPT = "Monitor this conversation and determine if a follow-up is needed. Consider: user engagement, unanswered questions, and potential next steps. If appropriate, draft and send a follow-up message.";

const ConversationFollowUpSettings: React.FC<ConversationFollowUpSettingsProps> = ({ conversationId }) => {
  const dispatch = useAppDispatch();
  const conversation = useAppSelector(state => state.conversations.entities[conversationId]);
  const [followUpSettings, setFollowUpSettings] = useState<FollowUpSettings>({
    enabled: conversation?.follow_up?.enabled ?? false,
    delay_minutes: conversation?.follow_up?.delay_minutes ?? 1440,
    prompt: conversation?.follow_up?.prompt ?? DEFAULT_PROMPT,
    respect_business_hours: conversation?.follow_up?.respect_business_hours ?? false,
  });
  const [initialFollowUpSettings, setInitialFollowUpSettings] = useState<FollowUpSettings>({
    enabled: conversation?.follow_up?.enabled ?? false,
    delay_minutes: conversation?.follow_up?.delay_minutes ?? 1440,
    prompt: conversation?.follow_up?.prompt ?? DEFAULT_PROMPT,
    respect_business_hours: conversation?.follow_up?.respect_business_hours ?? false,
  });
  const [saving, setSaving] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  // Initialize follow-up settings from conversation.follow_up
  React.useEffect(() => {
    console.log('Conversation data:', conversation);
    if (conversation) {
      const followUp = conversation.follow_up || {};
      const newSettings = {
        enabled: followUp.enabled ?? false,
        delay_minutes: followUp.delay_minutes ?? 1440,
        prompt: followUp.prompt ?? DEFAULT_PROMPT,
        respect_business_hours: followUp.respect_business_hours ?? false,
      };
      setFollowUpSettings(newSettings);
      setInitialFollowUpSettings(newSettings);
      console.log('Initial settings set:', newSettings);
    } else {
      console.log('Conversation not available.');
    }
  }, [conversation]);

  const handleSave = async () => {
    try {
      setSaving(true);
      await dispatch(
        updateConversation({
          conversationId,
          channelId: conversation.channel_id,
          conversationData: { follow_up: followUpSettings },
        })
      ).unwrap();
      setIsExpanded(false);
      setInitialFollowUpSettings(followUpSettings);
    } catch (error) {
      console.error('Failed to update follow-up settings:', error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <FollowUpSettingsForm
      followUpSettings={followUpSettings}
      setFollowUpSettings={setFollowUpSettings}
      handleSave={handleSave}
      saving={saving}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      initialFollowUpSettings={initialFollowUpSettings}
    />
  );
};

export default ConversationFollowUpSettings; 
