// begin frontend/src/components/ValidatedPhoneInput.tsx

import { CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import { baseURL } from 'src/libs/axios';
import ErrorPopup from './ErrorPopup';
import { useAppDispatch } from 'src/store';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';

const ValidatedPhoneInput = ({ value, setValue, setIsPhoneVerified, showValidateButton = false, setPhoneError = (_) => { } }) => {
  const [code, setCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(value || "");
  const [validating, setValidating] = useState(false);
  const [error, setError] = useState(null);
  const [showValidate, setShowValidate] = useState(showValidateButton)
  const dispatch = useAppDispatch()

  const handlePhoneValidationFail = (message) => {
    triggerError(message)
    setIsPhoneVerified(false);
    setPhoneError("Sorry! Could not verify Cell Phone Number");
    dispatch(trackAnalytics({ action_name: "user_phone_verification_failed", payload: { description: `user failed to verify phone number ${phoneNumber}`, failed_verification_phone: phoneNumber } }))
    setTimeout(() => {
      setPhoneError(null);
    }, 10000);
    restart()
  }

  const triggerError = (message) => {
    setError(message)
    setTimeout(() => {
      setError(null)
    }, 5000)
  }

  const onStart = async () => {
    console.warn('Validating', phoneNumber);
    setValidating(true);
    fetch(`${baseURL}/verify_phone/start?phone=%2b${phoneNumber}`, {
      method: 'POST',
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if (data.success) {
        setCode('');
        dispatch(trackAnalytics({ action_name: "user_phone_verification_started", payload: { description: `user started phone verification ${phoneNumber}`, verified_phone_number: phoneNumber } }))
        setValidating(false);
      } else {
        triggerError(data.message)
        setValidating(false);
      }
    }).catch((error) => {
      triggerError(error.message)
      setValidating(false);
    });
  };

  const onCheck = async () => {
    console.warn('Validating', phoneNumber);
    setValidating(true);

    fetch(`${baseURL}/verify_phone/check?phone=%2b${phoneNumber}&code=${code}`, {
      method: 'POST',
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if (data.success) {
        setCode(data.existing_user ? null : true);
        setValue("existing_user", data.existing_user);
        setValue("phone_verification", data.token);
        dispatch(trackAnalytics({ action_name: "user_phone_verification_completed", payload: { description: `user completed phone verification` } }))
        setIsPhoneVerified(true);
        setValidating(false);
      } else {
        handlePhoneValidationFail(data.message)
        setValidating(false);
      }
    }).catch((error) => {
      handlePhoneValidationFail(error.message)
      setValidating(false);
    });
  };

  const restart = () => {
    setCode(null)
    setValue("phone", '')
    setPhoneNumber('')
  };

  if (code === null) {
    return (
      <>
        {error && <ErrorPopup message={error} />}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
          <PhoneInput
            value={phoneNumber}
            enableSearch={true}
            onlyCountries={['us']}
            disableDropdown={true}
            country='us'
            placeholder='Enter phone number'
            inputProps={{
              id: 'phone',
              name: 'phone',
              className: 'ht-46 w-100 form-control',
            }}
            onChange={(e) => {
              if (!e.startsWith("1")) { e = "1" + e }
              setValue("phone", e);
              setValue("existing_user", false);
              setIsPhoneVerified(false);
              setPhoneNumber(e)
              setShowValidate(true)
            }}
          />

          <button
            disabled={validating}
            onClick={onStart}
            className='btn btn-lg btn-primary'
            style={{
              transition: '300ms',
              visibility: phoneNumber.length > 9 && showValidate ? 'visible' : 'hidden',
              opacity: phoneNumber.length > 9 && showValidate ? '1' : '0',
            }}
          >
            {validating ? <CircularProgress color="inherit" size="1rem" /> : <span className="fw-medium">Start verification</span>}
          </button>
        </div>
      </>
    );
  }

  if (code === true) {
    return <div>Phone number verified: +{phoneNumber}</div>;
  }

  return (
    <>
      {error && <ErrorPopup message={error} />}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
        Please enter the code sent to you via text.
        <input type='text' value={code} className='ht-46 w-50 form-control' onChange={(e) => setCode(e.target.value)} />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
          <button onClick={restart} disabled={validating} className='btn btn-lg btn-dark' type='button'>
            <span className="fw-medium">Restart</span>
          </button>
          <button onClick={onCheck} disabled={validating} className='btn btn-lg btn-primary' type='button'>
            {validating ? <CircularProgress color="inherit" size="1rem" /> : <span className="fw-medium">Validate phone</span>}
          </button>
        </div>
      </div>
    </>
  );
};

export default ValidatedPhoneInput;


//end frontend/src/components/ValidatedPhoneInput.tsx
