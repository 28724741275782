const SCOPES = "https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly";
const CLIENT_ID = process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID;
const REDIRECT_URI = `${window.location.origin}/calendar`;

const params = {
  client_id: CLIENT_ID,
  redirect_uri: REDIRECT_URI,
  response_type: "code",
  scope: SCOPES,
  access_type: "offline",
  include_granted_scopes: "true",
  prompt: 'consent'
};

export class GoogleAuthorization {
  private authEndpoint: string;
  private onAuthWindowClosed: Function;

  constructor(onAuthWindowClosed: Function) {
    this.authEndpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
    this.onAuthWindowClosed = onAuthWindowClosed;
  }

  authorizeUser = (event: React.MouseEvent) => {
    event.preventDefault();

    const url = this.buildAuthorizationUrl();
    window.location.href = url.toString();
    // const authWindow = window.open(url.toString(), '_blank', 'popup');

    // this.pollWindowClosed(authWindow);
  };

  private buildAuthorizationUrl() {
    const url = new URL(this.authEndpoint);
    url.search = new URLSearchParams(params).toString();

    return url;
  }

  private pollWindowClosed = (authWindow: Window | null) => {
    const checkInterval = setInterval(() => {
      if (!authWindow || authWindow.closed) {
        clearInterval(checkInterval);
        this.onAuthWindowClosed();
      }
    }, 500);
  }
}
