import React, { useState, useEffect, useRef } from 'react';
import { Box, MobileStepper, useTheme, Button, Link, Typography } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import TitleSlide from './TitleSlide';
import Problem from './Problem';
import Solutions from './Solutions';
import MlsRevenueProjection from './MlsRevenueProjection';
import OppyFinancialLandscapeChart from './OppyFinancialLandscapeChart';
import { useAppDispatch } from 'src/store';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import TeamSlide from './TeamSlide';
import TamSomSlide from './TamSomSlide'; // Added import
import BasicSlideTemplate from './BasicSlideTemplate';
import EmailCaptureModal from './EmailCaptureModal'; // Import the modal component
import ProgressSlide from './ProgressSlide';
import ThreeDOppySlide from './ThreeDOppySlide';

const Deck: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // State for email modal and user info
  const [emailModalOpen, setEmailModalOpen] = useState(true);
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');

  const slides = [
    // TitleSlide,
    ThreeDOppySlide,
    () => {
      console.log('Rendering placeholder slide 0');
      return <BasicSlideTemplate 
        title="" 
        imageUrl="https://res.cloudinary.com/kw/image/upload/v1728673161/oppy/Screenshot_2024-10-11_at_11.32.51_AM.png"
      />;
    },
    () => {
      console.log('Rendering placeholder slide 1');
      return <BasicSlideTemplate 
        title="" 
        imageUrl="https://res.cloudinary.com/kw/image/upload/v1728673161/oppy/Screenshot_2024-10-11_at_11.32.36_AM.png"
      />;
    },
    () => {
      console.log('Rendering placeholder slide 2');
      return <BasicSlideTemplate 
        title="" 
        imageUrl="https://res.cloudinary.com/kw/image/upload/v1728673161/oppy/Screenshot_2024-10-11_at_11.31.21_AM.png"
      />;
    },
    Problem,
    () => {
      console.log('Rendering placeholder slide 3');
      return <BasicSlideTemplate 
        title="Happy Customers" 
        imageUrl="https://res.cloudinary.com/kw/image/upload/v1728673161/oppy/Screenshot_2024-10-11_at_11.33.15_AM.png"
      />;
    },
    Solutions,
    () => {
      console.log('Rendering placeholder slide 3');
      return <BasicSlideTemplate 
        title="" 
        imageUrl="https://res.cloudinary.com/kw/image/upload/v1728673161/oppy/Screenshot_2024-10-11_at_11.26.47_AM.png"
      />;
    },
    () => {
      console.log('Rendering placeholder slide 4');
      return <BasicSlideTemplate 
        title="Why is Oppy Special?" 
        imageUrl="https://res.cloudinary.com/kw/image/upload/v1728673162/oppy/Screenshot_2024-10-11_at_11.33.42_AM.png"
      />;
    },
    () => {
      console.log('Rendering placeholder slide 1');
      return <BasicSlideTemplate
        title="Human in the Loop"
        imageUrl="https://res.cloudinary.com/kw/image/upload/v1728673163/oppy/Screenshot_2024-10-11_at_11.34.07_AM.png"
      />;
    },
    ProgressSlide,
    TamSomSlide,
    () => {
      console.log('Rendering placeholder slide 0');
      return <BasicSlideTemplate 
        title="" 
        imageUrl="https://res.cloudinary.com/kw/image/upload/v1728673162/oppy/Screenshot_2024-10-11_at_11.36.30_AM.png"
      />;
    },
    MlsRevenueProjection,
    TeamSlide,
    () => {
      return <BasicSlideTemplate 
        title="Use cases" 
        imageUrl="https://res.cloudinary.com/kw/image/upload/v1728673166/oppy/Screenshot_2024-10-11_at_11.37.34_AM.png"
      />;
    },
    () => {
    // Log rendering of use cases slides
    console.log('Rendering use cases slide 2');
    return <BasicSlideTemplate 
      title="Use Cases" 
      imageUrl="https://res.cloudinary.com/kw/image/upload/v1728673167/oppy/Screenshot_2024-10-11_at_11.37.50_AM.png"
    />;
  },
  () => {
    console.log('Rendering use cases slide 3');
    return <BasicSlideTemplate 
      title="Use Cases 3" 
      imageUrl="https://res.cloudinary.com/kw/image/upload/v1728673167/oppy/Screenshot_2024-10-11_at_11.37.59_AM.png"
    />;
  },
  () => {
    console.log('Rendering use cases slide 4');
    return <BasicSlideTemplate 
      title="Use Cases 4" 
      imageUrl="https://res.cloudinary.com/kw/image/upload/v1728673167/oppy/Screenshot_2024-10-11_at_11.38.10_AM.png"
    />;
  },
    () => {
      return <BasicSlideTemplate 
        title="Thank you." 
        imageUrl="https://res.cloudinary.com/kw/image/upload/v1728673163/oppy/Screenshot_2024-10-11_at_11.36.50_AM.png"
      />;
    },
    // () => {
    //   console.log('Rendering placeholder slide 3');
    //   return <BasicSlideTemplate 
    //     title="Placeholder Title 3" 
    //     videoUrl="path/to/video.mp4" 
    //   />;
    // },
  ];

  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Log current slide number whenever it changes
  useEffect(() => {
    console.log(`Current slide is now ${currentSlide + 1} of ${slides.length}`);
  }, [currentSlide]);

  // Handle arrow key navigation
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      console.log('Key pressed:', e.key);

      if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
        e.preventDefault(); // Prevent default scrolling
        console.log('Default action prevented for right/down arrow');
        handleNext();
      } else if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
        e.preventDefault(); // Prevent default scrolling
        console.log('Default action prevented for left/up arrow');
        handleBack();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleNext = () => {
    setCurrentSlide((prev) => {
      const newSlide = Math.min(prev + 1, slides.length - 1);
      console.log(`Current slide: ${prev}, Next slide: ${newSlide}`);
      dispatch(
        trackAnalytics({
          action_name: 'next_slide',
          payload: { from: prev, to: newSlide },
        })
      );
      return newSlide;
    });
  };

  const handleBack = () => {
    setCurrentSlide((prev) => {
      const newSlide = Math.max(prev - 1, 0);
      console.log(`Current slide: ${prev}, Previous slide: ${newSlide}`);
      dispatch(
        trackAnalytics({
          action_name: 'previous_slide',
          payload: { from: prev, to: newSlide },
        })
      );
      return newSlide;
    });
  };

  // Handle email modal closure
  const handleEmailModalClose = (email: string, name: string) => {
    console.log('Email modal closed with email:', email, 'name:', name);
    setUserEmail(email); // Save email
    setUserName(name);   // Save name

    setEmailModalOpen(false); // Close modal

    // Dispatch analytics event with user's name and email
    dispatch(trackAnalytics({
      action_name: 'email_captured_on_pitch_deck',
      payload: { email, name },
    }));
  };

  // Trigger analytics event on deck visit
  useEffect(() => {
    console.log('User visited pitch deck');
    dispatch(trackAnalytics({
      action_name: 'pitch_deck_visited',
      payload: {}, // No payload needed
    }));
  }, []); // Empty dependency array to run only once on mount

  return (
    <>
      {/* Email Capture Modal */}
      <EmailCaptureModal open={emailModalOpen} onClose={handleEmailModalClose} />

      {/* Render slides only if email is captured */}
      {!emailModalOpen && (
        <Box
          sx={{
            width: '100%',
            // Remove fixed height
            height: '100vh',
            overflowY: 'hidden',
            p: 0,
            backgroundColor: 'black',
            position: 'relative',
          }}
        >
          {/* Render slides */}
          {slides.map((Slide, index) => (
            <Box
              key={index}
              sx={{
                width: '100%',
                // Remove fixed height
                height: 'calc(100vh - 150px)',
                overflowY: 'auto', // Keep overflow auto to allow scrolling
                transition: 'transform 0.5s ease-in-out',
                transform: `translateY(${(index - currentSlide) * 100}%)`,
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            >
              <Slide />
            </Box>
          ))}
          {/* Log height adjustment */}
          <Box
            sx={{
              position: 'fixed', // Fixed to bottom of the browser
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.85)',
              backdropFilter: 'blur(8px)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'baseline',
              width: '100%',
              height: '115px',
              gap: 0,
              padding: .5,
              zIndex: 1000,
            }}
          >
            {/* First Row: Invest Button */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
                mb: 1,
              }}
            >
              <Button
                href="https://oppy.pro?ref=deck"
                target="_blank"
                sx={{
                  color: 'grey.500', // Subdued grey color
                  textTransform: 'none',
                  height:'100%'
                }}
                onClick={() => {
                  console.log('Oppy button clicked, opening Oppy website');
                }}
              >
                Oppy
              </Button>
              <Button
                variant="contained"
                size="large"
                onClick={() => {
                  // Open email client with pre-filled message
                  window.open(
                    "mailto:ag@oppy.pro?subject=Oppy Investment Inquiry&body=Hey Alex, I'd love to learn more about investing in Oppy. Can you please send me your SAFE? My name is",
                    "_blank"
                  );
                  console.log('Invest button clicked, opening email client');

                  // Dispatch analytics event for invest button click
                  dispatch(
                    trackAnalytics({
                      action_name: 'invest_button_clicked',
                      payload: {},
                    })
                  );
                  console.log('Analytics event dispatched: invest_button_clicked');
                }}
                sx={{
                  color: 'white',
                  fontFamily: theme.typography.fontWeightBold,
                  mb: -2,
                  height: '100%'
                }}
              >
                Invest/Inquire
              </Button>
              {/* Slide Counter */}
              <Typography
                variant="body2"
                sx={{
                  color: 'grey.500', // Subdued grey color
                  ml: 2, // Margin left to space out from the button
                  mb:.5,
                  display:'flex',
                  alignItems:'end'
                }}
              >
                Slide: {currentSlide + 1}/{slides.length}
              </Typography>
            </Box>

            {/* Second Row: Navigation Controls */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                mb: -2,
              }}
            >

              {/* Progress dots */}
              <MobileStepper
                steps={slides.length}
                position="static"
                activeStep={currentSlide}
                variant="dots"
                
                nextButton={
                  <Button
                    size="large"
                    onClick={() => {
                      handleNext();
                      console.log(`Navigated to next slide: ${currentSlide + 2}`);
                    }}
                  
                    disabled={currentSlide === slides.length - 1}
                    sx={{
                      color: 'white',
                      fontSize: '2rem',
                      minWidth: '64px',
                    }}
                  >
                    <KeyboardArrowRight fontSize="inherit" />
                  </Button>}
                backButton={
                  < Button
                    size="large"
                    onClick={() => {
                      handleBack();
                      console.log(`Navigated to previous slide: ${currentSlide}`);
                    }}
                    disabled={currentSlide === 0}
                    sx={{
                      color: 'white',
                      fontSize: '2rem',
                      minWidth: '64px',
                    }}
                  >
                    <KeyboardArrowLeft fontSize="inherit" />
                  </Button>
                }
                sx={{
                  backgroundColor: 'transparent',
                  flexGrow: 1,
                  '& .MuiMobileStepper-dot': {
                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                    width: 10,
                    height: 10,
                  },
                  '& .MuiMobileStepper-dotActive': {
                    backgroundColor: 'white',
                  },
                  '& .MuiMobileStepper-dots': {
                    maxWidth: 'calc(100% - 128px)',
                    // hide on small and xs screens 
                    display: { xs: 'none', sm: 'flex' },
                    overflowX: 'hidden',
                    justifyContent: 'center',
                  },
                  '& .MuiMobileStepper-root': {
                    justifyContent: 'space-between',
                    display: 'none',
                  }
                }}
              />
            </Box>

          </Box>
        </Box>
      )}
    </>
  );
};

export default Deck;
