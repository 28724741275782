import { Button, Modal } from 'react-bootstrap';
import { useAppSelector } from 'src/store';

const ExitConfirmation = ({
  showExitConfirmation,
  closeExitConfirmation,
  handleKeepBooking,
}) => {
  const darkMode = useAppSelector( state => state.theme.darkMode )

  return (
    <Modal
      show={showExitConfirmation}
      fullscreen={'sm-down'}
      keyboard={false}
      centered
      onHide={closeExitConfirmation}
      contentClassName="medium-width"
    >
      <Modal.Header>
        <div
          className="py-2 px-3 fw-medium fs-14 go-back"
          role="button"
          onClick={handleKeepBooking}
        >
          <img src={ darkMode ? "/icon/backLight.svg" : "/icon/back.svg" } alt="" />
          &nbsp; Go back
        </div>
      </Modal.Header>
      <Modal.Body>
        {/* LargeScreen */}
        <div className="d-none d-sm-block">
          <p className="fs-4 px-3 fw-medium lh-sm">
            Are you sure you want to leave?
          </p>
          <p className="px-3 fs-14">You'll lose any details you've entered</p>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={handleKeepBooking}>
              <span className="fw-medium">No, keep booking</span>
            </Button>
            <Button variant="primary" onClick={closeExitConfirmation}>
              <span className="fw-medium">Yes</span>
            </Button>
          </Modal.Footer>
        </div>

        {/* Small Screen */}
        <div className="px-3 d-sm-none">
          <p className="fs-4 fw-medium lh-sm text-center">
            Are you sure you want to leave?
          </p>
          <p className="fs-14 text-center">
            You'll lose any details you've entered
          </p>
          <Button
            variant="primary"
            onClick={closeExitConfirmation}
            className="w-100"
          >
            <span className="fw-medium">Yes, leave</span>
          </Button>
          <Button
            variant="outline-dark"
            onClick={handleKeepBooking}
            className="w-100 mt-2"
          >
            <span className="fw-medium">No, keep booking</span>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ExitConfirmation;
