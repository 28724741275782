import ServiceTier from "./ServiceTier";

const TieredFeeListing = ({ showError, handleChange, values, touched, errors, setFieldValue }) =>
  (<ServiceTier
    showError={showError}
    handleChange={handleChange}
    values={values}
    touched={touched}
    errors={errors}
    setFieldValue={setFieldValue}
    tierType="pricing"
  />);

export default TieredFeeListing;
