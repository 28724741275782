import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box } from '@mui/material';

interface EmailCaptureModalProps {
  open: boolean;
  onClose: (email: string, name: string) => void;
}

const EmailCaptureModal: React.FC<EmailCaptureModalProps> = ({ open, onClose }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleSubmit = () => {
    let valid = true;

    // Validate name
    if (!name) {
      setNameError('Name is required');
      valid = false;
      console.log('Name validation failed');
    } else {
      setNameError('');
    }

    // Validate email
    if (!email) {
      setEmailError('Email is required');
      valid = false;
      console.log('Email validation failed: empty');
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Invalid email address');
      valid = false;
      console.log('Email validation failed: invalid format');
    } else {
      setEmailError('');
    }

    // Validate password
    if (password !== 'oppiesgetbusy') {
      setPasswordError('Incorrect password');
      valid = false;
      console.log('Password validation failed');
    } else {
      setPasswordError('');
    }

    if (valid) {
      console.log('Validation passed, submitting form');
      onClose(email, name);
    } else {
      console.log('Validation failed, not submitting form');
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Welcome to Oppy's Deck</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Your Name"
            type="text"
            fullWidth
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            error={!!nameError}
            helperText={nameError} // Show name error
          />
          <TextField
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            error={!!emailError}
            helperText={emailError} // Show email error
          />
          <TextField
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            error={!!passwordError}
            helperText={passwordError} // Show password error
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          View Deck
        </Button>
      </DialogActions>
      {/* Removed DialogFooter as it is not defined */}
      <Box textAlign="center" mt={2}>
        Having trouble viewing the deck? Email us at ag@oppy.pro
      </Box>
    </Dialog>
  );
};

export default EmailCaptureModal;
