import { Modal } from 'react-bootstrap';import { getBranding } from 'src/utils/branding';

const AlertConfirmation = ({ show, onClose, photographer }) => {  
  const branding_logo = getBranding('logo');
  return (
    <Modal
      backdrop="static"
      show={show}
      fullscreen={'sm-down'}
      keyboard={false}
      centered
      contentClassName="medium-width"
      className="alert-confirmation"
      onHide={onClose}
    >
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>
        <div className="d-flex flex-column align-items-center text-center px-3 mb-5">
          <p className="fs-4 px-3 fw-medium lh-sm">Alert Created</p>
        </div>

        <div className="mx-4 text-center">
          <img src="/images/alert.png" alt="" width="85" />

          <p className="px-3 fs-14 mt-3">
            We will be in touch if {photographer?.name} becomes available for this time.
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex remark-label fs-14">
          Powered by 
          <img src={branding_logo} className="me-2" alt="" />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertConfirmation;
