import { useState } from 'react';
import { Nav, Image, Col, Badge, Row } from 'react-bootstrap';
import { useAppSelector } from 'src/store';
import NoEnableService from '../NoEnableService';
import EmbedBookingModal from './EmbedBookingModal';

const ServicesNavigation = ({ servicesAmount, serviceList }) => {
  const [isEnableDashboard] = useState(false);
  const [embedModalShow, setEmbedModalShow] = useState(false);
  const { user } = useAppSelector((state) => state.user);
  const [showNoEnableService, setShowNoEnableService] = useState(false)
  const darkMode = useAppSelector(state => state.theme.darkMode )
  const canAccessChannels = user?.role >= 1

  const rootUrl = window.location.origin
  const bookingPagePath = `/b/${user.uuid}`
  const bookingPageUrl = `${rootUrl}${bookingPagePath}`

  const closeEmbedModal = () => {
    setEmbedModalShow(false);
  };

  const showEmbedModal = () => {
    setEmbedModalShow(true);
  }

  const closeNoEnableService = () => {
    setShowNoEnableService(false);
  }

  const anyEnabledService = () => {
    if (!serviceList){ return false }
    for (var i = 0; i < serviceList.length; i++) {
      if (!serviceList[i].hidden) {
        return true
      }
    }
    return false
  }


  return (
    <>
      <NoEnableService
        show={showNoEnableService}
        handleGoBack={closeNoEnableService}
      />
      <div className="pt-4">
        <EmbedBookingModal showView={embedModalShow} closeView={closeEmbedModal} bookingPageUrl={bookingPageUrl} user={user} />
        {isEnableDashboard && (
          <Row>
            <Col>
              <a className="d-flex align-items-center text-dark" href="">
                <Image className="me-2" src="assets/img/icon-left-chevron.svg" />
                Go to Dashboard
              </a>
            </Col>
          </Row>
        )}
        <Row className="align-items-center">
          <Col xs={12} sm={6}>
            <h3 className="mt-2 fw-bold">
              Services
              <Badge className="border ms-2 px-2" bg="light" text="dark">
                {servicesAmount}
              </Badge>
            </h3>
          </Col>
          <Col xs={12} sm={6}>
            <Nav
              className="justify-content-sm-end nav-horizontal flex-column flex-sm-row"
              activeKey="/home"
            >
              <Nav.Item>
                <Nav.Link
                  className="p-0 me-4 d-flex mb-2 fw-bold"
                  onClick={() => {
                    if(anyEnabledService()){showEmbedModal()}
                    else{setShowNoEnableService(true)}
                  }}
                >
                  <Image className="me-2" src="icon/embeded.svg" />
                  Embed Instant Booking
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  className="p-0 me-4 d-flex mb-2 fw-bold"
                  onClick={(e) => {
                    if(!anyEnabledService()){
                      setShowNoEnableService(true)
                      e.preventDefault()
                    }
                  }}
                  href={bookingPagePath}
                  target="_blank"
                >
                  <Image className="me-2" src="icon/externalLinkIcon.svg" />
                  View as Customer
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ServicesNavigation;
