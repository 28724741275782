import { useEffect, useState } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { getAllBookingServices, getFirstGalleryItems, setPropertyInfo, setSelectedBookingService, setShowListingPrice, setShowSquareFeet, updateBooking } from "src/features/services/booking.slice"
import { useAppDispatch, useAppSelector } from "src/store"
import { isPricingMethodology } from "../Booking/BookingUtils"
import AddServiceToExistingBookingConfirmation from "../ExitConfirmation/AddServiceToExistingBookingConfirmation"
import PropertyInfo from "../PropertyInfo"
import SuccessPopup from "../SuccessPopup"
import { delayTime } from "../ToastMessage"
import DetailedService from "./DetailedService"
import SuggestedServiceItem from "./SuggestedServiceItem"

const SuggestedServices = ({ }) => {
  const [showPropertyInfo, setShowPropertyInfo] = useState(false);
  const [success, setSuccess] = useState(null);
  const { square_feet, listing_price, booking, bookingServices, selectedBookingService, firstGalleryItems } = useAppSelector((state) => state.bookingServices)
  const [showServiceDetail, setShowServiceDetail] = useState(false);
  const [showFullView, setShowFullView] = useState(false);
  const [showAddServiceConfirmation, setShowAddServiceConfirmation] = useState(false);
  const dispatch = useAppDispatch();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const getSuggestedServices = () => {
    dispatch(getAllBookingServices({
      uuid: booking.photographer.uuid,
      listing_price: booking.listing_price,
      address: booking.formatted_address,
      square_feet: booking.area,
      excluded_ids: booking.service_ids
    }));
    dispatch(getFirstGalleryItems({ uuid: booking.photographer.uuid }))
  };

  useEffect(() => {
    getSuggestedServices()
    dispatch(setPropertyInfo({
      address: booking.formatted_address,
      square_feet: booking.area,
      listing_price: booking.listing_price
    }))
  }, [booking.service_ids]);

  const closeServiceDetail = () => {
    setShowServiceDetail(false);
  };

  const addServiceToBooking = (service) => {
    if (!!service.price) {
      dispatch(setSelectedBookingService(service))
      setShowAddServiceConfirmation(true)
    } else {
      if ((isPricingMethodology([service], 'square_footage') || isPricingMethodology([service], 'tiered_sqft_fee')) && !booking.area) {
        dispatch(setShowSquareFeet(true))
      }
      if (isPricingMethodology([service], 'tiered_price_fee') && !booking.listing_price) {
        dispatch(setShowListingPrice(true))
      }
      setShowPropertyInfo(true)
    }
  };

  const closeAddServiceConfirmation = () => {
    setShowAddServiceConfirmation(false)
  };

  const handleAddToCart = () => {
    setShowServiceDetail(false)
    setShowFullView(false)
    addServiceToBooking(selectedBookingService)
  };

  const closePropertyInfo = () => {
    setShowPropertyInfo(false)
    dispatch(setShowListingPrice(false))
    dispatch(setShowSquareFeet(false))
  };

  const handleSubmitPropertyInfo = (data) => {
    dispatch(setPropertyInfo({
      address: booking.formatted_address,
      square_feet: data.square_feet || square_feet,
      listing_price: data.listing_price || listing_price
    }))
    dispatch(getAllBookingServices({
      uuid: booking.photographer.uuid,
      address: booking.formatted_address,
      square_feet: booking.area || data.square_feet,
      listing_price: booking.listing_price || data.listing_price,
      excluded_ids: booking.service_ids
    })).unwrap().then(() => {
      setShowPropertyInfo(false)
      dispatch(setShowSquareFeet(false))
      dispatch(setShowListingPrice(false))
    })
  };

  const checkAvailabilityAndUpdateBooking = (service) => {
    const data = {
      square_feet: square_feet,
      listing_price: listing_price,
      service_ids: [service.id],
    }
    dispatch(updateBooking({ data, booking_uuid: booking.uuid })).unwrap().then(() => {
      setShowAddServiceConfirmation(false)
      setSuccess("Your current booking is updated!")
      setTimeout(() => {
        setSuccess(null)
      }, delayTime);
    }).catch(() => {
      setShowAddServiceConfirmation(false)
    })
  };

  return (
    <>
      {success && <SuccessPopup message={success} />}
      <PropertyInfo
        closePropertyInfo={closePropertyInfo}
        showPropertyInfo={showPropertyInfo}
        onSubmit={handleSubmitPropertyInfo}
        value={{}}
        setValue={() => { }}
        showAddressField={false}
        showErrorPopup={() => { }}
      />
      {showServiceDetail &&  <DetailedService
        handleAddToCart={handleAddToCart}
        closeServiceDetail={closeServiceDetail}
        showServiceDetail={showServiceDetail}
        handleErrorPopup={() => { }}
        uuid={booking.photographer.uuid}
        showFullView={showFullView}
        setShowFullView={setShowFullView}
      />}
      <AddServiceToExistingBookingConfirmation
        showAddServiceConfirmation={showAddServiceConfirmation}
        handleGoBack={closeAddServiceConfirmation}
        service={selectedBookingService}
        booking={booking}
        checkAvailabilityAndUpdateBooking={checkAvailabilityAndUpdateBooking}
      />
      {bookingServices.length ?
        <>
          <Carousel responsive={responsive}>
            {bookingServices.map((service, index) => (
              <div className="mx-3" key={index}>
                <SuggestedServiceItem
                  firstGalleryItem={firstGalleryItems[service.id]}
                  service={service}
                  setShowServiceDetail={setShowServiceDetail}
                  addServiceToBooking={addServiceToBooking}
                />
              </div>
            ))}
          </Carousel>
        </>
        :
        <div className="text-muted">
          No services to show...
        </div>
      }
    </>
  );
};

export default SuggestedServices;
