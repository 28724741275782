import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { trackAnalytics } from "src/features/analytics/analytics.slice";
import { getUserCalendar, updateUserCalendar } from "src/features/user/user.slice";
import { useAppDispatch, useAppSelector } from "src/store";
import { userCalendarSchema } from "src/utils/formSchemas";
import ErrorPopup from "../ErrorPopup";
import SuccessPopup from "../SuccessPopup";
import { delayTime } from "../ToastMessage";

const GoogleCalendarForm = () => {
  const [successMessage, setSuccessMessage] = useState("")
  const { userCalendar, userLoading, userError } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const calendarLength = userCalendar.length
  const selected_calendar = userCalendar.find((elem) => {
    return elem.selected
  })
  const initialValues = {calendar_id: selected_calendar?.id || ''}

  useEffect(() => {
    dispatch(getUserCalendar());
  }, []);

  const onSubmit = (data) => {
    dispatch(updateUserCalendar(data))
    .unwrap()
    .then((response) => {
      dispatch(trackAnalytics({action_name: "google_calendar_selected", payload: { description: "user updated google calendar form", calendar_id: data.calendar_id}}))
      setSuccessMessage("Successfully Updated")
      setTimeout(() => {
        setSuccessMessage("")
      }, delayTime)
    })
  };


  return(
    <>
      <h2 className="mb-2">Select Your Calendar</h2>
      {userError && <ErrorPopup message={userError}/>}
      {successMessage && <SuccessPopup message={successMessage}/>}
      <p className="mb-4">
        We will use your Google Calendar to book open slots based on the booking availability.
        If you do not wish to be booked during one of those times,
        create a placeholder event on your calendar.
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={userCalendarSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          resetForm,
          values,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            {userCalendar.length === 0 && !userError && userLoading && (
              <div>Loading....</div>
            )}

            <div className="mb-3">
                {userCalendar.map((item, i) => (
                  <Form.Group key={item.id} className="mb-3">
                    <Form.Check
                      type="radio"
                      label={item.summary}
                      id={item.id}
                      value={item.id}
                      name="calendar_id"
                      disabled={userLoading}
                      onChange={handleChange}
                      isValid={touched.calendar_id && !errors.calendar_id}
                      isInvalid={!!errors.calendar_id}
                      checked={
                        values.calendar_id === item.id
                      }
                    />
                    {i+1 == calendarLength && !!errors.calendar_id && (
                      <div className="text-danger mt-2">
                        {errors.calendar_id}
                      </div>
                    )}
                  </Form.Group>
                ))}
            </div>

            <hr className="mt-5 mb-4" />
            <div className="action-buttons">
              <Button variant="primary" type="submit" disabled={userLoading}>
                Save
              </Button>
              <Button variant="outline-secondary" className="me-lg-3" onClick={() => resetForm()}>
                Cancel
              </Button>{' '}
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default GoogleCalendarForm;
