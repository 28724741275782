import { useState } from 'react';
import { Formik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { embedFormSchema } from 'src/utils/formSchemas';
import { useAppDispatch } from 'src/store';
import { uploadGalleryItem } from 'src/features/services/gallery.slice';
import { getBranding } from 'src/utils/branding';
const UploadEmbedGalleryItemV2 = ({ galleriableType,galleriableId, show, close }) => {
  const initialValues = {
    title: '',
    url: '',
    iframeTag: '',
  };

  const dispatch = useAppDispatch();
  const [showIframeInput, setShowIframeInput] = useState(false);
  const [invalidIframeTag, setInvalidIframeTag] = useState(null);
  const branding_logo = getBranding('logo');
  const onSubmit = (data) => {
    dispatch(uploadGalleryItem({
      galleriable_id: galleriableId,
      galleriable_type: galleriableType,
      itemable_type: "EmbedCode",
      embed_params: { url: data.url, title: data.title }
    }))
    close()
  };

  const setUrl = (e, setFieldValue) => {
    const validURL = new RegExp('src="([^"]+)"');
    const matchURL = e.target.value.match(validURL)
    if (matchURL) {
      setFieldValue("url", matchURL[1])
      setInvalidIframeTag(null)
    }
    else if (e.target.value === "") {
      setInvalidIframeTag(null)
    }
    else { setInvalidIframeTag("Invalid iframe code !") }
  };

  return (
    <Modal
      backdrop="static"
      show={show}
      size="lg"
      keyboard={false}
      centered
      onHide={() => {
        setShowIframeInput(false)
        close()
      }}
    >
      <Modal.Header closeButton>
        <>
          <div className="me-auto d-flex align-items-center fs-12 d-none d-sm-block ms-3">
            Powered by 
            <img src={branding_logo} className="me-2" alt="" />
          </div>
        </>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center text-center">
          <p className="fs-4 px-3 fw-medium lh-sm">Add embed code</p>
          <p className="px-3 fs-14">
          </p>
          <div className='w-100'>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize
              validationSchema={embedFormSchema}
            >
              {({
                handleSubmit,
                handleChange,
                setFieldValue,
                resetForm,
                values,
                touched,
                errors,
              }) => {
                return (
                  <Form noValidate onSubmit={handleSubmit}>
                    <div className="mb-32">
                      <Form.Label htmlFor="formatted_address">
                        Title <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        value={values.title}
                        id="title"
                        name="title"
                        type="text"
                        className="mb-4"
                        onChange={handleChange}
                        isValid={touched.title && !errors.title}
                        isInvalid={touched.title && !!errors.title}
                      />
                      <Form.Control.Feedback type="invalid">
                        <>{errors.title}</>
                      </Form.Control.Feedback>
                    </div>

                    <div className="mb-32">
                      <Form.Label htmlFor="formatted_address">
                        URL <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        value={values.url}
                        id="url"
                        name="url"
                        type="text"
                        className="mb-4"
                        onChange={handleChange}
                        isValid={touched.url && !errors.url}
                        isInvalid={touched.url && !!errors.url}
                      />
                      <Form.Control.Feedback type="invalid">
                        <>{errors.url}</>
                      </Form.Control.Feedback>
                    </div>

                    <h3>-OR-</h3>
                    <br />

                    {showIframeInput ? <div className="mb-32">
                      <Form.Label htmlFor="formatted_address">
                        Embed Code (Supersedes URL Input Above) <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        value={values.iframeTag}
                        id="iframeTag"
                        name="iframeTag"
                        as="textarea"
                        rows={2}
                        className="mb-4"
                        onChange={(e) => {
                          handleChange(e)
                          setUrl(e, setFieldValue)
                        }}
                      />
                      <p className='text-danger'>
                        {invalidIframeTag}
                      </p>
                    </div> :
                      <Button
                        className="w-100 mb-3"
                        variant="primary"
                        onClick={() => setShowIframeInput(true)}
                      >
                        <span className="fw-medium">{"Add <iframe> Manually"}</span>
                      </Button>
                    }

                    <Button
                      className="w-50 mb-3"
                      variant="primary"
                      type="submit"
                    >
                      <span className="fw-medium">Add Embed Gallery Item</span>
                    </Button>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UploadEmbedGalleryItemV2;
