import axios, { AxiosInstance } from 'axios';
import authToken from 'src/services/auth-header';

export const baseURL = process.env.REACT_APP_API_URL

const httpClient = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const plainHttpClient = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const attachAuthInterceptor = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    if (!config.headers.common['Authorization']) {
      const token = authToken();
      if (token) {
        config.headers.common['Authorization'] = `Bearer ${token}`;
      }
    }
    return config;
  });
};

attachAuthInterceptor(httpClient);

export default httpClient;
