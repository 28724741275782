import { useEffect } from 'react';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'src/store';
import FlatFee from './pricing-types/FlatFee';
import SquareFootage from './pricing-types/SquareFootage';
import TieredFeeListing from './pricing-types/TieredFeeListing';
import TieredFeeSq from './pricing-types/TieredFeeSq';
import UnitTier from './pricing-types/UnitTier';
import subscriptionPopup from 'src/components/Subscription/SubscriptionPopup';

const PriceSelector = ({
  showError,
  handleChange,
  setFieldValue,
  values,
  touched,
  errors,
}) => {
  const [currentPriceType, setCurrentPriceType] = useState(null);
  const { subscriptionFeatures } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (values.service_pricing_attributes.pricing_methodology) {
      setSelected(values.service_pricing_attributes.pricing_methodology)
    }
  }, [values, errors, touched, showError])

  //TODO convert into a High order component to prevent prop drilling
  const setSelected = (priceType: string) => {
    switch (priceType) {
      case 'flat_fee':
        setCurrentPriceType(() => (
          <FlatFee
            showError={showError}
            handleChange={handleChange}
            values={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
          />
        ));
        return;
      case 'square_footage':
        setCurrentPriceType(() => (
          <SquareFootage
            showError={showError}
            handleChange={handleChange}
            values={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
          />
        ));
        return;
      case 'tiered_sqft_fee':
        setCurrentPriceType(() => (
          <TieredFeeSq
            showError={showError}
            handleChange={handleChange}
            values={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
          />
        ));
        return;
      case 'tiered_price_fee':
        setCurrentPriceType(() => (
          <TieredFeeListing
            showError={showError}
            handleChange={handleChange}
            values={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
          />
        ));
        return;
      case 'Unit':
        setCurrentPriceType(() => (
          <UnitTier
            handleChange={handleChange}
            values={values}
            touched={touched}
            errors={errors}
          />
        ));
        return;
      default:
        setCurrentPriceType(null);
        return;
    }
  };

  return (
    <>
      <Form.Group>
        <Form.Label
          htmlFor="service_pricing_attributes.pricing_methodology"
          className="fs-14 required fw-medium mb-1"
        >
          Price Based On
        </Form.Label>
        <Form.Select
          aria-label="Choose a price type"
          className="mb-3"
          id="service_pricing_attributes.pricing_methodology"
          name="service_pricing_attributes.pricing_methodology"
          value={values.service_pricing_attributes.pricing_methodology}
          isValid={touched.service_pricing_attributes?.pricing_methodology && !errors.service_pricing_attributes?.pricing_methodology}
          isInvalid={touched.service_pricing_attributes?.pricing_methodology && !!errors.service_pricing_attributes?.pricing_methodology}
          onChange={(event) => {
            if (!event.target.value || subscriptionFeatures?.variable_service_pricing?.allowed_values.some(value => value === event.target.value)) {
              setFieldValue('service_pricing_attributes.pricing_methodology', event.target.value);
              setSelected(event.target.value);
            } else { subscriptionPopup(subscriptionFeatures, "Only Fixed Service Price method is available for this plan", { feature_id: "variable_service_pricing", description: "Provider can only access the fixed pricing methodology" }, dispatch) }
          }}
        >
          <option value="">Pricing Method</option>
          <option value="flat_fee">Fixed Price (Regardless of space)</option>
          <option value="square_footage">Per Square Foot (With Min & Max)</option>
          <option value="tiered_sqft_fee">Square Footage Tiers</option>
          <option value="tiered_price_fee">Listing Price Tiers (Real estate specific)</option>
          {/* <option value="Unit">Unit</option>
          <option value="TieredUnit">Tiered Fee, Unit</option> */}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          <>{errors.service_pricing_attributes?.pricing_methodology}</>
        </Form.Control.Feedback>
      </Form.Group>
      {currentPriceType}
    </>
  );
};

export default PriceSelector;
