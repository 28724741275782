import CloudinaryVideoTag from "../CloudinaryVideoTag";

const PreviewGalleryItem = ({ item }) => {
  switch (item.itemable_type) {
    case "Video":
      return <div className='w-100 h-100' key={"preview-video"} >
        <CloudinaryVideoTag cloudinary_public_id={item?.video_public_id} />
      </div>
    case "EmbedCode":
      return <iframe
        key={"preview-embedcode"}
        src={item.embed_params.url}
        title={item.embed_params.title}
        className="w-100 h-100"
        loading="lazy"
      />
    case "Photo":
      return <img
        key={"preview-image"}
        className="d-block mw-100 h-100 mx-auto img-cover"
        src={item.itemable_url}
        alt="Image"
      />
    default:
      return <div key={"preview-default"}></div>
  }
}

export default PreviewGalleryItem;
