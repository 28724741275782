import React from 'react';
import { Typography } from '@mui/material';
import { useAppDispatch } from 'src/store';
import { updateConversationStatus } from 'src/features/account/conversations.slice';

const CloseConversationButton = ({ conversation }) => {
  const dispatch = useAppDispatch();

  const handleCloseConversation = (event) => {
    event.preventDefault(); // Prevent default action
    event.stopPropagation();
    
    if (conversation.status === 'loading') return; // Prevent action if already loading

    dispatch(updateConversationStatus({ conversationId: conversation.id, status: 'closed', channelId: conversation.channel_id }))

  };

  return (
    <Typography
      sx={{
        cursor: conversation.status === 'loading' ? 'default' : 'pointer',
        color: 'lightcoral',
        fontSize: '0.875rem',
        animation: conversation.status === 'loading' ? 'pulsate 1.5s infinite' : 'none',
        display: conversation.status === 'closed' ? 'none' : ''
      }}
      onClick={handleCloseConversation}
    >
      {conversation.status === 'loading' ? 'Closing...' : 'Close conversation'}
    </Typography>
  );
};

export default CloseConversationButton;

// Add this CSS in your global styles or component styles
// @keyframes pulsate {
//   0% { opacity: 1; }
//   50% { opacity: 0.5; }
//   100% { opacity: 1; }
// }
