import AccountForm from 'src/components/profile/AccountForm';
import ProfileLayout from 'src/components/profile/ProfileLayout';

const Profile = () => {
  return (
    <ProfileLayout activeKey="account" >
      <AccountForm />
    </ProfileLayout>
  );
};

export default Profile;
