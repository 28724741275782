const branding = {
    logo: "/images/oppy_wordmarks/oppy_blue.svg",
    oppy_expressions_smiling: "/images/oppies/Oppy_Expressions_smiling.svg",
    oppy_word_mark: "/images/oppy_wordmarks/oppy_blue.svg",
    oppy_word_mark_yellow: "/images/oppy_wordmarks/oppy_yellow.svg",
    oppy_expressions_no_mouth: "/images/oppies/Oppy_Expressions_no_mouth.svg",
    oppy_expressions_drunk: "/images/oppies/Oppy_Expressions_drunk.svg",
    oppy_conversation_icon: "/images/oppy_chat_yellow.svg",
    oppy_wave_animation: "/lotties/Oppy_Wave.json",

    company: "Oppy",
    meta: {
        title: "Your Meta Title",
        video: "Your Meta VideoUrl"
    }
};

export function getBranding(key = "logo") {
    return branding[key];
}
