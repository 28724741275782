import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Popover, Slider, Typography, Tabs, Tab, TextField, FormControlLabel, Switch, Select, MenuItem, Button } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import * as THREE from 'three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';

// Add SVG data here
const oppySvgData = `<?xml version="1.0" encoding="UTF-8"?>
<svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1200 1200">
  <!-- Generator: Adobe Illustrator 29.0.1, SVG Export Plug-In . SVG Version: 2.1.0 Build 192)  -->
  <defs>
    <style>
      .st0 {
        fill: #fff;
      }

      .st1 {
        fill: #3d3ac9;
      }
    </style>
  </defs>
  <path class="st1" d="M947.7,795.2c-28.6,14.2-62.7,23.4-95,18.3-16-2.5-31.2-8.9-43.8-19.2-13.5-11.1-28.2-29.2-34.3-45.6-.4-1.2-.8-2.3-1.1-3.5,5-.5,10.8,1.1,15.8,1.8,32.2,4.4,63.3,6.7,95-2.4,27.8-8.1,53.7-23,73.7-44.1,25.8-27.2,40.1-62.9,46.2-99.6,2.5-15,3.7-30.2,4-45.3.8-41.8-5.4-83.5-16.1-123.8-9.7-36.5-23.7-71.6-36.9-106.9-8.9-23.8-13.7-62.8,7.4-82.4,4.7-4.4,10.1-6.9,15.8-8.2,5.5-1.2,9.7-5.9,9.5-11.4-.2-4.2-1.9-7.9-6.2-10-2.9-1.5-6.1-2.7-9.5-3.6-7.5-2.1-15.5-1.5-22.6,1.5h-.1c-11.4,4.8-66,31-60,113,.6,8.6,2.1,21.7,3.3,30.4,3.5,25,6.2,49.7,7.8,74.7,3,50.3,6.2,108-25,150.9-11.7,16-27.5,29.1-45.5,37.3-10.1,4.6-47.6,19.3-47.2,1.5.5-22.2,4.4-43.8,9.7-65.1-20.9,10.2-44,15.7-67.2,15.3-34-.6-68-14.4-91.3-39.2-6-6.4-11.5-14.1-12.1-22.8h-.5l.5-.9c0,.3,0,.6,0,.9h183.2c10.5-37.8,19.8-75.7,15.5-116-4.6-42-19.9-82.9-45.2-116.8-12.4-16.6-27.1-31.5-43.7-44-44.5-33.6-103.9-45.6-162.8-32.8-61.8,13.4-113.6,51.3-142.1,103.9-25.4,46.8-36,105.4-30.9,169.5,2.4,30.3,9.4,58.2,16.1,85.2,7.8,31.3,15.1,61,13.8,89.3-69.6-26.1-126.1-122-130.8-193.8-2.8-41.9,4.5-84.2,14-124.9,5.4-22.9,17.6-49.1,18.3-72.7.5-15.3-9.3-28.5-26-22.5-.8.3-1.7.6-2.5,1-11.5,5.1-22.8,29-29.1,38.7-18.9,29-35.4,59.8-48.8,91.8-32.3,77-39,151.6-12.6,231.7,31.9,96.7,103.8,176.2,213.5,165.4-7.8,9.5-15.9,18.5-24.9,27.1-23.4,22.9-52.3,37.1-84.8,36.4-63.8,4.6-143.7-78.5-193.1-119.4-6.6,36.2-2.7,72.2,6.7,107.5,21.9,82.1,86.7,153.5,174.7,165.8,26.4,3.7,53.8,4.3,79.9-2.3,14.7-3.7,29-8.4,43.1-14.1,7-2.8,14-5.9,20.8-9.1,1.2-.6,18.6-8.8,18.6-8.5.9,5-5.5,14.6-7.8,19.3-3.2,6.6-6.7,13-10.5,19.3-7.1,11.7-15.1,22.9-24.2,33.2-24.6,28.1-45.9,37.1-109.9,47.8-77.1,12.8-72.6,63.1-74.5,65.3h344.2c1.8-5.9,17.1-221.9,29.2-222h.7c12.2-.1,27.9,217.2,29.4,222h344.2c-1.9-2.2,2.6-52.4-74.5-65.3-33.5-5.6-70.1-11.2-96.5-34.2-19.5-17-35.2-38.3-46.5-61.5-5.8-11.8-10.5-24.1-14.1-36.8-1.6-5.7-3.1-11.4-4.6-17.2-.4-1.5-1.1-9-2.2-9.8,17,13.3,36.8,23.6,59.7,29.9,74.2,19.7,151.8-5.5,203.8-60.1,44.9-47.1,73.7-110.3,76.2-174.3-41.9,33.2-90.5,73.5-136.8,96.5h0Z"/>
  <path class="st0" d="M721.3,340.5h0c-23.1-10-44.3-7.8-62.7,0-19.8,8.5-36.3,23.5-48.1,36.9-16.4-18.8-32.3-30.5-47.4-37-23.1-10-44.3-7.8-62.7,0-41.6,17.8-68.4,64.5-68.4,64.5,23.9,35.6,46.9,55.3,68.4,64.6,23.1,10,44.3,7.8,62.7,0,19.8-8.5,36.3-23.5,48.1-36.9,16.4,18.8,32.3,30.5,47.4,37,23.1,10,44.3,7.8,62.7,0,41.6-17.8,68.4-64.5,68.4-64.5-23.9-35.6-46.9-55.4-68.4-64.6ZM528.8,457.6c-26.4-1.4-48.1-23-49.6-49.4-1.7-30.1,22-55.1,51.6-55.6,0,0,0,.2,0,.3v24.8c0,7.9,6.9,15.4,15.1,15.1,1.3,0,31.2.8,37,.1.9,3.9,1.5,8,1.5,12.2,0,30-25.2,54.2-55.5,52.5ZM687,457.6c-26.4-1.4-48.1-23-49.6-49.4-1.7-30.1,22-55.1,51.6-55.6,0,0,0,.2,0,.3v24.8c0,7.9,6.9,15.4,15.1,15.1,1.3,0,31.2.8,37,.1.9,3.9,1.5,8,1.5,12.2,0,30-25.2,54.2-55.5,52.5Z"/>
</svg>`;

// Add these constants at the top after imports
const LOGO_LAYER = 0;
const FOG_LAYER = 1;

// Add the enhanced fog shader
const fogShader = {
  uniforms: {
    time: { value: 1 },
    fogHeight: { value: 100.9 },
    fogColor: { value: new THREE.Color(0x101010) },
    cameraPos: { value: new THREE.Vector3() }
  },
  vertexShader: `
    varying vec2 vUv;
    varying float vElevation;
    varying vec4 vWorldPosition;
    
    void main() {
      vUv = uv;
      vec4 modelPosition = modelMatrix * vec4(position, 1.0);
      vWorldPosition = modelPosition;
      vElevation = modelPosition.y;
      gl_Position = projectionMatrix * viewMatrix * modelPosition;
    }
  `,
  fragmentShader: `
    uniform float time;
    uniform float fogHeight;
    uniform vec3 fogColor;
    uniform vec3 cameraPos;
    varying vec2 vUv;
    varying float vElevation;
    varying vec4 vWorldPosition;

    float random(vec2 st) {
      return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
    }

    float noise(vec2 st) {
      vec2 i = floor(st);
      vec2 f = fract(st);
      vec2 u = f * f * (3.0 - 2.0 * f);
      return mix(
        mix(random(i), random(i + vec2(1.0, 0.0)), u.x),
        mix(random(i + vec2(0.0, 1.0)), random(i + vec2(1.0, 1.0)), u.x),
        u.y
      );
    }

    float fbm(vec2 st) {
      float value = 0.0;
      float amplitude = 0.5;
      float frequency = 1.0;
      float rotation = time * 0.1;
      
      mat2 rot = mat2(
        cos(rotation), -sin(rotation),
        sin(rotation), cos(rotation)
      );
      
      for (int i = 0; i < 6; i++) {
        st = rot * st;
        value += amplitude * noise(st * frequency);
        st = st * 2.1 + time * 0.1;
        amplitude *= 0.5;
        frequency *= 2.0;
      }
      return value;
    }

    float billowNoise(vec2 st) {
      return abs(fbm(st) * 2.0 - 1.0);
    }

    void main() {
      float distFromCamera = distance(vWorldPosition.xz, cameraPos.xz);
      float edgeFalloff = smoothstep(50.0, 5.0, distFromCamera);
      
      float baseNoise = billowNoise(vUv * 8.0 + time * 0.1);
      float secondaryNoise = billowNoise(vUv * 4.0 - time * 0.15);
      float tertiaryNoise = billowNoise(vUv * 12.0 + time * 0.05);
      
      vec2 swirl = vec2(
        sin(time * 0.2 + vUv.y * 3.0),
        cos(time * 0.3 + vUv.x * 3.0)
      );
      float swirlNoise = billowNoise(vUv + swirl * 0.2);
      
      float finalNoise = baseNoise * 0.5 + 
                        secondaryNoise * 0.3 + 
                        tertiaryNoise * 0.2 + 
                        swirlNoise * 0.4;
      
      float heightFalloff = smoothstep(fogHeight, 0.0, vElevation);
      
      vec2 centered = vUv * 2.0 - 1.0;
      float radialDist = length(centered);
      float radialFalloff = smoothstep(1.0, 0.2, radialDist);
      
      float opacity = finalNoise * heightFalloff * radialFalloff * edgeFalloff;
      opacity = smoothstep(0.1, 1.0, opacity) * 0.95;
      
      gl_FragColor = vec4(fogColor, opacity);
    }
  `
};

// Add glitter shader code at the top of the file
const glitterShader = {
  uniforms: {
    time: { value: 0 },
    glitterSize: { value: 30.0 },
    glitterDensity: { value: 1.0 },
    glitterColor: { value: new THREE.Color('#ffffff') }
  },
  vertexShader: `
    varying vec2 vUv;
    varying vec3 vNormal;
    varying vec3 vViewPosition;
    
    void main() {
      vUv = uv;
      vNormal = normalize(normalMatrix * normal);
      vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
      vViewPosition = -mvPosition.xyz;
      gl_Position = projectionMatrix * mvPosition;
    }
  `,
  fragmentShader: `
    float random(vec2 st) {
      return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
    }
    
    float noise(vec2 st) {
      vec2 i = floor(st);
      vec2 f = fract(st);
      
      float a = random(i);
      float b = random(i + vec2(1.0, 0.0));
      float c = random(i + vec2(0.0, 1.0));
      float d = random(i + vec2(1.0, 1.0));
      
      vec2 u = f * f * (3.0 - 2.0 * f);
      return mix(a, b, u.x) + (c - a)* u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
    }
    
    float glitter(vec2 uv, float size, float density) {
      vec2 st = uv * size;
      float sparkle = noise(st + time * 2.0);
      sparkle *= noise(st * 2.1 - time * 3.0);
      sparkle = pow(sparkle, 10.0) * density;
      return sparkle;
    }
  `
};

// Create a common TextField style object to use across all inputs
const textFieldStyle = {
  '& .MuiInputBase-input': {
    color: 'rgba(255, 255, 255, 0.87)', // Bright text
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.4)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.7)',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
  '& .MuiSelect-icon': {
    color: 'rgba(255, 255, 255, 0.7)',
  },
};

const ThreeDOppySlide: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const mainGroupRef = useRef<THREE.Group | null>(null);
  const spotlightRef = useRef<THREE.SpotLight | null>(null);
  const materialsRef = useRef<THREE.Material[]>([]);
  const meshesRef = useRef<THREE.Mesh[]>([]);
  
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  
  const [settings, setSettings] = useState({
    thickness: 200,
    rotationSpeed: 0.005,
    size: 6,
    bevelSize: 5,
    bevelThickness: 5,
    height: 0,
    positionX: 0,
    positionZ: 10,
    rotation: {
      x: 0,
      y: 0,
      z: 0,
    },
    scale: {
      x: 1,
      y: 1,
      z: 1
    },
    bevelSegments: 8,
    curveSegments: 30,
    bevelEnabled: true,
    materialSettings: {
      metalness: 1,
      roughness: 0.2,
      envMapIntensity: 3.0,
      clearcoat: 0.0,
      clearcoatRoughness: 0.1,
      color: '#ffd700',
      emissive: '#ff9500',
      emissiveIntensity: 0,
      opacity: 1.0,
      wireframe: false,
      sparkleEnabled: true,
      sparkleColor: '#ffffff',
      sparkleIntensity: 1.0,
      sparkleSpeed: 10.0
    }
  });

  const [lightSettings, setLightSettings] = useState({
    spotlight: {
      intensity: 3000.5,
      color: '#ffffff',
      angle: (136 * Math.PI) / 180,
      penumbra: 0.8,
      decay: 1.5,
      distance: 200,
      position: {
        x: 0,
        y: 10,
        z: 30
      },
      target: {
        x: 0,
        y: 0,
        z: 0
      },
      castShadow: true,
      shadowBias: -0.0001,
      shadowMapSize: 1024,
      shadowCameraFov: 50,
      shadowCameraNear: 1,
      shadowCameraFar: 100
    }
  });

  const [activeTab, setActiveTab] = useState('controls'); // 'controls', 'lighting'

  const [fogSettings, setFogSettings] = useState({
    height: 0.9,
    color: '#101010',
    layers: 5,
    scale: 1.0
  });

  const [eyeSettings, setEyeSettings] = useState({
    leftEye: {
      depth: .99,
      bevelThickness: 1.5,
      bevelSize: 1.5,
      bevelOffset: -2,
      metalness: 0.3,
      roughness: 0.3,
      clearcoat: 1.0,
      clearcoatRoughness: 0.1,
      emissive: '#000000',
      emissiveIntensity: 0,
      inner: {
        depth: .9,
        bevelThickness: 1.0,
        bevelSize: 1.0,
        bevelOffset: -1,
        metalness: 0.5,
        roughness: 0.2,
        color: '#3d3ac9',
        emissive: '#000000',
        emissiveIntensity: 0
      }
    },
    rightEye: {
      depth: .99,
      bevelThickness: 1.5,
      bevelSize: 0.5,
      bevelOffset: 2,
      metalness: 0.3,
      roughness: 0.3,
      clearcoat: 1.0,
      clearcoatRoughness: 0.1,
      emissive: '#000000',
      emissiveIntensity: 0,
      inner: {
        depth: 1.3,
        bevelThickness: 1.0,
        bevelSize: 1.0,
        bevelOffset: -1,
        metalness: 0.5,
        roughness: 0.2,
        color: '#3d3ac9',
        emissive: '#000000',
        emissiveIntensity: 0
      }
    }
  });

  useEffect(() => {
    if (!containerRef.current) return;

    console.log('Setting up scene...');

    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0x000000);
    
    const camera = new THREE.PerspectiveCamera(
      45,
      containerRef.current.clientWidth / containerRef.current.clientHeight,
      0.1,
      1000
    );
    
    camera.position.set(0, 2, 30);
    camera.lookAt(0, 0, 0);

    const renderer = new THREE.WebGLRenderer({ 
      antialias: true,
      alpha: true 
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(containerRef.current.clientWidth, containerRef.current.clientHeight);
    containerRef.current.appendChild(renderer.domElement);

    // Enhance lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 1.0);
    scene.add(ambientLight);

    const spotlight = new THREE.SpotLight(
      new THREE.Color(lightSettings.spotlight.color),
      lightSettings.spotlight.intensity
    );
    spotlightRef.current = spotlight;
    spotlight.position.set(
      lightSettings.spotlight.position.x,
      lightSettings.spotlight.position.y,
      lightSettings.spotlight.position.z
    );
    spotlight.target.position.set(
      lightSettings.spotlight.target.x,
      lightSettings.spotlight.target.y,
      lightSettings.spotlight.target.z
    );

    spotlight.castShadow = lightSettings.spotlight.castShadow;
    spotlight.shadow.bias = lightSettings.spotlight.shadowBias;
    spotlight.shadow.mapSize.width = lightSettings.spotlight.shadowMapSize;
    spotlight.shadow.mapSize.height = lightSettings.spotlight.shadowMapSize;
    spotlight.shadow.camera.fov = lightSettings.spotlight.shadowCameraFov;
    spotlight.shadow.camera.near = lightSettings.spotlight.shadowCameraNear;
    spotlight.shadow.camera.far = lightSettings.spotlight.shadowCameraFar;

    spotlight.angle = lightSettings.spotlight.angle;
    spotlight.penumbra = lightSettings.spotlight.penumbra;
    spotlight.decay = lightSettings.spotlight.decay;
    spotlight.distance = lightSettings.spotlight.distance;

    scene.add(spotlight);
    scene.add(spotlight.target);

    // Create Oppy 3D
    const loader = new SVGLoader();
    
    // Create blob URL from SVG data
    const blob = new Blob([oppySvgData], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    
    // Process SVG paths separately for body and eyes
    const mainGroup = new THREE.Group();
    mainGroupRef.current = mainGroup;
    
    // Main body extrusion settings
    const bodyExtrudeSettings = {
      depth: settings.thickness,
      bevelEnabled: settings.bevelEnabled,
      bevelThickness: settings.bevelThickness,
      bevelSize: settings.bevelSize,
      bevelOffset: 0,
      bevelSegments: settings.bevelSegments,
      curveSegments: settings.curveSegments
    };

    // Eyes extrusion settings (deeper)
    const eyesExtrudeSettings = {
      ...bodyExtrudeSettings,
      depth: settings.thickness * 1.2 // Eyes slightly deeper
    };

    // Change the loader.load call to use the blob URL
    loader.load(url, (data) => {
      console.log('SVG loaded, creating meshes...');
      data.paths.forEach((path, index) => {
        const shapes = SVGLoader.createShapes(path);
        
        shapes.forEach((shape) => {
          // Determine if this is part of the eyes (white paths)
          const isEyes = path.color.equals(new THREE.Color(0xffffff));
          // Check if this is the inner part of the eye (the blue part)
          const isInnerEye = path.color.equals(new THREE.Color(0x3d3ac9));
          
          let extrudeSettings = bodyExtrudeSettings;
          let eyeConfig = null;

          if (isEyes) {
            const center = shape.getPoint(0);
            const isLeftEye = center.x > 0;
            eyeConfig = isLeftEye ? eyeSettings.leftEye : eyeSettings.rightEye;
            
            if (isInnerEye) {
              extrudeSettings = {
                ...bodyExtrudeSettings,
                depth: settings.thickness * eyeConfig.inner.depth,
                bevelThickness: settings.bevelThickness * eyeConfig.inner.bevelThickness,
                bevelSize: settings.bevelSize * eyeConfig.inner.bevelSize,
                bevelOffset: eyeConfig.inner.bevelOffset,
              };
            } else {
              extrudeSettings = {
                ...bodyExtrudeSettings,
                depth: settings.thickness * eyeConfig.depth,
                bevelThickness: settings.bevelThickness * eyeConfig.bevelThickness,
                bevelSize: settings.bevelSize * eyeConfig.bevelSize,
                bevelOffset: eyeConfig.bevelOffset,
              };
            }
          }
          
          const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
          
          // Different materials for body and eyes
          const material = new THREE.MeshPhysicalMaterial({
            color: isEyes ? 
              (isInnerEye ? new THREE.Color(eyeConfig.inner.color) : path.color) : 
              new THREE.Color(settings.materialSettings.color),
            metalness: isEyes ? 
              (isInnerEye ? eyeConfig.inner.metalness : eyeConfig.metalness) : 
              settings.materialSettings.metalness,
            roughness: isEyes ? 
              (isInnerEye ? eyeConfig.inner.roughness : eyeConfig.roughness) : 
              settings.materialSettings.roughness,
            envMapIntensity: settings.materialSettings.envMapIntensity,
            clearcoat: isEyes ? 
              (isInnerEye ? eyeConfig.inner.clearcoat : eyeConfig.clearcoat) : 
              settings.materialSettings.clearcoat,
            clearcoatRoughness: isEyes ? 
              (isInnerEye ? eyeConfig.inner.clearcoatRoughness : eyeConfig.clearcoatRoughness) : 
              settings.materialSettings.clearcoatRoughness,
            emissive: new THREE.Color(isEyes ? 
              (isInnerEye ? eyeConfig.inner.emissive : eyeConfig.emissive) : 
              settings.materialSettings.emissive),
            emissiveIntensity: isEyes ? 
              (isInnerEye ? eyeConfig.inner.emissiveIntensity : eyeConfig.emissiveIntensity) : 
              settings.materialSettings.emissiveIntensity,
            opacity: settings.materialSettings.opacity,
            transparent: settings.materialSettings.opacity < 1,
            wireframe: settings.materialSettings.wireframe,
            side: THREE.DoubleSide,
            polygonOffset: true,
            polygonOffsetFactor: isEyes ? -1 : 0,
            polygonOffsetUnits: isEyes ? -1 : 0,
            depthWrite: true,
            depthTest: true
          });

          if (settings.materialSettings.sparkleEnabled && !isEyes) {
            material.onBeforeCompile = (shader) => {
              // Add uniforms
              shader.uniforms = {
                ...shader.uniforms,
                time: { value: 0 },
                glitterSize: { value: 100.0 },
                glitterDensity: { value: 2.0 },
                glitterColor: { value: new THREE.Color(settings.materialSettings.sparkleColor) }
              };

              // Add glitter functions before main
              shader.fragmentShader = shader.fragmentShader.replace(
                'void main() {',
                `
                uniform float time;
                uniform float glitterSize;
                uniform float glitterDensity;
                uniform vec3 glitterColor;

                float random(vec2 st) {
                  return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
                }
                
                float noise(vec2 st) {
                  vec2 i = floor(st);
                  vec2 f = fract(st);
                  float a = random(i);
                  float b = random(i + vec2(1.0, 0.0));
                  float c = random(i + vec2(0.0, 1.0));
                  float d = random(i + vec2(1.0, 1.0));
                  vec2 u = f * f * (3.0 - 2.0 * f);
                  return mix(a, b, u.x) + (c - a)* u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
                }
                
                float glitter(vec2 uv) {
                  vec2 st = uv * glitterSize;
                  float sparkle = noise(st + time * 2.0);
                  sparkle *= noise(st * 2.1 - time * 3.0);
                  sparkle = pow(sparkle, 10.0) * glitterDensity;
                  return sparkle;
                }

                void main() {
                `
              );

              // Add glitter effect at the very end of the shader
              shader.fragmentShader = shader.fragmentShader.replace(
                'gl_FragColor = vec4( outgoingLight, diffuseColor.a );',
                `
                  float glitterEffect = glitter(vUv);
                  vec3 finalColor = outgoingLight + glitterColor * glitterEffect * ${settings.materialSettings.sparkleIntensity.toFixed(1)};
                  gl_FragColor = vec4(finalColor, diffuseColor.a);
                `
              );

              material.userData.shader = shader;
            };

            material.needsUpdate = true;
          }

          const mesh = new THREE.Mesh(geometry, material);
          mainGroup.add(mesh);
        });
      });

      // Scale and position the entire group
      mainGroup.scale.y *= -1; // Flip SVG coordinates
      
      const box = new THREE.Box3().setFromObject(mainGroup);
      const center = box.getCenter(new THREE.Vector3());
      const size = box.getSize(new THREE.Vector3());
      
      const scale = settings.size / Math.max(size.x, size.y);
      mainGroup.scale.multiplyScalar(scale);

      // First move the group's geometry to center
      mainGroup.children.forEach(child => {
        child.position.x -= center.x;
        child.position.z -= center.z;
      });
      
      // Then position the group
      mainGroup.position.set(
        settings.positionX,
        Math.max(settings.height, 0) - center.y * scale,
        settings.positionZ
      );

      console.log('Adding group to scene...');
      scene.add(mainGroup);
      
      // Log object position
      console.log('Main group position:', mainGroup.position);
      console.log('Main group scale:', mainGroup.scale);

      // Remove scene.fog as we're using custom fog
      // scene.fog = null;

      // Set up layers for logo
      mainGroup.traverse((object) => {
        if (object instanceof THREE.Mesh) {
          object.layers.set(LOGO_LAYER);
        }
      });

      // Create fog layers
      const createFogLayer = (y: number, scale: number) => {
        const fogGeometry = new THREE.PlaneGeometry(100, 100, 500, 500);
        const material = new THREE.ShaderMaterial({
          ...fogShader,
          transparent: true,
          uniforms: {
            ...fogShader.uniforms,
            fogHeight: { value: fogSettings.height },
            fogColor: { value: new THREE.Color(fogSettings.color) }
          }
        });

        const plane = new THREE.Mesh(fogGeometry, material);
        plane.rotation.x = -Math.PI / 2;
        plane.position.y = y;
        plane.scale.set(scale, scale, 1);
        plane.layers.set(FOG_LAYER);
        return plane;
      };

      // Create multiple fog layers
      const fogLayers = Array.from({ length: fogSettings.layers }, (_, i) => {
        const offset = (i - fogSettings.layers / 2) * 0.1;
        const scale = fogSettings.scale - Math.abs(offset) * 0.1;
        return createFogLayer(fogSettings.height / 2 + offset, scale);
      });

      fogLayers.forEach(layer => scene.add(layer));

      // Update the animation loop
      const clock = new THREE.Clock();

      const animate = () => {
        requestAnimationFrame(animate);
        
        const elapsedTime = clock.getElapsedTime();
        
        // Update fog layers
        scene.children.forEach(child => {
          if (child.material && 'uniforms' in child.material) {
            child.material.uniforms.time.value = elapsedTime * 0.2;
            child.material.uniforms.cameraPos.value.copy(camera.position);
          }
        });

        if (mainGroup) {
          // Apply rotation settings
          mainGroup.rotation.x = settings.rotation.x;
          mainGroup.rotation.y += settings.rotationSpeed; // Keep continuous rotation
          mainGroup.rotation.z = settings.rotation.z;
          
          // Apply scale settings
          mainGroup.scale.x = settings.scale.x * scale; // Multiply by base scale
          mainGroup.scale.y = settings.scale.y * -scale; // Keep Y inverted
          mainGroup.scale.z = settings.scale.z * scale;
        }

        // Update all materials with glitter effect
        scene.traverse((object) => {
          if (object.material && object.material.userData.shader) {
            const shader = object.material.userData.shader;
            if (shader.uniforms.time) {
              shader.uniforms.time.value = elapsedTime * settings.materialSettings.sparkleSpeed;
            }
            if (shader.uniforms.glitterColor) {
              shader.uniforms.glitterColor.value.set(settings.materialSettings.sparkleColor);
            }
          }
        });

        renderer.render(scene, camera);
      };
      animate();
    },
    // Add progress and error handlers
    (xhr) => {
      console.log((xhr.loaded / xhr.total * 100) + '% loaded');
    },
    (error) => {
      console.error('An error happened:', error);
    });

    // Handle resize
    const handleResize = () => {
      if (!containerRef.current) return;
      const width = containerRef.current.clientWidth;
      const height = containerRef.current.clientHeight;
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      console.log('Cleaning up scene...');
      URL.revokeObjectURL(url);
      window.removeEventListener('resize', handleResize);
      renderer.dispose();
      if (mainGroupRef.current) {
        mainGroupRef.current.clear();
      }
      containerRef.current?.removeChild(renderer.domElement);
    };
  }, [settings, lightSettings, fogSettings, eyeSettings]);

  useEffect(() => {
    const spotlight = spotlightRef.current;
    if (!spotlight) return;

    // Update color and intensity
    spotlight.color.set(lightSettings.spotlight.color);
    spotlight.intensity = lightSettings.spotlight.intensity;
    
    // Update spotlight properties
    spotlight.angle = lightSettings.spotlight.angle;
    spotlight.penumbra = lightSettings.spotlight.penumbra;
    spotlight.decay = lightSettings.spotlight.decay;
    spotlight.distance = lightSettings.spotlight.distance;

    // Update position
    const radius = 30;
    const height = 10;
    const angle = lightSettings.spotlight.angle * 2; // Use angle for position
    spotlight.position.set(
      Math.sin(angle) * radius,
      height,
      Math.cos(angle) * radius
    );

    // Ensure spotlight points at center
    spotlight.target.position.set(0, 0, 0);
    spotlight.target.updateMatrixWorld();
    spotlight.updateMatrixWorld();

    // Update helper if it exists
    if (spotlight.userData.helper) {
      spotlight.userData.helper.update();
    }
  }, [lightSettings]);

  useEffect(() => {
    // Update materials
    materialsRef.current.forEach((material: any) => {
      if (material instanceof THREE.MeshPhysicalMaterial) {
        // Update material properties based on type
        const isEyes = material.userData.isEyes;
        const isInnerEye = material.userData.isInnerEye;
        const eyeConfig = material.userData.eyeConfig;

        if (isEyes) {
          if (isInnerEye) {
            material.color.set(eyeConfig.inner.color);
            material.metalness = eyeConfig.inner.metalness;
            material.roughness = eyeConfig.inner.roughness;
            material.clearcoat = eyeConfig.inner.clearcoat;
            material.clearcoatRoughness = eyeConfig.inner.clearcoatRoughness;
            material.emissive.set(eyeConfig.inner.emissive);
            material.emissiveIntensity = eyeConfig.inner.emissiveIntensity;
          } else {
            material.color.set(material.userData.originalColor);
            material.metalness = eyeConfig.metalness;
            material.roughness = eyeConfig.roughness;
            material.clearcoat = eyeConfig.clearcoat;
            material.clearcoatRoughness = eyeConfig.clearcoatRoughness;
            material.emissive.set(eyeConfig.emissive);
            material.emissiveIntensity = eyeConfig.emissiveIntensity;
          }
        } else {
          // Update body material
          material.color.set(settings.materialSettings.color);
          material.metalness = settings.materialSettings.metalness;
          material.roughness = settings.materialSettings.roughness;
          material.clearcoat = settings.materialSettings.clearcoat;
          material.clearcoatRoughness = settings.materialSettings.clearcoatRoughness;
          material.emissive.set(settings.materialSettings.emissive);
          material.emissiveIntensity = settings.materialSettings.emissiveIntensity;
          material.wireframe = settings.materialSettings.wireframe;
        }
        material.needsUpdate = true;
      }
    });

    // Update meshes
    meshesRef.current.forEach((mesh) => {
      if (mesh.userData.isEyes) {
        const eyeConfig = mesh.userData.isLeftEye ? eyeSettings.leftEye : eyeSettings.rightEye;
        if (mesh.userData.isInnerEye) {
          mesh.scale.z = settings.thickness * eyeConfig.inner.depth;
        } else {
          mesh.scale.z = settings.thickness * eyeConfig.depth;
        }
      } else {
        mesh.scale.z = settings.thickness;
      }
    });
  }, [settings, eyeSettings]);

  return (
    <Box sx={{ height: 'calc(100vh - 115px)', width: '100%', position: 'relative' }}>
      <Box 
        ref={containerRef}
        sx={{ width: '100%', height: '100%' }}
      />
      
      <IconButton
        sx={{
          position: 'absolute',
          bottom: 16,
          right: 16,
          color: 'white',
          backgroundColor: 'rgba(0,0,0,0.5)'
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <SettingsIcon />
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box sx={{ p: 2, width: 300, bgcolor: 'rgba(0,0,0,0.9)', color: 'white' }}>
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            sx={{ mb: 2, borderBottom: 1, borderColor: 'divider' }}
          >
            <Tab 
              value="controls" 
              label="Controls" 
              sx={{ color: 'white' }}
            />
            <Tab 
              value="lighting" 
              label="Lighting" 
              sx={{ color: 'white' }}
            />
            <Tab 
              value="eyes" 
              label="Eyes" 
              sx={{ color: 'white' }}
            />
          </Tabs>

          {activeTab === 'controls' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {/* Position Controls */}
              <Typography variant="subtitle2">Position</Typography>
              {['X', 'Y', 'Z'].map((axis) => (
                <Box key={`position-${axis}`} sx={{ mb: 1 }}>
                  <Typography variant="caption">Position {axis}</Typography>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Slider
                      value={axis === 'Y' ? settings.height : settings[`position${axis}`]}
                      onChange={(_, value) => setSettings(prev => ({
                        ...prev,
                        [axis === 'Y' ? 'height' : `position${axis}`]: value as number
                      }))}
                      min={-20}
                      max={20}
                      step={0.1}
                      sx={{ flexGrow: 1 }}
                    />
                    <TextField
                      type="number"
                      value={axis === 'Y' ? settings.height : settings[`position${axis}`]}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        if (!isNaN(value)) {
                          setSettings(prev => ({
                            ...prev,
                            [axis === 'Y' ? 'height' : `position${axis}`]: value
                          }));
                        }
                      }}
                      sx={{ 
                        width: '100px',
                        ...textFieldStyle 
                      }}
                      size="small"
                      inputProps={{ step: 0.1 }}
                    />
                  </Box>
                </Box>
              ))}

              {/* Rotation Controls */}
              <Typography variant="subtitle2">Rotation</Typography>
              {['x', 'y', 'z'].map((axis) => (
                <Box key={`rotation-${axis}`} sx={{ mb: 1 }}>
                  <Typography variant="caption">Rotation {axis.toUpperCase()}</Typography>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Slider
                      value={settings.rotation[axis]}
                      onChange={(_, value) => setSettings(prev => ({
                        ...prev,
                        rotation: { ...prev.rotation, [axis]: value as number }
                      }))}
                      min={-Math.PI}
                      max={Math.PI}
                      step={0.01}
                      sx={{ flexGrow: 1 }}
                    />
                    <TextField
                      type="number"
                      value={settings.rotation[axis]}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        if (!isNaN(value)) {
                          setSettings(prev => ({
                            ...prev,
                            rotation: { ...prev.rotation, [axis]: value }
                          }));
                        }
                      }}
                      sx={{ 
                        width: '100px',
                        ...textFieldStyle 
                      }}
                      size="small"
                      inputProps={{ step: 0.01 }}
                    />
                  </Box>
                </Box>
              ))}

              {/* Scale Controls */}
              <Typography variant="subtitle2">Scale</Typography>
              {['x', 'y', 'z'].map((axis) => (
                <Box key={`scale-${axis}`} sx={{ mb: 1 }}>
                  <Typography variant="caption">Scale {axis.toUpperCase()}</Typography>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Slider
                      value={settings.scale[axis]}
                      onChange={(_, value) => setSettings(prev => ({
                        ...prev,
                        scale: { ...prev.scale, [axis]: value as number }
                      }))}
                      min={0.1}
                      max={5}
                      step={0.1}
                      sx={{ flexGrow: 1 }}
                    />
                    <TextField
                      type="number"
                      value={settings.scale[axis]}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        if (!isNaN(value) && value > 0) {
                          setSettings(prev => ({
                            ...prev,
                            scale: { ...prev.scale, [axis]: value }
                          }));
                        }
                      }}
                      sx={{ 
                        width: '100px',
                        ...textFieldStyle 
                      }}
                      size="small"
                      inputProps={{ step: 0.1, min: 0.1 }}
                    />
                  </Box>
                </Box>
              ))}

              {/* Geometry Controls */}
              <Typography variant="subtitle2">Geometry</Typography>
              <Box sx={{ mb: 1 }}>
                <Typography variant="caption">Thickness</Typography>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Slider
                    value={settings.thickness}
                    onChange={(_, value) => setSettings(prev => ({ ...prev, thickness: value as number }))}
                    min={0}
                    max={300}
                    step={1}
                    sx={{ flexGrow: 1 }}
                  />
                  <TextField
                    type="number"
                    value={settings.thickness}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value);
                      if (!isNaN(value)) {
                        setSettings(prev => ({ ...prev, thickness: value }));
                      }
                    }}
                    sx={{ 
                      width: '100px',
                      ...textFieldStyle 
                    }}
                    size="small"
                    inputProps={{ step: 1 }}
                  />
                </Box>
              </Box>

              {/* Bevel Controls */}
              <Typography variant="subtitle2">Bevel</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.bevelEnabled}
                    onChange={(e) => setSettings(prev => ({ ...prev, bevelEnabled: e.target.checked }))}
                  />
                }
                label="Enable Bevel"
              />
              {settings.bevelEnabled && (
                <>
                  {['bevelSize', 'bevelThickness'].map((prop) => (
                    <Box key={prop} sx={{ mb: 1 }}>
                      <Typography variant="caption">{prop.replace(/([A-Z])/g, ' $1').toUpperCase()}</Typography>
                      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Slider
                          value={settings[prop]}
                          onChange={(_, value) => setSettings(prev => ({ ...prev, [prop]: value as number }))}
                          min={0}
                          max={20}
                          step={0.1}
                          sx={{ flexGrow: 1 }}
                        />
                        <TextField
                          type="number"
                          value={settings[prop]}
                          onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            if (!isNaN(value)) {
                              setSettings(prev => ({ ...prev, [prop]: value }));
                            }
                          }}
                          sx={{ 
                            width: '100px',
                            ...textFieldStyle 
                          }}
                          size="small"
                          inputProps={{ step: 0.1 }}
                        />
                      </Box>
                    </Box>
                  ))}
                  
                  {['bevelSegments', 'curveSegments'].map((prop) => (
                    <Box key={prop} sx={{ mb: 1 }}>
                      <Typography variant="caption">{prop.replace(/([A-Z])/g, ' $1').toUpperCase()}</Typography>
                      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Slider
                          value={settings[prop]}
                          onChange={(_, value) => setSettings(prev => ({ ...prev, [prop]: value as number }))}
                          min={1}
                          max={32}
                          step={1}
                          sx={{ flexGrow: 1 }}
                        />
                        <TextField
                          type="number"
                          value={settings[prop]}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (!isNaN(value)) {
                              setSettings(prev => ({ ...prev, [prop]: value }));
                            }
                          }}
                          sx={{ 
                            width: '100px',
                            ...textFieldStyle 
                          }}
                          size="small"
                          inputProps={{ step: 1, min: 1 }}
                        />
                      </Box>
                    </Box>
                  ))}
                </>
              )}

              {/* Material Controls */}
              <Typography variant="subtitle2">Material</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.materialSettings.wireframe}
                    onChange={(e) => setSettings(prev => ({
                      ...prev,
                      materialSettings: { ...prev.materialSettings, wireframe: e.target.checked }
                    }))}
                  />
                }
                label="Wireframe"
              />
              {Object.entries(settings.materialSettings).map(([prop, value]) => {
                if (prop === 'wireframe') return null;
                const isColor = prop === 'color' || prop === 'emissive';
                return (
                  <Box key={prop} sx={{ mb: 1 }}>
                    <Typography variant="caption">{prop.replace(/([A-Z])/g, ' $1').toUpperCase()}</Typography>
                    {isColor ? (
                      <TextField
                        type="color"
                        value={value as string}
                        onChange={(e) => setSettings(prev => ({
                          ...prev,
                          materialSettings: { ...prev.materialSettings, [prop]: e.target.value }
                        }))}
                        fullWidth
                        size="small"
                      />
                    ) : (
                      <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Slider
                          value={value as number}
                          onChange={(_, newValue) => setSettings(prev => ({
                            ...prev,
                            materialSettings: { ...prev.materialSettings, [prop]: newValue }
                          }))}
                          min={0}
                          max={prop.includes('Intensity') ? 2 : 1}
                          step={0.01}
                          sx={{ flexGrow: 1 }}
                        />
                        <TextField
                          type="number"
                          value={value}
                          onChange={(e) => {
                            const newValue = parseFloat(e.target.value);
                            if (!isNaN(newValue)) {
                              setSettings(prev => ({
                                ...prev,
                                materialSettings: { ...prev.materialSettings, [prop]: newValue }
                              }));
                            }
                          }}
                          sx={{ 
                            width: '100px',
                            ...textFieldStyle 
                          }}
                          size="small"
                          inputProps={{ step: 0.01 }}
                        />
                      </Box>
                    )}
                  </Box>
                );
              })}

              {/* Gold Preset Button */}
              <Box sx={{ mb: 2 }}>
                <Button 
                  variant="contained" 
                  onClick={() => setSettings(prev => ({
                    ...prev,
                    materialSettings: {
                      ...prev.materialSettings,
                      metalness: 1,
                      roughness: 0.2, // Decreased for more shine
                      envMapIntensity: 3.0, // Increased for more reflection
                      clearcoat: 1.0,
                      clearcoatRoughness: 0.1,
                      color: '#ffd700',
                      emissive: '#ff9500',
                      emissiveIntensity: 0.3,
                      sparkleEnabled: true,
                      sparkleColor: '#ffffff',
                      sparkleIntensity: 1.0, // Increased intensity
                      sparkleSpeed: 1.0
                    }
                  }))}
                  sx={{ 
                    background: 'linear-gradient(45deg, #ffd700 30%, #ffeb3b 90%)',
                    color: 'black'
                  }}
                >
                  Make it Gold & Sparkly ✨
                </Button>
              </Box>

              {/* Sparkle Controls */}
              <Typography variant="subtitle2" sx={{ mt: 2 }}>Sparkle Effects</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.materialSettings.sparkleEnabled}
                    onChange={(e) => setSettings(prev => ({
                      ...prev,
                      materialSettings: {
                        ...prev.materialSettings,
                        sparkleEnabled: e.target.checked
                      }
                    }))}
                  />
                }
                label="Enable Sparkles"
              />

              {settings.materialSettings.sparkleEnabled && (
                <>
                  <Box>
                    <Typography variant="caption">Sparkle Color</Typography>
                    <TextField
                      type="color"
                      value={settings.materialSettings.sparkleColor}
                      onChange={(e) => setSettings(prev => ({
                        ...prev,
                        materialSettings: {
                          ...prev.materialSettings,
                          sparkleColor: e.target.value
                        }
                      }))}
                      fullWidth
                      size="small"
                    />
                  </Box>

                  <Box>
                    <Typography variant="caption">Sparkle Intensity</Typography>
                    <Slider
                      value={settings.materialSettings.sparkleIntensity}
                      onChange={(_, value) => setSettings(prev => ({
                        ...prev,
                        materialSettings: {
                          ...prev.materialSettings,
                          sparkleIntensity: value as number
                        }
                      }))}
                      min={0}
                      max={1}
                      step={0.1}
                    />
                  </Box>

                  <Box>
                    <Typography variant="caption">Sparkle Speed</Typography>
                    <Slider
                      value={settings.materialSettings.sparkleSpeed}
                      onChange={(_, value) => setSettings(prev => ({
                        ...prev,
                        materialSettings: {
                          ...prev.materialSettings,
                          sparkleSpeed: value as number
                        }
                      }))}
                      min={0}
                      max={2}
                      step={0.1}
                    />
                  </Box>
                </>
              )}
            </Box>
          )}

          {activeTab === 'lighting' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="subtitle2">Spotlight Settings</Typography>
              
              {/* Intensity with much higher range */}
              <Box>
                <Typography variant="caption">Intensity</Typography>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Slider
                    value={lightSettings.spotlight.intensity}
                    onChange={(_, value) => setLightSettings(prev => ({
                      ...prev,
                      spotlight: { ...prev.spotlight, intensity: value as number }
                    }))}
                    min={0}
                    max={20000}  // Much higher max intensity
                    step={1}
                    sx={{ flexGrow: 1 }}
                  />
                  <TextField
                    type="number"
                    value={lightSettings.spotlight.intensity}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value);
                      if (!isNaN(value) && value >= 0) {
                        setLightSettings(prev => ({
                          ...prev,
                          spotlight: { ...prev.spotlight, intensity: value }
                        }));
                      }
                    }}
                    sx={{ 
                      width: '100px',
                      ...textFieldStyle 
                    }}
                    size="small"
                    inputProps={{ step: 1, min: 0 }}
                  />
                </Box>
              </Box>

              {/* Color */}
              <Box>
                <Typography variant="caption">Color</Typography>
                <TextField
                  type="color"
                  value={lightSettings.spotlight.color}
                  onChange={(e) => setLightSettings(prev => ({
                    ...prev,
                    spotlight: { ...prev.spotlight, color: e.target.value }
                  }))}
                  fullWidth
                  size="small"
                />
              </Box>

              {/* Angle with proper conversion */}
              <Box>
                <Typography variant="caption">Angle (degrees)</Typography>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Slider
                    value={Math.round((lightSettings.spotlight.angle * 180) / Math.PI)}
                    onChange={(_, value) => setLightSettings(prev => ({
                      ...prev,
                      spotlight: { 
                        ...prev.spotlight, 
                        angle: (value as number * Math.PI) / 180 
                      }
                    }))}
                    min={0}
                    max={180}
                    step={1}
                    sx={{ flexGrow: 1 }}
                  />
                  <TextField
                    type="number"
                    value={Math.round((lightSettings.spotlight.angle * 180) / Math.PI)}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value);
                      if (!isNaN(value) && value >= 0 && value <= 180) {
                        setLightSettings(prev => ({
                          ...prev,
                          spotlight: { 
                            ...prev.spotlight, 
                            angle: (value * Math.PI) / 180 
                          }
                        }));
                      }
                    }}
                    sx={{ 
                      width: '100px',
                      ...textFieldStyle 
                    }}
                    size="small"
                    inputProps={{ step: 1, min: 0, max: 180 }}
                  />
                </Box>
              </Box>

              {/* Penumbra with wider range */}
              <Box>
                <Typography variant="caption">Penumbra (Edge Softness)</Typography>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Slider
                    value={lightSettings.spotlight.penumbra}
                    onChange={(_, value) => setLightSettings(prev => ({
                      ...prev,
                      spotlight: { ...prev.spotlight, penumbra: value as number }
                    }))}
                    min={0}
                    max={10}  // Increased range
                    step={0.1}
                    sx={{ flexGrow: 1 }}
                  />
                  <TextField
                    type="number"
                    value={lightSettings.spotlight.penumbra}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value);
                      if (!isNaN(value)) {
                        setLightSettings(prev => ({
                          ...prev,
                          spotlight: { ...prev.spotlight, penumbra: value }
                        }));
                      }
                    }}
                    sx={{ 
                      width: '100px',
                      ...textFieldStyle 
                    }}
                    size="small"
                    inputProps={{ step: 0.1, min: 0 }}
                  />
                </Box>
              </Box>

              {/* Decay with wider range */}
              <Box>
                <Typography variant="caption">Decay</Typography>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Slider
                    value={lightSettings.spotlight.decay}
                    onChange={(_, value) => setLightSettings(prev => ({
                      ...prev,
                      spotlight: { ...prev.spotlight, decay: value as number }
                    }))}
                    min={0}
                    max={50}  // Much higher decay possible
                    step={0.1}
                    sx={{ flexGrow: 1 }}
                  />
                  <TextField
                    type="number"
                    value={lightSettings.spotlight.decay}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value);
                      if (!isNaN(value)) {
                        setLightSettings(prev => ({
                          ...prev,
                          spotlight: { ...prev.spotlight, decay: value }
                        }));
                      }
                    }}
                    sx={{ 
                      width: '100px',
                      ...textFieldStyle 
                    }}
                    size="small"
                    inputProps={{ step: 0.1, min: 0 }}
                  />
                </Box>
              </Box>

              {/* Distance with much wider range */}
              <Box>
                <Typography variant="caption">Distance</Typography>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Slider
                    value={lightSettings.spotlight.distance}
                    onChange={(_, value) => setLightSettings(prev => ({
                      ...prev,
                      spotlight: { ...prev.spotlight, distance: value as number }
                    }))}
                    min={0}
                    max={1000}  // Much longer distance possible
                    step={1}
                    sx={{ flexGrow: 1 }}
                  />
                  <TextField
                    type="number"
                    value={lightSettings.spotlight.distance}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value);
                      if (!isNaN(value)) {
                        setLightSettings(prev => ({
                          ...prev,
                          spotlight: { ...prev.spotlight, distance: value }
                        }));
                      }
                    }}
                    sx={{ 
                      width: '100px',
                      ...textFieldStyle 
                    }}
                    size="small"
                    inputProps={{ step: 1, min: 0 }}
                  />
                </Box>
              </Box>

              {/* Position Controls */}
              <Typography variant="subtitle2" sx={{ mt: 2 }}>Light Position</Typography>
              {['x', 'y', 'z'].map((axis) => (
                <Box key={`position-${axis}`}>
                  <Typography variant="caption">Position {axis.toUpperCase()}</Typography>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Slider
                      value={lightSettings.spotlight.position[axis]}
                      onChange={(_, value) => setLightSettings(prev => ({
                        ...prev,
                        spotlight: {
                          ...prev.spotlight,
                          position: {
                            ...prev.spotlight.position,
                            [axis]: value as number
                          }
                        }
                      }))}
                      min={-100}
                      max={100}
                      step={0.1}
                      sx={{ flexGrow: 1 }}
                    />
                    <TextField
                      type="number"
                      value={lightSettings.spotlight.position[axis]}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        if (!isNaN(value)) {
                          setLightSettings(prev => ({
                            ...prev,
                            spotlight: {
                              ...prev.spotlight,
                              position: {
                                ...prev.spotlight.position,
                                [axis]: value
                              }
                            }
                          }));
                        }
                      }}
                      sx={{ 
                        width: '100px',
                        ...textFieldStyle 
                      }}
                      size="small"
                      inputProps={{ step: 0.1 }}
                    />
                  </Box>
                </Box>
              ))}

              {/* Target Position Controls */}
              <Typography variant="subtitle2" sx={{ mt: 2 }}>Light Target</Typography>
              {['x', 'y', 'z'].map((axis) => (
                <Box key={`target-${axis}`}>
                  <Typography variant="caption">Target {axis.toUpperCase()}</Typography>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Slider
                      value={lightSettings.spotlight.target[axis]}
                      onChange={(_, value) => setLightSettings(prev => ({
                        ...prev,
                        spotlight: {
                          ...prev.spotlight,
                          target: {
                            ...prev.spotlight.target,
                            [axis]: value as number
                          }
                        }
                      }))}
                      min={-100}
                      max={100}
                      step={0.1}
                      sx={{ flexGrow: 1 }}
                    />
                    <TextField
                      type="number"
                      value={lightSettings.spotlight.target[axis]}
                      onChange={(e) => {
                        const value = parseFloat(e.target.value);
                        if (!isNaN(value)) {
                          setLightSettings(prev => ({
                            ...prev,
                            spotlight: {
                              ...prev.spotlight,
                              target: {
                                ...prev.spotlight.target,
                                [axis]: value
                              }
                            }
                          }));
                        }
                      }}
                      sx={{ 
                        width: '100px',
                        ...textFieldStyle 
                      }}
                      size="small"
                      inputProps={{ step: 0.1 }}
                    />
                  </Box>
                </Box>
              ))}

              {/* Shadow Controls */}
              <Typography variant="subtitle2" sx={{ mt: 2 }}>Shadow Settings</Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={lightSettings.spotlight.castShadow}
                    onChange={(e) => setLightSettings(prev => ({
                      ...prev,
                      spotlight: {
                        ...prev.spotlight,
                        castShadow: e.target.checked
                      }
                    }))}
                  />
                }
                label="Cast Shadows"
              />

              {lightSettings.spotlight.castShadow && (
                <>
                  <Box>
                    <Typography variant="caption">Shadow Bias</Typography>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      <Slider
                        value={lightSettings.spotlight.shadowBias}
                        onChange={(_, value) => setLightSettings(prev => ({
                          ...prev,
                          spotlight: {
                            ...prev.spotlight,
                            shadowBias: value as number
                          }
                        }))}
                        min={-0.001}
                        max={0.001}
                        step={0.0001}
                        sx={{ flexGrow: 1 }}
                      />
                      <TextField
                        type="number"
                        value={lightSettings.spotlight.shadowBias}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          if (!isNaN(value)) {
                            setLightSettings(prev => ({
                              ...prev,
                              spotlight: {
                                ...prev.spotlight,
                                shadowBias: value
                              }
                            }));
                          }
                        }}
                        sx={{ 
                          width: '100px',
                          ...textFieldStyle 
                        }}
                        size="small"
                        inputProps={{ step: 0.0001 }}
                      />
                    </Box>
                  </Box>

                  <Box>
                    <Typography variant="caption">Shadow Map Size</Typography>
                    <Select
                      value={lightSettings.spotlight.shadowMapSize}
                      onChange={(e) => setLightSettings(prev => ({
                        ...prev,
                        spotlight: {
                          ...prev.spotlight,
                          shadowMapSize: Number(e.target.value)
                        }
                      }))}
                      fullWidth
                      size="small"
                    >
                      {[512, 1024, 2048, 4096].map((size) => (
                        <MenuItem key={size} value={size}>{size}</MenuItem>
                      ))}
                    </Select>
                  </Box>

                  <Box>
                    <Typography variant="caption">Shadow Camera FOV</Typography>
                    <Slider
                      value={lightSettings.spotlight.shadowCameraFov}
                      onChange={(_, value) => setLightSettings(prev => ({
                        ...prev,
                        spotlight: {
                          ...prev.spotlight,
                          shadowCameraFov: value as number
                        }
                      }))}
                      min={1}
                      max={180}
                      step={1}
                    />
                  </Box>

                  <Box>
                    <Typography variant="caption">Shadow Camera Near</Typography>
                    <Slider
                      value={lightSettings.spotlight.shadowCameraNear}
                      onChange={(_, value) => setLightSettings(prev => ({
                        ...prev,
                        spotlight: {
                          ...prev.spotlight,
                          shadowCameraNear: value as number
                        }
                      }))}
                      min={0.1}
                      max={10}
                      step={0.1}
                    />
                  </Box>

                  <Box>
                    <Typography variant="caption">Shadow Camera Far</Typography>
                    <Slider
                      value={lightSettings.spotlight.shadowCameraFar}
                      onChange={(_, value) => setLightSettings(prev => ({
                        ...prev,
                        spotlight: {
                          ...prev.spotlight,
                          shadowCameraFar: value as number
                        }
                      }))}
                      min={50}
                      max={1000}
                      step={10}
                    />
                  </Box>
                </>
              )}
            </Box>
          )}

          {activeTab === 'eyes' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {['leftEye', 'rightEye'].map((eyeSide) => (
                <Box key={eyeSide}>
                  <Typography variant="subtitle2" sx={{ mt: 1 }}>
                    {eyeSide === 'leftEye' ? 'Left Eye' : 'Right Eye'}
                  </Typography>
                  
                  {/* Outer eye controls remain the same */}
                  {/* ... */}

                  <Typography variant="subtitle2" sx={{ mt: 2 }}>
                    Inner Eye
                  </Typography>
                  {Object.entries(eyeSettings[eyeSide].inner).map(([property, value]) => {
                    const isColor = property === 'color' || property === 'emissive';
                    const isSlider = !isColor;
                    
                    const getRange = (prop: string) => {
                      switch(prop) {
                        case 'depth': return { min: 1, max: 3, step: 0.01 };
                        case 'bevelThickness': 
                        case 'bevelSize': return { min: 0, max: 3, step: 0.1 };
                        case 'bevelOffset': return { min: -10, max: 10, step: 0.5 };
                        case 'metalness':
                        case 'roughness': return { min: 0, max: 1, step: 0.01 };
                        case 'emissiveIntensity': return { min: 0, max: 200, step: 0.1 };
                        default: return { min: 0, max: 1, step: 0.1 };
                      }
                    };

                    return (
                      <Box key={property} sx={{ mb: 2 }}>
                        <Typography variant="caption">
                          {property.replace(/([A-Z])/g, ' $1').toUpperCase()}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                          {isSlider && (
                            <>
                              <Slider
                                value={value as number}
                                onChange={(_, newValue) => setEyeSettings(prev => ({
                                  ...prev,
                                  [eyeSide]: {
                                    ...prev[eyeSide],
                                    inner: {
                                      ...prev[eyeSide].inner,
                                      [property]: newValue
                                    }
                                  }
                                }))}
                                {...getRange(property)}
                                sx={{ flexGrow: 1 }}
                              />
                              <TextField
                                type="number"
                                value={value}
                                onChange={(e) => {
                                  const newValue = parseFloat(e.target.value);
                                  const range = getRange(property);
                                  if (newValue >= range.min && newValue <= range.max) {
                                    setEyeSettings(prev => ({
                                      ...prev,
                                      [eyeSide]: {
                                        ...prev[eyeSide],
                                        inner: {
                                          ...prev[eyeSide].inner,
                                          [property]: newValue
                                        }
                                      }
                                    }));
                                  }
                                }}
                                sx={{ 
                                  width: '100px',
                                  ...textFieldStyle 
                                }}
                                size="small"
                                inputProps={{
                                  step: getRange(property).step,
                                  min: getRange(property).min,
                                  max: getRange(property).max
                                }}
                              />
                            </>
                          )}
                          {isColor && (
                            <TextField
                              type="color"
                              value={value as string}
                              onChange={(e) => setEyeSettings(prev => ({
                                ...prev,
                                [eyeSide]: {
                                  ...prev[eyeSide],
                                  inner: {
                                    ...prev[eyeSide].inner,
                                    [property]: e.target.value
                                  }
                                }
                              }))}
                              fullWidth
                              size="small"
                            />
                          )}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default ThreeDOppySlide;
