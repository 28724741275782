import GoogleCalendarForm from "src/components/profile/GoogleCalendarForm";
import ProfileLayout from "src/components/profile/ProfileLayout";

const GoogleCalendar = () => {
    return (
      <ProfileLayout activeKey="google_calendar" >
          <GoogleCalendarForm />
      </ProfileLayout>
    );
  };

export default GoogleCalendar;
