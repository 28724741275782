import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';


function CountdownTimerButton({ style = { fontStyle: {}, countdownBox: {} }, currentTime, futureTime, onClick = () => {}, timerPrefix = '', enabled = true }) {
  const [timeLeft, setTimeLeft] = useState(new Date(futureTime).getTime() - new Date(currentTime).getTime());
  const calculatePercentage = () => {
    const totalDuration = new Date(futureTime).getTime() - new Date(currentTime).getTime();
    return ((totalDuration - timeLeft) / totalDuration) * 100;
  };
  const CountdownBox = styled(Button)({
    ...style.countdownBox,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
    minWidth: 'fit-content',
    padding: '20px',
    cursor: 'pointer',
    borderRadius: '25px',
    border: '2px solid transparent',
    background: `linear-gradient(transparent, transparent) padding-box,
              conic-gradient(from 0deg, #1976d2, #1976d2 ${calculatePercentage()}%, white ${calculatePercentage()}%) border-box`,
  });
  useEffect(() => {
    const timer = setInterval(() => {
      const newTimeLeft = new Date(futureTime).getTime() - new Date().getTime();
      if (newTimeLeft < 0) {
        clearInterval(timer);
        setTimeLeft(0);
      } else {
        setTimeLeft(newTimeLeft);
      }
    }, 1000); // Update every 1000ms (1 second) instead of every 10ms

    return () => clearInterval(timer);
  }, [futureTime]);

  if (timeLeft <= 0) return null; // Do not render if timeLeft is 0 or negative

  const formatTimeLeft = (time) => {
    const milliseconds = time % 1000;
    time = (time - milliseconds) / 1000;
    const seconds = time % 60;
    time = (time - seconds) / 60;
    const minutes = time % 60;
    time = (time - minutes) / 60;
    const hours = time % 24;
    const days = (time - hours) / 24;
    let result = '';
    if (days > 0) result += `${days}d `;
    if (hours > 0) result += `${hours}h `;
    if (minutes > 0) result += `${minutes}m `;
    result += `${seconds}s`;
    return result.trim();
  };

  return (
    <CountdownBox sx={{
      ...style,
      border: '2px solid #1976d2',
      background: `linear-gradient(white, white) padding-box,
                  conic-gradient(from 0deg, #1976d2, #1976d2 ${calculatePercentage()}%, #ccc ${calculatePercentage()}%) border-box`
    }} onClick={onClick} disabled={!enabled} key={timeLeft}>
      <Typography sx={{...style.fontStyle}} variant="body1">{timerPrefix}{formatTimeLeft(timeLeft)}</Typography>
    </CountdownBox>
  );
}

export default CountdownTimerButton;

// function ParentComponent({ conversations }) {
//   const nextPendingMessage = conversations.messages.find(message => message.status === 'pending_user_reply' && new Date(message.deliver_after) > new Date());

//   if (!nextPendingMessage) {
//     return <Typography>No pending messages to deliver.</Typography>;
//   }

//   return (
//     <CountdownTimer currentTime={new Date().toISOString()} futureTime={nextPendingMessage.deliver_after} />
//   );
// }

// export default ParentComponent;
