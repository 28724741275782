import { Button, Modal } from 'react-bootstrap';
import { useAppSelector } from 'src/store';

const CancelBookingConfirmation = ({
  showExitConfirmation,
  handleGoBack,
  handleCancelBooking,
}) => {
  const darkMode = useAppSelector( state => state.theme.darkMode )

  return (
    <Modal
      show={showExitConfirmation}
      fullscreen={'sm-down'}
      keyboard={false}
      centered
      onHide={handleGoBack}
      contentClassName="medium-width"
    >
      <Modal.Header>
        <div className="py-2 px-3 fw-medium fs-14 go-back">
          <img src={ darkMode ? "/icon/backLight.svg" : "/icon/back.svg" } onClick={handleGoBack} alt="" />
          &nbsp; Go back
        </div>
      </Modal.Header>
      <Modal.Body>
        {/* LargeScreen */}
        <div>
          <p className="fs-4 px-3 fw-medium lh-sm">
            Are you sure you want to cancel?
          </p>
          <p className="px-3 fs-14">We’ll send you an email to confirm</p>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={handleGoBack}>
              <span className="fw-medium">No</span>
            </Button>
            <Button variant="danger" onClick={handleCancelBooking}>
              <span className="fw-medium">Yes</span>
            </Button>
          </Modal.Footer>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CancelBookingConfirmation;
