import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Button, Collapse } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const CallSummary = function CallSummary({ transcriptionComplete, onComplete }) {
  const [showTranscript, setShowTranscript] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [profileUpdateText, setProfileUpdateText] = useState('');
  const [callSummaryText, setCallSummaryText] = useState('');

  const profileUpdate = `I've added the following details to Kai's profile:
- First-time home buyer
- Interested in a modern two-bedroom apartment with a balcony
- Budget: Around $300,000`;

  const callSummary = `Call Summary:
Kai, a first-time home buyer, reached out to learn about the home buying process. They're looking for a modern two-bedroom apartment, preferably with a balcony, with a budget of around $300,000. The next step is to discuss potential neighborhoods that fit Kai's preferences and budget.`;

  useEffect(() => {
    if (transcriptionComplete) {
      setTimeout(() => setShowSummary(true), 1000);
    }
  }, [transcriptionComplete]);

  useEffect(() => {
    if (showSummary) {
      let profileIndex = 0;
      let summaryIndex = 0;
      
      const profileInterval = setInterval(() => {
        if (profileIndex < profileUpdate.length) {
          setProfileUpdateText(prev => prev + profileUpdate[profileIndex]);
          profileIndex++;
        } else {
          clearInterval(profileInterval);
          
          // Start typing the call summary after a short delay
          setTimeout(() => {
            const summaryInterval = setInterval(() => {
              if (summaryIndex < callSummary.length) {
                setCallSummaryText(prev => prev + callSummary[summaryIndex]);
                summaryIndex++;
              } else {
                clearInterval(summaryInterval);
                setTimeout(() => onComplete(), 5000); // Call onComplete after 5 seconds
              }
            }, 20);
          }, 500);
        }
      }, 20);

      return () => {
        clearInterval(profileInterval);
      };
    }
  }, [showSummary, onComplete]);

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Collapse in={transcriptionComplete} timeout={500}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 60 }}>
          <Button 
            onClick={() => setShowTranscript(!showTranscript)}
            startIcon={
              <CheckCircleOutlineIcon sx={{ 
                color: 'success.main', 
                fontSize: 40,
                animation: 'checkmark-pop 0.5s ease-out',
                '@keyframes checkmark-pop': {
                  '0%': { transform: 'scale(0)' },
                  '50%': { transform: 'scale(1.2)' },
                  '100%': { transform: 'scale(1)' },
                },
              }} />
            }
          >
            <Typography variant="h6" sx={{ ml: 2, animation: 'fade-in 0.5s ease-out', '@keyframes fade-in': { from: { opacity: 0 }, to: { opacity: 1 } } }}>
              Transcript Complete
            </Typography>
          </Button>
        </Box>
      </Collapse>
      
      <Collapse in={showTranscript} timeout={500}>
        {/* Add your transcript content here */}
        <Paper elevation={3} sx={{ mt: 2, p: 2, bgcolor: 'white', color: 'black', borderRadius: 2, maxWidth: '80%', ml: 'auto', mr: 'auto' }}>
          <Typography variant="body1">Transcript content goes here...</Typography>
        </Paper>
      </Collapse>

      <Collapse in={showSummary} timeout={1000}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: '80%', ml: 'auto', mr: 'auto' }}>
          <Paper 
            elevation={3} 
            sx={{ 
              mt: 2, 
              p: 2, 
              bgcolor: 'black', 
              color: 'white',
              borderRadius: 2,
            }}
          >
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              {profileUpdateText}
            </Typography>
          </Paper>
          <Paper 
            elevation={3} 
            sx={{ 
              p: 2, 
              bgcolor: 'black', 
              color: 'white',
              borderRadius: 2,
              mb: 4, // Add margin at the bottom of the last message
            }}
          >
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              {callSummaryText}
            </Typography>
          </Paper>
        </Box>
      </Collapse>
    </Box>
  );
};

export default CallSummary;
