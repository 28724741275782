import { Box, createTheme, ThemeProvider, Typography } from '@mui/material';
import { DataGrid, GridRowsProp, GridColDef, GridCellEditStopParams, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';
import { useAppDispatch, useAppSelector } from 'src/store';
import { useSearchParams } from "react-router-dom";
import Masquerade from 'src/components/Masquerade/Masquerade';

export function useSearchParamsState(
    searchParamName: string,
    defaultValue: any
): readonly [
    searchParamsState: string,
    setSearchParamsState: (newState: string) => void
] {
    const [searchParams, setSearchParams] = useSearchParams();

    const acquiredSearchParam = searchParams.get(searchParamName);
    const searchParamsState = acquiredSearchParam ?? defaultValue;

    const setSearchParamsState = (newState: string) => {
        const next = Object.assign(
            {},
            [...searchParams.entries()].reduce(
                (o, [key, value]) => ({ ...o, [key]: value }),
                {}
            ),
            { [searchParamName]: newState }
        );
        setSearchParams(next);
    };
    return [searchParamsState, setSearchParamsState];
}

const UserManager = ({ user }) => {
  const [users, setUsers] = useState(null);
  const [masqueradeOpen, setMasqueradeOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [selectedUserName, setSelectedUserName] = useState('');

  console.log('User role:', user?.role);

  // Define columns conditionally based on user role
  const columns: GridColDef[] = [
    { field: 'photo', headerName: '', width: 60, renderCell: renderAvatar },
    { field: 'first_name', headerName: 'First Name', width: 150 },
    { field: 'last_name', headerName: 'Last Name', width: 150, editable: true },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'account_status', headerName: 'Account Status', width: 100, editable: true, type: "singleSelect", valueOptions: ["Default", "Active", "Rejected"] },
    { field: 'contact_phone', headerName: 'Contact Phone', width: 150 },
    { field: 'created_at', headerName: 'Created At', width: 150 },
    { field: 'updated_at', headerName: 'Last Updated', width: 150 },
    { field: 'timezone', headerName: 'Timezone', width: 150 },
    { field: 'address', headerName: 'Address', width: 250, renderCell: renderAddress },
    // Conditionally add the masquerade column
    ...(user?.role === 11 ? [{ field: 'masquerade', headerName: 'Masquerade', width: 150, renderCell: renderMasquerade }] : []),
  ];

  const [serviceSlugs, setServiceSlugs] = useSearchParamsState("service_slugs", '');
  const [address, setAddress] = useSearchParamsState("address", '');
  const [availableSlugs, setSlugOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const dispatch = useAppDispatch();
  const darkMode = useAppSelector(state => state.theme.darkMode )
  const rows: GridRowsProp = users || [];

  function renderAvatar(params: GridRenderCellParams) {
    return <img className="avatar--round" src={`${params.value}`}></img>;
  }
  function renderAddress(params: GridRenderCellParams) {
    return params.value?.formatted_address
  }
  function renderMasquerade(params: GridRenderCellParams) {
    return (
      <a
        onClick={() => handleMasqueradeClick(params.row.uuid, params.row.first_name + ' ' + params.row.last_name)}
        style={{ color: 'green', cursor: 'pointer' }}
      >
        Masquerade
      </a>
    );
  }


  function setUserData(data: any) {
    // Convert dates to timestamps for comparison
    const sortedUsers = data.users.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    setUsers(sortedUsers);
    setSlugOptions(data.filters.slugs);
  }

  const fetchUsers = () => {
    setLoading(true);
    const url = new URL(`${process.env.REACT_APP_API_V3_URL}/private/users`);
    if (address) {
      url.searchParams.append("address", address);
    }
    if (serviceSlugs) {
      url.searchParams.append("service_slugs", serviceSlugs);
    }

    httpClient.get(url.href, {
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(({ data }) => {
      setLoading(false);
      setUserData(data);
    })
    .catch(error => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchUsers();
  }, [address, serviceSlugs]);

  const userUpdater = (payload) => {
    const url = `${API_ROUTES.bulk_user_update}`
    return httpClient.post(url, {ids: [payload.uuid], user: payload})
      .then(({ data }) => {
        if (!data.success) {
          throw new Error(data.message)
        }
      })
  }

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });

  const handleMasqueradeClick = (userId, userName) => {
    setSelectedUserId(userId);
    setSelectedUserName(userName);
    setMasqueradeOpen(true);
  };

  const handleMasqueradeClose = () => {
    setMasqueradeOpen(false);
    setSelectedUserId('');
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh', overflowY: 'scroll', p: 2 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h4">Manage Users</Typography>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <DataGrid
            loading={loading}
            onRowClick={(params, event) => {
              event.stopPropagation();
            }}
            rows={rows}
            columns={columns}
            getRowId={(row) => row.uuid}
            processRowUpdate={(newRow: GridCellEditStopParams) => {
              userUpdater(newRow);
              return newRow;
            }}
            sx={{ height: '100%', width: '100%' }}
          />
        </Box>
      </Box>
      <Masquerade open={masqueradeOpen} onClose={handleMasqueradeClose} userId={selectedUserId} userName={selectedUserName} />
    </ThemeProvider>
  );
};

export default UserManager;
