import { useEffect, useState, useRef } from 'react';
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme, Theme } from '@mui/material/styles';

const ConversationStatusIndicator = ({conversationStatus, variant = 'full', sx = {}}) => {
  
  const theme = useTheme();
  const [currentStatus, setCurrentStatus] = useState(conversationStatus);
  const prevStatusRef = useRef(conversationStatus);

  useEffect(() => {
    if (conversationStatus !== prevStatusRef.current) {
      setCurrentStatus(conversationStatus);
      prevStatusRef.current = conversationStatus;
    }
  }, [conversationStatus]);

  const getStatusLabel = (status) => {
    const statusLabels = {
      closed: "Closed",
      error: "Error",
      pending_assistant_reply: "Pending...",
      pending_user_reply: "Awaiting reply",
      processing_autocomplete: "Generating...",
      standby: "Standby",
      needs_attention: "Needs Attention!",
      loading: "Loading...",
      loading_messages: "Loading Messages...",
      paused_pending: "Paused - Pending Delivery",
    };
    return statusLabels[status] || "Unknown Status";
  };

  const dynamicRotationStyle = () => {
    if (variant === 'circle' && ['processing_autocomplete', 'loading_messages', 'loading', 'pending_assistant_reply'].includes(currentStatus)) {
      return {
        '@keyframes rotate': {
          '0%': { transform: 'rotate(0deg)' },
          '100%': { transform: 'rotate(360deg)' }
        },
        animation: `rotate 2s linear infinite`,
      };
    }
    return {
      animation: 'none',
    };
  };

  const statusAnimation = () => {
    const statusAnimations = {
      processing_autocomplete: "rotate 2s linear infinite",
      pending_user_reply: "pulse 5s ease infinite",
      pending_assistant_reply: "pulse 1s ease infinite",
      loading_messages: "pulse 1s ease infinite",
      loading: "pulse 1s ease infinite",
      error: "flash 5s ease 2 alternate",
      needs_attention: "flash 5s ease alternate",
      closed: "none",
      standby: "none"
    };
    return statusAnimations[currentStatus] || "none";
  };


  const styles = {
    full: {
      bgcolor: theme.statusColors[currentStatus] || 'default',
      pl: 2,
      pr: 2,
      pt: .5,
      pb: .5,
      borderRadius: '8px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    condensed: {
      bgcolor: theme.statusColors[currentStatus] || 'default',
      p: 0.5,
      borderRadius: '50%',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    circle: {
      height: 35,
      width: 35,
      bgcolor: theme.statusColors[currentStatus] || 'default',
      animation: currentStatus === 'paused_pending' ? 'blink 1s infinite' : 'none',
      '@keyframes blink': {
        '0%': { opacity: 1 },
        '50%': { opacity: 0.5 },
        '100%': { opacity: 1 },
      },
    },
    circleLabel: {
      bgcolor: theme.statusColors[currentStatus] || 'default',
      p: 0.5,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  };

  const renderChip = (status) => {
    const label = getStatusLabel(status);
    const selectedStyle = styles[variant];

    return (
      <Tooltip title={label}>
        <Box sx={selectedStyle}>
          {variant !== 'circle' && <Typography variant="caption">{label}</Typography>}
        </Box>
      </Tooltip>
    );
  };

  return (
    <>
      {renderChip(currentStatus)}
    </>
  );
};

export default ConversationStatusIndicator;
