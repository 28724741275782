import ProfileLayout from "src/components/profile/ProfileLayout";
import TravelForm from "src/components/profile/TravelForm";

const TravelAndLocation = () => {
  return (
    <ProfileLayout activeKey="travel">
        <TravelForm />
    </ProfileLayout>
  );
};

export default TravelAndLocation;
