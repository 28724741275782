import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import notesService from 'src/services/notes.service';
import { RootState } from 'src/store';
import { enqueueSnackbar } from 'notistack';

export interface Note {
  id: string;
  subject: string;
  body: string;
  owner_id: string;
  owner_type: string;
  status?: string;
  order?: number;
  metadata?: any;
  tags?: string[];
  created_at: string;
  updated_at: string;
  is_html?: boolean;
  created_by?: string;
  updated_by?: string;
}

interface ErrorPayload {
  status?: number;
  error?: string;
}

interface NotesState {
  items: Note[];
  loading: boolean;
  error: string | null;
}

const initialState: NotesState = {
  items: [],
  loading: false,
  error: null,
};

export const fetchNotes = createAsyncThunk<
  Note[],
  { ownerType: string; ownerId: string; params?: any },
  { rejectValue: ErrorPayload }
>(
  'notes/fetchNotes',
  async ({ ownerType, ownerId, params }, { rejectWithValue }) => {
    try {
      const paramsWithOwner = { owner_type: ownerType, owner_id: ownerId, ...params };
      const response = await notesService.getNotes(paramsWithOwner);
      console.log('Fetched notes response:', response);
      return response.data.notes;
    } catch (error: any) {
      console.error('Error fetching notes:', error);
      enqueueSnackbar('Error fetching notes', { variant: 'error' });
      return rejectWithValue(error.response.data);
    }
  }
);

export const createNote = createAsyncThunk<
  Note,
  any,
  { rejectValue: ErrorPayload }
>(
  'notes/createNote',
  async (noteData, { rejectWithValue }) => {
    try {
      const response = await notesService.createNote(noteData);
      console.log('Created note response:', response);
      return response.data.note;
    } catch (error: any) {
      console.error('Error creating note:', error);
      enqueueSnackbar('Error creating note', { variant: 'error' });
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateNote = createAsyncThunk<
  Note,
  { noteId: string; noteData: any },
  { rejectValue: ErrorPayload }
>(
  'notes/updateNote',
  async ({ noteId, noteData }, { rejectWithValue }) => {
    try {
      const response = await notesService.updateNote(noteId, noteData);
      console.log('Updated note response:', response);
      return response.data.note;
    } catch (error: any) {
      console.error('Error updating note:', error);
      enqueueSnackbar('Error updating note', { variant: 'error' });
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteNote = createAsyncThunk<
  string,
  string,
  { rejectValue: ErrorPayload }
>(
  'notes/deleteNote',
  async (noteId, { rejectWithValue }) => {
    try {
      const response = await notesService.deleteNote(noteId);
      console.log('Deleted note response:', response);
      return noteId;
    } catch (error: any) {
      console.error('Error deleting note:', error);
      enqueueSnackbar('Error deleting note', { variant: 'error' });
      return rejectWithValue(error.response.data);
    }
  }
);

const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    clearNotesState: (state) => {
      state.items = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotes.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchNotes.fulfilled, (state, action) => {
      const notes = action.payload || [];
      console.log('Fetched notes:', notes);

      state.items = notes.filter((note) => note !== undefined);

      state.loading = false;
      state.items.sort(
        (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    });
    builder.addCase(fetchNotes.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as ErrorPayload)?.error || 'Error fetching notes';

      const payload = action.payload as ErrorPayload;

      if (payload?.status === 500) {
        enqueueSnackbar('Internal Server Error: Unable to fetch notes', { variant: 'error' });
      } else if (payload?.error) {
        enqueueSnackbar(`Error getting notes`, { variant: 'error' });
      }
    });

    builder.addCase(createNote.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createNote.fulfilled, (state, action) => {
      console.log('Created note:', action.payload);

      if (!state.items) {
        state.items = [];
      }

      const newNote = action.payload;
      if (newNote) {
        state.items.unshift(newNote);
      } else {
        console.error('Received undefined note in createNote.fulfilled');
      }

      state.loading = false;
      state.error = null;
    });
    builder.addCase(createNote.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as ErrorPayload)?.error || 'Error creating note';

      const payload = action.payload as ErrorPayload;

      if (payload?.status === 500) {
        enqueueSnackbar('Internal Server Error: Unable to create note', { variant: 'error' });
      } else if (payload?.error) {
        enqueueSnackbar(`Error creating note`, { variant: 'error' });
      }
    });

    builder.addCase(updateNote.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateNote.fulfilled, (state, action) => {
      console.log('Updated note:', action.payload);
      const index = state.items.findIndex((note) => note.id === action.payload.id);
      if (index !== -1) {
        state.items[index] = action.payload;
      }
      state.loading = false;
    });
    builder.addCase(updateNote.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as ErrorPayload)?.error || 'Error updating note';

      const payload = action.payload as ErrorPayload;

      if (payload?.status === 500) {
        enqueueSnackbar('Internal Server Error: Unable to update note', { variant: 'error' });
      } else if (payload?.error) {
        enqueueSnackbar(`Error updating note`, { variant: 'error' });
      }
    });

    builder.addCase(deleteNote.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteNote.fulfilled, (state, action) => {
      console.log('Deleted note ID:', action.payload);
      state.items = state.items?.filter((note) => note.id !== action.payload);
      state.loading = false;
    });
    builder.addCase(deleteNote.rejected, (state, action) => {
      state.loading = false;
      state.error = (action.payload as ErrorPayload)?.error || 'Error deleting note';

      const payload = action.payload as ErrorPayload;

      if (payload?.status === 500) {
        enqueueSnackbar('Internal Server Error: Unable to delete note', { variant: 'error' });
      } else if (payload?.error) {
        enqueueSnackbar(`Error deleting note`, { variant: 'error' });
      }
    });
  },
});

export const { clearNotesState } = notesSlice.actions;
export default notesSlice.reducer;

export const selectNotesByOwner = (state: RootState, ownerId: string) => {
  console.log('Notes Selector State:', state);

  return (
    state.notes.items?.filter((note) => {
      if (!note) {
        console.warn('Undefined note encountered in selectNotesByOwner');
        return false;
      }
      return note.owner_id === ownerId;
    }) || []
  );
};
