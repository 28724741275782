import React, { useState, useEffect } from 'react';
import { Box, Drawer, IconButton, Avatar, List, ListItemIcon, ListItemText, Typography, ListItemButton, Collapse, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import PeopleIcon from '@mui/icons-material/People';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { getBranding } from 'src/utils/branding';
import { useAppSelector, useAppDispatch } from 'src/store';
import { setActivePageString, toggleChannelsList, toggleCreateChannelDialog } from 'src/features/theme/theme.slice';
import ChannelsList from '../Channels/ChannelsList';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import SimpleChannelCreate from '../Channels/create/SimpleChannelCreate';
import AppsIcon from '@mui/icons-material/Apps'; // Use AppsIcon instead of AddCircle
import IntegrationsDialog from 'src/components/integrations/IntegrationsDialog';

const drawerWidth = 260;
const collapsedWidth = 64;

const DarkNav = ({ upgradePlan, user, userLogOut }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { channels } = useAppSelector(state => state.channels);
  const { activeProSubscriber } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const { activePage } = useAppSelector(state => state.theme);
  const oppyNoMouth = getBranding('oppy_expressions_no_mouth');
  const oppyConversationIcon = getBranding('oppy_conversation_icon');
  const oppyWordMarkYellow = getBranding('oppy_word_mark_yellow');
  const [oppiesOpen, setOppiesOpen] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [isIntegrationsDialogOpen, setIsIntegrationsDialogOpen] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
      //setIsMobile(true);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleDrawerToggle = () => {
    const newExpandedState = !isExpanded;
    setIsExpanded(newExpandedState);
    if (!newExpandedState) {
      setOppiesOpen(false);
    }
  };

  const handleListItemClick = (activePage) => {
    if (activePage !== 'Oppies') {
      if (isMobile) {
        setIsExpanded(false);
      }
      setOppiesOpen(false);
    }
    // const downcased = activePage?.toLowerCase();
    // dispatch(setActivePageString(downcased));
    // if (activePage === "Oppies") {
    //   dispatch(toggleChannelsList());
    // }
  };

  const handleUpgradePlan = () => {
    if (typeof upgradePlan === 'function') {
      upgradePlan();
    }
  };

  const handleOppiesClick = () => {
    setOppiesOpen(!oppiesOpen);
  };

  // Function to toggle the integrations dialog
  const toggleIntegrationsDialog = () => {
    console.log('Toggling integrations dialog'); // Debugging log
    setIsIntegrationsDialogOpen(prevState => !prevState); // Use prevState for toggling
  };

  const drawer = (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        height: '100%', 
        bgcolor: 'grey.900', 
        color: 'grey.300',
        width: isExpanded || isMobile ? drawerWidth : collapsedWidth,
        transition: 'width 0.3s ease-in-out',
      }}
      onMouseEnter={() => !isMobile && setIsExpanded(true)}
      onMouseLeave={() => !isMobile && setIsExpanded(false)}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
        <Box sx={{ position: 'relative', height: '32px', width: '100%' }}>
          <img 
            style={{
              height: '32px',
              position: 'absolute',
              opacity: isExpanded ? 1 : 0,
              transition: 'opacity 0.3s ease-in-out',
            }} 
            src={oppyWordMarkYellow} 
            alt="Logo" 
          />
          <img 
            style={{
              height: '32px',
              position: 'absolute',
              opacity: isExpanded ? 0 : 1,
              transition: 'opacity 0.3s ease-in-out',
            }} 
            src={oppyNoMouth} 
            alt="Logo" 
          />
        </Box>
        {isMobile && (
          <IconButton
            color="inherit"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <IconButton
          color="inherit"
          onClick={toggleIntegrationsDialog} // Open integrations dialog
          sx={{ display: { sm: 'none' } }}
        >
          <AppsIcon /> {/* Corrected icon */}
        </IconButton>
      </Box>

      <List>
        <ListItemButton 
          onClick={handleOppiesClick}
          sx={{ 
            justifyContent: 'center',
            height: '48px',
            px: isExpanded ? 2 : 0,
          }}
        >
          <ListItemIcon sx={{ 
            minWidth: 0,
            mr: isExpanded ? 2 : 0,
            justifyContent: 'center',
          }}>
            <img src={oppyNoMouth} alt="Oppies" style={{width: '24px', height: '24px'}} />
          </ListItemIcon>
          {isExpanded && (
            <>
              <ListItemText primary="Oppies" sx={{ '& .MuiTypography-root': { fontFamily: 'Power Grotesk, sans-serif' } }} />
              {oppiesOpen ? <ExpandLess /> : <ExpandMore />}
            </>
          )}
        </ListItemButton>
        <Collapse in={oppiesOpen && isExpanded} timeout="auto" unmountOnExit>
          <Box sx={{ pl: 2, pr: 2, overflowY: 'auto' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => dispatch(toggleCreateChannelDialog())}
                sx={{
                  fontFamily: 'Power Grotesk, sans-serif',
                  height: '40px',
                  '&:hover': { bgcolor: 'grey.800' },
                }}
              >
                Create
                <AddIcon fontSize="small" />
              </Button>
            </Box>
            <ChannelsList variant="list" />
          </Box>
        </Collapse>
        {[
          { text: 'Conversations', icon: <img src={oppyConversationIcon} alt="Conversations" style={{width: '24px', height: '24px'}} />, to: '/conversations', page: 'conversations' },
          { text: 'Contacts', icon: <PeopleIcon sx={{ color: 'white' }} />, to: '/contacts', page: 'contacts' },
        ].map((item) => (
          <ListItemButton 
            key={item.text}
            component={Link}
            to={item.to}
            disabled={item.text !== 'Oppies' && item.text !== 'Settings' && !channels.length}
            selected={activePage === item.page}
            onClick={() => handleListItemClick(item.text)}
            sx={{ 
              '&.Mui-selected': { bgcolor: 'grey.800' },
              justifyContent: 'center',
              height: '48px',
              px: isExpanded ? 2 : 0,
            }}
          >
            <ListItemIcon sx={{ 
              minWidth: 0,
              mr: isExpanded ? 2 : 0,
              justifyContent: 'center',
            }}>
              {item.icon}
            </ListItemIcon>
            {isExpanded && <ListItemText primary={item.text} sx={{ '& .MuiTypography-root': { fontFamily: 'Power Grotesk, sans-serif' } }} />}
          </ListItemButton>
        ))}
        <ListItemButton 
          onClick={toggleIntegrationsDialog} // Open integrations dialog
          sx={{ 
            justifyContent: 'center',
            height: '48px',
            px: isExpanded ? 2 : 0,
          }}
        >
          <ListItemIcon sx={{ 
            minWidth: 0,
            mr: isExpanded ? 2 : 0,
            justifyContent: 'center',
          }}>
            <AppsIcon sx={{ color: 'grey.500' }} /> {/* Set color to light grey */}
          </ListItemIcon>
          {isExpanded && (
            <ListItemText primary="Integrations" sx={{ '& .MuiTypography-root': { fontFamily: 'Power Grotesk, sans-serif' } }} />
          )}
        </ListItemButton>
      </List>

      <Box sx={{ mt: 'auto', p: 2, borderTop: 1, borderColor: 'grey.800', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Avatar component={Link} to="/profile" sx={{ width: 34, height: 34, mr: isExpanded ? 2 : 0 }} src={user.picture} alt="Profile Picture" />
          {isExpanded && (
            <Box>
              <Typography variant="body2" component={Link} to="/profile" sx={{ color: 'grey.300', fontWeight: 600, fontFamily: 'Power Grotesk, sans-serif' }}>{user.name}</Typography>
              <Typography variant="caption" onClick={userLogOut} sx={{ color: 'grey.500', cursor: 'pointer', display: 'block', fontFamily: 'Power Grotesk Light, sans-serif' }}>Sign Out</Typography>
            </Box>
          )}
        </Box>
        {/* {!activeProSubscriber && isExpanded && (
          <Box 
            onClick={handleUpgradePlan} 
            sx={{ 
              cursor: 'pointer', 
              p: 2, 
              borderRadius: 2, 
              bgcolor: 'primary.main',
              '&:hover': {
                bgcolor: 'primary.dark',
              },
              position: 'relative',
              height: '40px',
              width: '100%',
              overflow: 'hidden',
            }}
          >
            <Typography 
              variant="subtitle2" 
              sx={{ 
                color: 'white', 
                fontWeight: 800, 
                fontFamily: 'Power Grotesk, sans-serif',
                position: 'absolute',
                opacity: isExpanded ? 1 : 0,
                transition: 'opacity 0.3s ease-in-out',
                width: '100%',
                textAlign: 'center',
              }}
            >
              Upgrade to PRO
            </Typography>
            <Typography 
              variant="subtitle2" 
              sx={{ 
                color: 'white', 
                fontWeight: 800, 
                fontFamily: 'Power Grotesk, sans-serif',
                position: 'absolute',
                opacity: isExpanded ? 0 : 1,
                transition: 'opacity 0.3s ease-in-out',
                width: '100%',
                textAlign: 'center',
              }}
            >
              PRO
            </Typography>
          </Box>
        )} */}
      </Box>
    </Box>
  );

  return (
    <>
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ 
            position: 'fixed', 
            bottom: 16, 
            left: 16, 
            zIndex: 1200,
            bgcolor: 'grey.800',
            color: 'grey.300',
            '&:hover': { bgcolor: 'grey.700' },
          }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <Box
        component="nav"
        sx={{
          width: { sm: isExpanded ? drawerWidth : collapsedWidth },
          flexShrink: 0,
          transition: 'width 0.3s ease-in-out',
        }}
      >
        <Drawer
          variant={isMobile ? "temporary" : "permanent"}
          open={isMobile ? isExpanded : true}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            '& .MuiDrawer-paper': { 
              boxSizing: 'border-box', 
              width: isMobile ? drawerWidth : (isExpanded ? drawerWidth : collapsedWidth),
              transition: 'width 0.3s ease-in-out',
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Dialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <SimpleChannelCreate />
      </Dialog>
      <IntegrationsDialog open={isIntegrationsDialogOpen} onClose={toggleIntegrationsDialog} />
    </>
  );
};

export default DarkNav;
