import { API_ROUTES } from 'src/constants/routes';
import httpClient, { plainHttpClient } from 'src/libs/axios';
import { ReqAdminBooking, ReqAvailabilityAlert, ReqBooking, ReqServiceBooking, ReqUpdateBooking, ResBooking, ResGalleryItem, ResPhotographerType, ResResPhotographerAvailability, ServiceBookingInput } from 'src/types/bookings';
import { ResServiceListType, ResServiceType } from 'src/types/services';
import { ResBookingSettingsType } from 'src/types/user';
import { ResSubscriptionFeaturesType, UserType } from 'src/types/user';

const getAll = (data: ServiceBookingInput): Promise<ResServiceListType> => {
  return plainHttpClient.get(API_ROUTES.photographer_services, {params: data});
};

const getBookingSettings = (uuid: string): Promise<ResBookingSettingsType> => {
  return plainHttpClient.get(API_ROUTES.photographer_booking_settings, {params: {uuid}});
};

const getBooking = (uuid: string): Promise<ResBooking> => {
  return plainHttpClient.get(`${API_ROUTES.photographer_booking}/${uuid}`);
};

const get = (uuid: string, service_id: number): Promise<ResServiceType> => {
  return plainHttpClient.get(`${API_ROUTES.provider_services}/${service_id}`, {params: {uuid}});
};

const getGalleryItems = (data: {uuid: string, service_id: number}): Promise<ResGalleryItem>  => {
  return plainHttpClient.get(API_ROUTES.photographer_service_gallery_items, {params: data});
};

const getPhotographerAvailabilities = (data: ReqServiceBooking): Promise<ResResPhotographerAvailability> => {
  return plainHttpClient.get(API_ROUTES.photographer_availability, {params: data});
};

const getPhotographer = (data: {uuid: string}): Promise<ResPhotographerType> => {
  return plainHttpClient.get(API_ROUTES.photographer, {params: data});
};

const create = (data: ReqBooking): Promise<ResBooking> => {
  return plainHttpClient.post(API_ROUTES.photographer_booking, data);
}

const update = (data: ReqUpdateBooking, booking_uuid: string): Promise<ResBooking> => {
  return plainHttpClient.put(`${API_ROUTES.photographer_booking}/${booking_uuid}`, data);
}

const cancel = (uuid: string) => {
  return plainHttpClient.post(API_ROUTES.booking_cancelation, {uuid});
}

const cancelBooking = (cancel_booking_token: string) => {
  return plainHttpClient.put(API_ROUTES.cancel_booking, {booking_token: cancel_booking_token});
}

const availabilityAlert = (data: ReqAvailabilityAlert) => {
  return plainHttpClient.post(API_ROUTES.availability_alert, data);
}

const calculateDistancePhotographer = (data:  {address: string, uuid: string}) => {
  return plainHttpClient.post(API_ROUTES.calculate_distances_photographer, data);
}

const calculateDistanceProvider = (data:  {address: string, uuid: string}) => {
  return httpClient.post(API_ROUTES.calculate_distances_provider, data);
}

const getFirstGalleryItems = (data: {uuid: string}): Promise<ResGalleryItem> => {
  return plainHttpClient.get(API_ROUTES.photographer_first_gallery_items, {params: data});
}

const getAdminGalleryItem = (data: {uuid: string}): Promise<ResGalleryItem> => {
  return plainHttpClient.get(API_ROUTES.admin_booking_gallery_item, {params: data});
}

const createStripeAccount = (data: { booking_uuid: String }) => {
  return plainHttpClient.post(API_ROUTES.create_stripe_account, data);
}

const checkPaymentStatus = (data: { session_secret: String }) => {
  return plainHttpClient.put(API_ROUTES.create_stripe_account, data);
}

const finalizeInvoice = (data: { booking_uuid: String }) => {
  return httpClient.post(API_ROUTES.finalize_invoice, data);
}

const verifiedInvoice = ( booking_token: String ) => {
  return httpClient.put(API_ROUTES.verified_invoice, { booking_token });
}

const finalizeInvoiceMail = (uuid:  String ) => {
  return httpClient.post(API_ROUTES.finalize_invoice_mail, {uuid});
}

const getInvoices = (data: { booking_uuid: String }) => {
  return httpClient.get(API_ROUTES.invoices, {params: data});
}

const createAdminBooking = (data: ReqAdminBooking): Promise<ResBooking> => {
  return httpClient.post(API_ROUTES.provider_booking, data);
}

const getAdminAvailabilities = (data: ReqServiceBooking): Promise<ResResPhotographerAvailability> => {
  return httpClient.get(API_ROUTES.provider_availability, {params: data});
};

const getAllAdminServices = (data: ServiceBookingInput): Promise<ResServiceListType> => {
  return httpClient.get(API_ROUTES.provider_services, {params: data});
};

const getProviderSubscription = async (data: {uuid: string}): Promise<ResSubscriptionFeaturesType> => {
  return plainHttpClient.get(`${API_ROUTES.subscription_paywall_providers}`, { params: data });
};

const BookingService = {
  getAll,
  get,
  getGalleryItems,
  getPhotographerAvailabilities,
  getPhotographer,
  create,
  update,
  getBookingSettings,
  getBooking,
  cancel,
  cancelBooking,
  availabilityAlert,
  calculateDistancePhotographer,
  calculateDistanceProvider,
  getFirstGalleryItems,
  getAdminGalleryItem,
  createStripeAccount,
  checkPaymentStatus,
  finalizeInvoice,
  verifiedInvoice,
  finalizeInvoiceMail,
  getInvoices,
  createAdminBooking,
  getAdminAvailabilities,
  getAllAdminServices,
  getProviderSubscription
};

export default BookingService;
