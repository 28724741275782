import React from 'react';
import { Box, Card, Typography, Link } from '@mui/material';
import { getBranding } from 'src/utils/branding';

const ChannelNotFound = () => {
  const oppyExpressionDrunk = getBranding('oppy_expressions_drunk');

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      p={3}
    >
      <Card
        sx={{
          maxWidth: 480,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: 3,
        }}
      >
        <Box
          component="img"
          src={oppyExpressionDrunk}
          alt="Lost and confused character"
          sx={{ width: '100%', maxWidth: 200, mb: 2 }}
        />
        <Typography variant="h2" gutterBottom textAlign="center">
          We all get lost sometimes...
        </Typography>
        <Typography variant="caption" textAlign="center" mb={2}>
          Oppy not found. Please check the URL and try again or create an Oppy at <Link href="https://oppy.pro">oppy.pro</Link>.
        </Typography>
      </Card>
    </Box>
  );
};

export default ChannelNotFound;
