import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import GoogleService from 'src/services/googleCalendar.services';
import LocationService from 'src/services/location.service';
import { setMessage } from '../auth/message.slice';
import { serializeErrorMessage } from 'src/utils';

interface onBoardingState {
  step: string;
  location: string;
  error: string | null;
  apiLoading: boolean;
}

const initialState: onBoardingState = {
  step: 'initial',
  location: '',
  error: null,
  apiLoading: false,
};

export const setLocation = createAsyncThunk(
  'location/setLocation',
  async (data: { formatted_address: string }, thunkAPI) => {
    try {
      const response = await LocationService.setLocation(data);
      thunkAPI.dispatch(setMessage(response.data.message));
      return { location: response.data.location };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const setGoogleCalendarToken = createAsyncThunk(
  'google/setToken',
  async (data: { authorization_code: string, redirect_uri?: string }, thunkAPI) => {
    try {
      const response = await GoogleService.setGoogleCalendarToken(data);
      thunkAPI.dispatch(setMessage(response.data.message));
      return { data: response.data };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const onBoardingSlice = createSlice({
  name: 'on-boarding',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setLocation.fulfilled, (state, action) => {
        state.location = action.payload.location;
        state.error = null;
      })
      .addCase(setLocation.pending, (state, _action) => {
        state.error = null;
      })
      .addCase(setLocation.rejected, (state, action) => {
        state.error = action.payload as string;
      })
      .addCase(setGoogleCalendarToken.fulfilled, (state, _action) => {
        state.error = null;
        state.apiLoading = false
      })
      .addCase(setGoogleCalendarToken.pending, (state, _action) => {
        state.error = null;
        state.apiLoading = true
      })
      .addCase(setGoogleCalendarToken.rejected, (state, action) => {
        state.error = action.payload as string;
        state.apiLoading = false
      });
  },
});

export default onBoardingSlice.reducer;
