import React, { useState, useEffect } from 'react';
// import Autocomplete from '@mui/material/Autocomplete';
import useDebounce from 'src/hooks/useDebounce';
import { httpClient } from 'src/libs';
import { API_ROUTES } from 'src/constants/routes';
import { Alert, Snackbar } from '@mui/material';
import PhoneNumberSelectDialog from './PhoneNumberSelectDialog';
import PhoneNumberPurchaseDialog from './PhoneNumberPurchaseDialog';
import { useAppSelector } from 'src/store';


const PhoneNumberBrowserDialog = ({ openDialog, handleCloseDialog, channelId }) => {
  const { channel, loading, error } = useAppSelector((state) => state.channels.channels.find((channel) => channel.id === channelId))
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [loadingPhoneNumbers, setLoadingPhoneNumbers] = useState(false);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [areaCode, setAreaCode] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  const debouncedAreaCode = useDebounce(areaCode, 1000);
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [alert, setAlert] = useState({ open: false, type: '', message: '' });
  // const [searchDialogOpen, setSearchDialogOpen] = useState(openDialog);
  const [selectPhoneDialogOpen, setSelectPhoneDialogOpen] = useState(openDialog);
  const [phonePurchaseDialogOpen, setPhonePurchaseDialogOpen] = useState(false);
  const [subAccountStatus, setSubAccountStatus] = useState(null);
  const [a2pApproved, setA2pApproved] = useState(null);

  useEffect(() => {
    setSelectPhoneDialogOpen(openDialog)
  }, [openDialog])

  useEffect(() => {
    fetchPhoneNumbers();
  }, []);

  useEffect(() => {
    if (!debouncedSearchTerm && !debouncedAreaCode) return;
    if (debouncedAreaCode && debouncedAreaCode.length < 3) return;
    fetchPhoneNumbers();
  }, [debouncedSearchTerm, debouncedAreaCode]);

  const fetchPhoneNumbers = () => {
    setLoadingPhoneNumbers(true);
    const url = `${process.env.REACT_APP_API_URL}${API_ROUTES.available_phone_numbers}`
    httpClient.get(url, {
      params: {
        searchTerm: debouncedSearchTerm,
        areaCode: debouncedAreaCode,
      },
    }).then(response => {
      setPhoneNumbers(response.data.phone_numbers);
      setSubAccountStatus(response.data.sub_account?.status)
      setA2pApproved(response.data.messaging_service_a2p_approved)
    }).catch(error => {
      console.error(error);
    }).finally(() => {
      setLoadingPhoneNumbers(false);
    });
  }

  const alertHandleClose = () => {
    setAlert({ open: false, type: '', message: '' });
  };

  const handlePurchasePhoneNumber = () => {
    if (!selectedPhoneNumber || channel.twilio_phone_number) return;
    setPurchaseLoading(true);
    const url = `${process.env.REACT_APP_API_URL}${API_ROUTES.phone_purchase_success}`
    httpClient.post(url, {
      ...selectedPhoneNumber,
      "channel_id": channel.embed_key,
      "agreedToTerms": true,
    }).then(response => {
      if (response.data.success) {
        setAlert({
          open: true, type: "success",
          message: "Phone number purchased and assigned to your chat channel!"
        });
        setTimeout(() => {
          handleCloseDialog();
          window.location.reload();
        }, 3000);
      } else {
        setAlert({
          open: true,
          type: "error",
          message: response.data.message
        });
      }
    }).catch(error => {
      console.error(error);
      setAlert({
        open: true,
        type: "error",
        message: "An error occurred while making the request."
      });
    })
      .finally(() => {
        setPurchaseLoading(false);
      });
  };

  return (
    <>
      <Snackbar open={alert.open} autoHideDuration={6000} onClose={alertHandleClose} sx={{ zIndex: (theme) => theme.zIndex.snackbar }}>
        <Alert onClose={alertHandleClose} >
          {alert.message}
        </Alert>
      </Snackbar>

      <PhoneNumberSelectDialog
        open={selectPhoneDialogOpen}
        onClose={handleCloseDialog}
        onPurchaseClick={(phoneRow) => {
          setPhonePurchaseDialogOpen(true)
          setSelectPhoneDialogOpen(false)
          setSelectedPhoneNumber(phoneRow)
        }}
        purchaseLoading={purchaseLoading}
        loadingPhoneNumbers={loadingPhoneNumbers}
        phoneNumbers={phoneNumbers}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        areaCode={areaCode}
        setAreaCode={setAreaCode}
        subAccountStatus={subAccountStatus}
        a2pApproved={a2pApproved}
        channelId={channelId}
      />
      {phonePurchaseDialogOpen && 
        <PhoneNumberPurchaseDialog
          isOpen={phonePurchaseDialogOpen}
          onClose={() => {
            setPhonePurchaseDialogOpen(false);
            setSelectPhoneDialogOpen(true);
          }}
          handlePurchasePhoneNumber={handlePurchasePhoneNumber}
          subAccountStatus={subAccountStatus}
          a2pApproved={a2pApproved}
          channelId={channelId}
        />
      }
    </>
  );
};

export default PhoneNumberBrowserDialog;
