import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import ImportWebsitesInputArray from './ImportWebsitesInputArray';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { FormControl, FormGroup } from '@mui/material';
import Button from '@mui/material/Button';

type Page = {
  tracking_id: string;
  page_url: string;
}

// Define the props for the component
interface PagesDialogProps {
  channelId?: string;
  incomingPages?: Page[];  // now it can be an optional prop
  maxItems?: number;  // make it optional and give it a default value later
  onUpdatePages: (updatedPages: Page[]) => void;  // the function onUpdatePages should accept a parameter of Page array
}

const PagesDialog: React.FC<PagesDialogProps> = ({ 
  incomingPages = [{ tracking_id: '', page_url: '' }], 
  maxItems = Infinity,
  onUpdatePages,
  channelId
}) =>{

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
// write me a MUI edit pages button that opens a dialog with the input array component  and actions to save or cancel
// start with the button that says Teach me about your website aligned left with an arrow icon centered on the right
// when the user clicks the button, open a dialog with the input array component and actions to save or cancel
<>
    {/* button goes here */}
  <Button variant="contained" color="secondary" onClick={handleShow}>
    Import Websites
  </Button>
  {/* dialog goes here */}
  <Dialog open={show}
    onClose={handleClose}
    fullWidth
    disableEscapeKeyDown
  > 
    <DialogTitle>
      <Typography variant="h6">Import & Edit Pages</Typography>
      <Typography variant="body2">Webpages for your Oppy to learn before responding</Typography>
    </DialogTitle>
    <DialogContent>
      <ImportWebsitesInputArray
        maxItems={maxItems}
        channelId={channelId}
        incomingPages={incomingPages}
        onUpdatePages={onUpdatePages}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Cancel</Button>
      <Button onClick={handleClose}>Save</Button>
    </DialogActions>
  </Dialog>
</>
  );
};

export default PagesDialog;

// END Parent Component frontend/src/components/Channels/PagesDialog.tsx
