import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import { fetchFilteredContacts, setFilter } from 'src/features/account/filteredContacts.slice'; // Import setFilter
import { Button, Box, Grid, IconButton, TextField, CircularProgress, Popover, InputAdornment, Checkbox, FormControlLabel, Typography, Tooltip } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import ClearIcon from '@mui/icons-material/Clear';
import { selectIsFilterActive } from 'src/features/account/filteredContacts.slice'; // Import selector

const ContactsFilterBar = () => {
  const dispatch = useAppDispatch();
  const contactsLoading = useAppSelector(state => state.filteredContacts.contactsLoading);
  const [inputValue, setInputValue] = useState('');
  // State for Popover anchor
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const initialFilters = {
    first_name: null,
    last_name: null,
    name: null,
    status: null,
    tags: [], // Keep as []
    company_name: null,
    address: null,
    created_at_before: null,
    created_at_after: null,
    last_contacted_at_before: null,
    last_contacted_at_after: null,
    zip: null,
    state: null,
    phone_number: null,
    email: null,
    without_phone_number: false,
    without_email: false,
    company_size: null,
    company_industry: null,
    company_website: null,
    company_address: null,
    stage: null,
    notes: null,
  };

  const [advancedFilters, setAdvancedFilters] = useState(initialFilters);

  // Check if any filter param exists
  const isFilterActive = useAppSelector(selectIsFilterActive); // Use selector

  const handleInputChange = (newInputValue: string) => {
    setInputValue(newInputValue);
    console.log('Input value changed:', newInputValue);
  };

  // Open Popover
  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    console.log('Filter icon clicked, anchor set:', event.currentTarget);
  };

  // Close Popover
  const handlePopoverClose = () => {
    setAnchorEl(null);
    console.log('Popover closed');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'advanced-filter-popover' : undefined;

  const handleAdvancedFilterChange = (field: string, value: any) => {
    if (field === 'tags') {
      // Split input string into array
      const tagsArray = value.split(',').map(tag => tag.trim());
      setAdvancedFilters(prev => ({ ...prev, tags: tagsArray }));
      console.log(`Advanced filter changed - ${field}:`, tagsArray); // Log the array
    } else if (field === 'company_size') {
      // Convert company_size to number or null
      const size = value ? parseInt(value, 10) : null;
      setAdvancedFilters(prev => ({ ...prev, company_size: size }));
      console.log(`Advanced filter changed - ${field}:`, size);
    } else {
      setAdvancedFilters(prev => ({ ...prev, [field]: value || null }));
      console.log(`Advanced filter changed - ${field}:`, value);
    }
  };

  // Function to format phone number to +1XXXXXXXXXX format
  const formatPhoneNumber = (phone: string): string => {
    console.log('Original phone number:', phone); // Log the original phone number
    // Remove all non-digit characters
    const digits = phone.replace(/\D/g, '');
    console.log('Digits extracted:', digits); // Show the digits extracted
    let formattedNumber = digits;
    if (digits.length === 10) {
      formattedNumber = '+1' + digits;
      console.log('Formatted phone number:', formattedNumber); // Show the formatted number
    } else if (digits.length === 11 && digits.startsWith('1')) {
      formattedNumber = '+' + digits;
      console.log('Formatted phone number:', formattedNumber); // Show the formatted number
    } else {
      console.log('Invalid phone number length'); // Log invalid length
      // Handle invalid phone number length
      formattedNumber = phone; // Keep as is or set to empty string if preferred
    }
    return formattedNumber;
  };

  // Updated handleSubmit to format phone number
  const handleSubmit = () => {
    let params = { query: inputValue, ...advancedFilters };
    console.log('Submitting search with initial params:', params);

    // Format the phone number
    if (params.phone_number) {
      const formattedPhoneNumber = formatPhoneNumber(params.phone_number);
      params.phone_number = formattedPhoneNumber;
    }

    // Process 'stage' parameter
    if (params.stage) {
      let stage = params.stage;
      stage = stage.replace(/^\s*\d+-?\s*/, ''); // Remove leading numbers and hyphens
      stage = stage.trim().toLowerCase().replace(/\s+/g, '_'); // Convert to snake_case
      params.stage = stage;
      console.log('Transformed stage:', stage);
    }

    // Omit 'without_phone_number' and 'without_email' if false
    if (!params.without_phone_number) delete params.without_phone_number;
    if (!params.without_email) delete params.without_email;

    // Filter out null, empty strings, and empty arrays
    const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([key, value]) => {
        console.log(`Key: ${key}, Value: ${value}`);
        if (
          value === null ||
          value === '' ||
          (Array.isArray(value) && value.length === 0)
        ) {
          console.log(`Filtering out key: ${key} with value: ${value}`);
          return false;
        }
        return true;
      })
    );

    console.log('Submitting search with filtered params:', filteredParams);
    dispatch(setFilter(filteredParams));
    dispatch(fetchFilteredContacts(filteredParams));
    handlePopoverClose();
  };

  // Function to clear all filters
  const handleClearFilters = () => {
    setAdvancedFilters(initialFilters);
    setInputValue('');
    console.log('All filters cleared');
    dispatch(setFilter({})); // Reset filters in Redux
    dispatch(fetchFilteredContacts({})); // Fetch all contacts without filters
  };

  // Function to clear individual date filters
  const handleClearDateFilter = (field: string) => {
    setAdvancedFilters(prev => ({ ...prev, [field]: '' }));
    console.log(`Date filter cleared - ${field}`);
  };

  // Function to handle key press events in input fields
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    console.log('Key pressed:', event.key); // Log the key pressed
    if (event.key === 'Enter') {
      console.log('Enter key detected. Triggering search.');
      handleSubmit();
    }
  };

  // Function to create stringified version of filters
  const getFilterString = (filters: typeof advancedFilters) => {
    console.log('Converting filters to string:', filters); // Log the filters object
    const filterEntries = Object.entries(filters)
      .filter(([key, value]) => {
        const isValid =
          value !== null &&
          value !== '' &&
          !(Array.isArray(value) && value.length === 0) &&
          value !== false;
        console.log(`Filter key: ${key}, value: ${value}, is valid: ${isValid}`); // Log each filter's validity
        return isValid;
      })
      .map(([key, value]) => {
        let entry = '';
        if (typeof value === 'boolean') {
          entry = key;
        } else if (Array.isArray(value)) {
          entry = `${key}: ${value.join(', ')}`;
        } else {
          entry = `${key}: ${value}`;
        }
        console.log(`Formatted filter entry: ${entry}`); // Log formatted filter entry
        return entry;
      });
    const filterString = filterEntries.join('; ');
    console.log('Generated filter string:', filterString); // Log the final filter string
    return filterString;
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        <TextField
          label="Search Contacts"
          placeholder="Search for contacts..."
          variant="standard"
          fullWidth
          autoComplete="off"
          value={inputValue}
          onChange={(event) => handleInputChange(event.target.value)}
          onKeyDown={handleKeyPress}
          InputProps={{
            endAdornment: (
              <>
                {contactsLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <IconButton onClick={handleFilterClick}>
                    <TuneIcon color={isFilterActive ? "primary" : "inherit"} />
                  </IconButton>
                )}
              </>
            ),
            inputProps: {
              sx: {
                // Disable LastPass popup
                '& div[data-lastpass-icon-root]': { display: 'none' },
                '& div[data-lastpass-root]': { display: 'none' },
              },
            },
          }}
        />
      </Box>

      {/* Display applied filters subtitle if any filters are active */}
      {isFilterActive && (
        <Box display="flex" alignItems="center" mt={1}>
          <Typography variant="subtitle2">
            {getFilterString(advancedFilters)}
          </Typography>
          <Tooltip title="Clear Filters">
            <IconButton size="small" onClick={handleClearFilters}>
              <ClearIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={2}>
          <Grid container spacing={2}>

            {/* Personal Information */}
            <Grid item xs={12}>
              <Box fontWeight="bold">Personal Information</Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="First Name"
                variant="standard"
                fullWidth
                value={advancedFilters.first_name}
                onChange={(e) => handleAdvancedFilterChange('first_name', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Last Name"
                variant="standard"
                fullWidth
                value={advancedFilters.last_name}
                onChange={(e) => handleAdvancedFilterChange('last_name', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Full Name"
                variant="standard"
                fullWidth
                value={advancedFilters.name}
                onChange={(e) => handleAdvancedFilterChange('name', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone Number"
                variant="standard"
                fullWidth
                value={advancedFilters.phone_number}
                onChange={(e) => handleAdvancedFilterChange('phone_number', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                variant="standard"
                fullWidth
                value={advancedFilters.email}
                onChange={(e) => handleAdvancedFilterChange('email', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Address"
                variant="standard"
                fullWidth
                value={advancedFilters.address}
                onChange={(e) => handleAdvancedFilterChange('address', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>

            {/* Company Information */}
            <Grid item xs={12}>
              <Box fontWeight="bold">Company Information</Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Company Name"
                variant="standard"
                fullWidth
                value={advancedFilters.company_name}
                onChange={(e) => handleAdvancedFilterChange('company_name', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Company Size"
                variant="standard"
                fullWidth
                value={advancedFilters.company_size}
                onChange={(e) => handleAdvancedFilterChange('company_size', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Company Industry"
                variant="standard"
                fullWidth
                value={advancedFilters.company_industry}
                onChange={(e) => handleAdvancedFilterChange('company_industry', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Company Website"
                variant="standard"
                fullWidth
                value={advancedFilters.company_website}
                onChange={(e) => handleAdvancedFilterChange('company_website', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Company Address"
                variant="standard"
                fullWidth
                value={advancedFilters.company_address}
                onChange={(e) => handleAdvancedFilterChange('company_address', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>

            {/* Status and Stage */}
            <Grid item xs={12}>
              <Box fontWeight="bold">Status and Stage</Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Status"
                variant="standard"
                fullWidth
                value={advancedFilters.status}
                onChange={(e) => handleAdvancedFilterChange('status', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Stage"
                variant="standard"
                fullWidth
                value={advancedFilters.stage}
                onChange={(e) => handleAdvancedFilterChange('stage', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>

            {/* Tags and Notes */}
            <Grid item xs={12}>
              <Box fontWeight="bold">Tags and Notes</Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Tags"
                variant="standard"
                fullWidth
                value={advancedFilters.tags.join(', ')}
                onChange={(e) => handleAdvancedFilterChange('tags', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Notes"
                variant="standard"
                fullWidth
                value={advancedFilters.notes}
                onChange={(e) => handleAdvancedFilterChange('notes', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>

            {/* Dates */}
            <Grid item xs={12}>
              <Box fontWeight="bold">Date Filters</Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* Created At After */}
              <TextField
                label="Created At After"
                type="date"
                variant="standard"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={advancedFilters.created_at_after}
                onChange={(e) => handleAdvancedFilterChange('created_at_after', e.target.value)}
                onKeyDown={handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleClearDateFilter('created_at_after')}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* Created At Before */}
              <TextField
                label="Created At Before"
                type="date"
                variant="standard"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                value={advancedFilters.created_at_before}
                onChange={(e) => handleAdvancedFilterChange('created_at_before', e.target.value)}
                onKeyDown={handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleClearDateFilter('created_at_before')}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* ... Include other date fields like Last Contacted At After/Before ... */}

            {/* Location Information */}
            <Grid item xs={12}>
              <Box fontWeight="bold">Location Information</Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="State"
                variant="standard"
                fullWidth
                value={advancedFilters.state}
                onChange={(e) => handleAdvancedFilterChange('state', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Zip"
                variant="standard"
                fullWidth
                value={advancedFilters.zip}
                onChange={(e) => handleAdvancedFilterChange('zip', e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </Grid>
            {/* ... Include other location fields like Country Code, Locality, etc. ... */}

            {/* Other Filters */}
            <Grid item xs={12}>
              <Box fontWeight="bold">Other Filters</Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={advancedFilters.without_phone_number}
                    onChange={(e) => handleAdvancedFilterChange('without_phone_number', e.target.checked)}
                  />
                }
                label="Without Phone Number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={advancedFilters.without_email}
                    onChange={(e) => handleAdvancedFilterChange('without_email', e.target.checked)}
                  />
                }
                label="Without Email"
              />
            </Grid>
            {/* ... Include other checkboxes or fields as needed ... */}

          </Grid>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="outlined" color="secondary" onClick={handleClearFilters}>
              Clear Filters
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Search
            </Button>
            
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default ContactsFilterBar;
