import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAutocompleteSchedule, Channel } from '../../features/account/channels.slice';
import { Button, Select, MenuItem, FormControl, InputLabel, Box, Typography, SelectChangeEvent } from '@mui/material';
import { RootState } from '../../store';

const frequencies = [
  { value: '', label: 'No schedule' },
  { value: '* * * * *', label: 'Every minute' },
  { value: '*/15 * * * *', label: 'Every 15 minutes' },
  { value: '*/30 * * * *', label: 'Every 30 minutes' },
  { value: '0 * * * *', label: 'Every hour' },
  { value: '0 0 * * *', label: 'Daily at midnight' },
  { value: '0 0 * * 1', label: 'Weekly on Monday' },
  { value: '0 0 1 * *', label: 'Monthly on the 1st' },
];

interface AutocompleteSchedulerProps {
  channelId: string;
}

export const AutocompleteScheduler: React.FC<AutocompleteSchedulerProps> = ({ channelId }) => {
  const dispatch = useDispatch();
  const channel = useSelector((state: RootState) => state.channels.entities[channelId] as Channel | undefined);
  const [schedule, setSchedule] = useState(channel?.autocomplete_schedule || '');

  useEffect(() => {
    if (channel) {
      setSchedule(channel.autocomplete_schedule || '');
    }
  }, [channel]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    setSchedule(event.target.value as string);
  };

  const handleSubmit = () => {
    dispatch(updateAutocompleteSchedule({ channelId, schedule }));
  };

  if (!channel) return null;

  return (
    <Box>
      <Typography variant="body1" gutterBottom>
        Autocomplete Schedule
      </Typography>
      <FormControl fullWidth margin="normal" size="small">
        <InputLabel>Frequency</InputLabel>
        <Select value={schedule} onChange={handleChange} label="Frequency">
          {frequencies.map((freq) => (
            <MenuItem key={freq.value} value={freq.value}>
              {freq.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit}
        disabled={schedule === channel.autocomplete_schedule}
        size="small"
        sx={{ mt: 1 }}
      >
        Update Schedule
      </Button>
    </Box>
  );
};
