import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';
import { ResGalleryItem } from 'src/types/bookings';
import { ReqGalleryItemType, ResServiceListType, ServicesType } from 'src/types/services';

const bulk_reorder = (serviceId: string, order: Object[]): Promise<{data: { message: string }}> => {
  return httpClient.put(`${API_ROUTES.services}/${serviceId}/gallery_items/bulk_reorder`, {
    service: {
      gallery_items_attributes: order
    }
  })
};

const getAll = (): Promise<ResServiceListType> => {
  return httpClient.get(API_ROUTES.services);
};

const get = (id: string) => {
  return httpClient.get(`${API_ROUTES.services}/${id}`);
};

const create = (data: ServicesType) => {
  return httpClient.post(API_ROUTES.services, { service: data });
};

const update = (id: string, data) => {
  return httpClient.put(`${API_ROUTES.services}/${id}`, { service: data });
};

const remove = (id) => {
  return httpClient.delete(`${API_ROUTES.services}/${id}`);
};

const getServicePhotos = async (serviceId: string) => {
  return httpClient.get(`/services/${serviceId}/${API_ROUTES.gallery_items}`);
};

const uploadServicePhoto = async ( data: ReqGalleryItemType ) => {
  return httpClient.post(`/services/${data.service_id}/${API_ROUTES.gallery_items}`, data);
};

const deleteServicePhoto = async (serviceId: string, photoId: number) => {
  return httpClient.delete(`/services/${serviceId}/${API_ROUTES.gallery_items}/${photoId}`);
};

const getFirstGalleryItems = async (): Promise<ResGalleryItem> => {
  return httpClient.get(API_ROUTES.service_first_gallery_items);
};

const deleteService = (id: string) => {
  return httpClient.delete(`${API_ROUTES.services}/${id}`);
}

const reorderServices = async (services_order: {id: number, order: number}[]) => {
  return httpClient.put(API_ROUTES.reorder_services, { services_order });
};

const PhotographService = {
  getAll,
  get,
  create,
  update,
  remove,
  getServicePhotos,
  uploadServicePhoto,
  deleteServicePhoto,
  bulk_reorder,
  getFirstGalleryItems,
  deleteService,
  reorderServices,
};

export default PhotographService;
