// WYSIWYGEditor Component
import { useState, useEffect, useCallback } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertFromRaw } from 'draft-js';
// import 'src/styles/_dark_mode_wysiwyg_editor.scss';
import { markdownToDraft } from 'markdown-draft-js';
import { stateToHTML } from 'draft-js-export-html';
import TurndownService from 'turndown';

let turndownService = new TurndownService();

const WYSIWYGEditor = ({ initialValue, onChange, maxLength }) => {
  const textAreaValue = String(initialValue).replace(/\\n/g, '\n');
  const rawData = markdownToDraft(textAreaValue);
  const contentState = convertFromRaw(rawData);

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );

  const onTextAreaBlur = useCallback(() => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState);
    const markdown = turndownService.turndown(html);
    onChange(markdown);
  }, [editorState, onChange]);

  // Effect to update editorState when initialValue changes
  useEffect(() => {
    const rawData = markdownToDraft(textAreaValue);
    const contentState = convertFromRaw(rawData);
    setEditorState(EditorState.createWithContent(contentState));
  }, [textAreaValue]);

  const handleEditorChange = (state) => {
    const contentState = state.getCurrentContent();
    const html = stateToHTML(contentState);
    const markdown = turndownService.turndown(html);

    if (markdown.length <= maxLength) {
      setEditorState(state);
    }
  };

  const handleEditorClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div onClick={handleEditorClick}>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        onBlur={onTextAreaBlur}
        wrapperClassName="wysiwyg-wrapper-class"
        editorClassName="wysiwyg-editor-class"
        toolbarClassName="wysiwyg-toolbar-class"
        dropdownClassName="wysiwyg-dropdown-class"
        toolbar={{
          options: ['blockType', 'link'],
          blockType: {
            inDropdown: true,
            options: [
              'Normal',
              'H1',
              'H2',
              'H3',
              'H4',
              'H5',
              'H6',
            ],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            title: undefined,
          },
        }}
      />
    </div>
  );
};

export default WYSIWYGEditor;
