import { Button, Card, Carousel } from "react-bootstrap";
import NumberFormat from "react-number-format";
import PreviewGalleryItem from "./PreviewGalleryItem";
import Markdown from "markdown-to-jsx"

const PreviewService = ({handleAddToCart=()=>{}, handleRemove=()=>{}, service, galleryItems, selectedServices=[], handleFullGalleryView=()=>{}, propertyInfoFilled=false, handleErrorPopup=(_)=>{}, addButtonDisable=false}) => {

  return(
    <Card border="light">
      <Card.Header>
        <Card.Title>
          {service?.name}
        </Card.Title>
        <p className="card-subtitle">
          {service?.tagline ? service.tagline : "Service Subtitle"}
        </p>
      </Card.Header>
      <div className="photo-gallery">
        {galleryItems.length > 0 ?
          <Carousel onClick={handleFullGalleryView}>
            {galleryItems.map((galleryItem)=>(
              <Carousel.Item key={`galleryItem-${galleryItem.id}`}>
                <div style={{height: "300px"}}>
                  <PreviewGalleryItem item={galleryItem} />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        :
        <div className="placeholder-image"/>
        }
      </div>
      <Card.Body className="">
        <div>
          <div>
            {/* <div className="d-flex mt-2">
              <div className="me-2 black-medium fs-14">Duration:</div>
              <span className="text-gray fs-14">
              { service?.fixed_time && service?.time_per_k && square_feet > 0  ?
                 <span className="text-gray fs-14">{service?.fixed_time + (square_feet/1000) * service?.time_per_k } Minutes </span>
              :
                <span className="text-gray fs-14"> Minutes</span>
              }
              </span>
            </div> */}
            <div className="d-flex mb-4" style={{marginTop:'8px'}}>
              <div className="me-2 black-medium fs-14">Turnaround:</div>
              { service?.turnaround_time_string && <span className="text-gray fs-14">{service?.turnaround_time_string}</span> }
            </div>
          </div>

          <div className="d-flex justify-content-between mb-2">
            <div className="d-flex">
              <h6 className="fs-4" style={{ overflowWrap: 'anywhere' }}>
                {propertyInfoFilled  && <NumberFormat value={service?.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />}
              </h6>
              {selectedServices.includes(service?.id) && (
                <div
                  className="d-flex align-items-baseline ms-3 fw-medium"
                  onClick={ () => { handleRemove() }}
                >
                  <img src="/icon/check.svg" alt="" className="me-2" />{' '}
                  Service Added
                </div>
              )}
            </div>
          </div>

          <div>
            { !selectedServices.includes(service?.id) && (
            <Button disabled={addButtonDisable} variant="primary" onClick={ () => { propertyInfoFilled ? handleAddToCart() : handleErrorPopup("Service Detail") }}>
              <span className="fs-18 fw-medium d-flex align-items-center">
                <img src="/icon/plus.svg" alt="" className="me-2" />
                Add Service
              </span>
            </Button>
            )}
          </div>

          <div className="mt-4">
            <span className="text-secondary fs-12 fw-medium">
              Description
            </span>
            <Markdown>{service?.description}</Markdown>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default PreviewService;
