import { createAsyncThunk, createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import MessagesService from 'src/services/messages.services';
import { serializeErrorMessage } from 'src/utils';
import { addMessage, removeMessageFromConversation } from './conversations.slice';

interface MessageState {
  message_content: any;
  conversation_id: string;
  error: string | null;
  loading: boolean;
  marked_to_be_deleted: boolean;
}

const initialState: MessageState = {
  message_content: null,
  conversation_id: '',
  error: null,
  loading: false,
  marked_to_be_deleted: false,
}

export const getMessage = createAsyncThunk(
  'message/get',
  async (data: { messageId: any }, thunkAPI) => {
    try {
      const response = await MessagesService.get(data);
      if (response.status === 200 && response.data.message) {
        const message = response.data.message;
        thunkAPI.dispatch(addMessage(message));
      } else {
        console.error('Invalid response:', response);
        return thunkAPI.rejectWithValue('Invalid response from server');
      }
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const updateMessage = createAsyncThunk(
  'message/update',
  async (data: { messageId: any, content: string, channelId: any }, thunkAPI) => {
    try {
      const response = await MessagesService.update(data);
      return response.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const markMessageForDeletion = createAction<{ messageId: string }>('message/markForDeletion');

export const deleteMessage = createAsyncThunk(
  'message/delete',
  async (data: { messageId: any, channelId: any, conversationId: any }, thunkAPI) => {
    try {
      // Mark message for deletion
      thunkAPI.dispatch(markMessageForDeletion({ messageId: data.messageId }));
      // Remove message from conversation
      thunkAPI.dispatch(removeMessageFromConversation({ messageId: data.messageId, conversationId: data.conversationId }));
      const response = await MessagesService.destroy(data);
      return response.data;
    } catch (error) {
      const errorData = serializeErrorMessage(error);
      if (errorData.status_code === 422 && errorData.error_code === "UnprocessableEntity" && errorData.message === "404 Message not found") {
        thunkAPI.dispatch(removeMessageFromConversation({ messageId: data.messageId, conversationId: data.conversationId }));
      }
      return thunkAPI.rejectWithValue(errorData);
    }
  }
)

export const deliverMessage = createAsyncThunk(
  'message/deliver',
  async (data: { messageId: any, channelId: any }, thunkAPI) => {
    try {
      const response = await MessagesService.deliver(data);
      return response.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const deliverMessageNow = createAsyncThunk(
  'message/force_deliver_now',
  async (data: { messageId: any, channelId: any }, thunkAPI) => {
    try {
      const response = await MessagesService.force_deliver_now(data);
      return response.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const postMessage = createAsyncThunk(
  'message/post',
  async (data, thunkAPI) => {
    try {
      const response = await MessagesService.post_message(data);
      return response.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

const messagesSlice = createSlice({
  name: 'messages',
  initialState: initialState,
  reducers: {
    markForDeletion: (state, action: PayloadAction<{ messageId: string }>) => {
      state.marked_to_be_deleted = true;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(postMessage.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postMessage.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(updateMessage.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
      })
      .addCase(updateMessage.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deleteMessage.fulfilled, (state, action) => {
        state.error = null;
        state.loading = false;
        state.message_content = 'Pending delete';
      })
      .addCase(deleteMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deliverMessage.fulfilled, (state, action) => {
        state.error = null
        state.loading = false;
      })
      .addCase(deliverMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(deliverMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(deliverMessageNow.fulfilled, (state, action) => {
        state.error = null
        state.loading = false;
      })
      .addCase(deliverMessageNow.pending, (state) => {
        state.loading = true;
      })
      .addCase(deliverMessageNow.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(markMessageForDeletion, (state, action) => {
        state.marked_to_be_deleted = true;
      });
  },
});

export const { markForDeletion } = messagesSlice.actions;
export default messagesSlice.reducer;
