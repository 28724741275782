import { useEffect, useState } from 'react';
import { logout, setHasSession } from 'src/features/auth/auth.slice';
import { clearUser, getUser } from 'src/features/user/user.slice';
import authToken from 'src/services/auth-header';
import { useAppDispatch, useAppSelector } from 'src/store';

const useAuth = () => {
  const { isLoggedIn, authLoading } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  const [hasSessionActive, setHasSessionActive] = useState(false);
  const hasAuthToken = authToken() !== '';

  const initializeSession = async () => {
    if (!hasAuthToken) return;
    if (isLoggedIn) return;

    dispatch(getUser())
      .unwrap()
      .then((data) => {
        dispatch(setHasSession());
      });
  };

  useEffect(() => {
    if (hasSessionActive) return;
    initializeSession();
  }, []);

  const logOut = async (cb?: () => void) => {
    setHasSessionActive(false);
    dispatch(clearUser())
    dispatch(logout()).then(() => {
      if (cb) {
        // prenvent calling in the same rendering
        setTimeout(() => cb(), 0);
      }
    });
  };

  return {
    isLoggedIn,
    authLoading,
    logOut,
  };
};

export default useAuth;
