import ToastMessage from "./ToastMessage";

const SuccessPopup = ({ message }) => {

  return (
    <ToastMessage message={message} type="success"/>
  );
};

export default SuccessPopup;
