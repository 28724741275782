import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Account from '../components/BookingSettings/Account/Account';
import Availability from '../components/BookingSettings/Availability/Availability';
import Confirmation from '../components/BookingSettings/Confirmation/Confirmation';
import Location from '../components/BookingSettings/Location/Location';
import TravelPreferences from '../components/BookingSettings/Travel/TravelPreferences';
import Registration from '../components/Onboarding/Account/Registration';
import Calendar from '../components/Onboarding/Gcalendar/Calendar';
import CalenderConnectedSuccess from '../components/Onboarding/Gcalendar/Successful';
import Empty from '../components/Onboarding/Services/Empty';
import Service from '../components/Onboarding/Services/Index';
import Templates from '../components/Onboarding/Services/Templates';
import Address from '../components/Onboarding/Address/index';
import Booking from '../pages/Booking';
import Auth from './Auth';

const EmbedBooking = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => setScreenWidth(window.innerWidth));
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Booking />} />
      <Route path="registration" element={<Auth screen={"register"} />} />
      <Route path="address" element={<Address />} />
      <Route path="gcalendar" element={<Calendar />} />
      <Route path="gcalendarSuccess" element={<CalenderConnectedSuccess />} />
      <Route path="empty" element={<Empty screenWidth={screenWidth} />} />
      <Route path="templates" element={<Templates />} />
      {/* <Route path="service" element={<Service />} /> */}
      <Route
        path="travel"
        element={<TravelPreferences screenWidth={screenWidth} />}
      />
      <Route path="account" element={<Account screenWidth={screenWidth} />} />
      <Route path="location" element={<Location screenWidth={screenWidth} />} />
      <Route
        path="confirmation"
        element={<Confirmation screenWidth={screenWidth} />}
      />
      <Route
        path="availability"
        element={<Availability screenWidth={screenWidth} />}
      />
    </Routes>
  );
};

export default EmbedBooking;
