import { eachHourOfInterval } from 'date-fns';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import {
  getUserBookingAvailability,
  updateUserBookingAvailability,
} from 'src/features/user/user.slice';
import { compareChanges } from 'src/libs/utils';
import {
  bookingAvailabilityMapper,
  bookingAvailabilitySerializer,
  getBookingAvailabilityEmptyState,
  weekDays,
} from 'src/middleware/Photograph.middleware';
import { useAppDispatch, useAppSelector } from 'src/store';
import { availabilitySchema } from 'src/utils/formSchemas';
import ErrorPopup from '../ErrorPopup';
import SuccessPopup from '../SuccessPopup';
import { InputGroup } from 'react-bootstrap';
import { delayTime } from '../ToastMessage';
import subscriptionPopup from '../Subscription/SubscriptionPopup';

const START_WORKING_HOUR = 0;
const END_WORKING_HOUR = 23;

const generateTimeInterval = (
  startHour = START_WORKING_HOUR,
  endHour = END_WORKING_HOUR
) => {
  const START_DATE = new Date();
  const workingHoursInterval = eachHourOfInterval({
    start: new Date(
      START_DATE.getFullYear(),
      START_DATE.getMonth(),
      START_DATE.getDate(),
      startHour
    ),
    end: new Date(
      START_DATE.getFullYear(),
      START_DATE.getMonth(),
      START_DATE.getDate(),
      endHour
    ),
  });

  return workingHoursInterval;
};

const WeekDayItem = ({
  dayName,
  values,
  errors,
  touched,
  disabled,
  handleChange,
  workingHours,
  setFieldValue,
}) => {
  const strftime = require('strftime')

  return (
    <div className="mb-5">
      <div className="d-flex justify-content-between align-items-center booking--header mb-2">
        <h5 className="m-0" style={{textTransform: 'capitalize'}}>{dayName}</h5>
        <Form.Check
          className="form-check form-switch align-end"
          type="checkbox"
          role="switch"
          id={values[dayName]}
          value={values[dayName].enabled}
          disabled={disabled}
          onChange={(event) => {
            setFieldValue(`[${dayName}][enabled]`, event.target.checked);
          }}
          checked={values[dayName].enabled}
        />
      </div>
      {values[dayName].enabled && (
        <Row>
          <Col>
            <Form.Label className="fw-medium fs-14">
              Start Time
              <span className="text-danger"> *</span>
            </Form.Label>
            <Form.Select
              aria-label={`${dayName} start hour`}
              name={`${dayName}.start_time`}
              value={values[dayName].start_time}
              disabled={disabled}
              onChange={handleChange}
              isInvalid={
                (values[dayName].enabled && !values[dayName].start_time) ||
                parseInt(values[dayName].start_time) >=
                  parseInt(values[dayName].end_time)
              }
            >
              <option value="">-</option>
              {workingHours.map((date: Date) => (
                <option key={strftime("%H:%M",date)} value={strftime("%H:%M",date)}>
                  {strftime("%I:%M %p",date)}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              <>{errors[dayName]?.start_time}</>
            </Form.Control.Feedback>
          </Col>
          <Col>
            <Form.Label className="fw-medium fs-14">
              Stop Time
              <span className="text-danger"> *</span>
            </Form.Label>
            <Form.Select
              aria-label={`${dayName} end hour`}
              name={`[${dayName}][end_time]`}
              onChange={handleChange}
              disabled={disabled}
              value={values[dayName].end_time}
              isInvalid={
                values[dayName].enabled && !values[dayName].end_time
              }
            >
              <option value="">-</option>
              {workingHours.map((date: Date) => (
                <option key={strftime("%H:%M",date)} value={strftime("%H:%M",date)}>
                {strftime("%I:%M %p",date)}
              </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              <>{errors[dayName]?.end_time}</>
            </Form.Control.Feedback>
          </Col>
          <Col style={{ display: 'none' }}>
            <Form.Control
              type="text"
              className="ht-46"
              name={values[dayName].day}
              value={values.day}
              required
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

const BookingAvailabilityForm = () => {
  const [successMessage, setSuccessMessage] = useState("")
  const workingHours = generateTimeInterval();
  const dispatch = useAppDispatch();
  const { bookingAvailability, userError, userLoading } = useAppSelector((state) => state.user);
  const { subscriptionFeatures } = useAppSelector((state) => state.auth);
  let initialValues = getBookingAvailabilityEmptyState();

  if (bookingAvailability) {
    const currentAvailability = bookingAvailabilitySerializer(
      bookingAvailability?.booking_availabilities
    );
    initialValues = { ...initialValues, ...currentAvailability, lead_time: bookingAvailability.lead_time };
  }

  const onSubmit = (values) => {
    let payload = compareChanges(initialValues, values)
    const lead_time = values.lead_time
    delete(values.lead_time)
    const bookingAvailabilityData = bookingAvailabilityMapper(values);
    dispatch(updateUserBookingAvailability({...bookingAvailabilityData, lead_time: lead_time}))
    .unwrap()
    .then((response) => {
      dispatch(trackAnalytics({action_name: "booking_availability_saved", payload: {...payload, description: "user updated booking availability form"}}))
      setSuccessMessage("Successfully Updated")
      setTimeout(() => {
        setSuccessMessage("")
      }, delayTime)
    })
  };

  useEffect(() => {
    dispatch(getUserBookingAvailability());
  }, []);

  return (
    <>
      <h3 className="mb-5">Booking Availability</h3>
      {userError && <ErrorPopup message={userError}/>}
      {successMessage && <SuccessPopup message={successMessage}/>}

      <Formik
        initialValues={initialValues}
        validationSchema={availabilitySchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          setFieldValue,
          handleChange,
          resetForm,
          values,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Col sm={12} md={6}>
              <Form.Label htmlFor="lead_time">
                Lead time before soonest availability:
                <span className="text-danger"> *</span>
              </Form.Label>
              <InputGroup>
                <Form.Control
                  value={subscriptionFeatures?.customizable_lead_time?.permitted ? values.lead_time : subscriptionFeatures?.customizable_lead_time?.allowed_value }
                  placeholder='1 hour - 24 hours'
                  id="lead_time"
                  name="lead_time"
                  type="number"
                  className="mb-4"
                  min={1}
                  max={24}
                  disabled={userLoading}
                  onChange={(e) => {
                    if (subscriptionFeatures?.customizable_lead_time?.permitted) { handleChange(e) }
                    else { subscriptionPopup(subscriptionFeatures, "Lead Time can not be customized ", { feature_id: "customizable_lead_time", description: "Provider can not customize lead time" }, dispatch) }
                  }}
                  isValid={touched.lead_time && !errors.lead_time}
                  isInvalid={touched.lead_time && !!errors.lead_time}
                />
                <InputGroup.Text className="mb-4">Hours</InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  <>{errors.lead_time}</>
                </Form.Control.Feedback>
              </InputGroup>
            </Col>

            {weekDays.map((day) => (
              <WeekDayItem
                key={day}
                values={values}
                touched={touched}
                dayName={day}
                errors={errors}
                disabled={userLoading}
                handleChange={handleChange}
                workingHours={workingHours}
                setFieldValue={setFieldValue}
              />
            ))}

            <hr className="mt-5 mb-4" />
            <div className="action-buttons">
              <Button variant="primary" type="submit" disabled={userLoading}>
                Save
              </Button>
              <Button variant="outline-secondary" className="me-lg-3" onClick={() => resetForm()}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default BookingAvailabilityForm;
