interface AssistantIconProps {
  size?: number;
  movementSpeed?: number;
}

const AssistantIcon: React.FC<AssistantIconProps> = ({ size = 40, movementSpeed = 1 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" style={{ width: size, height: size }}>
    <style>
      {`
      @keyframes moveBlob {
        0%, 100% { cx: 50; cy: 50; rx: 22; ry: 22; }
        25% { cx: 55; cy: 45; rx: 24; ry: 20; }
        50% { cx: 45; cy: 55; rx: 20; ry: 24; }
        75% { cx: 53; cy: 47; rx: 23; ry: 21; }
      }
      @keyframes pulseYellowBlob {
        0%, 100% { cx: 50; cy: 50; rx: 28; ry: 28; }
        10% { cx: 52; cy: 48; rx: 32; ry: 30; }
        25% { cx: 48; cy: 52; rx: 26; ry: 29; }
        40% { cx: 51; cy: 49; rx: 30; ry: 31; }
        60% { cx: 49; cy: 51; rx: 29; ry: 27; }
        75% { cx: 53; cy: 47; rx: 31; ry: 30; }
        90% { cx: 47; cy: 53; rx: 27; ry: 28; }
      }
      @keyframes moveWrapper {
        0%, 100% { d: M50,10 A40,40 0 0,1 50,90 A40,40 0 0,1 50,10 Z; }
        20% { d: M47,8 A43,43 0 0,1 53,92 A43,43 0 0,1 47,8 Z; }
        40% { d: M53,12 A37,37 0 0,1 47,88 A37,37 0 0,1 53,12 Z; }
        60% { d: M46,9 A44,42 0 0,1 54,91 A44,42 0 0,1 46,9 Z; }
        80% { d: M152,11 A38,39 0 0,1 48,89 A38,39 0 0,1 52,11 Z; }
      }
      .blob { animation: moveBlob ${3 / movementSpeed}s ease-in-out infinite; }
      .yellow-blob { animation: pulseYellowBlob ${5 / movementSpeed}s ease-in-out infinite; }
      .wrapper { animation: moveWrapper ${4 / movementSpeed}s ease-in-out infinite; }
      .blob:nth-child(2) { animation-delay: -0.75s; }
      .blob:nth-child(3) { animation-delay: -1.5s; }
      .blob:nth-child(4) { animation-delay: -2.25s; }
      `}
    </style>
    <defs>
      <filter id="goo">
        <feGaussianBlur in="SourceGraphic" stdDeviation="3" result="blur" />
        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
      </filter>
      <mask id="circle-mask">
        <path className="lip-mask" d="M50,10 A40,40 0 0,1 50,90 A40,40 0 0,1 50,10 Z" fill="white" />
      </mask>
    </defs>
    <g>
      <path className="wrapper" d="M50,10 A40,40 0 0,1 50,90 A40,40 0 0,1 50,10 Z" fill="#000000" stroke="#000000" strokeWidth="6" />
      <g filter="url(#goo)" mask="url(#circle-mask)">
        <circle cx="50" cy="50" r="34" fill="#000000" />
        <ellipse className="yellow-blob" cx="50" cy="50" rx="28" ry="28" fill="#ceff1a" />
        <ellipse className="blob" cx="50" cy="50" rx="22" ry="22" fill="#bbb5ff" />
        <ellipse className="blob" cx="50" cy="50" rx="22" ry="22" fill="#8b91a7" />
        <ellipse className="blob" cx="50" cy="50" rx="22" ry="22" fill="#4949c8" />
      </g>
    </g>
  </svg>
);

export default AssistantIcon;
