import React from 'react';
import Swal from 'sweetalert2';

const WaitlistPopup = () => {
  const handleTabPress = (event) => {
    if (event.keyCode === 9) {
      Swal.fire({
        html: '<iframe src="https://form.typeform.com/to/LPCPeLMW" width="100%" height="600px" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>',
        width: '100%',
        heightAuto: true,
        showConfirmButton: false,
        showCloseButton: true,
      });
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', handleTabPress);
    return () => {
      document.removeEventListener('keydown', handleTabPress);
    };
  }, []);

  return <></>;
};

export default WaitlistPopup;
