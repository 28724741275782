import { Field, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CircularProgress, Link, Button, Divider } from '@mui/material';
import { register } from 'src/features/auth/auth.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { registrationSchema } from 'src/utils/formSchemas';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import DocumentMeta from 'react-document-meta';
import { getOnboardingPath } from 'src/libs/utils';
import { PhoneVerification } from 'src/components/PhoneVerification';
import { Box, TextField, Typography } from '@mui/material';

const params = new URLSearchParams(window.location.search);
const categoryCookie = document.cookie.replace(/(?:(?:^|.*;\s*)rmk_category\s*\=\s*([^;]*).*$)|^.*$/, "$1");
const category = categoryCookie || params.get("category");

const initialValues = {
  firstName: '',
  lastName: '',
  password: '',
  email: '',
  termOfUse: false,
  phone: '',
  phone_verification: '',
  existing_user: false,
  category: category || "",
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
};

const RegistrationForm = ({
  setFieldValue, handleSubmit, handleChange, setFieldError, values, touched, errors,
  showPassword, setShowPassword, showNameFields, setShowNameFields,
  error, setError, loading, setLoading,
  isPhoneVerified, setIsPhoneVerified, phoneError, setPhoneError,
  displayedAuthScreen, setDisplayedAuthScreen,
  authError,
  dispatch, navigate,
}) => {

  // Update UI based on form values and authentication errors
  useEffect(() => {
    setError(authError);
    const isEmailValid = /^\S+@\S+\.\S+$/.test(values.email);
    setShowPassword(isEmailValid);
    if (isEmailValid) {
      setShowNameFields(values.password.length >= 8);
    } else {
      setShowNameFields(false);
    }
    console.log('Email validation status:', isEmailValid);
  }, [values.email, values.password, authError]);

  return (
    <Form noValidate onSubmit={handleSubmit}>
      {/* Email Field */}
      <Form.Group className="mb-3" controlId="validationFormik03">
        <TextField
          sx={{ width: '100%' }}
          type="email"
          name="email"
          value={values.email}
          onChange={(e) => {
            handleChange(e);
            setError(false);
            setFieldError("email", null);
            console.log('Email changed:', e.target.value);
          }}
          label="Email"
          error={touched.email && !!errors.email}
          helperText={touched.email && errors.email}
          required
          autoFocus
        />
      </Form.Group>

      {/* Password Field */}
      {showPassword && (
        <Form.Group className="mb-3" controlId="validationFormik04">
          <TextField
            sx={{ width: '100%' }}
            type="password"
            name="password"
            value={values.password}
            onChange={handleChange}
            label="Password"
            error={touched.password && !!errors.password}
            helperText={touched.password && errors.password}
            required
          />
        </Form.Group>
      )}

      {/* Name Fields */}
      {showNameFields && (
        <>
          <Form.Group className="mb-3" controlId="validationFormik01">
            <TextField
              sx={{ width: '100%' }}
              type="text"
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              label="First Name"
              error={touched.firstName && !!errors.firstName}
              helperText={touched.firstName && errors.firstName}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="validationFormik02">
            <TextField
              sx={{ width: '100%' }}
              type="text"
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              label="Last Name"
              error={touched.lastName && !!errors.lastName}
              helperText={touched.lastName && errors.lastName}
              required
            />
          </Form.Group>

          {/* Phone Verification */}
          <Form.Group className="mb-3">
            <PhoneVerification
              initialPhoneNumber={values.phone}
              existingUser={false}
              onPhoneNumberChange={(phone) => {
                setFieldValue('phone', phone);
                console.log(`Phone number changed to ${phone}`);
              }}
              onPhoneVerification={(phoneNumber, token) => {
                setIsPhoneVerified(true);
                setFieldValue('phone_verification', token);
                console.log('Phone number verified:', phoneNumber);
              }}
              onErrorsChange={(fieldName, error) => {
                setFieldError(fieldName, error);
                console.log(`Error in ${fieldName}:`, error);
              }}
            />
            {phoneError && (
              <Typography color="error">{phoneError}</Typography>
            )}
          </Form.Group>
        </>
      )}

      {/* Submit Button */}
      <Row className="justify-content-center p-2">
        <Button
          variant="contained"
          type="submit"
          disabled={loading}
          sx={{ mt: 3, mb: 3 }}
        >
          {loading && <CircularProgress size={24} />}
          Create Account
        </Button>
      </Row>
    </Form>
  );
};

const Registration = ({ displayedAuthScreen, setDisplayedAuthScreen }) => {
  const [loading, setLoading] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [phoneError, setPhoneError] = useState(null);
  const { authError } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNameFields, setShowNameFields] = useState(false);
  const [user, setUser] = useState(null);

  // Log user registration
  useEffect(() => {
    if (user) {
      console.log('User registered:', user);
    }
  }, [user]);

  const onSubmit = (values) => {
    if (!isPhoneVerified) {
      setPhoneError("Please validate your Phone Number before submitting!");
      console.log('Phone number not verified');
      return;
    }
    const refCookie = document.cookie.replace(/(?:(?:^|.*;\s*)rmk_ref\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    const ref = refCookie || searchParams.get("ref");
    const accountInviteCookie = document.cookie.replace(/(?:(?:^|.*;\s*)rmk_account_invite\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    const account_invite = accountInviteCookie || searchParams.get("account_invite");

    setLoading(true);
    dispatch(register({ ...values, ref, account_invite }))
      .unwrap()
      .then(() => {
        dispatch(trackAnalytics({ action_name: "user_registered", payload: { description: `User with email ${values.email} created account` } }));
        navigate(getOnboardingPath());
        console.log('Registration successful:', values.email);
      })
      .catch(() => {
        setLoading(false);
        console.log('Registration failed');
      });
  };

  return (
    <>
      <Typography variant='h3' sx={{ opacity: 0.9 }}>
        Register
      </Typography>
      <Typography variant='body2' sx={{ opacity: 0.7, pb: 1 }}>
        Create new account{' '}
        <Link
          onClick={() => {
            navigate('/login');
            setDisplayedAuthScreen('login');
          }}
          color="primary"
          component={RouterLink}
          to="/register"
          sx={{ textDecoration: 'none', cursor: 'pointer' }}
        >
          or sign in
        </Link>
      </Typography>
      <Box width={'100%'} mt={2}>
        <Formik
          initialValues={initialValues}
          validationSchema={registrationSchema}
          onSubmit={onSubmit}
        >
          {(formikProps) => (
            <RegistrationForm
              {...formikProps}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              showNameFields={showNameFields}
              setShowNameFields={setShowNameFields}
              error={error}
              setError={setError}
              loading={loading}
              setLoading={setLoading}
              isPhoneVerified={isPhoneVerified}
              setIsPhoneVerified={setIsPhoneVerified}
              phoneError={phoneError}
              setPhoneError={setPhoneError}
              displayedAuthScreen={displayedAuthScreen}
              setDisplayedAuthScreen={setDisplayedAuthScreen}
              authError={authError}
              dispatch={dispatch}
              navigate={navigate}
            />
          )}
        </Formik>
      </Box>
    </>
  );
};

export default Registration;
