import React, { useState, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { selectChannelById } from 'src/features/account/channels.slice';
import { useAppSelector } from 'src/store';
import ContactsSearchAutocomplete from '../Contacts/ContactsSearchAutocomplete';
import { setOpenDialog } from 'src/features/theme/theme.slice';
import { httpClient } from 'src/libs';
import { API_ROUTES } from 'src/constants/routes';
import { selectContactById } from 'src/features/account/contacts.slice';

interface ConversationCallOptionsProps {
  open: boolean;
  channelId: string;
  incomingContactId?: string | null;
  onClose: () => void;
}

const ConversationCallOptions = React.memo(({open, channelId, incomingContactId = null, onClose }: ConversationCallOptionsProps) => {
  const dispatch = useDispatch();
  const user = useAppSelector(state => state.user);
  const channel = useAppSelector(state => selectChannelById(state, channelId));
  const contact = useAppSelector(state => selectContactById(state, incomingContactId));
  const [selectedContact, setSelectedContact] = useState(contact);
  const [callerName, setCallerName] = useState(user?.user?.name || '');
  const [toAdminPhoneNumber, setToAdminPhoneNumber] = useState(user?.user?.contact_phone || '');

  const [callStatus, setCallStatus] = useState('idle');
  const [conversationId, setConversationId] = useState(null);

  const handleInitiateCall = async () => {
    if (!selectedContact) return;
    
    setCallStatus('calling');
    try {
      const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_channel}/${channel?.id}/initiate_call`;
      const response = await httpClient.post(url, {
        from_oppy_number: channel?.twilio_phone_number,
        to_admin_number: toAdminPhoneNumber,
        connect_to_number: selectedContact.phone_number,
        caller_name: callerName,
        contact_name: selectedContact.name
      });

      const result = response.data;
      if (result.success) {
        setCallStatus('success');
        setConversationId(result.conversation_id);
      } else {
        setCallStatus('error');
      }
    } catch (error) {
      setCallStatus('error');
      console.error('Failed to initiate call:', error);
    }
  };

  const handleSelectContact = useCallback((contact) => {
    setSelectedContact(contact);
  }, []);

  const handleClose = () => {
    onClose();
  };

  const renderCallButton = () => {
    switch (callStatus) {
      case 'idle':
        return (
          <Button onClick={handleInitiateCall} disabled={!selectedContact}>
            Call
          </Button>
        );
      case 'calling':
        return (
          <Button disabled>
            Calling {toAdminPhoneNumber}...
          </Button>
        );
      case 'success':
        return (
          <Link to={`/conversations?conversation_id=${conversationId}&channel_id=${channelId}`}>
            <Button onClick={handleClose}>
              Call created - track conversation
            </Button>
          </Link>
        );
      case 'error':
        return (
          <Button onClick={handleInitiateCall} color="error">
            Error - Retry Call
          </Button>
        );
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Place a call</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Your admin phone number to host the call from (Hidden from contact)"
          type="tel"
          fullWidth
          value={toAdminPhoneNumber}
          onChange={(e) => setToAdminPhoneNumber(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Caller Name"
          type="text"
          fullWidth
          value={callerName}
          onChange={(e) => setCallerName(e.target.value)}
        />
        <ContactsSearchAutocomplete onSelectContact={handleSelectContact} />
        <Typography>
          <Typography>
            1. <Box component="span" fontWeight="bold">{channel?.chatbot_name}</Box> (<Box component="span" fontWeight="bold" fontStyle="italic">{channel?.twilio_phone_number}</Box>) will first call <Box component="span" fontWeight="bold">{callerName}</Box> at <Box component="span" fontWeight="bold" fontStyle="italic">{toAdminPhoneNumber}</Box>.
          </Typography>
          <Typography>
            2. When <Box component="span" fontWeight="bold">{callerName}</Box> answers, the call will begin ringing <Box component="span" fontWeight="bold">{selectedContact?.name}</Box> at <Box component="span" fontWeight="bold" fontStyle="italic">{selectedContact?.phone_number}</Box>.
          </Typography>
          <Typography>
            3. The call will appear to come from <Box component="span" fontWeight="bold">{channel?.chatbot_name}</Box> at <Box component="span" fontWeight="bold" fontStyle="italic">{channel?.twilio_phone_number}</Box>.
          </Typography>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {renderCallButton()}
      </DialogActions>
    </Dialog>
  );
}, (prevProps, nextProps) => {
  return prevProps.open === nextProps.open && 
         prevProps.channelId === nextProps.channelId;
});

ConversationCallOptions.displayName = 'ConversationCallOptions';

export default ConversationCallOptions;
