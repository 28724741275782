import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import Markdown from 'markdown-to-jsx';

interface TranscriptionMessageProps {
  speaker: string;
  text: string;
  isFinal: boolean;
  startTime: Date;
  endTime?: Date;
  onMessageFinalized?: (message: TranscriptionMessageProps) => void;
}

const TranscriptionMessage = ({
  speaker,
  text,
  isFinal: initialIsFinal,
  startTime,
  endTime,
  onMessageFinalized,
}: TranscriptionMessageProps) => {
  const [isFinal, setIsFinal] = useState<boolean>(initialIsFinal);
  const [currentText, setCurrentText] = useState<string>('');
  const [processedText, setProcessedText] = useState<string>('');
  const [unprocessedText, setUnprocessedText] = useState<string>('');
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isFinal) return;

    setCurrentText((prev) => `${prev} ${text}`.trim());
  }, [text]);

  useEffect(() => {
    if (isFinal) return;

    const newUnprocessedText = currentText.replace(processedText, '').trim();
    setUnprocessedText(newUnprocessedText);
  }, [currentText, processedText, isFinal]);

  useEffect(() => {
    if (isFinal) return;

    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    if (unprocessedText) {
      debounceTimerRef.current = setTimeout(() => {
        console.log('Debounce timeout reached, processing transcript...');
        cleanUpTranscript(unprocessedText);
      }, 1000);
    }

    return () => {
      if (debounceTimerRef.current) clearTimeout(debounceTimerRef.current);
    };
  }, [unprocessedText, isFinal]);

  const cleanUpTranscript = async (newText: string) => {
    if (!newText) return;

    try {
      console.log('Sending new text to OpenAI API:', newText);

      // Prepare messages for OpenAI API
      const messages = [
        {
          role: 'system',
          content: `GOAL: Make the transcript phonetically correct based on prior context. Adjust prior words if needed only if it seems like the transcript is incorrect. Do not make up new words and do not delete or change words unless you are 100% sure it is incorrect. Anything you say will fully replace the user's message, so do not respond with any characters unless you are sure the transcript is incorrect. Previous sanitized transcript to build on:\n${processedText}`,
        },
        {
          role: 'user',
          content: `<unprocessed_transcript>\n${newText}\n</unprocessed_transcript> NOW you must immediately take this unprocessed_transcript and fulfill your goals. Respond with 'do_nothing' if no changes are needed.`,
        },
      ];

      // Call OpenAI API with newText and previous context
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer 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`, // Securely handle API key
        },
        body: JSON.stringify({
          model: 'gpt-4o-mini',
          max_tokens: 50,
          messages: messages,
        }),
      });

      const data = await response.json();
      console.log('Received response from OpenAI:', data);

      if (response.ok && data.choices && data.choices.length > 0) {
        const processedNewText = data.choices[0].message.content.trim();
        console.log('Processed text from OpenAI:', processedNewText);

        // Only update if the assistant suggests changes
        if (processedNewText.toLowerCase() !== 'do_nothing') {
          // Update processedText and reset unprocessedText
          setProcessedText((prev) => `${prev} ${processedNewText}`.trim());
          setCurrentText((prev) => `${processedText} ${processedNewText}`.trim());
        } else {
          // If no changes, append unprocessedText to processedText
          setProcessedText((prev) => `${prev} ${unprocessedText}`.trim());
          setCurrentText((prev) => `${processedText} ${unprocessedText}`.trim());
        }
        setUnprocessedText('');
      } else {
        console.error('Error processing transcript:', data);
      }
    } catch (error: any) {
      console.error('Error calling OpenAI API:', error);
    }
  };

  const handleFinalize = () => {
    console.log('Checkbox clicked, setting isFinal to true.');
    setIsFinal(true);

    // Clear any pending debounce timer
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    // Process any remaining unprocessed text
    if (unprocessedText) {
      cleanUpTranscript(unprocessedText);
    }

    if (onMessageFinalized) {
      onMessageFinalized({
        speaker,
        text: processedText,
        isFinal: true,
        startTime,
        endTime: new Date(),
      });
    }
  };

  const formatTimestamp = (date: Date) =>
    date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
        {speaker} {isFinal ? '' : '(processing...)'}
      </Typography>
      <Typography variant="body2">
        <Markdown>{processedText}</Markdown>{' '}
        <span style={{ color: 'grey' }}>{unprocessedText}</span>
      </Typography>
      <Typography variant="caption" color="textSecondary">
        {formatTimestamp(startTime)}
        {endTime && isFinal ? ` - ${formatTimestamp(endTime)}` : ''}
      </Typography>
      {!isFinal && (
        <FormControlLabel
          control={<Checkbox checked={isFinal} onChange={handleFinalize} />}
          label="Finalize Message"
        />
      )}
    </Box>
  );
};

export default TranscriptionMessage;
