import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PhotographMapper } from 'src/middleware/Photograph.middleware';
import authToken from 'src/services/auth-header';
import { PhotographUser } from 'src/types/user';
import { serializeErrorMessage } from 'src/utils';
import AuthService from '../../services/auth.service';
import { getUser } from '../user/user.slice';
import { SubscriptionFeaturesType } from 'src/types/user'
import { fetchChannels } from '../account/channels.slice'

// import { setMessage } from './message.slice';

interface AuthState {
  isLoggedIn: boolean;
  authError: any;
  authLoading: boolean;
  authSuccessMessage: null | string;
  subscriptionFeatures: SubscriptionFeaturesType;
  activeProSubscriber: boolean;
  globalOnboardingSessionId: string;
}

const hasAuthToken = authToken() !== '';
const initialState: AuthState = {
  isLoggedIn: false,
  authError: null,
  authLoading: hasAuthToken,
  authSuccessMessage: null,
  subscriptionFeatures: null,
  activeProSubscriber: false,
  globalOnboardingSessionId: null
};

interface LoginUser {
  email: string;
  password: string;
}

interface ValidateSms {
  phone: string;
  code: string;
}

export const register = createAsyncThunk(
  'auth/register',
  async (userData: PhotographUser, thunkAPI) => {
    try {
      const data = await AuthService.register(PhotographMapper(userData));
      thunkAPI.dispatch(getUser());
      thunkAPI.dispatch(fetchChannels());
      return { user: data };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }: LoginUser, thunkAPI) => {
    try {
      const data = await AuthService.login(email, password);
      thunkAPI.dispatch(getUser());
      thunkAPI.dispatch(fetchChannels());
      return { user: data };
    } catch (error) {
      const message = serializeErrorMessage(error);
      error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendValidationCode = createAsyncThunk(
  'auth/sendValidationCode',
  async ({ phone }: { phone: string }, thunkAPI) => {
    try {
      const data = await AuthService.sendSms(phone);
      return data
    } catch (error) {
      const message = serializeErrorMessage(error);
      error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const validateCode = createAsyncThunk(
  'auth/validateCode',
  async ({ phone, code }: ValidateSms, thunkAPI) => {
    try {
      const data = await AuthService.validateSms(phone , code);
      return data
    } catch (error) {
      const message = serializeErrorMessage(error);
      error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const loginWithSms = createAsyncThunk(
  'auth/loginWithSms',
  async ({ phone_token }: { phone_token: string }, thunkAPI) => {
    try {
      const data = await AuthService.smsLogin(phone_token);
      thunkAPI.dispatch(getUser());
      thunkAPI.dispatch(fetchChannels());
      return { user: data };
    } catch (error) {
      const message = serializeErrorMessage(error);
      error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUserSubscription = createAsyncThunk(
  'scheduler/subscription_paywall_user',
  async (_, thunkAPI) => {
    try {
      const res = await AuthService.getUserSubscription();
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createSubscription = createAsyncThunk(
  'scheduler/paymnents/subscription',
  async (data: { checkout_session_id: String }, thunkAPI) => {
    try {
      const res = await AuthService.createSubscription(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

interface ForgotPassword {
  email: string;
}

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async ({ email }: ForgotPassword, thunkAPI) => {
    try {
      const data = await AuthService.forgotPassword({
        email: email,
      });

      return data.data.message;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

interface ResetPassword {
  resetPasswordToken: string;
  password: string;
  passwordConfirmation: string;
}

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async ({ resetPasswordToken, password, passwordConfirmation }: ResetPassword, thunkAPI) => {
    try {
      const data = await AuthService.resetPassword({
        reset_password_token: resetPasswordToken,
        password: password,
        password_confirmation: passwordConfirmation
      });

      return data.data.message;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const logout = createAsyncThunk('auth/logout', async () => {
  await AuthService.logout();
});

export const masquerade = createAsyncThunk(
  'auth/masquerade',
  async (userId: string, thunkAPI) => {
    try {
      const data = await AuthService.masquerade(userId);
      thunkAPI.dispatch(getUser());
      return { user: data };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setHasSession: (state) => {
      state.isLoggedIn = true;
      state.authError = null;
      state.authLoading = false;
    },
    setGlobalOnboardingSessionId: (state, action) => {
      state.globalOnboardingSessionId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.authError = null;
        state.authLoading = false;
      })
      .addCase(register.pending, (state, _) => {
        state.authLoading = true;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.authError = action.payload;
        state.authLoading = false;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.authError = null;
        state.authLoading = false;
      })
      .addCase(login.pending, (state, _) => {
        state.authLoading = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.authError = action.payload;
        state.authLoading = false;
      })
      .addCase(loginWithSms.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.authError = null;
        state.authLoading = false;
      })
      .addCase(loginWithSms.pending, (state, _) => {
        state.authLoading = true;
      })
      .addCase(loginWithSms.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.authError = action.payload as string;
        state.authLoading = false;
      })
      .addCase(sendValidationCode.fulfilled, (state, action) => {
      })
      .addCase(sendValidationCode.pending, (state, _) => {
      })
      .addCase(sendValidationCode.rejected, (state, action) => {
      })
      .addCase(getUserSubscription.fulfilled, (state, action) => {
        let subscriptionFeatures = action.payload
        state.subscriptionFeatures = subscriptionFeatures;
        state.activeProSubscriber = subscriptionFeatures?.higher_tiers.length < 0; //if there are higher tiers, then the user is not a pro subscriber
      })
      .addCase(getUserSubscription.rejected, (state, action) => {
        state.subscriptionFeatures = null;
        state.activeProSubscriber = false;
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.authError = null;
      })
      .addCase(createSubscription.pending, (state, _) => {
      })
      .addCase(createSubscription.rejected, (state, action) => {
        state.authError = action.payload as string;
      })
      .addCase(logout.fulfilled, (state, _action) => {
        state.isLoggedIn = false;
        state.authError = null;
        state.authLoading = false;
        state.subscriptionFeatures= null
        state.activeProSubscriber = false;
      })
      .addCase(logout.pending, (state, _) => {
        state.authLoading = true;
      })
      .addCase(logout.rejected, (state, _) => {
        state.authLoading = false;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.authSuccessMessage = action.payload as string;
        state.authError = null
        state.authLoading = false;
      })
      .addCase(forgotPassword.pending, (state, _) => {
        state.authLoading = true;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.authSuccessMessage = null
        state.authError = action.payload as string;
        state.authLoading = false;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.authSuccessMessage = action.payload as string;
        state.authError = null
        state.authLoading = false;
      })
      .addCase(resetPassword.pending, (state, _) => {
        state.authLoading = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.authSuccessMessage = null
        state.authError = action.payload as string;
        state.authLoading = false;
      })
      .addCase(masquerade.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.authError = null;
        state.authLoading = false;
      })
      .addCase(masquerade.pending, (state, _) => {
        state.authLoading = true;
      })
      .addCase(masquerade.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.authError = action.payload;
        state.authLoading = false;
      });
  },
});

export const { setHasSession, setGlobalOnboardingSessionId } = authSlice.actions;

const authReducer = authSlice.reducer;

export default authReducer;
