import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import {
  getUserBookingSettings,
  updateUserBookingSettings,
} from 'src/features/user/user.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { bookingSettingsSchema } from 'src/utils/formSchemas';
import ErrorPopup from '../ErrorPopup';
import SuccessPopup from "../SuccessPopup";
import { trackAnalytics } from "src/features/analytics/analytics.slice";
import { compareChanges } from "src/libs/utils";
import { delayTime } from "../ToastMessage";
import WYSIWYGEditor from "../WYSIWYGEditor";

const ConfirmationForm = () => {
  const { bookingSettings, userLoading, userError } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [successMessage, setSuccessMessage] = useState("")
  const [editorState, setEditorState] = useState('')

  const initialValues = {
    confirmation_message: '',
    confirmation_video_url: '',
    cancellation_policy: '',
    ...bookingSettings,
  };

  useEffect(() => {
    setEditorState(initialValues.cancellation_policy)
  }, [initialValues.cancellation_policy]);

  const onSubmit = (data) => {
    let payload = compareChanges(initialValues, data)

    dispatch(updateUserBookingSettings(data))
    .unwrap()
    .then((response) => {
      dispatch(trackAnalytics({action_name: "confirmation_messages_saved", payload: {...payload, description: "user updated confirmation form"}}))
      setSuccessMessage("Successfully Updated")
      setTimeout(() => {
        setSuccessMessage("")
      }, delayTime)
    })
  };

  useEffect(() => {
    dispatch(getUserBookingSettings());
  }, []);

  return (
    <>
      <h3 className="mb-5">Confirmation</h3>
      {userError && <ErrorPopup message={userError}/>}
      {successMessage && <SuccessPopup message={successMessage}/>}
      <h4 className="mb-2">What’s Next</h4>
      <p className="mb-4">
        Give some instructions or an introduction message to your clients in the
        confirmation booking page.
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={bookingSettingsSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ handleSubmit, handleChange, resetForm, setFieldValue, values, touched, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="confirmation_message">
                What’s Next
              </Form.Label>
              <Form.Control
                id="confirmation_message"
                value={values.confirmation_message}
                onChange={handleChange}
                disabled={userLoading}
                placeholder="Thank you for your booking, we look forward to working with you."
                as="textarea"
                rows={3}
                isValid={touched.confirmation_message && !errors.confirmation_message}
                isInvalid={touched.confirmation_message && !!errors.confirmation_message}
              />
              <Form.Control.Feedback type="invalid">
                <>{errors.confirmation_message}</>
              </Form.Control.Feedback>
            </Form.Group>
            <h4 className="mb-2">Video</h4>
            <p className="mb-4">
              Add a personal touch by showing a video to your clients on your booking confirmation page. You can use a YouTube or Vimeo URL
            </p>

            <Form.Group className="mb-4">
              <Form.Label htmlFor="confirmation_video_url">URL</Form.Label>
              <Form.Control
                id="confirmation_video_url"
                value={values.confirmation_video_url}
                onChange={handleChange}
                disabled={userLoading}
                type="text"
                isValid={touched.confirmation_video_url && !errors.confirmation_video_url}
                isInvalid={touched.confirmation_video_url && !!errors.confirmation_video_url}
              />
              <Form.Control.Feedback type="invalid">
                <>{errors.confirmation_video_url}</>
              </Form.Control.Feedback>
            </Form.Group>
            <h4 className="mb-2">Cancellation Policy</h4>
            <p className="mb-4">
              Enter your cancellation policy. This will be displayed prior to booking and again on your booking confirmation page.
            </p>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="cancellation_policy">
                Cancellation Policy
              </Form.Label>
              <WYSIWYGEditor
                initialValue={editorState}
                onChange={(e) => {
                  setEditorState(e)
                  setFieldValue("cancellation_policy", e)
                }}
                maxLength={6000}
              />
              <Form.Control.Feedback type="invalid">
                <>{errors.cancellation_policy}</>
              </Form.Control.Feedback>
            </Form.Group>
            <hr className="mt-5 mb-4" />
            <div className="action-buttons">
              <Button variant="primary" type="submit" disabled={userLoading}>
                Save
              </Button>
              <Button variant="outline-secondary" className="me-lg-3" onClick={() => resetForm()}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ConfirmationForm;
