import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, TextField, Grid, CircularProgress, Select, MenuItem, FormControl, InputLabel, Tabs, Tab, Divider, IconButton, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Formik, Form, Field } from 'formik';
import { updateContact, updateContactState, selectContactById } from 'src/features/account/contacts.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { fetchFilteredContacts } from 'src/features/account/filteredContacts.slice';
import NotesList from '../Notes/NotesList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ContactDialogProps {
  contactId: string;
  open: boolean;
  onClose: () => void;
  handleContactUpdate?: (updatedContact: any) => void;
}

const ContactDialog: React.FC<ContactDialogProps> = ({ contactId, open, onClose, handleContactUpdate }) => {
  const dispatch = useAppDispatch();
  const contact = useAppSelector(state => selectContactById(state, contactId));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [showAdvanced, setShowAdvanced] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const onSubmit = (values) => {
    setIsSubmitting(true);
    values.name = `${values.first_name} ${values.last_name}`.trim(); // Add 'name' field
    console.log('Updating contact with name:', values.name); // Log the name
    
    // Create a copy of values to avoid mutating the original object
    const sanitizedValues = { ...values };

    // Check if last_contacted is a valid date
    if (sanitizedValues.last_contacted) {
      if (!(sanitizedValues.last_contacted instanceof Date) || isNaN(sanitizedValues.last_contacted.getTime())) {
        // If it's not a valid Date object, set it to null or remove it
        delete sanitizedValues.last_contacted;
        // Alternatively, you could set it to the current date:
        // sanitizedValues.last_contacted = new Date();
      } else {
        // If it is a valid Date object, convert it to ISO string
        sanitizedValues.last_contacted = sanitizedValues.last_contacted.toISOString();
      }
    } else {
      // If last_contacted is null or undefined, remove it from the object
      delete sanitizedValues.last_contacted;
    }

    dispatch(updateContact(sanitizedValues))
      .unwrap()
      .then((updatedContact) => {
        dispatch(updateContactState(updatedContact));
        if (handleContactUpdate) {
          handleContactUpdate(updatedContact);
        }
        setIsSubmitting(false);
        handleClose();
        // Refresh filtered contacts
        dispatch(fetchFilteredContacts({ query: '' }));
      })
      .catch(error => {
        setIsSubmitting(false);
        console.error('Failed to update contact:', error);
      });
  };

  useEffect(() => {
    // This effect will run whenever the contact in the Redux store changes
    if (contact) {
      // You can update the form values here if needed
      // For example, if you're using Formik's setValues:
      // formikRef.current.setValues(contact);
    }
  }, [contact]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleToggleAdvanced = () => {
    setShowAdvanced(!showAdvanced);
    console.log(`Advanced fields ${showAdvanced ? 'hidden' : 'shown'}`); // Log toggle action
  };

  const initialFormValues = {
    ...contact,
    last_contacted: contact.last_contacted ? new Date(contact.last_contacted) : null,
    assigned_to: contact.assigned_to || '',
    deal_size: contact.deal_size || '',
    score: contact.score || '',
    // Ensure default values for new fields
    company_size: contact.company_size || '',
    vector_db_status: contact.vector_db_status || '',
    locality: contact.locality || '',
    zip: contact.zip || '',
    state: contact.state || '',
    country_code: contact.country_code || '',
    street_address_1: contact.street_address_1 || '',
    street_address_2: contact.street_address_2 || '',
    position: contact.position || '',
    stage: contact.stage || '',
    metadata: contact.metadata || {},
    picture_url: contact.picture_url || '',
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant='h5'>
          Edit {contact?.name || `${contact?.first_name} ${contact?.last_name}`?.trim() || 'Contact'}
          </Typography>
        <Typography variant='caption'>
          {contact?.id}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="Details" />
          <Tab label="Notes" />
        </Tabs>
        {tabIndex === 0 && (
          <Formik
            initialValues={initialFormValues}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => {
              // Log form values for debugging
              console.log('Form values:', values);
              return (
                <Form noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="first_name"
                        label="First Name"
                        value={values.first_name}
                        onChange={handleChange}
                        error={touched.first_name && Boolean(errors.first_name)}
                        helperText={touched.first_name && errors.first_name}
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="last_name"
                        label="Last Name"
                        value={values.last_name}
                        onChange={handleChange}
                        error={touched.last_name && Boolean(errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="email"
                        label="Email"
                        value={values.email}
                        onChange={handleChange}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="phone_number"
                        label="Phone Number"
                        value={values.phone_number}
                        onChange={handleChange}
                        error={touched.phone_number && Boolean(errors.phone_number)}
                        helperText={touched.phone_number && errors.phone_number}
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth disabled={isSubmitting}>
                        <InputLabel id="preferred-contact-method-label">Preferred Contact Method</InputLabel>
                        <Select
                          labelId="preferred-contact-method-label"
                          name="preferred_contact_method"
                          value={values.preferred_contact_method}
                          onChange={handleChange}
                          label="Preferred Contact Method"
                        >
                          <MenuItem value="phone">Phone</MenuItem>
                          <MenuItem value="email">Email</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          label="Last Contacted"
                          value={values.last_contacted}
                          onChange={(newValue) => {
                            setFieldValue('last_contacted', newValue);
                          }}
                          disabled={isSubmitting}
                          slotProps={{ textField: { fullWidth: true } }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="company_name"
                        label="Company Name"
                        value={values.company_name}
                        onChange={handleChange}
                        error={touched.company_name && Boolean(errors.company_name)}
                        helperText={touched.company_name && errors.company_name}
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="status"
                        label="Status"
                        value={values.status}
                        onChange={handleChange}
                        error={touched.status && Boolean(errors.status)}
                        helperText={touched.status && errors.status}
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="shared_notes"
                        label="Shared Notes"
                        placeholder="Enter shared notes. These notes will be shared with: all users and Oppies who have access to this contact."
                        value={values.shared_notes}
                        onChange={handleChange}
                        error={touched.shared_notes && Boolean(errors.shared_notes)}
                        fullWidth
                        multiline
                        rows={4}
                        disabled={isSubmitting}
                        helperText="Great for non-sensitive contact info to reference or use to help conversations. These notes will be accessible to: admins, Oppies who have access to this contact, and the contact themselves."
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="admin_notes"
                        label="Admin Notes"
                        placeholder="Only your eyes and those of your admin team will see these notes. These notes will not be shared with Oppies who have access to this contact unless you explicitly choose to do so."
                        value={values.admin_notes}
                        onChange={handleChange}
                        error={touched.admin_notes && Boolean(errors.admin_notes)}
                        helperText="Only your eyes and those of your admin team will see these notes. These notes will not be shared with Oppies who have access to this contact unless you explicitly choose to do so."
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="address"
                        label="Address"
                        value={values.address}
                        onChange={handleChange}
                        error={touched.address && Boolean(errors.address)}
                        helperText={touched.address && errors.address}
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="company_industry"
                        label="Industry"
                        value={values.company_industry}
                        onChange={handleChange}
                        error={touched.company_industry && Boolean(errors.company_industry)}
                        helperText={touched.company_industry && errors.company_industry}
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="company_website"
                        label="Company Website"
                        value={values.company_website}
                        onChange={handleChange}
                        error={touched.company_website && Boolean(errors.company_website)}
                        helperText={touched.company_website && errors.company_website}
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="company_address"
                        label="Company Address"
                        value={values.company_address}
                        onChange={handleChange}
                        error={touched.company_address && Boolean(errors.company_address)}
                        helperText={touched.company_address && errors.company_address}
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="home_address"
                        label="Home Address"
                        value={values.home_address}
                        onChange={handleChange}
                        error={touched.home_address && Boolean(errors.home_address)}
                        helperText={touched.home_address && errors.home_address}
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="tags"
                        label="Tags"
                        value={values.tags}
                        onChange={handleChange}
                        error={touched.tags && Boolean(errors.tags)}
                        helperText={touched.tags && errors.tags}
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="timezone"
                        label="Timezone"
                        value={values.timezone}
                        onChange={handleChange}
                        error={touched.timezone && Boolean(errors.timezone)}
                        helperText={touched.timezone && errors.timezone}
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="assigned_to"
                        label="Assigned To"
                        value={values.assigned_to}
                        onChange={handleChange}
                        error={touched.assigned_to && Boolean(errors.assigned_to)}
                        helperText={touched.assigned_to && errors.assigned_to}
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="deal_size"
                        label="Deal Size"
                        type="number"
                        value={values.deal_size}
                        onChange={handleChange}
                        error={touched.deal_size && Boolean(errors.deal_size)}
                        helperText={touched.deal_size && errors.deal_size}
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="score"
                        label="Score"
                        type="number"
                        value={values.score}
                        onChange={handleChange}
                        error={touched.score && Boolean(errors.score)}
                        helperText={touched.score && errors.score}
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider>
                        <Button
                          size="small"
                          onClick={handleToggleAdvanced}
                          endIcon={<ExpandMoreIcon />}
                        >
                          Advanced
                        </Button>
                      </Divider>
                    </Grid>
                    {showAdvanced && (
                      <>
                        <Grid item xs={12}>
                          <TextField
                            name="company_size"
                            label="Company Size"
                            value={values.company_size}
                            onChange={handleChange}
                            error={touched.company_size && Boolean(errors.company_size)}
                            helperText={touched.company_size && errors.company_size}
                            fullWidth
                            disabled={isSubmitting}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="stage"
                            label="Stage"
                            value={values.stage}
                            onChange={handleChange}
                            error={touched.stage && Boolean(errors.stage)}
                            helperText={touched.stage && errors.stage}
                            fullWidth
                            disabled={isSubmitting}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="position"
                            label="Position"
                            type="number"
                            value={values.position}
                            onChange={handleChange}
                            error={touched.position && Boolean(errors.position)}
                            helperText={touched.position && errors.position}
                            fullWidth
                            disabled={isSubmitting}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="street_address_1"
                            label="Street Address 1"
                            value={values.street_address_1}
                            onChange={handleChange}
                            error={touched.street_address_1 && Boolean(errors.street_address_1)}
                            helperText={touched.street_address_1 && errors.street_address_1}
                            fullWidth
                            disabled={isSubmitting}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="street_address_2"
                            label="Street Address 2"
                            value={values.street_address_2}
                            onChange={handleChange}
                            error={touched.street_address_2 && Boolean(errors.street_address_2)}
                            helperText={touched.street_address_2 && errors.street_address_2}
                            fullWidth
                            disabled={isSubmitting}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="locality"
                            label="City"
                            value={values.locality}
                            onChange={handleChange}
                            error={touched.locality && Boolean(errors.locality)}
                            helperText={touched.locality && errors.locality}
                            fullWidth
                            disabled={isSubmitting}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="state"
                            label="State"
                            value={values.state}
                            onChange={handleChange}
                            error={touched.state && Boolean(errors.state)}
                            helperText={touched.state && errors.state}
                            fullWidth
                            disabled={isSubmitting}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="zip"
                            label="Zip Code"
                            value={values.zip}
                            onChange={handleChange}
                            error={touched.zip && Boolean(errors.zip)}
                            helperText={touched.zip && errors.zip}
                            fullWidth
                            disabled={isSubmitting}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="country_code"
                            label="Country Code"
                            value={values.country_code}
                            onChange={handleChange}
                            error={touched.country_code && Boolean(errors.country_code)}
                            helperText={touched.country_code && errors.country_code}
                            fullWidth
                            disabled={isSubmitting}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="picture_url"
                            label="Picture URL"
                            value={values.picture_url}
                            onChange={handleChange}
                            error={touched.picture_url && Boolean(errors.picture_url)}
                            helperText={touched.picture_url && errors.picture_url}
                            fullWidth
                            disabled={isSubmitting}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="metadata"
                            label="Metadata"
                            value={JSON.stringify(values.metadata, null, 2)}
                            onChange={(e) => {
                              try {
                                // Parse JSON input
                                const parsedMetadata = JSON.parse(e.target.value);
                                setFieldValue('metadata', parsedMetadata);
                                console.log('Metadata updated:', parsedMetadata);
                              } catch (error) {
                                console.error('Invalid JSON input:', error);
                                // Optionally display error to user
                              }
                            }}
                            error={touched.metadata && Boolean(errors.metadata)}
                            helperText={touched.metadata && errors.metadata}
                            fullWidth
                            disabled={isSubmitting}
                            multiline
                            rows={6}
                          />
                        </Grid>
                        {console.log('Advanced fields updated')}
                      </>
                    )}
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={isSubmitting}
                        sx={{ mt: 3 }}
                      >
                        {isSubmitting ? (
                          <>
                            <CircularProgress size={24} sx={{ mr: 1 }} />
                            Updating...
                          </>
                        ) : (
                          'Update Contact'
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        )}
        {tabIndex === 1 && (
          <NotesList ownerId={contactId} ownerType="Contact" />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ContactDialog;
