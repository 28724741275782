import { useEffect, useState } from 'react';
import { MuiTelInput } from 'mui-tel-input';
import { Button, CircularProgress, Typography } from '@mui/material';
import dropdownIcon from '../assets/svg/arrow_down.svg';
import { styled } from '@mui/system';

const StyledPhoneInput = styled(MuiTelInput)(({ theme }) => ({
  '& fieldset': {
    borderColor: theme.palette.border,
  },
  '& .MuiTelInput-Flag': {
    marginLeft:"-20px",
    marginRight:"-20px"
  },
  '& .MuiTelInput-Flag::after': {
    content: `url(${dropdownIcon})`,
    marginLeft: '10px',
  },
  '& .Mui-disabled .MuiTelInput-IconButton': {
    display: 'none',
  },
  '.MuiTelInput-IconButton': {
    margin: 10,
  },
  '& input': {
    fontSize: '16px',
    color: theme.palette.text.primary,
    WebkitTextFillColor: `${theme.palette.text.primary} !important`,
  },
  '& button': {
    borderColor: theme.palette.border,
  },
}));

export const PhoneField = ({
  initialPhoneNumber = '',
  loading = false,
  isVerified = false,
  initialError = '',
  onSubmit,
  onPhoneNumberChange = (phone) => {},
}) => {
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);
  const [enableVerifyButton, setEnableVerifyButton] = useState(false);
  const [error, setError] = useState(initialError);

  useEffect(() => {
    setError(initialError);
  }, [initialError]);

  const handleChange = (newPhone, info) => {
    setError('');

    if (info.nationalNumber.length <= 10) {
      setPhoneNumber(info.numberValue);
      onPhoneNumberChange(info.numberValue);
    }
    setEnableVerifyButton(info.nationalNumber.length >= 10);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && enableVerifyButton && !isVerified) {
      e.preventDefault();
      onSubmit(phoneNumber);
    }
  };

  return (
    <StyledPhoneInput
      error={error?.length > 0}
      fullWidth={true}
      helperText={error}
      value={phoneNumber}
      label='Phone Number'
      defaultCountry='US'
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      disabled={isVerified}
      autoFocus
      InputProps={{
        endAdornment: (
          <Button
            disabled={!enableVerifyButton || isVerified}
            variant={isVerified ? 'text' : 'outlined'}
            onClick={() => {
              if (!isVerified) {
                onSubmit(phoneNumber);
              }
            }}
            color='primary'
            sx={{
              width: '81px',
              height: '36px',
              padding: '1px 35px',
              borderRadius: '4px',
            }}
          >
            <Typography
              variant='subtitle1'
              color={(enableVerifyButton || isVerified) && 'primary'}
              sx={{
                fontSize: '14px',
                fontWeight: 550,
                textTransform: 'uppercase',
              }}
            >
              {loading ? <CircularProgress size={20} /> : isVerified ? 'Verified' : 'Verify'}
            </Typography>
          </Button>
        ),
      }}
    />
  );
};
