import { Form, FormControl, InputGroup } from 'react-bootstrap';
import subscriptionPopup from 'src/components/Subscription/SubscriptionPopup';
import { useAppDispatch, useAppSelector } from 'src/store';

const TimeService = ({ handleChange, values, touched, errors }) => {
  const { subscriptionFeatures } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  return (
    <>
      <Form.Group>
        <Form.Label
          htmlFor="fixed_amount_time"
          className="fw-medium fs-14 mb-1 required"
        >
          Fixed amount of time
        </Form.Label>

        <InputGroup className="mb-1">
          <FormControl
            aria-describedby="fixed_minutes"
            type="number"
            id="fixed_time"
            name="fixed_time"
            min={0}
            value={values.fixed_time}
            onChange={handleChange}
            isValid={touched.fixed_time && !errors.fixed_time}
            isInvalid={touched.fixed_time && !!errors.fixed_time}
            required
          />
          <InputGroup.Text id="fixed_minutes">minutes</InputGroup.Text>
          <Form.Control.Feedback type="invalid">
          <>{errors.fixed_time}</>
        </Form.Control.Feedback>
        </InputGroup>
        <p className="text-gray-added pt-1 fs-12 fw-medium-light mb-3">
          Fixed amount of time for service. This can be the minimum amount of time it takes for you to load your gear in and get started, or this could be the entire appointment, if the service time doesn't depend on the size of the property.
        </p>
      </Form.Group>

      <Form.Group>
        <Form.Label htmlFor="time_per_k" className="fw-medium fs-14 mb-1 required">
          Minutes per 1,000 Sq Ft in the property
        </Form.Label>

        <InputGroup className="mb-1">
          <FormControl
            aria-describedby="minutes"
            type="number"
            id="time_per_k"
            name="time_per_k"
            min={0}
            value={subscriptionFeatures?.time_estimates?.permitted ? values.time_per_k : "0" }
            onChange={(e) => {
              if (subscriptionFeatures?.time_estimates?.permitted) { handleChange(e) }
              else { subscriptionPopup(subscriptionFeatures, "Minutes per 1,000 Sq Ft can not be changed", { feature_id: "time_estimates", description: "Provider can not change time_per_k from 0" }, dispatch) }
            }}
            isValid={touched.time_per_k && !errors.time_per_k}
            isInvalid={touched.time_per_k && !!errors.time_per_k}
          />
          <InputGroup.Text id="minutes">minutes</InputGroup.Text>
          <Form.Control.Feedback type="invalid">
            <>{errors.time_per_k}</>
          </Form.Control.Feedback>
        </InputGroup>
        <p className="text-gray-added pt-1 fs-12 fw-medium-light mb-3">
          This will be added to the fixed time unless you enter 0. Think about how much time you need for 1,000sqft once you've gotten settled in and can begin this service. It's always a safe bet to start a little higher than what you think it will take, in case you get a tougher-than-usual booking. You can always modify the length of a booking on your calendar after you've been booked.
        </p>
      </Form.Group>

      <Form.Group>
        <Form.Label
          htmlFor="turnaround_time_string"
          className="fw-medium fs-14 mb-1"
        >
          Service Turnaround Time (optional)
        </Form.Label>

        <InputGroup className="mb-1">
          <FormControl
            aria-describedby="minutes"
            type="text"
            id="turnaround_time_string"
            name="turnaround_time_string"
            placeholder="Two business days by noon"
            value={values.turnaround_time_string}
            onChange={handleChange}
            isInvalid={
              touched.turnaround_time_string && !!errors.turnaround_time_string
            }
          />

          <Form.Control.Feedback type="invalid">
            <>{errors.turnaround_time_hours}</>
          </Form.Control.Feedback>
        </InputGroup>
        <p className="text-gray-added pt-1 fs-12 fw-medium-light mb-3">
          If a service doesn't require deliverables after the appointment itself, just put "Instant Delivery".
        </p>
      </Form.Group>
      {/*
        <div className="pt-4 d-flex justify-content-start">
          <Button size="sm" onClick={testTimeDuration}>
            Test Time Duration
          </Button>
        </div>
      */}
    </>
  );
};

export default TimeService;
