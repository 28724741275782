import { Box, Button, CircularProgress, IconButton, Menu, MenuItem, Tooltip, Typography, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import SettingsIcon from '@mui/icons-material/Settings';
import useDebounce from 'src/hooks/useDebounce';
import { AutocompleteScheduler } from './AutocompleteScheduler';
import FunctionPermissionDialog from './FunctionPermissionDialog';

const ChannelOptionsMenu = ({ sx = {}, channel, handleChannelDeliveryStateChange, handleChannelDeliveryDelayChange, loading }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [delayValue, setDelayValue] = useState(channel.delivery_delay / 1000);
  const debouncedDelayValue = useDebounce(delayValue, 500);
  const open = Boolean(anchorEl);
  const [deliveryState, setDeliveryState] = useState(channel.delivery_state);

  useEffect(() => {
    setDeliveryState(channel.delivery_state);
  }, [channel.delivery_state]);

  useEffect(() => {
    setDelayValue(channel.delivery_delay / 1000);
  }, [channel.delivery_delay]);

  useEffect(() => {
    if (debouncedDelayValue !== channel.delivery_delay / 1000) {
      handleDelayChange(debouncedDelayValue);
    }
  }, [debouncedDelayValue]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const marks = [
    {
      value: 60,
      label: '1',
    },
    {
      value: 180,
      label: '3 ',
    },
    {
      value: 300,
      label: '5',
    },
    {
      value: 600,
      label: '10 min',
    },
    {
      value: 1800,
      label: '30 min',
    }
  ];

  function valuetext(value) {
    if (value < 60) {
      return `${value} seconds`;
    } else {
      return `${Math.round(value / 60 * 10) / 10} minutes`;
    }
  }

  const handleDelayChange = (newValue: number) => {
    const valueInMilliseconds = newValue * 1000;
    handleChannelDeliveryDelayChange(valueInMilliseconds);
  };

  const handleChange = (event, newValue) => {
    if (typeof newValue === 'number') {
      setDelayValue(newValue);
    }
  };

  const deliveryStateNextValue = () => {
    if (deliveryState === 'play') {
      return 'pause';
    } else if (deliveryState === 'pause') {
      return 'play';
    }
    return 'play';
  }

  const handleDeliveryStateChange = () => {
    const value = deliveryStateNextValue();
    handleChannelDeliveryStateChange(value);
  }

  const deliveryStateNextTooltip = () => {
    if (deliveryStateNextValue() === 'play') {
      return 'Resume Assistant Reply';
    } else if (deliveryStateNextValue() === 'pause') {
      return 'Pause Assistant Reply';
    }
    return 'Resume Assistant Reply';
  }

  const [tabValue, setTabValue] = useState(0);
  const [functionDialogOpen, setFunctionDialogOpen] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Button
        sx={{ ...sx }}
        onMouseDown={(e) => e.preventDefault()}
        onClick={handleClick}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <SettingsIcon sx={{ fontSize: 18 }} />
         Delivery Options
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="channel options tabs">
          <Tab label="Basic" />
          <Tab label="Advanced" />
        </Tabs>

        {tabValue === 0 && (
          <>
            <MenuItem >
              {true &&
                <>
                  <Typography variant="body1" sx={{}}>
                    {deliveryState === 'play' &&
                      'Assistant reply is ON'
                    }
                    {deliveryState === 'pause' &&
                      'Assistant reply is PAUSED'
                    }
                    {deliveryState === 'stop' &&
                      'Assistant reply is OFF'
                    }
                  </Typography>
                  {!loading ?
                    <Tooltip sx={{ textDecoration: 'uppercase' }} title={deliveryStateNextTooltip()}>
                      <IconButton onClick={handleDeliveryStateChange}>
                        {deliveryState === 'pause' &&
                          <PlayArrowIcon sx={{ fontSize: 18 }} color='primary' />
                        }
                        {deliveryState === 'play' &&
                          <PauseIcon sx={{ fontSize: 18 }} color='primary' />
                        }
                        {deliveryState === 'stop' &&
                          <StopIcon sx={{ fontSize: 18 }} color='primary' />
                        }
                      </IconButton>
                    </Tooltip>
                    :
                    <CircularProgress size={18} />
                  }
                </>
              }
            </MenuItem>

            <Box sx={{ width: 300, m: 2 }}>
              <Typography variant='body1' id="non-linear-slider" gutterBottom>
                Delivery Delay: {valuetext(delayValue)}
              </Typography>
              <Slider
                aria-label="Custom marks"
                defaultValue={delayValue}
                getAriaValueText={valuetext}
                min={0}
                max={1800}
                marks={marks}
                onChange={handleChange}
              />
            </Box>

            <Box sx={{ width: 300, m: 2 }}>
              <AutocompleteScheduler 
                channelId={channel.id}
              />
            </Box>
          </>
        )}

        {tabValue === 1 && (
          <Box sx={{ width: 300, m: 2 }}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setFunctionDialogOpen(true)}
            >
              Manage Function Permissions
            </Button>
          </Box>
        )}
      </Menu>

      <FunctionPermissionDialog
        open={functionDialogOpen}
        onClose={() => setFunctionDialogOpen(false)}
        channelId={channel.id}
      />
    </>
  );
};
export default ChannelOptionsMenu;
