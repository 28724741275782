import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useNavigate } from 'react-router-dom';
import ErrorPopup from 'src/components/ErrorPopup';
import Layout from 'src/components/Layout';
import Loading from 'src/components/Loading';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import { setLocation } from 'src/features/onboarding/onboarding.slide';
import useAuth from 'src/hooks/useAuth';
import { useAppDispatch, useAppSelector } from 'src/store';
import { locationSchema } from 'src/utils/formSchemas';
import Swal from "sweetalert2";
import { getBranding } from 'src/utils/branding';
const Address = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { isLoggedIn } = useAuth();
  const initialValues = {
    location: '',
  };
  const dispatch = useAppDispatch();
  const { location, error: locationError } = useAppSelector(
    (state) => state.onBoarding
  );
  const { user, subscriptionPlan } = useAppSelector((state) => state.user);
  const { subscriptionFeatures } = useAppSelector((state) => state.auth);
  let navigate = useNavigate();
  const [value, setValue] = useState(null)
  const branding_logo = getBranding('logo');
  const onSubmit = (values: { location: string }) => {
    setLoading(true);
    dispatch(setLocation({ formatted_address: values.location }))
      .unwrap()
      .then(() => {
        dispatch(trackAnalytics({action_name: "onboarding_address", payload: { description: `user filled location ${values.location}`}}))
        navigate('/calendar');
      })
      .catch((_error) => {
        setLoading(false);
      });
  };

  if (!isLoggedIn) {
    navigate('/login');
  }

  useEffect(() => {
    if (user && user.address) {
      navigate('/calendar');
    }
  }, [user]);

  useEffect(() => {
    if (user && subscriptionFeatures && subscriptionPlan === "pro") {
      Swal.fire({
        showCancelButton: true,
        confirmButtonText: 'Subscribe Now',
        icon: 'info',
        title: "Join Our Pro Plan",
        text: 'Includes all features'
      }).then((result) => {
        if (result.isConfirmed) {
          window.open(subscriptionFeatures?.higher_tier_payment_details.pro.payment_link)
          Swal.fire('Subscription link now open', 'Please fill the details to subscribe', 'success')
          dispatch(trackAnalytics({ action_name: "subscribe_clicked", payload: { description: "while onboarding user clicked subscribe" } }))
        }
      })
    }
  }, [user, subscriptionFeatures]);

  if (!user) {
    return <Loading />;
  }

  return (
    <Layout isBoarding={true} >
      <Container className="bg-light min-vh-100" fluid>
        <Row className="pt-5 mb-5 justify-content-center">
          <Col sm={10} md={10} lg={8}>
            <div className="d-flex justify-content-center">
              <img
                src={branding_logo}
                alt=""
                height={80}
                width={80}
                className="pb-3"
              />
            </div>
            {location}
            <div className="d-flex flex-column align-items-center text-align-center">
              <div className="fw-medium fs-36 mb-3 mb-lg-4">
                Welcome, {user.name}!
              </div>
              {locationError && <ErrorPopup message={locationError}/>}

              <p className="text-black-50 fs-18">
                Our smart booking software will use the address you input below as
                the default location for calculating distances to different jobs.
                Typically this is your home or office address.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={6}>
            <Formik
              initialValues={initialValues}
              validationSchema={locationSchema}
              onSubmit={onSubmit}
            >
              {({ handleSubmit, handleChange, values, setFieldValue, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-medium fs-14 required">
                      Location
                    </Form.Label>
                    <GooglePlacesAutocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                      selectProps={{
                        placeholder:"Type location",
                        value,
                        onChange: (e) => {
                          setValue(e)
                          setFieldValue('location', e.label)
                        },
                        styles: {
                          control: (provided) => ({
                            ...provided,
                            backgroundColor: 'var(--bs-secondary-bg)',
                          }),
                          input: (provided) => ({
                            ...provided,
                            color: 'var(--body-text)',
                          }),
                          menu: (provided) => ({
                            ...provided,
                            backgroundColor: 'var(--bs-secondary-bg)',
                          }),
                          option: (provided, {isFocused, isSelected}) => ({
                            ...provided,
                            color: 'var(--body-text)',
                            backgroundColor: (isFocused || isSelected) ? 'var(--bs-body-bg)' : 'var(--bs-secondary-bg)',
                          }),
                          singleValue: (provided) => ({
                            ...provided,
                            color: 'var(--body-text)',
                          }),
                        },
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      <>{errors.location}</>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Row lg={'auto'} className="justify-content-end p-3">
                    <Button variant="primary" type="submit" disabled={loading}>
                      {loading && (
                        <span className="spinner-border spinner-border-sm me-2"></span>
                      )}
                      Continue
                    </Button>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Address;
