import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  LinearProgress,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setGoogleCalendarToken, disconnectGoogleCalendar, getUser } from 'src/features/user/user.slice';
import { getUserCalendar } from 'src/features/user/user.slice';
import { useSnackbar } from 'notistack';
import { GoogleAuthorization } from 'src/libs/googleAuthorization';

const GoogleCalendarDialog = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { user, userCalendar } = useAppSelector((state) => state.user);
  const [selectedCalendar, setSelectedCalendar] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCalendarLoading, setIsCalendarLoading] = useState(false);

  useEffect(() => {
    if (open) {
      if (user.google_calendar_connected) {
        setIsCalendarLoading(true);
        dispatch(getUserCalendar())
          .unwrap()
          .finally(() => setIsCalendarLoading(false));
      }
    }
  }, [open, dispatch, user.google_calendar_connected]);

  useEffect(() => {
    const initiallySelected = userCalendar.find((calendar) => calendar.selected);
    if (initiallySelected) {
      setSelectedCalendar(initiallySelected.id);
    }
  }, [userCalendar]);

  const handleConnect = (event) => {
    if (!selectedCalendar) return;

    // Trigger Google Authorization
    const googleAuth = new GoogleAuthorization(() => {
      console.log('Auth window closed');
    });
    googleAuth.authorizeUser(event);
  };

  const handleDisconnect = async () => {
    if (!window.confirm('Are you sure you want to disconnect your Google Calendar?')) return;
    setIsLoading(true);
    try {
      await dispatch(disconnectGoogleCalendar()).unwrap();
      enqueueSnackbar('Google Calendar disconnected', { variant: 'success' });
      
      // Reload user and calendar list
      await dispatch(getUser()).unwrap();
    } catch (error) {
      console.error('Failed to disconnect Google Calendar:', error);
      enqueueSnackbar('Failed to disconnect Google Calendar', { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Manage Google Calendar</DialogTitle>
      <DialogContent>
        {isLoading || isCalendarLoading ? (
          <LinearProgress />
        ) : (
          <Box sx={{ mt: 2 }}>
            {userCalendar.length > 0 && user.google_calendar_connected && (
              <Typography variant="body1" gutterBottom>
                Select a calendar to connect. You can only select one calendar at a time. If you wish to change the connected calendar, disconnect the current one first.
              </Typography>
            )}
            {user.google_calendar_connected ? 
              <>
                <FormControl component="fieldset">
                  <RadioGroup
                  row
                  value={selectedCalendar}
                  onChange={(e) => setSelectedCalendar(e.target.value)}
                >
                  {userCalendar.map((calendar) => (
                    <FormControlLabel
                      key={calendar.id}
                      value={calendar.id}
                      control={<Radio />}
                      label={calendar.summary}
                    />
                  ))}
                  </RadioGroup>
                </FormControl>
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={handleDisconnect}
                  sx={{ mt: 2 }}
                >
                  Disconnect Calendar
                </Button>
              </>
            : 
              <>
                <Typography variant="body1" gutterBottom>
                  Connect your Google Calendar to unlock powerful features:
                </Typography>
                <ul>
                  <li>Automate scheduling with AI-driven insights</li>
                  <li>Maintain control over calendar access and permissions</li>
                  <li>Seamlessly switch between calendars as needed</li>
                </ul>
                <Button
                  onClick={handleConnect}
                  variant="contained"
                  disabled={!selectedCalendar || isLoading}
                >
                  Connect Google Calendar
                </Button>
              </>
            }
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GoogleCalendarDialog; 
