import Typography from '@mui/material/Typography';
import { trackAnalytics } from "src/features/analytics/analytics.slice";
import { ReactComponent as ZapIcon } from 'src/assets/svg/zap.svg';
import { ReactComponent as BusinessIcon } from 'src/assets/svg/business.svg';
// import { AntSwitch } from '../AntSwitch';
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import PlanCard from './PlanCard';
import { useTheme } from '@mui/material/styles';
import { Dialog, DialogContent } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store';
import Box from '@mui/system/Box';
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const UpgradePlanPopup = ({ isOpen, onClose, subscriptionFeatures }) => {
  const { user } = useAppSelector((state) => state.user);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useAppDispatch();

  const [billedAnnually, setBilledAnnually] = useState(false);

  // const handleBilledAnnualToggle = () => {
  //   setBilledAnnually((prevChecked) => !prevChecked);
  // };

  const subscribe = (tier) => {
    window.open(subscriptionFeatures?.higher_tier_payment_details[tier].payment_link)
    dispatch(trackAnalytics({ action_name: "upgrade_plan_clicked", payload: { description: `user clicked on subscribe button for ${tier} plan` } }))
  };

  // const planData = [
  //   {
  //     tier: 'Basic',
  //     price: 'Free',
  //     features: [
  //       'Access to all basic features',
  //       'Basic reporting and analytics',
  //       'Up to 2 individual users',
  //       '10GB individual data each user',
  //     ],
  //     icon: BusinessIcon
  //   },
  //   {
  //     tier: 'PRO',
  //     price: `$${subscriptionFeatures?.higher_tier_payment_details["pro"].default_price}/mo`,
  //     features: [
  //       'Access to all basic features',
  //       'Basic reporting and analytics',
  //       'Up to 10 individual users',
  //       '20GB individual data each user',
  //     ],
  //     icon: ZapIcon
  //   },
  //   {
  //     tier: 'Business',
  //     price: '$100/mth',
  //     features: [
  //       '200+ integrations',
  //       'Advanced reporting and analytics',
  //       'Up to 20 individual users',
  //       '40GB individual data each user',
  //     ],
  //     icon: BusinessIcon
  //   },
  // ];

  // const filteredPlanData = planData.filter((plan) =>
  //   subscriptionFeatures?.higher_tiers.includes(plan.tier.toLowerCase()) ||
  //   subscriptionFeatures?.name === plan.tier.toLowerCase()
  // );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth={true}
      maxWidth="lg"
      aria-labelledby="form-dialog-title"
      sx={{
        textAlign: "center",
      }}
    >
      <Box sx={{
        fontFamily: 'Roboto, sans-serif',
        display: 'flex',
        flexDirection: 'column',
        gap: smallScreen ? '10px' : '14px',
        margin: smallScreen ? '10px' : '20px',
      }}>
        <Typography
          gutterBottom
          variant="h2"
          component="div"
          sx={{
            fontFamily: 'Roboto, sans-serif',
            fontSize: smallScreen ? '24px' : '36px',
            fontWeight: 600,
            lineHeight: '42px',
            letterSpacing: '-0.02em',
            textAlign: 'center',
          }}
        >
          Plan For All Sizes
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontFamily: 'Roboto, sans-serif',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '19px',
            letterSpacing: '0em',
            textAlign: 'center',

          }}
        >
          Pricing for every stage of your business.
        </Typography>
        {/*<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <AntSwitch checked={billedAnnually} onChange={handleBilledAnnualToggle} />
          <Typography
            variant="body2"
            sx={{
              fontFamily: 'Inter, sans-serif',
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0em',
              textAlign: 'left',
              color: 'linear-gradient(0deg, #42307D, #42307D)',
              marginLeft: '12px',
            }}
          >
            Annual pricing (save 20%)
          </Typography>
        </div>*/}

        {/* <div style={{
          display: 'flex',
          // flexDirection: smallScreen ? 'column' : 'row',
          justifyContent: 'center',
          // gap: mediumScreen ? '10px' : '32px',
          // marginTop: smallScreen ? '16px' : '32px',
        }}> */}
        {/* {filteredPlanData.map((plan, index) => (
            <PlanCard
              key={index}
              tier={plan.tier}
              price={plan.price}
              billedAnnually={billedAnnually}
              features={plan.features}
              subscribe={subscribe}
              icon={plan.icon}
              isCurrentPlan={subscriptionFeatures?.name === plan.tier.toLowerCase()}
            />
          ))} */}
        <DialogContent>
          <stripe-pricing-table
            pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
            publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
            customer-email={user.email}
          >
          </stripe-pricing-table>
        </DialogContent>
        {/* </div> */}
      </Box>
    </Dialog>
  )
};

export default UpgradePlanPopup;
