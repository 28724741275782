import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import ErrorPopup from 'src/components/ErrorPopup';
import useWindowSize from 'src/hooks/useWindowSize';
import GalleryItemV2 from '../Services/forms/GalleryV2/GalleryItemV2';
import { getGalleryItems } from 'src/features/services/gallery.slice';
import GalleryItemUploaderV2 from '../Services/forms/GalleryV2/GalleryItemUploaderV2';
import useAuth from 'src/hooks/useAuth';
import DownloadAsZipButton from './components/DownloadAsZipButton';
import NotifyAboutUpdatesButton from './components/NotifyAboutUpdatesButton';
import Masonry from '@mui/lab/Masonry';
import LightBox from './LightBox';
import EmbedDialogue from './EmbedDialogue';

const GalleryV2 = ({ galleriableType, galleriableId, isPaid, authorizedToPay=false, sendFinalizeInvoice=() => {} }) => {
  const dispatch = useAppDispatch();
  const { isLoggedIn } = useAuth();
  const { currentGalleryItems} = useAppSelector((state) => state.galleryServices);
  const [width, _height] = useWindowSize();
  const [errorMessage, setErrorMessage] = useState(null);
  const [openLightBox, setOpenLightBox] = useState(false);
  const [lightBoxItem, setLightBoxItem] = useState(null);
  const [showEmbedDialogue, setShowEmbedDialogue] = useState(false);

  var chunkSize = 3 // For screen size md and above
  if (width < 768) chunkSize = 2 // For screen size sm & below

  useEffect(()=>{
    dispatch(getGalleryItems({galleriableType, galleriableId}))
  }, [])

  return (
      <>
        {showEmbedDialogue && <EmbedDialogue item={lightBoxItem} show={showEmbedDialogue} close={() => setShowEmbedDialogue(false)} />}
        {openLightBox && <LightBox showView={openLightBox} closeView={() => setOpenLightBox(false)} child={lightBoxItem}/> }
        {errorMessage &&  <ErrorPopup message={errorMessage}/>}
        <div className='gallery-wrapper' id="media">
          <div className='gallery-title'>
            <h1>Media</h1>
          </div>
          <div className='global-gallery-buttons-wrapper'>
            { currentGalleryItems.length > 0 && (
            <DownloadAsZipButton
              galleriableType={galleriableType}
              galleriableId={galleriableId}
              isPaid={isPaid}
              authorizedToPay={authorizedToPay}
              sendFinalizeInvoice={sendFinalizeInvoice}
            />
            ) }
            { isLoggedIn && (
              <NotifyAboutUpdatesButton
                galleriableType={galleriableType}
                galleriableId={galleriableId}
              />
            )}
          </div>
          <Masonry columns={3} spacing={2}>
            {currentGalleryItems.map((item, index) => (
              <GalleryItemV2 permissions={!!isLoggedIn && 10} item={item} currentGalleryItems={currentGalleryItems} key={index} isPaid={isPaid} setLightBoxItem={setLightBoxItem} setOpenLightBox={setOpenLightBox} setShowEmbedDialogue={setShowEmbedDialogue}/>
            ))}
          </Masonry>
          <GalleryItemUploaderV2
            galleriableType={galleriableType}
            galleriableId={galleriableId}
            currentGalleryItems={currentGalleryItems}
          />
        </div>
      </>
  );
};

export default GalleryV2;
