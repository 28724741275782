import { useEffect, useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'src/store';
import AvailabilityAlert from '../AvailabilityAlert';
import { BookingTime } from './BookingTime';
import { getBookingSettings, getPhotographerAvailabilities } from 'src/features/services/booking.slice';
import { useParams } from 'react-router-dom';
import ErrorPopup from '../ErrorPopup';
import { trackBookingAnalytics } from 'src/features/analytics/analytics.slice';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';
import { CircularProgress, Skeleton } from '@mui/material';
 
const Booking = ({
  show,
  onClose,
  handleBooking,
  closeBooking,
  showBooking,
  setShowBookingModal,
  setShowCheckout,
  setSlotDetail,
}) => {
  const dateRef = useRef(null);
  const [bookingDate, setBookingDate] = useState('');
  const [minDate, setMinDate] = useState('');
  const [expensive, setExpensive] = useState(0);
  const [showAvailabilityAlert, setShowAvailabilityAlert] = useState(false);
  const strftime = require('strftime')
  let { uuid } = useParams();
  const dispatch = useAppDispatch();
  const { bookingServices, photographer, bookingError, photographerAvailabilities, address, isLoading , listing_price, selectedServices, square_feet } = useAppSelector((state) => state.bookingServices);
  const [value, setValue] = useState<Dayjs | null>(null);
  const { subscriptionFeatures } = useAppSelector((state) => state.bookingServices);

  useEffect(()=>{
    if(show && bookingDate){
      const data = { date: bookingDate, uuid, address, square_feet, listing_price, service_ids: selectedServices }
      dispatch(getPhotographerAvailabilities(data))
    }
  }, [show, bookingDate])

  const handleDateChange = (e) => {
    dispatch(trackBookingAnalytics({
      action_name: "user_searched_availability",
      payload: {
        services_chosen: selectedServices,
        address: address,
        date_selected: e.target.value,
        description: "user searched availability and selected date",
        uuid: uuid
      }
    }))
    setBookingDate(e.target.value);
  };

  const handleSelectAnotherDay = () => {
    setBookingDate('');
  };

  const handleSetAlert = () => {
    setShowAvailabilityAlert(true);
  };

  const handleShowCheckout = (slot) => {
    dispatch(trackBookingAnalytics({
      action_name: "user_selected_availability",
      payload: {
        services_chosen: selectedServices,
        address: address,
        date_selected: bookingDate,
        description: "user selected availability",
        slot: slot,
        uuid: uuid
      }
    }))
    dispatch(getBookingSettings(uuid))
    setSlotDetail(slot);
    handleBooking();
    setShowCheckout(true);
    setShowBookingModal(false);
  };

  const onEntered = () => {
    if (!bookingDate) { dateRef.current.showPicker() }
  };

  const totalPrice =
    bookingServices.filter((service) => (selectedServices.includes(service.id)))
      .reduce((sum,service) =>  sum = sum + parseFloat(service.price) , 0 )

  const calculateExpensive = () => {
    let maxFee = 0

    photographerAvailabilities.map((slot, index) => {
      maxFee = Math.max(maxFee, slot.travel_cost);
    })

    setExpensive(totalPrice + maxFee)
  };

  useEffect(() => {
    if(photographerAvailabilities.length != 0){ calculateExpensive() }
  }, [photographerAvailabilities]);

  useEffect(() => {
    const date = new Date();
    setMinDate(strftime("%F",date));
  }, []);

  return (
    <>
      { showAvailabilityAlert &&
      <AvailabilityAlert
        showAvailabilityAlert={showAvailabilityAlert}
        handleClose={() => setShowAvailabilityAlert(false)}
        handleBookingClose={closeBooking}
        handleBookingShow={showBooking}
        bookingDate={bookingDate}
      />}

      <Modal
        backdrop="static"
        show={show}
        fullscreen={'sm-down'}
        keyboard={false}
        centered
        contentClassName="medium-width"
        onHide={onClose}
        onEntered={onEntered}
      >
        <Modal.Header closeButton>
          { bookingError &&  <ErrorPopup message={bookingError}/>}
          <div
            className="py-2 px-3 fw-medium fs-14 go-back"
            role="button"
            onClick={onClose}
          >
            <img src="/icon/back.svg" alt="" />
            &nbsp; Go back
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-column align-items-center text-center px-3">
            <p className="fs-4 px-3 fw-medium lh-sm">Check a Day</p>
          </div>
          <Form>
            <Form.Group className="mb-3 mx-5 mt-2">
              <Form.Control
                type="date"
                value={bookingDate}
                placeholder="Pick a date"
                title="Pick a date"
                className="mb-2 fs-14"
                min={minDate}
                onChange={handleDateChange}
                ref={dateRef}
              />

            </Form.Group>

            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Basic example"
                value={value}
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider> */}
          </Form>
          
          {isLoading && 
            <>
              <Skeleton width={250} animation="wave" className='mx-5 mb-2' height={40} />
              <Skeleton width={250} animation="wave" className='mx-5 mb-2' height={40} />
              <Skeleton width={250} animation="wave" className='mx-5 mb-2' height={40} />
              <Skeleton width={250} animation="wave" className='mx-5 mb-2' height={40} />
              <Skeleton width={250} animation="wave" className='mx-5 mb-2' height={40} />
              <Skeleton width={250} animation="wave" className='mx-5 mb-2' height={40} />
              <Skeleton width={250} animation="wave" className='mx-5 mb-2' height={40} />
              <Skeleton width={250} animation="wave" className='mx-5 mb-2' height={40} />
            </>
          }

          {!isLoading && bookingDate && (photographerAvailabilities.length > 0) && (
            <div className="mx-5">
              <div className="fw-medium-light fs-14">Times displayed in: {photographer.timezone}</div>
                {photographerAvailabilities.map((slot, index) => (
                  <div key={index}>
                    <BookingTime
                      time={slot.start_time_element}
                      meridian={slot.meridian}
                      onClick={ () => handleShowCheckout(slot)}
                      discount={Math.round(((expensive - (totalPrice + slot.travel_cost))/expensive)*100)}
                    />
                  </div>
                ))}
            </div>
          )}

          {!isLoading && !bookingError && bookingDate && (photographerAvailabilities.length === 0) && (
            <>
              <div className="mx-4 text-center">
                <img src="./images/SadCalendar.svg" alt="" width="171" />
                <div className="fw-medium fs-14 mt-4">
                  No availability for this date.
                </div>
                <p className=" fs-14 mt-1 mb-4">
                  Please select another day or check in later for availability!
                </p>
              </div>
              <div className="mx-5">
                <Form>
                  {subscriptionFeatures?.waitlist?.permitted &&
                    <Button
                      className="w-100 mb-3 mt-2"
                      variant="primary"
                      onClick={handleSetAlert}
                    >
                      <span className="fw-medium">Set Alert</span>
                    </Button>
                  }

                  <Button
                    className="w-100 mb-3"
                    variant="outline-dark"
                    onClick={handleSelectAnotherDay}
                  >
                    <span className="fw-medium">Select another day</span>
                  </Button>
                </Form>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Booking;
