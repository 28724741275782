import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { PhoneField } from './PhoneField';
import { OtpField } from './OtpField';
import { sendValidationCode, validateCode } from 'src/features/auth/auth.slice';
import { useAppDispatch } from 'src/store';

export const PhoneVerification = ({
  initialPhoneNumber = '',
  initialErrors = null,
  existingUser = null,
  onPhoneNumberChange = (phoneNumber) => {},
  onPhoneVerification = (phoneNumber, token, isExistingUser) => {},
  onErrorsChange = (fieldName, error) => {},
}) => {
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);
  const [showOtpField, setShowOtpField] = useState(false);
  const [errors, setErrors] = useState(initialErrors);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setErrors(initialErrors);
  }, [initialErrors]);

  const handlePhoneSubmit = (phone) => {
    onPhoneNumberChange(phone);
    setPhoneNumber(phone);
    setLoading(true);
    setErrors(null);

    dispatch(sendValidationCode({ phone }))
      .unwrap()
      .then(() => {
        setShowOtpField(true);
        setLoading(false);
      })
      .catch((error) => {
        setErrors(error);
        onErrorsChange('phone', error);
        setLoading(false);
      });
  };

  const handleOtpSubmit = async (code) => {
    setErrors(null);
    dispatch(validateCode({ phone: phoneNumber, code: code }))
      .unwrap()
      .then((response) => {
        if (existingUser != null && !existingUser && response.data.existing_user) {
          setErrors('User already exists with this phone number.');
        } else if (existingUser != null && existingUser && !response.data.existing_user) {
          setErrors('User not found with this phone number.');
        } else {
          setIsPhoneVerified(true);
          onPhoneVerification(phoneNumber, response.data.token, response.data.existing_user);
        }
        setShowOtpField(false);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        onErrorsChange('otp', error);
        setErrors(error);
      });
  };

  const handleChangePhoneNumber = () => {
    setErrors(null);
    onErrorsChange('otp', null);
    setShowOtpField(false);
    setIsPhoneVerified(false);
  };

  return (
    <>
      {showOtpField ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Box
            className='label'
            style={{ display: 'flex', gap: 10, alignItems: 'center' }}
          >
            <Typography color='text.secondary' variant='subtitle1'>
              Verify {phoneNumber}
            </Typography>
            <Typography
              variant='subtitle2'
              color='primary'
              sx={{ cursor: 'pointer' }}
              onClick={handleChangePhoneNumber}
            >
              Change
            </Typography>
          </Box>
          <Typography sx={{ lineHeight: '20px' }} variant='subtitle2' color='text.secondary'>
            Check your phone for a verification code <br />
          </Typography>
          <OtpField
            error={errors}
            loading={loading}
            phoneNumber={phoneNumber}
            onSubmit={handleOtpSubmit}
            onSendAgain={handlePhoneSubmit}
          />
        </Box>
      ) : (
        <PhoneField
          initialError={errors}
          loading={loading}
          initialPhoneNumber={phoneNumber}
          onPhoneNumberChange={onPhoneNumberChange}
          onSubmit={handlePhoneSubmit}
          isVerified={isPhoneVerified}
        />
      )}
    </>
  );
};
