import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PhoneIcon from '@mui/icons-material/Phone';
import { useAppDispatch, useAppSelector } from 'src/store';
import { releasePhoneNumber } from 'src/features/account/channels.slice';
import { CircularProgress } from '@mui/material';

export default function ReleasePhoneNumber({ channelId, formatPhoneNumber }) {
  const [open, setOpen] = useState(false);
  const channel = useAppSelector((state) => state.channels.channels.find(channel => channel.id === channelId));
  const {channelsLoading} = useAppSelector(state => state.channels);
  const dispatch = useAppDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const releasePhoneNumberAction = () => {
    dispatch(releasePhoneNumber(channel.id));
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    releasePhoneNumberAction()
    handleClose();
  }

  return (
    <div>
      <Button variant="outlined" color="warning" disabled={channelsLoading} onClick={handleClickOpen}>
        <PhoneIcon fontSize='small' />
        {!channelsLoading? 
          `Release ${formatPhoneNumber(channel.twilio_phone_number)} from this channel`
          :
          `${<CircularProgress color="warning" size={20} />} Releasing ${formatPhoneNumber(channel.twilio_phone_number)}...`
        }
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Release Phone Number Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to release this phone number? This is not reversible and you may not be able to get this phone number back. Your phone number subscription payment for this number will be terminated and pro-rated based on this date although it may take several business days to process the cancellation.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleAgree} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
