import { networkError } from 'src/libs/utils';
import validator from 'validator'

export const validatePhoneNumber = (value: string): boolean => {
  return validator.isMobilePhone(value)
};

export const serializeErrorMessage = (error) => {
  const message =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString();
  if (message === "Network Error"){ return networkError }
  else {return message;}
};

export const convertMilesToMeters = (miles: number) => 1609.34 * miles
