import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const Solutions: React.FC = () => {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      color: 'white',
      padding: 4,
    }}>
      <Typography variant="h3" align="center" sx={{ mb: 0 }}>Oppy: The Solution</Typography>
      <Typography variant="subtitle1" align="center" sx={{ mb: 4, fontStyle: 'italic' }}>The bottom line is top of mind.</Typography>
      <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
        <List>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon sx={{ color: '#4CAF50' }} />
            </ListItemIcon>
            <ListItemText
              primary="Business phone + AI Employees in a few clicks"
              secondary="Legacy business phone systems simply come with a number. Oppy comes with a team of employees."
              primaryTypographyProps={{ color: 'white', fontSize: '1.5rem' }}
              secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '1.2rem' }}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon sx={{ color: '#4CAF50' }} />
            </ListItemIcon>
            <ListItemText 
              primary="Simple AI with Access to 30+ functions" 
              secondary="No code prompting for unlimited solutions in any vertical"
              primaryTypographyProps={{ color: 'white', fontSize: '1.5rem', fontWeight: 'bold' }}
              secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '1.2rem' }}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon sx={{ color: '#4CAF50' }} />
            </ListItemIcon>
            <ListItemText 
              primary="24/7 AI-Powered Concierge" 
              secondary="Instant responses to inquiries, never miss an opportunity. "
              primaryTypographyProps={{ color: 'white', fontSize: '1.5rem', fontWeight: 'bold' }}
              secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '1.2rem' }}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon sx={{ color: '#4CAF50' }} />
            </ListItemIcon>
            <ListItemText
              primary="Transaction Coordination"
              secondary="Automates reminders, scheduling, signatures, and checks, reducing TC workload"
              primaryTypographyProps={{ color: 'white', fontSize: '1.5rem', fontWeight: 'bold' }}
              secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '1.2rem' }}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon sx={{ color: '#4CAF50' }} />
            </ListItemIcon>
            <ListItemText 
              primary="Multi-Channel Communication" 
              secondary="SMS, voice, email, and web chat options to cater to all preferences"
              primaryTypographyProps={{ color: 'white', fontSize: '1.5rem', fontWeight: 'bold' }}
              secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '1.2rem' }}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon sx={{ color: '#4CAF50' }} />
            </ListItemIcon>
            <ListItemText 
              primary="Intelligent Lead Qualification" 
              secondary="Prioritize high-quality leads for agents, saving time and increasing efficiency"
              primaryTypographyProps={{ color: 'white', fontSize: '1.5rem', fontWeight: 'bold' }}
              secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '1.2rem' }}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon sx={{ color: '#4CAF50' }} />
            </ListItemIcon>
            <ListItemText 
              primary="Automated Follow-ups" 
              secondary="Consistent nurturing of leads over time, increasing conversion rates"
              primaryTypographyProps={{ color: 'white', fontSize: '1.5rem', fontWeight: 'bold' }}
              secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '1.2rem' }}
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon sx={{ color: '#4CAF50' }} />
            </ListItemIcon>
            <ListItemText
              primary="Brokers, MLS, and Associations"
              secondary="Oppy is a turnkey solution for brokers, MLSs, and associations to offer a best-in-class customer experience to their clients & stakeholders."
              primaryTypographyProps={{ color: 'white', fontSize: '1.5rem', fontWeight: 'bold' }}
              secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '1.2rem' }}
            />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default Solutions;
