import React, { useState, useEffect } from 'react';
import { httpClient } from 'src/libs';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  LinearProgress,
} from '@mui/material';
import ChannelPicker from './ChannelPicker';
import MessageInputWithUploader, { Message } from '../common/MessageInputWithUploader';

const BulkConversationCreateDialog = ({ open, handleClose, selectedContacts }) => {
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [inputType, setInputType] = useState('preferred');
  const [emailCount, setEmailCount] = useState(0);
  const [smsCount, setSmsCount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isSending, setIsSending] = useState(false);
  const [successfulContactsCount, setSuccessfulContactsCount] = useState(0);
  const [failedContacts, setFailedContacts] = useState([]);
  const [showSummary, setShowSummary] = useState(false);
  const [preferredEmailCount, setPreferredEmailCount] = useState(0);
  const [preferredSmsCount, setPreferredSmsCount] = useState(0);
  const [sendingEntityRole, setSendingEntityRole] = useState('assistant');
  const [message, setMessage] = useState<Message>({
    method: 'preferred',
    content: '',
    mediaUrls: [],
    sendingEntityRole: 'assistant',
  });

  // Function to reset state to initial values
  const resetState = () => {
    setSelectedChannel(null);
    setInputType('preferred');
    setProgress(0);
    setIsSending(false);
    setSuccessfulContactsCount(0);
    setFailedContacts([]);
    setShowSummary(false);
  };

  useEffect(() => {
    const emailContacts = selectedContacts.filter(contact => contact.email);
    const smsContacts = selectedContacts.filter(contact => contact.phone_number);
    setEmailCount(emailContacts.length);
    setSmsCount(smsContacts.length);

    let prefEmailCount = 0;
    let prefSmsCount = 0;
    selectedContacts.forEach(contact => {
      let method = contact.preferred_method;
      if (!method) {
        method = contact.email ? 'email' : contact.phone_number ? 'phone' : null;
      }
      if (method === 'email' && contact.email) {
        prefEmailCount++;
      } else if (method === 'phone' && contact.phone_number) {
        prefSmsCount++;
      }
    });
    setPreferredEmailCount(prefEmailCount);
    setPreferredSmsCount(prefSmsCount);
  }, [selectedContacts]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isSending) {
        e.preventDefault();
        e.returnValue = '';
        console.log('Attempting to close tab during sending.');
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isSending]);

  const handleSendMessages = async () => {
    const { method, subject, content, mediaUrls, sendingEntityRole } = message;

    if (!selectedChannel) {
      alert('Please select a channel.');
      return;
    }
    if (!content.trim()) {
      alert('Please enter a message.');
      return;
    }

    setIsSending(true);
    setSuccessfulContactsCount(0);
    setFailedContacts([]);
    const totalContacts = selectedContacts.length;

    const url = `${process.env.REACT_APP_API_V3_URL}/chat/private/messages/post_message_v2`;
    setProgress(0);
    for (let i = 0; i < totalContacts; i++) {
      const contact = selectedContacts[i];
      let contactMethod = method;
      if (method === 'preferred') {
        contactMethod = contact.preferred_method || (contact.email ? 'email' : 'phone');
      }
      const toIdentifier = contactMethod === 'email' ? contact.email : contact.phone_number;
      if (!toIdentifier) {
        console.log(`Skipping ${contact.name || contact.first_name || contact.email}: No ${contactMethod} available.`);
        continue;
      }
      const data = {
        content: content,
        channel_id: selectedChannel.id,
        to_identifier: toIdentifier,
        to_tag: contactMethod === 'email' ? 'email' : 'sms',
        subject: contactMethod === 'email' ? subject : undefined,
        media_urls: mediaUrls.length > 0 ? mediaUrls : undefined,
        from_role: sendingEntityRole,
        contact_id: contact.id,
      };
      try {
        await httpClient.post(url, data, { headers: { "Content-Type": "application/json" } });
        setSuccessfulContactsCount(prevCount => prevCount + 1);
        console.log(`Message sent to ${contact.name || contact.first_name || contact.email} (${toIdentifier}).`);
      } catch (error) {
        setFailedContacts(prevFailed => [...prevFailed, { contact, error }]);
        console.error(`Error sending to ${contact.name || contact.first_name || contact.email} (${toIdentifier}):`, error);
      }
      setProgress(((i + 1) / totalContacts) * 100);
    }
    setIsSending(false);
    setShowSummary(true);
  };

  const handleCloseDialog = () => {
    if (isSending) {
      const confirmClose = window.confirm('Sending is not complete. Do you want to stop sending and close?');
      console.log('Attempting to close dialog during sending.');
      if (!confirmClose) return;
      resetState();
      handleClose();
      return;
    }

    if (showSummary) {
      resetState();
      handleClose();
      return;
    }

    if (message.content || message.subject || message.mediaUrls.length > 0) {
      const confirmDiscard = window.confirm('You have unsent changes. Do you really want to discard them and close?');
      if (!confirmDiscard) return;
    }

    resetState();
    handleClose();
  };

  const handleMessageChange = (newMessage: Message) => {
    setMessage(newMessage);
    setInputType(newMessage.method);
  };

  return (
    <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
      <DialogTitle>
        Send Bulk Message
        <Typography variant="subtitle1" component="div">
          Starts conversations with contacts. Replies handled by your chosen Oppy. Monitor in conversations tab.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <ChannelPicker
            selectedChannel={selectedChannel}
            setSelectedChannel={setSelectedChannel}
            inputType={inputType}
          />
          <Typography variant="body2" sx={{ mt: 2 }}>
            {`Total Contacts Selected: ${selectedContacts.length}`}
          </Typography>
          <Typography variant="body2">
            {`Contacts with Email: ${emailCount}`}
          </Typography>
          <Typography variant="body2">
            {`Contacts with SMS: ${smsCount}`}
          </Typography>
          <MessageInputWithUploader
            enablePreferred={true}
            enableSMS={smsCount > 0}
            enableEmail={emailCount > 0}
            disabled={isSending}
            onMessageChange={handleMessageChange}
            channelName={selectedChannel?.chatbot_name || 'Oppy'}  // Pass the channel name here
          />

          {inputType === 'preferred' && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              {`Will send to ${preferredEmailCount} contacts via Email and ${preferredSmsCount} contacts via SMS.`}
            </Typography>
          )}
          {inputType === 'phone' && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              {`Will send to ${smsCount} contacts via SMS.`}
            </Typography>
          )}
          {inputType === 'email' && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              {`Will send to ${emailCount} contacts via Email.`}
            </Typography>
          )}

          {isSending && (
            <Box sx={{ width: '100%', mt: 2 }}>
              <LinearProgress variant="determinate" value={progress} />
              <Typography variant="body2" color="textSecondary" align="center">
                {`Sending messages... ${Math.round(progress)}%`}
              </Typography>
            </Box>
          )}
          {showSummary && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">Summary</Typography>
              <Typography>{`Successfully sent to ${successfulContactsCount} contacts.`}</Typography>
              {failedContacts.length > 0 && (
                <>
                  <Typography>{`Failed to send to ${failedContacts.length} contacts:`}</Typography>
                  {failedContacts.map(({ contact, error }, index) => (
                    <Typography key={index} color="error">
                      {`${contact.name || contact.first_name || contact.email}: ${error.message}`}
                    </Typography>
                  ))}
                </>
              )}
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {showSummary ? (
          <Button onClick={handleCloseDialog}>Close</Button>
        ) : (
          <>
            <Button onClick={handleCloseDialog} disabled={isSending}>Cancel</Button>
            <Button
              onClick={handleSendMessages}
              disabled={!message.content || !selectedChannel || isSending}
            >
              {isSending ? 'Sending...' : 'Send'}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BulkConversationCreateDialog;
