import { useState } from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap';
import { useAppSelector } from 'src/store';

const EmbedBookingModal = ({ showView, closeView, bookingPageUrl, user }) => {
  const embedCode = `<iframe src="${bookingPageUrl}" name="Book ${user.name} Instantly" title="Book ${user.name} Instantly"></iframe> \n\n<a href="${bookingPageUrl}" target="booking">My Booking</a>`

  const [coppiedUrl, setCoppiedUrl] = useState(false)
  const [coppiedCode, setCoppiedCode] = useState(false)
  const darkMode = useAppSelector(state => state.theme.darkMode )

  const copyUrl = (text) => {
    navigator.clipboard.writeText(text)
    setCoppiedUrl(true)
    setTimeout(() => {
      setCoppiedUrl(false)
    }, 3000);
  }

  const copyCode = (text) => {
    navigator.clipboard.writeText(text)
    setCoppiedCode(true)
    setTimeout(() => {
      setCoppiedCode(false)
    }, 3000);
  }

  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        show={showView}
        onHide={closeView}
        centered
        size={'lg'}
        fullscreen={'sm-down'}
      >
        <Modal.Header closeButton className="fs-32 fw-medium">
          Share
        </Modal.Header>

        <Modal.Body>
          <h2>Booking Page</h2>
          <small >Link clients directly to your booking page using this URL.</small>
          <FormControl
            className='my-2'
            value={bookingPageUrl}
            disabled
          />
          <Button variant="outline-secondary" onClick={() => {copyUrl(bookingPageUrl)}}>
            <img src={`/icon/${coppiedUrl ? 'clipboard-check-fill' : 'clipboard'}${darkMode ? '-secondary': ''}.svg` }/>
            Copy URL
          </Button>

          <hr className="mt-4 mb-4" />

          <h2>Embed iframe</h2>
          <small >Embed your booking - copy and paste this code into your website or blog.</small>
          <pre className='text-success bg-dark p-3 my-2'>
            {embedCode}
          </pre>
          <Button variant="outline-secondary" onClick={() => {copyCode(embedCode)}}>
            <img src={`/icon/${coppiedCode ? 'clipboard-check-fill' : 'clipboard'}${darkMode ? '-secondary': ''}.svg` }/>
            Copy URL
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EmbedBookingModal;
