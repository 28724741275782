import { Formik } from 'formik';
import { Row, Col, Form, Image, Button, InputGroup } from 'react-bootstrap';
import {
  updateUser,
  deleteUserPhoto,
  getUser,
  changePassword,
  mailVerificationEmail,
} from 'src/features/user/user.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { accountSchema } from 'src/utils/formSchemas';
import ErrorPopup from '../ErrorPopup';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useState } from 'react';
import SuccessPopup from '../SuccessPopup';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import { compareChanges } from 'src/libs/utils';
import { timezonelist } from 'src/libs/timezones';
import Select from 'react-select';
import { delayTime } from '../ToastMessage';
import ChangePassword from './ChangePassword';
import ValidatedPhoneInput from '../ValidatedPhoneInput';

const AccountForm = () => {
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [phoneError, setPhoneError] = useState(null);
  const { user, userLoading, userError } = useAppSelector((state) => state.user);
  const [successMessage, setSuccessMessage] = useState("")
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [selectedPicture, setSelectedPicture] = useState(null);
  const dispatch = useAppDispatch();
  const initialValues = {
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    email: user.email,
    contact_phone: user.contact_phone,
    timezone: user.timezone,
    existing_user: false,
  };

  const onSubmit = (data) => {
    let payload = compareChanges(initialValues, data)
    dispatch(updateUser(data))
    .unwrap()
    .then((response) => {
      dispatch(trackAnalytics({action_name: "profile_saved", payload: {...payload, description: "user updated account form"}}))
      setSuccessMessage("Successfully Updated")
      setTimeout(() => {
        setSuccessMessage("")
      }, delayTime)
    })
  };

  const deletePhoto = () => {
    dispatch(deleteUserPhoto())
      .unwrap()
      .then(() => {
        // Prevent empty image BE error
        dispatch(getUser());
      });
  };

  const closeChangePassword = () => {
    setShowChangePassword(false)
  };

  const onSubmitPassword = (data) => {
    dispatch(changePassword(data)).unwrap().then(() => {
      setSuccessMessage("Successfully Updated Password")
      setTimeout(() => {
        setSuccessMessage("")
      }, delayTime)
    })
    setShowChangePassword(false)
  };

  const sendVerificationEmail = () => {
    dispatch(mailVerificationEmail()).unwrap().then((response) => {
      setSuccessMessage(response.data.message)
      setTimeout(() => {
        setSuccessMessage("")
      }, delayTime)
    })
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "var(--bs-body-bg)",
    }),
    menu: base => ({
      ...base,
      borderRadius: 0,
      marginTop: 0
    }),
    menuList: (base, state)  => ({
      ...base,
      padding: 0,
      background: "var(--bs-body-bg)",
    }),
    option: (styles, {isFocused, isSelected}) => ({
      ...styles,
      background: (isFocused || isSelected)? 'var(--bs-secondary-bg)' : undefined,
      color: (isSelected || isFocused)? 'var(--bs-primary)' : undefined
  }),
    singleValue: (provided,) => ({
      ...provided,
      color: "text-body",
    })
  };

  return (
    <>
      {showChangePassword && <ChangePassword
        show={showChangePassword}
        onClose={closeChangePassword}
        onSubmit={onSubmitPassword}
        userLoading={userLoading}
      />}
      <Row>
        <Col>
          <h3 className="mb-5">Account</h3>
        </Col>
        <Col>
          <Button  style={{display: "flex", marginLeft: "auto" }} className="mb-4" variant="primary"  disabled={userLoading} onClick={() => setShowChangePassword(true)}>
            Change Password
          </Button>
        </Col>
      </Row>
      {userError && <ErrorPopup message={userError}/>}
      {successMessage && <SuccessPopup message={successMessage}/>}
      <h4 className="mb-2">Profile</h4>

      <p className="mb-4">
        This information will be displayed publicly
      </p>

      <Formik
        initialValues={initialValues}
        validationSchema={accountSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          resetForm,
          values,
          touched,
          errors,
        }) => {
          const selectedzone = timezonelist.filter(zone => zone.value === values.timezone)
          return (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Row>
                <Col sm={12} md={6}>
                  <Form.Label htmlFor="first_name">
                    First Name
                    <span className="text-danger"> *</span>
                  </Form.Label>
                  <Form.Control
                    value={values.first_name}
                    id="first_name"
                    name="first_name"
                    type="text"
                    className="mb-4"
                    disabled={userLoading}
                    onChange={handleChange}
                    isValid={touched.first_name && !errors.first_name}
                    isInvalid={touched.first_name && !!errors.first_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    <>{errors.first_name}</>
                  </Form.Control.Feedback>
                </Col>

                <Col sm={12} md={6}>
                  <Form.Label htmlFor="last_name">
                    Last Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    value={values.last_name}
                    id="last_name"
                    name="last_name"
                    type="text"
                    className="mb-4"
                    disabled={userLoading}
                    onChange={handleChange}
                    isValid={touched.last_name && !errors.last_name}
                    isInvalid={touched.last_name && !!errors.last_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    <>{errors.last_name}</>
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <div className="mb-5">
                <p>Photo</p>
                <Image
                  className="me-1 mb-3 d-block profile-picture"
                  src={selectedPicture || user.picture}
                  width="40"
                  height="40"
                />
                <div>
                  <input
                    type="file"
                    id="avatar"
                    name="[photo_attributes].file"
                    accept="image/png, image/jpeg"
                    disabled={userLoading}
                    onChange={(event) => {
                      setFieldValue(
                        '[photo_attributes].file',
                        event.currentTarget.files[0]
                      );
                      setSelectedPicture(URL.createObjectURL(event.target.files[0]));
                    }}
                    style={{ visibility: 'hidden', display: 'none' }}
                  />

                  <label htmlFor="avatar" className="me-3 link-inline">
                    Choose file
                  </label>
                  <label
                    className="link-inline text-danger"
                    htmlFor="removeFile"
                    onClick={deletePhoto}
                  >
                    Remove
                  </label>
                </div>
              </div>
              <div className="mb-5">
                <Form.Label htmlFor="timezone">
                  Time Zone<span className="text-danger">*</span>
                </Form.Label>
                <Select
                  placeholder="Select Timezone"
                  className="mb-3"
                  id="timezone"
                  name="timezone"
                  defaultValue={selectedzone.length? selectedzone[0] : {label: values.timezone, value: values.timezone} }
                  onChange={e => setFieldValue("timezone",e.value)}
                  options={timezonelist}
                  styles={customStyles}
                />
                <div className="text-danger">
                  <>{errors.timezone}</>
                </div>
              </div>
              <h4 className="mb-2"> Personal Information</h4>
              <p className="mb-4">
                This information will be displayed publicly
              </p>
              <Row>
                <Col sm={12} md={6}>
                  <Form.Label htmlFor="email">
                    Email Address
                  </Form.Label>
                  <Form.Group>
                    <InputGroup className="mb-2">
                      <Form.Control
                        value={values.email}
                        id="email"
                        name="email"
                        type="text"
                        className="mb-4"
                        disabled={true}
                        onChange={handleChange}
                        isValid={touched.email && !errors.email}
                        isInvalid={touched.email && !!errors.email}
                      />
                      <InputGroup.Text className="mb-4 email-button" onClick={sendVerificationEmail}>update email</InputGroup.Text>
                      <Form.Control.Feedback type="invalid">
                        <>{errors.email}</>
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col sm={12} md={6}>
                  <Form.Label htmlFor="contact_phone">
                    Cell Phone Number<span className="text-danger">*</span>
                  </Form.Label>

                  <ValidatedPhoneInput
                    value={values.contact_phone}
                    setValue={(field, value) => setFieldValue(field, value)}
                    setIsPhoneVerified={setIsPhoneVerified}
                    setPhoneError={setPhoneError}
                  />
                  <div className="text-danger">
                    {phoneError}
                    { values.existing_user && <>Phone number +{values.contact_phone} is already associated with a different acccount</> }
                  </div>
                </Col>
              </Row>

              <hr className="mb-4" />
              <div className="action-buttons">
                <Button variant="primary" type="submit" disabled={userLoading}>
                  Save
                </Button>
                <Button variant="outline-secondary" type="reset" className="me-lg-3" onClick={() => resetForm({ values: initialValues })}>
                  Cancel
                </Button>{' '}
              </div>
            </Form.Group>
          </Form>
        )}}
      </Formik>
    </>
  );
};

export default AccountForm;
