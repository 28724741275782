// begin frontend/src/components/ValidatedPhoneInput.tsx

import { Alert, Box, Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import { baseURL } from 'src/libs/axios';
import ErrorPopup from 'src/components/ErrorPopup';
import { useAppDispatch } from 'src/store';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import VerifiedUserSharpIcon from '@mui/icons-material/VerifiedUserSharp';

const ValidatedPhoneInput = ({ value, setValue, setIsPhoneVerified, showValidateButton = false, setPhoneError = (_) => { } }) => {
  const [code, setCode] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(value || "");
  const [validatedPhoneNumber, setValidatedPhoneNumber] = useState(null)
  const [validating, setValidating] = useState(false);
  const [error, setError] = useState(null);
  const [showValidate, setShowValidate] = useState(showValidateButton)
  const [verified, setVerified] = useState(false)

  const dispatch = useAppDispatch()

  useEffect(() => {
    // if the phone number value changes from the last verified number then reset the verified state
    if (phoneNumber !== validatedPhoneNumber) {
      setVerified(false)
      setIsPhoneVerified(false)
      setShowValidate(true)
    } else if (phoneNumber === validatedPhoneNumber) {
      setVerified(true)
      setIsPhoneVerified(true)
      setShowValidate(false)
    }
  }, [phoneNumber])

  const handlePhoneValidationFail = (message) => {
    triggerError(message)
    setIsPhoneVerified(false);
    setVerified(false)
    setPhoneError("Sorry! Could not verify phone number");
    dispatch(trackAnalytics({ action_name: "user_phone_verification_failed", payload: { description: `user failed to verify phone number ${phoneNumber}`, failed_verification_phone: phoneNumber } }))
    setTimeout(() => {
      setPhoneError(null);
    }, 10000);
    restart()
  }

  const triggerError = (message) => {
    setError(message)
    setTimeout(() => {
      setError(null)
    }, 5000)
  }

  const onStart = async () => {
    console.warn('Validating', phoneNumber);
    setValidating(true);
    fetch(`${baseURL}/verify_phone/start?phone=%2b${phoneNumber}`, {
      method: 'POST',
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if (data.success) {
        setCode('');
        dispatch(trackAnalytics({ action_name: "user_phone_verification_started", payload: { description: `user started phone verification ${phoneNumber}`, verified_phone_number: phoneNumber } }))
        setValidating(false);
      } else {
        triggerError(data.message)
        setValidating(false);
        setVerified(false)
      }
    }).catch((error) => {
      triggerError(error.message)
      setValidating(false);
      setVerified(false)
    });
  };

  const onCheck = async () => {
    console.warn('Validating', phoneNumber);
    setValidating(true);

    fetch(`${baseURL}/verify_phone/check?phone=%2b${phoneNumber}&code=${code}`, {
      method: 'POST',
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if (data.success) {
        setCode(data.existing_user ? null : true);
        setValue({ existing_user: data.existing_user, phone: phoneNumber, phone_verification: data.token, valid: true });
        dispatch(trackAnalytics({ action_name: "user_phone_verification_completed", payload: { description: `user completed phone verification ${phoneNumber}` } }))
        setVerified(true)
        setValidating(false);
        setShowValidate(false)
        setValidatedPhoneNumber(phoneNumber)
      } else {
        handlePhoneValidationFail(data.message)
        setValidating(false);
        setValue({ existing_user: false, phone: '', phone_verification: '', valid: false });
        dispatch(trackAnalytics({ action_name: "user_phone_verification_failed", payload: { description: `user failed phone verification ${phoneNumber}` } }))
        setShowValidate(true)
        setVerified(false)
        setValidatedPhoneNumber(null)
      }
    }).catch((error) => {
      handlePhoneValidationFail(error.message)
      setValidating(false);
      setShowValidate(true)
      setVerified(false)
      setValidatedPhoneNumber(null)
    });
  };

  const restart = () => {
    setCode(null)
    setValue({ existing_user: false, phone: '', phone_verification: '', valid: false });
    setPhoneNumber('')
    setVerified(false)
  };

  if (code === null) {
    return (
      <>
        {error && <ErrorPopup message={error} />}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <PhoneInput
            value={phoneNumber}
            enableSearch={true}
            onlyCountries={['us']}
            disableDropdown={true}
            country='us'
            placeholder='Enter phone number'
            onChange={(e) => {
              if (!e.startsWith("1")) { e = "1" + e }
              setValue({ existing_user: false, phone: e, phone_verification: '', valid: false });
              setPhoneNumber(e)
            }}
            containerStyle={{ flexGrow: 1 }}
          />
          {verified && <VerifiedUserSharpIcon color="primary" />}
          {/* {verified ? <Typography color="primary">Verified</Typography> : <Typography color="error">Not verified</Typography>} */}
          {!verified &&
            <Button
              disabled={validating}
              onClick={onStart}
              variant="contained"
              color="primary"
              style={{
                transition: '300ms',
                visibility: phoneNumber.length > 9 && showValidate ? 'visible' : 'hidden',
                opacity: phoneNumber.length > 9 && showValidate ? '1' : '0',
              }}
            >
              {validating ?
                <CircularProgress color="inherit" size={20} />
                :
                "Start verification"
              }
            </Button>
          }
        </Box>
      </>
    );
  }

  if (code === true) {
    return <div>Phone number verified: +{phoneNumber}</div>;
  }

  return (
    <>
      {error && <ErrorPopup message={error} />}
      {verified && <Alert severity="success">+{phoneNumber} Verified!</Alert>}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
        <Typography>Please enter the code sent to you via text.</Typography>
        <TextField
          value={code}
          label="Enter code"
          variant="outlined"
          sx={{ maxWidth: '50%', backgroundColor: 'white' }}
          onChange={(e) => setCode(e.target.value)}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
          <Button onClick={restart} disabled={validating} color="secondary" variant="contained">
            Restart
          </Button>
          <Button onClick={onCheck} disabled={validating} color="primary" variant="contained">
            {validating ? <CircularProgress color="inherit" size={20} /> : "Validate phone"}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default ValidatedPhoneInput;
