const UnitTier = ({ handleChange, values, touched, errors }) => {
  return (
    <>
      <div className="fs-14 fw-medium mb-1 mt-3">Your Charge Per Unit</div>
      <div className="input-group mb-3">
        <div className="input-group-prepend">
          <span className="input-group-text ht-48">$</span>
        </div>
        <input
          type="text"
          className="form-control ht-48"
          aria-label="Amount (to the nearest dollar)"
        />
        <div className="input-group-append ht-48">
          <span className="input-group-text ht-48">USD</span>
        </div>
      </div>
    </>
  );
};

export default UnitTier;
