import {
  Modal,
  Button,
  Row,
  Col,
  Carousel,
} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useAppSelector } from 'src/store';
import PreviewGalleryItem from './PreviewGalleryItem';
import Markdown from 'markdown-to-jsx';

const FullGalleryView = ({ handleAddToCart=()=>{}, handleRemove=()=>{}, selectedBookingService, showView, closeView, isPropertyInfoUpdated, selectedServices, handleErrorPopup=(_)=>{}, addButtonDisable, galleryItems=[] }) => {
  const { square_feet } = useAppSelector((state) => state.bookingServices);

  return (
    <Modal
      backdrop="static"
      keyboard={false}
      fullscreen={true}
      show={showView}
      contentClassName="px-lg-3 w-100 bg-black text-white px-lg-0 px-md-0"
      onHide={closeView}
      centered
    >
      <Modal.Header
        closeButton
        closeVariant="white"
        className="bg-dark text-white"
      >
        <div className="h5 text-truncate">{selectedBookingService?.name}</div>
      </Modal.Header>

      <Modal.Body className="pt-0">
        <Row md={1} lg={12} className='h-100'>
          <Col xs lg="8" md="1" className="pt-lg-3">
            <Carousel className='h-100'>
              {galleryItems.map((galleryItem)=>(
                <Carousel.Item key={`galleryItemFull-${galleryItem.id}`} className="h-100">
                  <PreviewGalleryItem item={galleryItem} />
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
          <Col lg="4" className="bg-gradient pt-3">
            <div className="d-flex justify-content-between" style={{ overflowWrap: 'anywhere' }}>
              <span className="mr-auto fw-medium fs-4">{selectedBookingService?.name}</span>
            </div>
            <p className="fs-14 lh-1 text-secondary mt-1">{selectedBookingService?.tagline}</p>
            <div>
              <div>
                {selectedBookingService?.fixed_time + selectedBookingService?.time_per_k > 0 &&
                  <div className="d-flex mt-2 fs-18">
                    <div className="me-2">Duration:</div>
                    { selectedBookingService?.fixed_time && <span className="text-gray">{selectedBookingService?.fixed_time + (square_feet/1000) * selectedBookingService?.time_per_k } Minutes</span> }
                  </div>
                }
                <div className="d-flex mb-4 fs-18">
                  <div className="me-2">Turnaround:</div>
                  { selectedBookingService?.turnaround_time_string && <span className="text-gray">{selectedBookingService?.turnaround_time_string}</span> }
                </div>
              </div>

              <div className="d-flex justify-content-between mb-2">
                <div className="d-flex">
                  <h6 className="fs-4" style={{ overflowWrap: 'anywhere' }}>
                    {isPropertyInfoUpdated && <NumberFormat value={selectedBookingService?.price} displayType={'text'} thousandSeparator={true} prefix={'$ '} />}
                  </h6>
                  {selectedServices.includes(selectedBookingService?.id) && (
                    <div
                      className="d-flex align-items-baseline ms-3 fw-medium cursor-point"
                      onClick={handleRemove}
                    >
                      <img src="/icon/check.svg" alt="" className="me-2" />{' '}
                      Service Added
                    </div>
                  )}
                </div>
              </div>

              <div>
                {!selectedServices.includes(selectedBookingService?.id) && (
                  <Button disabled={addButtonDisable} variant="primary" onClick={ () => { isPropertyInfoUpdated ? handleAddToCart() : handleErrorPopup("Full Gallery View") }}>
                    <span className="fs-18 fw-medium d-flex align-items-center">
                      <img src="/icon/plus.svg" alt="" className="me-2" />
                      Add Service
                    </span>
                  </Button>
                )}
              </div>

              <div className="mt-4">
                <span className="text-secondary fs-12 fw-medium">
                  Description
                </span>
                <p className="my-1 fs-14">
                  <Markdown>{selectedBookingService?.description}</Markdown>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};


export default FullGalleryView;
