// utils/reducerHelpers.js

// Update a single conversation by applying changes
export const updateConversation = (conversations, conversationId, changes) => {
    return conversations.map(conversation => {
    if (conversation.id === conversationId) {
      return { ...conversation, ...changes };
    }
    return conversation;
  });
};

// Add or update a message within a conversation
export const addOrUpdateMessage = (conversation, newOrUpdatedMessage) => {
    const messageIndex = conversation.messages.findIndex(msg => msg.id === newOrUpdatedMessage.id);
    if (messageIndex > -1) {
        const newMessages = [...conversation.messages];
        newMessages[messageIndex] = newOrUpdatedMessage;
        return { ...conversation, messages: newMessages };
    } else {
      const newMessages = [...conversation.messages, newOrUpdatedMessage];
      return { ...conversation, messages: newMessages };
    }
};


// Add or update a whole conversation object while preserving existing messages
export const mergeExistingOrNewConversationAndGetUpdatedConversationObject = (existingConversation, newConversation) => {
  
  if (existingConversation) {
    const existingMessages = existingConversation.messages;
    const newMessages = newConversation.messages;
    // Use a Map to ensure unique messages by id
    const messageMap = new Map();
    existingMessages.forEach(msg => messageMap.set(msg.id, msg));
    newMessages.forEach(msg => messageMap.set(msg.id, msg));
    const mergedMessages = Array.from(messageMap.values());
    mergedMessages.sort((a, b) => new Date(a.deliver_after || a.created_at).getTime() - new Date(b.deliver_after || b.created_at).getTime());

    const updatedConversation = {
      ...newConversation,
      messages: mergedMessages
    };
    // Replace the existing conversation with the updated one
    return updatedConversation;
  } else {
    // Add new conversation if it doesn't exist
    return newConversation;
  }
};

export const removeConversation = (conversations, conversation) => {
  return conversations.filter(conversation => conversation.id !== conversation.id);
};

export const addOrUpdateContact = (contacts, newContact) => {
  const index = contacts.findIndex(contact => contact.id === newContact.id);
  if (index > -1) {
    return [...contacts.slice(0, index), newContact, ...contacts.slice(index + 1)];
  } else {
    return [...contacts, newContact];
  }
};

export const removeContact = (contacts, contact) => {
  return contacts.filter(contact => contact.id !== contact.id);
};

export const addOrUpdateFaq = (faqs, newFaq) => {
  const index = faqs.findIndex(faq => faq.id === newFaq.id);
  if (index > -1) {
    return [...faqs.slice(0, index), newFaq, ...faqs.slice(index + 1)];
  } else {
    return [...faqs, newFaq];
  }
};
