// BEGIN frontend/src/components/channels/ImportWebsiteInput.tsx
import { useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';
import { CircularProgress } from '@mui/material';
import { useAppDispatch } from 'src/store';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import { enqueueSnackbar } from 'notistack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/system/Box';
import ClearIcon from '@mui/icons-material/Clear';

// Define the Page type
type Page = {
  tracking_id: string;
  page_url: string;
}

// Define the props for the component
interface ImportWebsiteInputProps {
  inputStyle?: React.CSSProperties;
  incomingPage: Page;
  pages: Page[];
  handleUpdatePage: (updatedPage: Page, index: number) => void;
  handleAddPage: (index:number) => void;
  handleRemovePage: (index: number) => void;  // Change here
  handleResetPage: (index: number) => void;  // and here
  showRemoveButton?: boolean;
  showAddButton?: boolean;
  showResetButton?: boolean;
  index: number;
  channelId?: string;
}

const ImportWebsiteInput = ({
  inputStyle = {},
  incomingPage,
  pages,
  handleUpdatePage,
  handleAddPage,
  handleRemovePage,
  handleResetPage,
  showRemoveButton = false,
  showAddButton = false,
  showResetButton = false,
  index,
  channelId = null
}: ImportWebsiteInputProps) => {
  const [completed, setCompleted] = useState(!!incomingPage.tracking_id);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [inputErrorMessage, setInputErrorMessage] = useState('');
  const [inputHelperText, setInputHelperText] = useState('');
  const [allowSubmit, setAllowSubmit] = useState(true);

  const handleDomainChange = (event) => {
    const validUrl = ensureDomainHasProtocol(event.target.value);
    console.warn('validUrl', validUrl);
    console.warn('incomingPage', incomingPage);
    handleUpdatePage({ tracking_id: incomingPage.tracking_id, page_url: validUrl }, index);
    handleCheckForDuplicate(validUrl);
  };

  useEffect(() => {
    console.warn('changed incomingPage', incomingPage)
    if (incomingPage.tracking_id) {
      setCompleted(true);
    }
  }, [incomingPage]);

  const confirmRemovePage = (index) => {
    if (channelId === null) {
      setInputErrorMessage('');
      setInputHelperText('');
      if (index === 0) {
        handleResetPage(index);
      } else {
        handleRemovePage(index);
      }
      setCompleted(false);
      return
    }
    if (incomingPage && incomingPage.tracking_id === '') {
      // if the page has no tracking_id, just remove it from the array no confirmation needed
      handleRemovePage(index);
      setCompleted(false);
    } else if (window.confirm('Are you sure you want to remove this page?')) {
      // Http delete request to remove the page from the database and the page array route is private_channel_page_remove
      const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_channel_page_remove(channelId, incomingPage.tracking_id)}`;      httpClient.delete(url, { data: { tracking_id: incomingPage.tracking_id } })
      httpClient.delete(url, { data: { tracking_id: incomingPage.tracking_id } })
        .then((response) => {
          enqueueSnackbar(`Page ${incomingPage.page_url} removed`, { variant: 'info' });
          setInputErrorMessage('');
          setInputHelperText('');
          if (index === 0) {
            handleResetPage(index);
          } else {
            handleRemovePage(index);
          }
          setCompleted(false);
        }
        ).catch((error) => {
          enqueueSnackbar(`Failed to removing ${incomingPage.page_url}. Please try again later.`, { variant: 'error' });
          setInputErrorMessage(`Problem removing ${incomingPage.page_url}.`);
          setInputHelperText(`Error removing ${incomingPage.page_url}. Please check & try again.`);
          setLoading(false);
          return;
        }
        ).finally(() => {
          setLoading(false);
        });
      setLoading(false);
    } else {
      return;
    }
  }

  const handleCheckForDuplicate = (validUrl) => {
    // Check if the page_url already exists in pages
    const urlAlreadyExists = pages.some(page => page.page_url === validUrl);
    if (urlAlreadyExists && validUrl !== '' && validUrl !== 'https://' && validUrl !== 'http://') {

      setInputErrorMessage(`${validUrl} already added`);
      setInputHelperText(`${validUrl} exists. Please enter a new website`);
      setAllowSubmit(false);
      return;
    } else {
      setAllowSubmit(true);
      setInputErrorMessage('');
      setInputHelperText('');
    }
  }

  const handleSubmit = () => {
    if (!allowSubmit) {
      return;
    }
    const validUrl = ensureDomainHasProtocol();
    if (loading || !validUrl) {
      return;
    }

    handlePostUrl(validUrl);
  }

  const ensureDomainHasProtocol = (url = incomingPage.page_url) => {
    let incomingDomain = url;

    if (!incomingDomain.startsWith('http://') && !incomingDomain.startsWith('https://')) {
      let  validDomain = `https://${incomingDomain}`;
      return validDomain;
    }else {
      return incomingDomain;
    }
  };

  const handlePostUrl = (validUrl) => {
    setLoading(true);
    dispatch(trackAnalytics({ action_name: "user_import_website_submit", payload: { domain: validUrl, description: `user submitted website ${validUrl}` } }))
    const url = `${process.env.REACT_APP_API_URL}${API_ROUTES.post_url_for_content_summary}`;
    const data = { domain: validUrl, channel_id: channelId };
    // Refactor the httpClient call to catch an error and display an alert to the user if the call fails 
    httpClient
      .post(url, data, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        enqueueSnackbar(`Reading your page: ${response.data.website_title} in the background. This could take a few minutes - you can continue creating your Assistant.`, { variant: 'info' });
        // setTrackingId(response.data.tracking_id);
        // setDomain(response.data.page_url);
        setInputErrorMessage('');
        setInputHelperText('');
        handleUpdatePage({ tracking_id: response.data.tracking_id, page_url: response.data.page_url },index);
      }
      ).catch((error) => {
        enqueueSnackbar(`Something went wrong with the domain ${validUrl}. Please check the website again later.`, { variant: 'error' });
        setInputErrorMessage(`Something went wrong with the domain ${validUrl}. Please check the website again later.`);
        setInputHelperText(`Error loading ${validUrl}. Please check & try again.`);
        setLoading(false);
      }
      ).finally(() => {
        setLoading(false);
      });

  };

  return (
      <>
        <TextField 
          sx={{margin: 1, width: '80%'}}
          id="domain"
          label={"Import Website"}
          value={incomingPage?.page_url || ''}
          variant='outlined'
          onChange={handleDomainChange}
          onBlur={handleSubmit}
          error={inputErrorMessage.length > 0 ? true : false}
          helperText={incomingPage?.page_url || ''}
          disabled={loading || completed}
          InputProps={{endAdornment: 
          <>
            {loading ?
              // box with loading message pulsing
              <div className="d-flex align-items-center">
                <CircularProgress size={20} />
                {/* <span className="ms-2">{loadingMessage}</span> */}
              </div>
              :
              <>
                {incomingPage?.tracking_id ?
                  <Button onClick={handleSubmit} sx={{ }} disabled={!incomingPage.page_url || !allowSubmit }>
                    <CheckCircleIcon sx={{ color: 'green' }} />
                  </Button>
                :
                  <Button onClick={handleSubmit} sx={{ width: 200 }} disabled={!incomingPage.page_url || !allowSubmit}>
                    Learn Website
                  </Button>
                }
            </>
            }
          </>
          }}
        />
        <Box sx={{ w: "20%" }}>
          {!showRemoveButton && (incomingPage.page_url || incomingPage.tracking_id) &&
            <IconButton sx={{p:0}} onClick={() => confirmRemovePage(index)}>
              <ClearIcon />
            </IconButton>
          }
          {showRemoveButton &&
            <IconButton sx={{p:0}} onClick={() => confirmRemovePage(index)}>
              <RemoveCircleOutlineIcon />
            </IconButton>
          }
          {showAddButton &&
            <IconButton sx={{p:0, ml:1}} onClick={()=> handleAddPage(index)}>
              <AddCircleOutlineIcon />
            </IconButton>
          }
        </Box>
      </>

  );
};

export default ImportWebsiteInput;
// END frontend/src/components/channels/ImportWebsiteInput.tsx
