import { ReactComponent as ZapIcon } from 'src/assets/svg/zap.svg';
import { ReactComponent as BusinessIcon } from 'src/assets/svg/business.svg';

interface Plan {
  name: string;
  tier: string;
  subtitle: string;
  price: string;
  features: Array<string>;
  icon: any;
  disabledButtonText?: string;
  buttonText: string;
  buttonLink: string;
}

const planData: Plan[] = [
  {
    name: 'phone_number_only',
    tier: 'Phone Number',
    subtitle: '$6/mo phone number',
    price: '$6/m',
    features: [
      'Send and receive SMS texts through your assistant.',
      'Custom phone number.'
    ],
    icon: ZapIcon,
    disabledButtonText: 'First Time Setup Required',
    buttonText: 'Get Phone Number',
    buttonLink: 'https://buy.stripe.com/test_aEUaHB0fY4rvaqseV3'
  },
  {
    name: 'a2p_setup',
    tier: 'First Time Setup',
    subtitle: '$25 Setup + $6/mo recurring 1 Month Phone Number + White Glove Setup',
    price: '$29',
    features: [
      'US A2p 10DLC White Glove Setup so you don\'t have to teach yourself about A2P 10DLC. Oh and so US carriers like AT&T, Verizon, T-Mobile, will deliver your messages.',
      'Get permissions to use your phone number with your business.'
    ],
    icon: BusinessIcon,
    buttonText: 'Start form application',
    buttonLink: 'https://buy.stripe.com/test_aEUaHB0fY4rvaqseV3'
  },
];

// create the phonePlans function that will filter planData based on the parameters
export const phonePlans = (a2pApproved: boolean, subAccountStatus: string): Plan[] => {
  return planData.filter((plan) => {
    if (plan.name === "phone_number_only") {
      return a2pApproved || subAccountStatus !== "verification_not_started"
    } else if (plan.name === "a2p_setup") {
      return !a2pApproved && subAccountStatus === "verification_not_started"
    }
    return false
  })
}
