import { useEffect, useState } from 'react';
import { getAllPhotographerServices, getFirstGalleryItems } from 'src/features/services/photographer.slice';
import { useAppDispatch, useAppSelector } from 'src/store';

const usePhotographerServices = () => {
  const [hasFetchServices, setHasFetchServices] = useState(false);
  const { firstGalleryItems, serviceList, serviceError, servicesAmount, hasServices, isLoading } =
    useAppSelector((state) => state.photographerServices);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllPhotographerServices());
    dispatch(getFirstGalleryItems());
    setHasFetchServices(() => true);
  }, []);

  return {
    serviceList,
    serviceError,
    servicesAmount,
    hasServices,
    isLoading,
    firstGalleryItems,
  };
};

export default usePhotographerServices;
