import { useEffect, useState } from "react";
import { useParams } from "react-router";
import ChatPopup from "../Chat/ChatPopup";
import "src/styles/components/PublicShare.scss"
import "src/styles/components/dark_public_share.scss"
import "src/styles/components/light_public_share.scss"
import QRCode from 'qrcode.react';
import { httpClient } from "src/libs";
import { API_ROUTES } from "src/constants/routes";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useSearchParams } from "react-router-dom";
import { getChatbotTheme } from "src/libs/utils";
import { useAppDispatch } from "src/store";
import { trackAnalytics } from "src/features/analytics/analytics.slice";
import Loading from "../Loading";
import { Typography } from "@mui/material";
import ChannelNotFound from "./ChannelNotFound";

const PublicShare = () => {
  const { channel_embed_key:channelEmbedKey } = useParams();
  const [channel, setChannel] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialMessage = searchParams.get("initial_message")
  const s = searchParams.get("s")
  const utm_source = searchParams.get("utm_source")

  const [chatbotTheme, setChatbotTheme] = useState(Number(s) || 2);

  const dispatch = useAppDispatch();

  const whiteLabel = false;

  // const fetchChannel = async () => {
  //   const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.public_channel}/${channelEmbedKey}`;
  //   const response = await httpClient.get(url);
  //   if (response.status !== 200) {
  //     setLoadingError(true);
  //     setLoading(false);
  //     return;
  //   }
  //   setChannel(response.data);
  //   setLoading(false)
  //   dispatch(trackAnalytics({ action_name: "channel_loaded_public_share", payload: { channel: {channelEmbedKey} ,channel_object: response.data , description: `user opened channel`, utm_source: utm_source, theme: s } }))
  // };

  const fetchChannel = async () => {
    try {
      const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.public_channel}/${channelEmbedKey}`;
      const response = await httpClient.get(url);
      if (response.status !== 200) {
        throw new Error(`Server responded with status: ${response.status}`);
      }

      setChannel(response.data);
      setLoading(false);
      dispatch(trackAnalytics({
        action_name: "channel_loaded_public_share",
        payload: {
          channel: { channelEmbedKey },
          channel_object: response.data,
          description: `user opened channel`,
          utm_source: utm_source,
          theme: s
        }
      }));
    } catch (error) {
      console.error("Error fetching channel:", error);
      setLoadingError(true);
      setLoading(false);
    }
  };


  useEffect(() => {
    // when channel changes update the theme
    if (channel && Number(channel.style)) handleThemeChange(Number(channel.style));
  }, [channel]);

  useEffect(() => {
    fetchChannel();
    dispatch(trackAnalytics({ action_name: "user_opened_public_share", payload: { channel: {channelEmbedKey} , description: `user opened public share`, utm_source: utm_source, theme: s } }))
  }, []);

  useEffect(() => {
    if (!getChatbotTheme(Number(s))) setSearchParams({ s: "2" });
  }, [s])

  const handleThemeChange = (theme) => {
    setChatbotTheme(theme)
    setSearchParams({ s: theme });
  }

  return (
    <>
      {loading ?
        <Loading />
      :
        <div className={`${getChatbotTheme(chatbotTheme)} public-share-container `}>
          {loadingError ?
              <ChannelNotFound />
            :
              <>
                <Typography variant="h4" sx={{ textAlign: 'center' }}>{channel.title}</Typography>
                <ChatPopup
                  key={channelEmbedKey}
                  embedKey={channelEmbedKey}
                  open={true}
                  chatbotName={channel.chatbot_name}
                  headingText={`Meet ${channel.chatbot_name}`}
                  sendInitialMessage={initialMessage ? true : false}
                  initialMessage={initialMessage}
                  customClass="public-share-chat-popup"
                  chatbotTheme={chatbotTheme}
                  setChatbotTheme={handleThemeChange}
                />
                <div className="options-wrapper">
                  <QRCode style={{ borderRadius: '8px' }} value={`${window.location.origin}/s/${channelEmbedKey}?s=${s}&utm_source=qr_code_public`} />
                  {/* create a button below that will trigger the share dialogue on phones with the link to this URL */}
                  {/* <button className="public-share-button">Share</button> */}
                </div>
                <div className="public-share-footer">
                  <p>
                    Powered by
                    <a href="https://www.oppy.pro?utm_source=public_share" title="Build your own Chatbot in just a few clicks!" target="blank" rel="noopener"> Oppy.pro</a>
                  </p>
                </div>
              </>
          }
        </div>
      }
    </>
  );
};

export default PublicShare;
