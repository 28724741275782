import React, { useState, useCallback, useEffect } from 'react';
import { Add, Remove, DragIndicator } from '@mui/icons-material';
import { Button, TextField, Chip, Box, Typography, IconButton } from '@mui/material';

interface Variables {
  chatbotName: string;
  title: string;
  avatarUrl: string;
  requirements: string[];
  [key: string]: string | string[];
}

const PromptBuilder = () => {
  const [variables, setVariables] = useState<Variables>({
    chatbotName: 'David Eskridge',
    title: 'Real Estate Lead Qualification Agent',
    avatarUrl: '/assets/svg/support-icon.svg',
    requirements: ['admin_email', 'admin_phone']
  });

  const [goals, setGoals] = useState([
    "Prequalify real estate leads efficiently",
    "Build detailed contact profiles",
    "Manage property inquiries professionally"
  ]);

  const [systemMessage, setSystemMessage] = useState(`# {{title}} - v1.1 (August 2024)
## Keywords: real estate, lead qualification, customer service, property inquiries
As {{chatbotName}}, you are a sophisticated AI real estate concierge for Metro Brokers. Your primary function is to prequalify leads, build contacts, and manage property inquiries efficiently and professionally.

### Goals:
{{goals}}

### Core Responsibilities:
1. Qualify leads
2. Build detailed contact profiles
3. Manage property inquiries
4. Schedule viewings
5. Provide excellent customer service

### Communication Guidelines:
- Tone: Caring, friendly, and professional
- Language: Casual yet informative
- Emojis: Use sparingly, limited to: 🏠🔑🏙️🌇🏘️

### Key Functions:
1. **Initial Contact**
   - Use \`read_and_summarize_webpage_function\` to gather property details
   - Choose a personalized template:
     "Hey [First Name] - {{chatbotName}} here from Metro Brokers. You inquired about [Address] - [Key features, price]. Interested in viewing?"
2. **Lead Qualification**
   - Ask qualifying questions:
     - "Are you just starting your search? Is anyone helping you yet?"
     - "Have you been prequalified for a mortgage?"
   - Use \`update_contact_user_details_in_memory_function\` to store responses

### Requirements: {{requirements}}`);

  const [newVariable, setNewVariable] = useState({ key: '', value: '' });
  const [newGoal, setNewGoal] = useState('');

  useEffect(() => {
    updateSystemMessageWithGoals();
  }, [goals]);

  const handleVariableChange = useCallback((key, value) => {
    setVariables(prev => ({ ...prev, [key]: value }));
  }, []);

  const handleAddVariable = () => {
    if (newVariable.key && newVariable.value) {
      setVariables(prev => ({ ...prev, [newVariable.key]: newVariable.value }));
      setNewVariable({ key: '', value: '' });
    }
  };

  const handleRemoveVariable = (key: string) => {
    setVariables(prev => {
      const { [key]: _, ...rest } = prev;
      return {
        ...rest,
        chatbotName: prev.chatbotName,
        title: prev.title,
        avatarUrl: prev.avatarUrl,
        requirements: prev.requirements
      };
    });
  };

  const handleAddGoal = () => {
    if (newGoal) {
      setGoals(prev => [...prev, newGoal]);
      setNewGoal('');
    }
  };

  const handleRemoveGoal = (index) => {
    setGoals(prev => prev.filter((_, i) => i !== index));
  };

  const handleGoalChange = (index, value) => {
    setGoals(prev => prev.map((goal, i) => i === index ? value : goal));
  };

  const updateSystemMessageWithGoals = () => {
    setSystemMessage(prev => {
      const goalsList = goals.map((goal, index) => `${index + 1}. ${goal}`).join('\n');
      return prev.replace(/### Goals:[\s\S]*?(?=###)/, `### Goals:\n${goalsList}\n\n`);
    });
  };

  const handleDragStart = (e, text) => {
    e.dataTransfer.setData('text/plain', text);
    e.dataTransfer.effectAllowed = 'copy';
  };

  const handleDrop = (editor) => (e) => {
    e.preventDefault();
    const text = e.dataTransfer.getData('text');
    editor.chain().focus().insertContent(text).run();
  };

  const handleEditorDragOver = (e) => {
    e.preventDefault();
  };

  const handleEditorDrop = (e) => {
    e.preventDefault();
    const text = e.dataTransfer.getData('text');
    setSystemMessage(prev => `${prev}\n${text}`);
  };

  const renderVariableChips = () => {
    return Object.entries(variables).map(([key, value]) => (
      <Box key={key} sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
        <IconButton
          size="small"
          draggable="true"
          onDragStart={(e) => handleDragStart(e, `{{${key}}}`)}
        >
          <DragIndicator />
        </IconButton>
        <Chip label={key} color="primary" />
        <TextField
          value={value}
          onChange={(e) => handleVariableChange(key, e.target.value)}
          fullWidth
        />
        <IconButton onClick={() => handleRemoveVariable(key)} color="error" size="small">
          <Remove />
        </IconButton>
      </Box>
    ));
  };

  const renderGoals = () => {
    return goals.map((goal, index) => (
      <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
        <IconButton
          size="small"
          draggable="true"
          onDragStart={(e) => handleDragStart(e, goal)}
        >
          <DragIndicator />
        </IconButton>
        <TextField
          value={goal}
          onChange={(e) => handleGoalChange(index, e.target.value)}
          fullWidth
        />
        <IconButton onClick={() => handleRemoveGoal(index)} color="error" size="small">
          <Remove />
        </IconButton>
      </Box>
    ));
  };

  return (
    <Box sx={{ maxWidth: '4xl', mx: 'auto', p: 6, '& > *': { mb: 6 } }}>
      <Typography variant="h4" fontWeight="bold">Prompt Builder</Typography>

      <Box>
        <Typography variant="h5" fontWeight="semibold" mb={2}>Variables</Typography>
        {renderVariableChips()}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
          <TextField
            placeholder="Key"
            value={newVariable.key}
            onChange={(e) => setNewVariable(prev => ({ ...prev, key: e.target.value }))}
          />
          <TextField
            placeholder="Value"
            value={newVariable.value}
            onChange={(e) => setNewVariable(prev => ({ ...prev, value: e.target.value }))}
          />
          <IconButton onClick={handleAddVariable} color="primary">
            <Add />
          </IconButton>
        </Box>
      </Box>

      <Box>
        <Typography variant="h5" fontWeight="semibold" mb={2}>Goals</Typography>
        {renderGoals()}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
          <TextField
            placeholder="New Goal"
            value={newGoal}
            onChange={(e) => setNewGoal(e.target.value)}
          />
          <IconButton onClick={handleAddGoal} color="primary">
            <Add />
          </IconButton>
        </Box>
      </Box>

      <Box>
        <Typography variant="h5" fontWeight="semibold" mb={2}>System Message</Typography>
        <div
          onDragOver={handleEditorDragOver}
          onDrop={handleEditorDrop}
        >
        </div>
      </Box>
    </Box>
  );
};

export default PromptBuilder;
