import { useEffect, useState } from 'react';
import { Button, Image, Row } from 'react-bootstrap';
import { clearServiceImages, getPhotographerServiceGallery } from 'src/features/services/photographer.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import ErrorPopup from 'src/components/ErrorPopup';
import FullGalleryView from 'src/components/ServiceDetail/FullGalleryView';
import useWindowSize from 'src/hooks/useWindowSize';
import GalleryItemDragAndDrop from './Gallery/GalleryItemDragAndDrop';

const Gallery = ({ serviceId, values }) => {
  const [width, _height] = useWindowSize();
  const [showView, setShowView] = useState(false);
  const dispatch = useAppDispatch();
  const { currentGalleryItems, currentService } = useAppSelector((state) => state.photographerServices);
  const [enableDnd, setEnableDnd] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  var chunkSize = 3 // For screen size md and above
  if (width < 768) chunkSize = 2 // For screen size sm & below
  let rows = []
  for (let i = 0; i < currentGalleryItems.length; i += chunkSize) {
    rows = [...rows,currentGalleryItems.slice(i, i + chunkSize)]
  }

  useEffect(()=>{
    dispatch(getPhotographerServiceGallery({serviceId}))
    return () => {
      dispatch(clearServiceImages())
    }
  }, [])

  const IsEnableDnd = () => {
    setEnableDnd(!enableDnd)
  };

  const handleFullGalleryView = () => {
    setShowView(!showView);
  }

  return (
    <>
      <FullGalleryView
        addButtonDisable={false}
        showView={showView}
        closeView={handleFullGalleryView}
        isPropertyInfoUpdated={false}
        selectedBookingService={values}
        selectedServices={[]}
        galleryItems={currentGalleryItems}
      />
      <>
        {errorMessage &&  <ErrorPopup message={errorMessage}/>}
        <Row className="justify-content-between" >
          <Button
            size="sm"
            className="col-sm-5 col-md-4 my-2"
            onClick={() => IsEnableDnd()}
          >
            <Image
              src="/icon/enableDnDIcon.svg"
              className="me-3 text-blue-added"
            />
            {enableDnd? "Disable" : "Enable"} Drag & Drop
          </Button>
          <Button
            size="sm"
            className="col-sm-5 col-md-4 my-2 ml-auto"
            onClick={() => setShowView(true)}
          >
            <Image
              src="/icon/OpenIcon.svg"
              className="mr-3 me-2 text-blue-added"
            />
            Open Gallery
          </Button>
        </Row>
        <GalleryItemDragAndDrop
          setErrorMessage={setErrorMessage}
          chunkSize={chunkSize}
          currentGalleryItems={currentGalleryItems}
          serviceId={serviceId}
          enableDnd={enableDnd}
          rows={rows}
          currentService={currentService}
        />
      </>
    </>
  );
};

export default Gallery;
