import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Collapse,
  Divider,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  Badge,
  Menu,
  MenuItem,
  Checkbox,
  FormHelperText,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  ExpandLess,
  ExpandMore,
  HelpOutline as HelpIcon,
  MoreVert as MoreVertIcon,
  Schedule as ScheduleIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Pages } from 'src/types/pages';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CronScheduler from './CronScheduler';
import { dateTimeFormatter } from '../libs/dateTimeFormatter';
import { useAppSelector, useAppDispatch } from 'src/store';
import { updateChannel } from 'src/features/account/channels.slice';
import { useSnackbar } from 'notistack';

export interface PagesFormProps {
  channelId: string;
}

const PagesForm: React.FC<PagesFormProps> = ({ channelId }) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // Get the channel from the store
  const channel = useAppSelector(state => state.channels.entities[channelId]);

  // State for pages
  const [pages, setPages] = useState<Pages>(channel?.pages ?? {});
  const [initialPages, setInitialPages] = useState<Pages>(channel?.pages ?? {});
  const [saving, setSaving] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  // Update pages when channel changes
  useEffect(() => {
    if (channel) {
      setPages(channel.pages ?? {});
      setInitialPages(channel.pages ?? {});
    }
  }, [channel]);

  // Handle save function
  const handleSave = async (pagesToSave = pages) => {
    try {
      setSaving(true);
      await dispatch(
        updateChannel({ channelData: { id: channelId, pages: pagesToSave } })
      ).unwrap();
      setInitialPages(pagesToSave);
      enqueueSnackbar('Pages updated successfully!', { variant: 'success' });
    } catch (error) {
      console.error('Failed to update pages:', error);
      enqueueSnackbar('Failed to update pages.', { variant: 'error' });
    } finally {
      setSaving(false);
    }
  };

  const theme = useTheme();
  const [anchorEls, setAnchorEls] = useState<{ [url: string]: null | HTMLElement }>({});
  const [editingScheduleUrl, setEditingScheduleUrl] = useState('');
  const [isSchedulerOpen, setIsSchedulerOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedPages, setSelectedPages] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState(false);

  const formik = useFormik({
    initialValues: {
      newPageUrl: '',
    },
    validationSchema: Yup.object({
      newPageUrl: Yup.string()
        .required('Required')
        .test('is-valid-url', 'Invalid URL', (value) => {
          if (!value) return false;
          let url = value.trim();
          if (!/^https?:\/\//i.test(url)) {
            url = 'http://' + url;
          }
          try {
            new URL(url);
            return true;
          } catch {
            return false;
          }
        }),
    }),
    onSubmit: (values, { resetForm }) => {
      let url = values.newPageUrl.trim();
      if (!/^https?:\/\//i.test(url)) {
        url = 'http://' + url; // Prepend http:// if missing
      }
      if (pages[url]) {
        formik.setFieldError('newPageUrl', 'Page already exists');
        return;
      }
      const updatedPages = {
        ...pages,
        [url]: {
          status: null,
          last_sync: null,
          title: '',
          meta_description: '',
          error_message: '',
          // Add default values if any
        },
      };
      setPages(updatedPages);
      resetForm();
      handleSave(updatedPages);
    },
  });

  const handleRemovePage = (url: string) => {
    const updatedPages = { ...pages };
    delete updatedPages[url];
    setPages(updatedPages);
    handleSave(updatedPages);
  };

  // Handle opening the options menu
  const handleMenuOpen = (url: string, event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorEls({ ...anchorEls, [url]: event.currentTarget });
  };

  const handleMenuClose = (url: string) => {
    setAnchorEls({ ...anchorEls, [url]: null });
  };

  const handleEditSchedule = (url: string) => {
    setEditingScheduleUrl(url);
    setIsSchedulerOpen(true);
    handleMenuClose(url);
  };

  const handleScheduleSave = (schedule: string) => {
    const updatedPages = { ...pages };
    if (editingScheduleUrl === 'bulk') {
      selectedPages.forEach((url) => {
        updatedPages[url] = {
          ...updatedPages[url],
          schedule: schedule || undefined,
        };
      });
    } else {
      updatedPages[editingScheduleUrl] = {
        ...updatedPages[editingScheduleUrl],
        schedule: schedule || undefined,
      };
    }
    setPages(updatedPages);
    setSelectedPages([]);
    setSelectAll(false);
    setIsEditing(false);
    setIsSchedulerOpen(false);
    handleSave(updatedPages);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const allSelected = event.target.checked;
    setSelectAll(allSelected);
    setSelectedPages(allSelected ? Object.keys(pages) : []);
  };

  const handlePageCheckboxChange = (url: string) => {
    setSelectedPages((prevSelected) =>
      prevSelected.includes(url)
        ? prevSelected.filter((u) => u !== url)
        : [...prevSelected, url]
    );
  };

  const handleBulkDelete = () => {
    const updatedPages = { ...pages };
    selectedPages.forEach((url) => {
      delete updatedPages[url];
    });
    setPages(updatedPages);
    setSelectedPages([]);
    setSelectAll(false);
    handleSave(updatedPages);
  };

  const handleBulkSchedule = () => {
    setEditingScheduleUrl('bulk');
    setIsSchedulerOpen(true);
  };

  const hasUnsavedChanges = React.useMemo(() => {
    return JSON.stringify(pages) !== JSON.stringify(initialPages);
  }, [pages, initialPages]);

  // Function to determine color based on status
  const getUrlColor = (status: number | null) => {
    if (!status) {
      return theme.palette.text.primary;
    } else if (status >= 200 && status < 300) {
      return theme.palette.success.main;
    } else {
      return theme.palette.error.main;
    }
  };

  // Add this custom handleBlur function
  const handleBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    formik.handleBlur(e); // Retain Formik's default onBlur behavior
    await formik.validateField('newPageUrl'); // Validate the specific field
    if (formik.values.newPageUrl && !formik.errors.newPageUrl) {
      formik.handleSubmit(); // Submit the form if no errors
    }
  };

  return (
    <Box mt={2}>
      <Divider>
        <Badge color="info" variant="dot" invisible={!hasUnsavedChanges}>
          <Button
            fullWidth
            onClick={() => setIsExpanded(!isExpanded)}
            startIcon={<DescriptionOutlinedIcon />}
            endIcon={isExpanded ? <ExpandLess /> : <ExpandMore />}
            sx={{ justifyContent: 'flex-start' }}
          >
            Import Pages
          </Button>
        </Badge>
      </Divider>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Box p={2}>
          <Typography variant="h6" gutterBottom>
            Add Web Pages to Your Assistant
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            By adding public web pages, you help your assistant provide better answers using information from those pages.
          </Typography>

          {/* Conditionally render 'Your Pages' title and edit button if pages exist */}
          {Object.keys(pages).length > 0 && (
            <Box display="flex" alignItems="center" mt={2}>
              <Typography variant="h5" gutterBottom>
                Your Pages
              </Typography>
              <IconButton onClick={() => setIsEditing(!isEditing)} size="small" sx={{ ml: 1 }}>
                <EditIcon />
              </IconButton>
            </Box>
          )}

          {/* Options panel */}
          {isEditing && (
            <Box display="flex" alignItems="center" mt={1}>
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAll}
                indeterminate={
                  selectedPages.length > 0 && selectedPages.length < Object.keys(pages).length
                }
                sx={{ mr: 1 }}
              />
              <Typography sx={{ mr: 2 }}>Select All</Typography>

              <Button
                onClick={handleBulkSchedule}
                startIcon={<ScheduleIcon />}
                disabled={selectedPages.length === 0}
                sx={{ mr: 1 }}
              >
                Schedule
              </Button>
              <Button
                onClick={handleBulkDelete}
                startIcon={<DeleteIcon />}
                color="secondary"
                disabled={selectedPages.length === 0}
              >
                Delete
              </Button>
            </Box>
          )}

          <List>
            {Object.keys(pages).map((url) => (
              <React.Fragment key={url}>
                <ListItem
                  alignItems="flex-start"
                  disableGutters
                  sx={{
                    paddingRight: '16px',
                    paddingLeft: isEditing ? '0' : '16px',
                    cursor: 'pointer',
                  }}
                  onClick={(event) => handleMenuOpen(url, event)}
                >
                  {isEditing && (
                    <Checkbox
                      checked={selectedPages.includes(url)}
                      onChange={(e) => {
                        e.stopPropagation();
                        handlePageCheckboxChange(url);
                      }}
                    />
                  )}

                  <ListItemText
                    primary={
                      <Tooltip
                        title={
                          <>
                            <Typography>Status Code: {pages[url]?.status || 'Unknown'}</Typography>
                            {pages[url]?.error_message && (
                              <Typography>Error: {pages[url].error_message}</Typography>
                            )}
                            {/* Add other info as needed */}
                          </>
                        }
                        arrow
                      >
                        <Typography
                          sx={{
                            wordBreak: 'break-all',
                            color: getUrlColor(pages[url]?.status),
                          }}
                        >
                          {url}
                        </Typography>
                      </Tooltip>
                    }
                    secondary={
                      <>
                        {/* Caption text with last_sync and next_sync_at */}
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ wordBreak: 'break-all' }}
                        >
                          Last synced:{' '}
                          {pages[url]?.last_sync
                            ? dateTimeFormatter(pages[url].last_sync, 'day_month_ordinal_humanized_time_period')
                            : 'Pending'}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ wordBreak: 'break-all' }}
                        >
                          Next sync:{' '}
                          {pages[url]?.next_sync_at
                            ? dateTimeFormatter(pages[url].next_sync_at, 'day_month_ordinal_humanized_time_period')
                            : 'Not scheduled'}
                        </Typography>
                      </>
                    }
                    sx={{ marginRight: 2 }}
                  />
                </ListItem>
                {/* Options Menu */}
                <Menu
                  anchorEl={anchorEls[url]}
                  open={Boolean(anchorEls[url])}
                  onClose={() => handleMenuClose(url)}
                >
                  <MenuItem onClick={() => handleEditSchedule(url)}>
                    <ScheduleIcon sx={{ mr: 1 }} /> Edit Schedule
                  </MenuItem>
                  <MenuItem onClick={() => handleRemovePage(url)}>
                    <DeleteIcon sx={{ mr: 1 }} /> Remove Page
                  </MenuItem>
                </Menu>
                <Divider />
              </React.Fragment>
            ))}
          </List>

          {/* Scheduler dialog */}
          {isSchedulerOpen && (
            <CronScheduler
              url={editingScheduleUrl === 'bulk' ? '' : editingScheduleUrl}
              currentSchedule={
                editingScheduleUrl === 'bulk'
                  ? ''
                  : pages[editingScheduleUrl]?.schedule || ''
              }
              onSave={handleScheduleSave}
              onClose={() => setIsSchedulerOpen(false)}
            />
          )}

          <form onSubmit={formik.handleSubmit}>
            <Box mt={2}>
              <TextField
                label="Add Page URL"
                name="newPageUrl"
                value={formik.values.newPageUrl}
                onChange={formik.handleChange}
                onBlur={handleBlur} // Use the custom handleBlur
                error={
                  Boolean(formik.errors.newPageUrl) &&
                  (formik.touched.newPageUrl || formik.submitCount > 0)
                }
                helperText={
                  (formik.touched.newPageUrl || formik.submitCount > 0) ? formik.errors.newPageUrl : ''
                }
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <IconButton
                      color="primary"
                      type="submit"
                      edge="end"
                    >
                      <AddIcon />
                    </IconButton>
                  ),
                }}
              />
            </Box>
            <FormHelperText sx={{ ml: 0 }}>
              Enter the full web address (URL) of a public page you want your assistant to learn from.
            </FormHelperText>
          </form>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              onClick={() => handleSave()}
              variant="contained"
              color="primary"
              disabled={!hasUnsavedChanges || saving}
            >
              {saving ? 'Saving...' : 'Save'}
            </Button>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default PagesForm; 
