import CloudinaryVideoTag from "../CloudinaryVideoTag";
import { Card } from "react-bootstrap";

const GalleryItem = ({ item, handleServiceDetail }) => {
  switch (item.itemable_type) {
    case "Video":
      return <div className="w-100 h-100" key="service-video" >
        <CloudinaryVideoTag
          cloudinary_public_id={item.video_public_id}
          onClick={handleServiceDetail}
        />
      </div>
    case "EmbedCode":
      return <iframe
        onClick={handleServiceDetail}
        key="service-embedcode"
        src={item.embed_params.url}
        title={item.embed_params.title}
        className="w-100 h-100"
        loading="lazy"
      />
    default:
      return <Card.Img
        key="service-image"
        variant="top"
        src={item?.itemable_url}
        onClick={handleServiceDetail}
        className="h-100"
      />
  }
}

const ServiceGalleryItem = ({ galleryItem, service, handleServiceDetail }) => {

  return (
    <>
      {galleryItem ?
        <div style={{height: "200px"}}>
          <GalleryItem
            item={galleryItem}
            handleServiceDetail={handleServiceDetail}
          />
        </div>
        : <div
          className="placeholder-image"
          style={{ backgroundImage: "url(" + service?.icon + ")" }}
          onClick={() => handleServiceDetail()}
        />}
    </>
  );
}

export default ServiceGalleryItem;
