import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { firstGalleryItemSerializer } from "src/libs/utils";
import BookingService from "src/services/booking_services";
import { BookingType, GalleryItem, InvoiceType, PhotographerAvailability, PhotographerType, RedirectionType, ReqAdminBooking, ReqAdminServiceBooking, ReqAvailabilityAlert, ReqBooking, ReqServiceBooking, ReqUpdateBooking, ServiceBookingInput } from "src/types/bookings";
import { ResServicesType } from "src/types/services";
import { BookingSettingsType } from "src/types/user";
import { serializeErrorMessage } from "src/utils";
import { SubscriptionFeaturesType } from 'src/types/user'

interface bookingState {
  addButtonDisable: boolean;
  bookingSettings: BookingSettingsType;
  isLoading: boolean;
  bookingError?: string;
  calendarError?: string;
  bookingServices: ResServicesType[];
  bookingServicesCount: number;
  selectedBookingService?: ResServicesType;
  galleryItems: GalleryItem[];
  firstGalleryItems: GalleryItem[];
  photographerAvailabilities: PhotographerAvailability[];
  booking?: BookingType;
  square_feet?: number;
  listing_price?: number;
  address?: string;
  propertyInfoFilled: boolean;
  selectedServices: number[];
  editPropertyInfo: string;
  redirect: RedirectionType;
  photographer: PhotographerType;
  showSquareFeet: boolean;
  showListingPrice: boolean;
  invoices: InvoiceType[];
  subscriptionFeatures: SubscriptionFeaturesType;
}

const initialState: bookingState = {
  addButtonDisable: false,
  bookingSettings: null,
  isLoading: false,
  bookingError: null,
  calendarError: null,
  bookingServices: [],
  bookingServicesCount: null,
  selectedBookingService: null,
  galleryItems: [],
  photographerAvailabilities: [],
  booking: null,
  square_feet: null,
  listing_price: null,
  address: null,
  propertyInfoFilled: false,
  selectedServices: [],
  editPropertyInfo: '',
  redirect: null,
  photographer: null,
  showSquareFeet: null,
  showListingPrice: null,
  firstGalleryItems: [],
  invoices: [],
  subscriptionFeatures: null
}

export const getAllBookingServices = createAsyncThunk(
  'services/booking/getAll',
  async (data: ServiceBookingInput, thunkAPI) => {
    try {
      const res = await BookingService.getAll(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue({message: message, error_code: error.response?.data?.error_code});
    }
  }
);

export const getAllAdminBookingServices = createAsyncThunk(
  'services/booking/getAll',
  async (data: ServiceBookingInput, thunkAPI) => {
    try {
      const res = await BookingService.getAllAdminServices(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue({message: message, error_code: error.response?.data?.error_code});
    }
  }
);

export const getBookingService = createAsyncThunk(
  'services/booking/get',
  async ({uuid, service_id}:{uuid: string; service_id: number}, thunkAPI) => {
    try {
      const res = await BookingService.get(uuid, service_id);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getgalleryItemsServices = createAsyncThunk(
  'services/booking/getgalleryItems',
  async (data: {uuid: string; service_id: number}, thunkAPI) => {
    try {
      const res = await BookingService.getGalleryItems(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPhotographerAvailabilities = createAsyncThunk(
  'services/booking/getPhotographerAvailabilities',
  async (data: ReqServiceBooking, thunkAPI) => {
    try {
      const res = await BookingService.getPhotographerAvailabilities(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAdminAvailabilities = createAsyncThunk(
  'admin/booking/getAdminAvailabilities',
  async (data: ReqAdminServiceBooking, thunkAPI) => {
    try {
      const res = await BookingService.getAdminAvailabilities(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPhotographer = createAsyncThunk(
  'services/booking/getPhotographer',
  async (data: {uuid: string}, thunkAPI) => {
    try {
      const res = await BookingService.getPhotographer(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const createBooking = createAsyncThunk(
  'services/booking/create',
  async (data: ReqBooking, thunkAPI) => {
    try {
      const res = await BookingService.create(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createAdminBooking = createAsyncThunk(
  'admin/booking/create',
  async (data: ReqAdminBooking, thunkAPI) => {
    try {
      const res = await BookingService.createAdminBooking(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateBooking = createAsyncThunk(
  'services/booking/update',
  async ({ data, booking_uuid }: { data: ReqUpdateBooking; booking_uuid: string }, thunkAPI) => {
    try {
      const res = await BookingService.update(data, booking_uuid);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getBookingSettings = createAsyncThunk(
  'services/booking/getBookingSettings',
  async (uuid: string, thunkAPI) => {
    try {
      const res = await BookingService.getBookingSettings(uuid);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const getBooking = createAsyncThunk(
  'services/booking/uuid',
  async (uuid: string, thunkAPI) => {
    try {
      const res = await BookingService.getBooking(uuid);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendCancelationEmail = createAsyncThunk(
  'services/booking/cancel',
  async (uuid: string, thunkAPI) => {
    try {
      const res = await BookingService.cancel(uuid);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const cancelBooking = createAsyncThunk(
  'services/booking/cancellation',
  async (cancel_booking_token: string, thunkAPI) => {
    try {
      const res = await BookingService.cancelBooking(cancel_booking_token);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const availabilityAlert = createAsyncThunk(
  'photographer/availabilityAlert',
  async (data: ReqAvailabilityAlert, thunkAPI) => {
    try {
      const res = await BookingService.availabilityAlert(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const calculateDistance = createAsyncThunk(
  'photographer/calculateDistance',
  async (data: {address: string, uuid: string}, thunkAPI) => {
    try {
      const res = await BookingService.calculateDistancePhotographer(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const calculateDistanceAdmin = createAsyncThunk(
  'admin/calculateDistance',
  async (data: {address: string, uuid: string}, thunkAPI) => {
    try {
      const res = await BookingService.calculateDistanceProvider(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getFirstGalleryItems = createAsyncThunk(
  'photographer/booking/firstGalleryItems',
  async (data: {uuid: string}, thunkAPI) => {
    try {
      const res = await BookingService.getFirstGalleryItems(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAdminGalleryItem = createAsyncThunk(
  'photographer/booking/firstGalleryItems',
  async (data: {uuid: string; service_id: number}, thunkAPI) => {
    try {
      const res = await BookingService.getAdminGalleryItem(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createStripeAccount = createAsyncThunk(
  'photographer/booking/createStripeAccount',
  async (data: { booking_uuid: String }, thunkAPI) => {
    try {
      const res = await BookingService.createStripeAccount(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const checkPaymentStatus = createAsyncThunk(
  'photographer/booking/checkPaymentStatus',
  async (data: { session_secret: String }, thunkAPI) => {
    try {
      const res = await BookingService.checkPaymentStatus(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const finalizeInvoice = createAsyncThunk(
  'photographer/booking/finalizeInvoice',
  async (data: { booking_uuid: String }, thunkAPI) => {
    try {
      const res = await BookingService.finalizeInvoice(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifiedInvoice = createAsyncThunk(
  'photographer/booking/verifiedFinalizeInvoice',
  async (booking_token: string, thunkAPI) => {
    try {
      const res = await BookingService.verifiedInvoice(booking_token);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const finalizeInvoiceMail = createAsyncThunk(
  'photographer/booking/finalizeInvoiceMail',
  async (uuid: string, thunkAPI) => {
    try {
      const res = await BookingService.finalizeInvoiceMail(uuid);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getInvoices = createAsyncThunk(
  'photographer/booking/getInvoices',
  async (data: { booking_uuid: String }, thunkAPI) => {
    try {
      const res = await BookingService.getInvoices(data);
      return res.data.all_invoices;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getProviderSubscription = createAsyncThunk(
  'scheduler/subscription_paywall_provider',
  async (data: {uuid: string}, thunkAPI) => {
    try {
      const res = await BookingService.getProviderSubscription(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const bookingSlice = createSlice({
  name: 'bookingServices',
  initialState,
  reducers: {
    setShowSquareFeet: (state, action) => {
      state.showSquareFeet = action.payload
    },
    setShowListingPrice: (state, action) => {
      state.showListingPrice = action.payload
    },
    setPropertyInfo: (state, action) => {
      state.address = action.payload.address
      state.square_feet = action.payload.square_feet
      state.listing_price = action.payload.listing_price
      state.propertyInfoFilled = action.payload.address && true
    },
    addSelectedServices:  (state, action) => {
      state.selectedServices = [...state.selectedServices, action.payload.id]
    },
    removeSelectedServices: (state, action) => {
      state.selectedServices = state.selectedServices.filter((item) => item != action.payload.id)
    },
    setSelectedBookingService: (state, action) => {
      state.selectedBookingService = action.payload
    },
    editButtonPropertyInfo: (state, action) => {
      state.editPropertyInfo = action.payload
    },
    setRedirection: (state, action) => {
      state.redirect = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBookingServices.fulfilled, (state, action) => {
        state.bookingServices = action.payload;
        state.bookingServicesCount = action.payload.length;
        state.isLoading = false;
        state.bookingError = null
        state.addButtonDisable = false;
      })
      .addCase(getAllBookingServices.pending, (state, action) => {
        state.isLoading = true;
        state.bookingError = null
        state.addButtonDisable = true;
      })
      .addCase(getAllBookingServices.rejected, (state, action) => {
        let error = action.payload as any
        state.isLoading = false;
        state.bookingServices = []
        state.bookingServicesCount = 0
        if (error.error_code === "calendar_not_connected"){
          state.calendarError = error.message
        }else{ state.bookingError = error.message }
        state.addButtonDisable = true;
      })
      .addCase(getBookingService.fulfilled, (state, action) => {
        state.selectedBookingService = action.payload;
        state.isLoading = false;
        state.bookingError = null
      })
      .addCase(getBookingService.pending, (state, action) => {
        state.isLoading = true;
        state.bookingError = null
      })
      .addCase(getBookingService.rejected, (state, action) => {
        state.isLoading = false;
        state.selectedBookingService = null
        state.bookingError = action.payload as string
      })
      .addCase(getgalleryItemsServices.fulfilled, (state, action) => {
        var services = state.bookingServices
        var index = services.findIndex((s) => {
          return s.id === action.payload[0]?.galleriable_id
        });

        // var service = Object.assign({}, services[index]);
        if (index >= 0) {
          services[index].galleryItems = action.payload
          services[index].galleryLoading = false
        }
        // var photos = Object.assign([],photos);


        // state.galleryItems = action.payload
        state.galleryItems = []
        state.isLoading = false;
        state.bookingError = null
      })
      .addCase(getgalleryItemsServices.pending, (state, action) => {
        state.isLoading = true;
        state.bookingError = null
      })
      .addCase(getgalleryItemsServices.rejected, (state, action) => {
        state.galleryItems = []
        state.isLoading = false;
        state.bookingError = action.payload as string
      })
      .addCase(getPhotographerAvailabilities.fulfilled, (state, action) => {
        state.photographerAvailabilities = action.payload.available_time_slots
        state.isLoading = false;
        state.bookingError = null
      })
      .addCase(getPhotographerAvailabilities.pending, (state, action) => {
        state.isLoading = true;
        state.bookingError = null
      })
      .addCase(getPhotographerAvailabilities.rejected, (state, action) => {
        state.photographerAvailabilities = []
        state.isLoading = false;
        state.bookingError = action.payload as string
      })
      .addCase(getPhotographer.fulfilled, (state, action) => {
        state.photographer = action.payload
        state.isLoading = false;
        state.bookingError = null
      })
      .addCase(getPhotographer.pending, (state, action) => {
        state.isLoading = true;
        state.bookingError = null
      })
      .addCase(getPhotographer.rejected, (state, action) => {
        state.isLoading = false;
        state.bookingError = action.payload as string
      })
      .addCase(createBooking.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bookingError = null
      })
      .addCase(createBooking.pending, (state, action) => {
        state.isLoading = true;
        state.bookingError = null
      })
      .addCase(createBooking.rejected, (state, action) => {
        state.booking = null
        state.isLoading = false;
        state.bookingError = action.payload as string
      })
      .addCase(getBookingSettings.fulfilled, (state, action) => {
        state.bookingSettings = action.payload
        state.isLoading = false;
        // state.bookingError = null
      })
      .addCase(getBookingSettings.pending, (state, action) => {
        state.isLoading = true;
        // state.bookingError = null
      })
      .addCase(getBookingSettings.rejected, (state, action) => {
        state.bookingSettings = null
        state.isLoading = false;
        state.bookingError = action.payload as string
      })
      .addCase(getBooking.fulfilled, (state, action) => {
        state.booking = action.payload
        state.isLoading = false;
        state.bookingError = null
      })
      .addCase(getBooking.pending, (state, action) => {
        state.isLoading = true;
        state.bookingError = null
      })
      .addCase(getBooking.rejected, (state, action) => {
        state.booking = null
        state.isLoading = false;
        state.bookingError = action.payload as string
      })
      .addCase(sendCancelationEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bookingError = null
      })
      .addCase(sendCancelationEmail.pending, (state, action) => {
        state.isLoading = true;
        state.bookingError = null
      })
      .addCase(sendCancelationEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.bookingError = action.payload as string
      })
      .addCase(cancelBooking.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bookingError = null
      })
      .addCase(cancelBooking.pending, (state, action) => {
        state.isLoading = true;
        state.bookingError = null
      })
      .addCase(cancelBooking.rejected, (state, action) => {
        state.isLoading = false;
        state.bookingError = action.payload as string
      })
      .addCase(availabilityAlert.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bookingError = null
      })
      .addCase(availabilityAlert.pending, (state, action) => {
        state.isLoading = true;
        state.bookingError = null
      })
      .addCase(availabilityAlert.rejected, (state, action) => {
        state.isLoading = false;
        state.bookingError = action.payload as string
      })
      .addCase(calculateDistance.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bookingError = null
      })
      .addCase(calculateDistance.pending, (state, action) => {
        state.isLoading = true;
        state.bookingError = null
      })
      .addCase(calculateDistance.rejected, (state, action) => {
        state.isLoading = false;
        state.bookingError = action.payload as string
      })
      .addCase(getFirstGalleryItems.fulfilled, (state, action) => {
        state.firstGalleryItems = firstGalleryItemSerializer(action.payload)
        state.isLoading = false;
        state.bookingError = null
      })
      .addCase(getFirstGalleryItems.pending, (state, action) => {
        state.isLoading = true;
        state.bookingError = null
      })
      .addCase(getFirstGalleryItems.rejected, (state, action) => {
        state.isLoading = false;
        state.bookingError = action.payload as string
      })
      .addCase(createStripeAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bookingError = null
      })
      .addCase(createStripeAccount.pending, (state, action) => {
        state.isLoading = true;
        state.bookingError = null
      })
      .addCase(createStripeAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.bookingError = action.payload as string
      })
      .addCase(checkPaymentStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bookingError = null
      })
      .addCase(checkPaymentStatus.pending, (state, action) => {
        state.isLoading = true;
        state.bookingError = null
      })
      .addCase(checkPaymentStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.bookingError = action.payload as string
      })
      .addCase(finalizeInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bookingError = null
      })
      .addCase(finalizeInvoice.pending, (state, action) => {
        state.isLoading = true;
        state.bookingError = null
      })
      .addCase(finalizeInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.bookingError = action.payload as string
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.invoices = action.payload;
        state.bookingError = null
      })
      .addCase(getInvoices.pending, (state, action) => {
        state.bookingError = null
      })
      .addCase(getInvoices.rejected, (state, action) => {
        state.invoices = []
        state.bookingError = action.payload as string
      })
      .addCase(updateBooking.fulfilled, (state, action) => {
        state.booking = action.payload;
        state.bookingError = null
        state.isLoading = false
      })
      .addCase(updateBooking.pending, (state, action) => {
        state.isLoading = true;
        state.bookingError = null
      })
      .addCase(updateBooking.rejected, (state, action) => {
        state.bookingError = action.payload as string
        state.isLoading = false
      })
      .addCase(finalizeInvoiceMail.fulfilled, (state, action) => {
        state.bookingError = null
        state.isLoading = false
      })
      .addCase(finalizeInvoiceMail.pending, (state, action) => {
        state.isLoading = true;
        state.bookingError = null
      })
      .addCase(finalizeInvoiceMail.rejected, (state, action) => {
        state.bookingError = action.payload as string
        state.isLoading = false
      })
      .addCase(verifiedInvoice.fulfilled, (state, action) => {
        state.bookingError = null
        state.isLoading = false
      })
      .addCase(verifiedInvoice.pending, (state, action) => {
        state.isLoading = true;
        state.bookingError = null
      })
      .addCase(verifiedInvoice.rejected, (state, action) => {
        state.bookingError = action.payload as string
        state.isLoading = false
      })
      .addCase(getProviderSubscription.fulfilled, (state, action) => {
        state.subscriptionFeatures = action.payload;
        state.isLoading = false;
        state.bookingError = null;
      })
      .addCase(getProviderSubscription.pending, (state, _) => {
        state.isLoading = true;
        state.bookingError = null
      })
      .addCase(getProviderSubscription.rejected, (state, action) => {
        state.subscriptionFeatures = null;
        state.isLoading = false;
        state.bookingError = action.payload as string;
      });
  },
});

export const { setShowSquareFeet, setShowListingPrice, setRedirection, setPropertyInfo, addSelectedServices, removeSelectedServices, setSelectedBookingService, editButtonPropertyInfo } = bookingSlice.actions
const bookingReducer = bookingSlice.reducer;
export default bookingReducer;
