import { Card } from 'react-bootstrap';
import { Button, Carousel } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'src/store';
import { addSelectedServices, removeSelectedServices, setSelectedBookingService } from 'src/features/services/booking.slice';
import NumberFormat from 'react-number-format';
import { trackBookingAnalytics } from 'src/features/analytics/analytics.slice';
import { useParams } from 'react-router-dom';
import ServiceGalleryItem from 'src/components/ServiceDetail/ServiceGalleryItem';
import { getgalleryItemsServices } from 'src/features/services/booking.slice';
import { useEffect, useState } from 'react';
import PreviewGalleryItem from 'src/components/ServiceDetail/PreviewGalleryItem';
import Lottie from 'react-lottie';
import * as animationData from 'src/assets/lottie/loading-dots-bouncing.json'
import { Skeleton } from '@mui/material';

const BookingItem = ({
    bookingInfo,
    setShow,
    recallGallery,
    setShowFullView,
    propertyInfoFilled,
    selectedServices,
    handleErrorPopup,
    addButtonDisable,
    } )=> {
  let { uuid } = useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true)

  const galleryItems = bookingInfo.galleryItems || []

  useEffect(()=>{
    dispatch(getgalleryItemsServices({uuid, service_id: bookingInfo.id})).then(() => setLoading(false))
  }, [recallGallery])


  const handleServiceDetail = () => {
    dispatch(setSelectedBookingService(bookingInfo));
    setShow(false);
    setShowFullView(true)
  };

  const handleAddToCart = () => {
    dispatch(trackBookingAnalytics({action_name: "add_to_cart", payload: { description: `user added service with id ${bookingInfo.id} to cart`, service_id: bookingInfo.id, uuid: uuid }}))
    dispatch(addSelectedServices(bookingInfo))
  };

  const handleRemove = () => {
    dispatch(trackBookingAnalytics({action_name: "remove_from_cart", payload: { description: `user removed service with id ${bookingInfo.id} from cart`, service_id: bookingInfo.id, uuid: uuid }}))
    dispatch(removeSelectedServices(bookingInfo))
  };

  return (
    <>
        <Card className="service-card">
          <div className="photo-gallery">
            {loading ?
              <Skeleton variant="rectangular" width="100%" height={300} />
              :
              (galleryItems.length > 0 ?
                <Carousel onClick={handleServiceDetail}>
                  {galleryItems.map((galleryItem, index) => (
                    <Carousel.Item key={index}>
                      <div style={{ height: "300px" }}>
                        <PreviewGalleryItem item={galleryItem} />
                      </div>
                    </Carousel.Item>
                  ))}
                </Carousel>
                :
                <div className="placeholder-image" style={{ backgroundImage: "url(" + bookingInfo?.icon + ")", height: "300px" }} />
              )}
          </div>
            {/* <ServiceGalleryItem
              galleryItem={firstGalleryItem}
              service={bookingInfo}
              handleServiceDetail={handleServiceDetail}
            /> */}
            <Card.Body>
            <div className="service-title-subtitle" onClick={handleServiceDetail}>
                <span className="fw-bold fs-14 text-truncate">
                  {bookingInfo.name}
                </span>
                <p className="fs-14 fw-medium-light lh-1 text-secondary">
                  {bookingInfo.tagline}
                </p>
            </div>
            {/* <img className="mini-icon" style={{marginRight: "-0px"}} src={bookingInfo?.icon} alt="" /> */}
            <div className="d-flex justify-content-between align-items-center">
                <div className="fs-14" style={{ overflowWrap: 'anywhere' }}>
                {propertyInfoFilled &&
                <span onClick={handleServiceDetail}>
                  <span className="fw-bold"> <NumberFormat value={bookingInfo.price} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
                </span>
                }
                </div>
                { selectedServices.includes(bookingInfo.id) ? (
                <div
                className="d-flex align-items-baseline text-blue-added fw-medium"
                onClick={ () => { handleRemove() }}
                >
                    <img src="/icon/check.svg" alt="" className="me-2" />
                    Added
                </div>
                ) : (
                <Button
                    className="add-btn fw-medium px-3"
                    size="sm"
                    variant="outline-dark"
                    disabled={addButtonDisable}
                    onClick={ () => { propertyInfoFilled? handleAddToCart() :  handleErrorPopup("", bookingInfo) } }
                >
                    <span>Add to Cart</span>
                </Button>
                )}
            </div>
            </Card.Body>
        </Card>
    </>
  );
};

export default BookingItem;
