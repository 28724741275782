import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { connectPaymentProcessing, getPaymentCollection, updatePaymentCollection } from "src/features/user/user.slice";
import { useAppDispatch, useAppSelector } from "src/store";
import { useEffect, useState } from 'react';
import UpgradePlanPopup from '../Nav/UpgradePlanPopup';

const PaymentProcessingForm = () => {
  const dispatch = useAppDispatch();
  const { user, userLoading, paymentCollection } = useAppSelector((state) => state.user);
  const initialValues = {
    payment_collection_type: paymentCollection?.payment_collection_type
  };
  const { subscriptionFeatures } = useAppSelector((state) => state.auth)
  const [subscriptionPopupOpen, setSubscriptionPopupOpen] = useState(false)

  useEffect(() => {
    dispatch(getPaymentCollection())
  }, []);

  useEffect(() => {
    if (subscriptionFeatures && !subscriptionFeatures?.invoicing_paywalls_and_watermarks?.permitted) {
      setSubscriptionPopupOpen(true)
    }
  }, [subscriptionFeatures]);

  const onClick = () => {
    dispatch(connectPaymentProcessing()).unwrap().then((response) => {
      window.location.href = response.response.stripe_update_link
    })
  };

  const onSubmit = (data) => {
    dispatch(updatePaymentCollection(data))
  };

  return (
    <>
      <UpgradePlanPopup
        isOpen={subscriptionPopupOpen}
        onClose={() => setSubscriptionPopupOpen(false)}
        subscriptionFeatures={subscriptionFeatures}
      />
      <h2 className="mb-5">Payment Processing</h2>
      <div className='justify-content-between'>
        <Button variant="primary" onClick={onClick} disabled={ !subscriptionFeatures?.invoicing_paywalls_and_watermarks?.permitted || userLoading}>
          {paymentCollection?.has_stripe_account ? "Manage Stripe Account" : "Connect Payment Processing"}
        </Button>

        <Button variant="primary" className='ms-3' href='https://dashboard.stripe.com/' target='_blank' disabled={!subscriptionFeatures?.invoicing_paywalls_and_watermarks?.permitted}>
          Visit Stripe Dashboard
        </Button>
      </div>
      {
        paymentCollection?.has_stripe_account &&
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            resetForm,
            values,
            touched,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit} className="mt-3">
              <div className="mb-3">
                <Form.Label className="mb-2">
                  Payment Collection Type
                  <span className="text-danger"> *</span>
                </Form.Label>
                <Form.Check
                  type="radio"
                  label="Charge Later"
                  id="charge_later"
                  value="charge_later"
                  name="payment_collection_type"
                  disabled={ !subscriptionFeatures?.invoicing_paywalls_and_watermarks?.permitted || userLoading}
                  onChange={(e) => {
                    setFieldValue('payment_collection_type', "charge_later")
                    onSubmit({ payment_collection_type: "charge_later" })
                  }}
                  checked={values.payment_collection_type === "charge_later"}
                />
                <Form.Check
                  type="radio"
                  name="payment_collection_type"
                  label="Charge Upon Booking"
                  value="charge_upon_booking"
                  id="charge_upon_booking"
                  disabled={ !subscriptionFeatures?.invoicing_paywalls_and_watermarks?.permitted || userLoading}
                  onChange={(e) => {
                    setFieldValue('payment_collection_type', "charge_upon_booking")
                    onSubmit({ payment_collection_type: "charge_upon_booking" })
                  }}
                  checked={values.payment_collection_type === "charge_upon_booking"}
                />
                <Form.Check
                  type="radio"
                  name="payment_collection_type"
                  label="Never Charge"
                  value="never_charge"
                  id="never_charge"
                  disabled={!subscriptionFeatures?.invoicing_paywalls_and_watermarks?.permitted || userLoading}
                  onChange={(e) => {
                    setFieldValue('payment_collection_type', "never_charge")
                    onSubmit({ payment_collection_type: "never_charge" })
                  }}
                  checked={values.payment_collection_type === "never_charge"}
                />
                <Form.Control.Feedback type="invalid">
                  <>{errors.payment_collection_type}</>
                </Form.Control.Feedback>
              </div>
            </Form>
          )}
        </Formik>
      }
    </>
  )
}

export default PaymentProcessingForm;
