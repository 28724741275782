import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import { updateChannel } from 'src/features/account/channels.slice';
import PagesForm from '../PagesForm';
import { Pages } from 'src/types/pages';
import { enqueueSnackbar } from 'notistack';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

interface ChannelPagesSettingsProps {
  channelId: string;
  open?: boolean;
  onClose?: () => void;
}

const ChannelPagesSettings: React.FC<ChannelPagesSettingsProps> = ({ channelId, open, onClose }) => {
  const dispatch = useAppDispatch();
  const channel = useAppSelector((state) => state.channels.entities[channelId]);

  const [pages, setPages] = useState<Pages>(channel?.pages ?? {});
  const [saving, setSaving] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [initialPages, setInitialPages] = useState<Pages>(pages);

  useEffect(() => {
    if (channel) {
      setPages(channel.pages ?? {});
      setInitialPages(channel.pages ?? {});
    }
  }, [channel]);

  const handleSave = async () => {
    try {
      setSaving(true);
      await dispatch(updateChannel({ channelData: { id: channelId, pages } })).unwrap();
      setInitialPages(pages);
      enqueueSnackbar('Pages updated successfully!', { variant: 'success' });
    } catch (error) {
      console.error('Failed to update pages:', error);
      enqueueSnackbar('Failed to update pages.', { variant: 'error' });
    } finally {
      setSaving(false);
    }
  };

  const content = (
    <PagesForm
      channelId={channelId}
    />
  );

  if (typeof open !== 'undefined' && typeof onClose !== 'undefined') {
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Manage Pages</DialogTitle>
        <DialogContent>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            Add public web pages to enhance your assistant's knowledge. Your assistant will use information from these pages to provide better answers.
          </Typography>
          {content}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return <div>{content}</div>;
  }
};

export default ChannelPagesSettings; 
