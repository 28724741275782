import { Form, Image } from 'react-bootstrap';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import WYSIWYGEditor from 'src/components/WYSIWYGEditor';


const GeneralServiceInfo = ({ editorState, setEditorState, values, touched, errors, handleChange, setFieldValue }) => {

  return (
    <>
      <div className="p-2">
        <Form.Group>
          <Form.Label htmlFor="name" className="fw-medium fs-14 mb-1 required">
            Name
          </Form.Label>
          <Form.Control
            id="name"
            type="text"
            name="name"
            value={values.name}
            onChange={handleChange}
            isValid={touched.name && !errors.name}
            isInvalid={touched.name && !!errors.name}
            className="mb-2"
          />
          <Form.Control.Feedback type="invalid">
            <>{errors.name}</>
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label
            htmlFor="shortDescription"
            className="fw-medium fs-14 mb-1 required"
          >
            Service Subtitle
          </Form.Label>
          <Form.Control
            id="shortDescription"
            type="text"
            name="tagline"
            value={values.tagline}
            onChange={handleChange}
            isValid={touched.tagline && !errors.tagline}
            isInvalid={touched.tagline && !!errors.tagline}
            className="mb-2"
            maxLength={40}
          />
          <Form.Control.Feedback type="invalid">
            <>{errors.tagline}</>
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label
            htmlFor="description"
            className="fs-14 required fw-medium mb-1"
          >
            Description
          </Form.Label>
          <WYSIWYGEditor
            initialValue={editorState}
            onChange={(e) => {
              setEditorState(e)
              setFieldValue("description", e)
            }}
            maxLength={6000}
          />
          <Form.Control.Feedback type="invalid">
            <>{errors.description}</>
          </Form.Control.Feedback>
        </Form.Group>
        {/* TODO review how to handle the icons selector */}
        <p className="fs-14 fw-medium mb-1 align-items-center ">Icon</p>
        <div className="mb-5">
          <p>This will appear as the icon for this service</p>
          <Image
            className="me-1 mb-3 d-block profile-picture"
            src={values.icon}
            width="40"
            height="40"
          />
          <div>
            <input
              type="file"
              id="icon"
              name="photo_attributes.file"
              accept="image/png, image/jpeg"
              onChange={(event) => {
                const reader = new FileReader();
                reader.readAsDataURL(event.currentTarget.files[0]);
                reader.onload = () => {
                  setFieldValue(
                    'photo_attributes.file',
                    reader.result
                  );
                }

                setFieldValue('icon', URL.createObjectURL(event.currentTarget.files[0]))
              }}
              style={{ visibility: 'hidden', display: 'none' }}
            />

            <label htmlFor="icon" className="me-3 link-inline">
              Change
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralServiceInfo;
