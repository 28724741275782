import * as Yup from 'yup';
import { validatePhoneNumber } from '.';

export const passwordrules =
  'required: lower; required: upper; required: digit; required: [!#$%&*?@^]; minlength: 8;';

export const loginSchema = Yup.object().shape({
  email: Yup.string().required('Email is required!').email('Email is invalid'),
  password: Yup.string().required('Password is required!'),
});

export const firstNameSchema = Yup.string()
  .required('First name is required')
  .min(1, 'First name must be at least 1 character');

export const lastNameSchema = Yup.string()
  .required('Last name is required')
  .min(1, 'Last name must be at least 1 character');

export const phoneSchema = Yup.string()
  .required('Enter a valid phone number')
  .test('phone', 'Must be a valid phone number', (value) =>
    value ? validatePhoneNumber(value) : false
  );

export const passwordSchema = Yup.string()
  .required('Required')
  .min(8, 'Must be at least 8 characters long');

export const passwordConfirmationSchema = Yup.string().when("password", {
  is: val => (val && val.length > 0 ? true : false),
  then: Yup.string().oneOf(
    [Yup.ref("password")],
    "Passwords should match"
  )
})

export const weekDaySchema = Yup.object().shape({
  day: Yup.string().required('Required'),
  enabled: Yup.boolean().required('Required'),
  start_time: Yup.string().nullable(true).when("enabled", {
    is: true,
    then: Yup.string().nullable(true).required('Required')
  }),
  end_time: Yup.string().nullable(true).when("enabled", {
    is: true,
    then: Yup.string().nullable(true).required('Required')
  }),
});

export const emailSchema = Yup.string()
  .required('Email is required!')
  .email('Email is invalid');

export const urlSchema = Yup.string()
  .matches(
    /((http|https):\/\/)[a-z0-9-]+(\.[a-z0-9-]+)+([\/?].*)?/,
    'Enter correct url!'
  )

export const resetPasswordTokenSchema = Yup.string()


export const forgotPasswordSchema = Yup.object().shape({
  email: emailSchema,
});

export const resetPasswordSchema = Yup.object().shape({
  resetPasswordToken: resetPasswordTokenSchema,
  password: passwordSchema,
  passwordConfirmation: passwordConfirmationSchema,
});


export const registrationSchema = Yup.object().shape({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  password: passwordSchema,
  acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required'),
  // phone: phoneSchema
});

export const contactSchema = Yup.object().shape({
  name: Yup.string(),
  phone_number: Yup.string().matches(/^[+]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{1,4}[-\s\.]?[0-9]{1,4}$/, 'Invalid phone number'),
  email: Yup.string().required('Email is required').email('Invalid email'),
  first_name: Yup.string(),
  last_name: Yup.string(),
  address: Yup.string(),
  notes: Yup.string(),
  metadata: Yup.mixed(),
  picture_url: Yup.string().matches(
    /^(http:\/\/|https:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/,
    'Invalid URL - must begin with http:// or https://'
  ),
  status: Yup.string(),
  preferred_contact_method: Yup.string(),
  company_name: Yup.string(),
  company_size: Yup.number().integer('Must be an integer'),
  company_industry: Yup.string(),
  company_website: Yup.string().url('Invalid URL'),
  company_address: Yup.string(),
  home_address: Yup.string(),
  tags: Yup.array().of(Yup.string()),
  timezone: Yup.string(),
});

export const servicePricingAttr = Yup.object().shape({
  pricing_methodology: Yup.string().required(),
  amount: Yup.number(),
  min_price: Yup.number(),
  service_pricing_tier_attributes: Yup.array().of(
    Yup.object().shape({
      amount: Yup.number().required(),
      lower_limit: Yup.number().required(),
      upper_limit: Yup.number().required(),
    })
  ),
});

export const addServiceSchema = Yup.object().shape({
  hidden: Yup.boolean(),
  name: Yup.string().required('Required'),
  tagline: Yup.string().when("hidden", {
    is: false,
    then: Yup.string().required('Required'),
  }),
  description: Yup.string().when("hidden", {
    is: false,
    then: Yup.string().required('Required'),
  }),
  slug: Yup.string(),
  fixed_time: Yup.number().when("hidden", {
    is: false,
    then: Yup.number()
    .min(0, "Please enter a number more than or equal to 0")
    .required('Required')
  }),
  time_per_k: Yup.number().when("hidden", {
    is: false,
    then: Yup.number()
    .min(0, "Please enter a number more than or equal to 0")
    .required('Required')
  }),
  turnaround_time_hours: Yup.number().when("hidden", {
    is: false,
    then: Yup.number().min(0, "Please enter a number more than or equal to 0"),
  }),
  turnaround_time_string: Yup.string(),
  service_pricing_attributes: Yup.object().when("hidden", {
    is: false,
    then: Yup.object().shape({
      pricing_methodology: Yup.string().required('Required'),
      amount: Yup.number().when("pricing_methodology", {
        is: (pricing_methodology) => pricing_methodology === 'flat_fee' || pricing_methodology === 'square_footage',
        then: Yup.number().required('Required')
      }).
      moreThan(0, "Please enter a number more than 0"),
      min_price: Yup.number().when("pricing_methodology", {
        is: "square_footage",
        then: Yup.number().min(0, "Please enter a number more than or equal to 0")
      }),
      max_price: Yup.number().when("pricing_methodology", {
        is: "square_footage",
        then: Yup.number().when("min_price", {
          is: val => (val),
          then: Yup.number().min(Yup.ref("min_price"), "This should be greater than minimum charge")
        })
      }),
      service_pricing_tier_attributes: Yup.array().of(Yup.object().shape({}))
      .when("pricing_methodology", {
        is: (pricing_methodology) => pricing_methodology === 'tiered_price_fee' || pricing_methodology === 'tiered_sqft_fee',
        then: Yup.array().of(
          Yup.object().shape({
            amount: Yup.number().typeError('Required').required('Required'),
            lower_limit: Yup.number().typeError('Required').required('Required'),
            upper_limit: Yup.number().nullable(true).typeError('Required').moreThan(Yup.ref("lower_limit"), "This should be greater than lower limit "),
          })
        ),
      })
    }),
    otherwise: Yup.object().shape({
      pricing_methodology: Yup.string(),
      amount: Yup.number(),
      min_price: Yup.number().when("pricing_methodology", {
        is: "square_footage",
        then: Yup.number().min(0, "Please enter a number more than or equal to 0")
      }),
      max_price: Yup.number().when("pricing_methodology", {
        is: "square_footage",
        then: Yup.number().when("min_price", {
          is: val => (val),
          then: Yup.number().min(Yup.ref("min_price"), "This should be greater than minimum charge")
        })
      }),
      service_pricing_tier_attributes: Yup.array().of(Yup.object().shape({}))
        .when("pricing_methodology", {
          is: (pricing_methodology) => pricing_methodology === 'tiered_price_fee' || pricing_methodology === 'tiered_sqft_fee',
          then: Yup.array().of(
            Yup.object().shape({
              amount: Yup.number().nullable(true),
              lower_limit: Yup.number().typeError('Required').required('Required'),
              upper_limit: Yup.number().nullable(true).typeError('Required').moreThan(Yup.ref("lower_limit"), "This should be greater than lower limit "),
            })
          ),
        })
    })
  }),
});

export const locationSchema = Yup.object().shape({
  location: Yup.string().required('Required'),
});

export const accountSchema = Yup.object().shape({
  first_name: firstNameSchema,
  last_name: lastNameSchema,
  timezone: Yup.string().required('Required'),
});

export const locationAndTravelSchema = Yup.object().shape({
  formatted_address: Yup.string().required('Required'),
  photographer_willing_to_surchage_travel: Yup.boolean().required('Required'),
  appointment_buffer: Yup.number().min(0, "Please enter a number more than or equal to 0"),
  photographer_surcharge_travel_distance: Yup.number().
  min(6, "Please enter a number more than or equal to 6").
  required('Required'),
  photographer_base_travel_distance: Yup.number().when("photographer_willing_to_surchage_travel", {
    is: true,
    then: Yup.number().
    min(0, "Please enter a number more than or equal to 0").
    required('Required')
  }),
  max_drive_time: Yup.number().required('Required'),
  photographer_travel_surcharge: Yup.number().when("photographer_willing_to_surchage_travel", {
    is: true,
    then: Yup.number().
    min(0, "Please enter a number more than or equal to 0").
  required('Required')
  }),
  snappiness_filter_max: Yup.number().min(0, "Please enter a number more than 0").max(100, "Maximum Snappiness can be 100")
});

export const availabilitySchema = Yup.object().shape({
  lead_time: Yup.number().min(1, "This should be between 1 to 24").max(24, "This should be between 1 to 24").required('Required'),
  monday: weekDaySchema,
  tuesday: weekDaySchema,
  wednesday: weekDaySchema,
  thursday: weekDaySchema,
  friday: weekDaySchema,
  saturday: weekDaySchema,
  sunday: weekDaySchema,
});

export const bookingSettingsSchema = Yup.object().shape({
  confirmation_message: Yup.string(),
  confirmation_video_url: urlSchema,
  confirmation_policy: Yup.string(),
});

export const userCalendarSchema = Yup.object().shape({
  calendar_id: Yup.string().required('Select one of the calendar'),
});

export const propertyInfoSchema = Yup.object().shape({
  address: Yup.string().required('Required'),
  show_listing_price: Yup.boolean(),
  listing_price: Yup.number().when("show_listing_price", {
    is: true,
    then: Yup.number().required('Required')
  }),
  show_square_feet: Yup.boolean(),
  square_feet: Yup.number().when("show_square_feet", {
    is: true,
    then: Yup.number().required('Required')
  }),
});

export const userCheckoutSchema = Yup.object().shape({
  created_by_first_name: Yup.string().required('Required'),
  created_by_last_name: Yup.string().required('Required'),
  created_by_phone: phoneSchema,
  created_by_email: Yup.string().required('Required'),
  client_notes: Yup.string(),
});

export const waitlistCustomerSchema =  Yup.object().shape({
  email: emailSchema,
});

export const availabilityAlertSchema =  Yup.object().shape({
  name: Yup.string().required('Required'),
  phone_number: phoneSchema,
  email: emailSchema,
});

export const changePasswordSchema =  Yup.object().shape({
  current_password: Yup.string().required('Old password is required!'),
  password: passwordSchema,
  password_confirmation: Yup.string().required('Confirm password is required!').oneOf([Yup.ref('password'), null], 'Passwords must match')
});

export const UpdateEmailSchema = Yup.object().shape({
  email: emailSchema,
});

export const embedFormSchema =  Yup.object().shape({
  title: Yup.string().required('Required'),
  url: urlSchema.required('Required')
});

export const adminBookingSchema = Yup.object().shape({
  address: Yup.string().required('Required'),
  show_listing_price: Yup.boolean(),
  listing_price: Yup.number().when("show_listing_price", {
    is: true,
    then: Yup.number().required('Required')
  }),
  show_square_feet: Yup.boolean(),
  square_feet: Yup.number().when("show_square_feet", {
    is: true,
    then: Yup.number().required('Required')
  }),
  duration: Yup.number().min(0, "Please enter a number more than or equal to 0").required('Required'),
  created_by_first_name: Yup.string().required('Required'),
  created_by_last_name: Yup.string().required('Required'),
  created_by_phone: phoneSchema,
  created_by_email: Yup.string().required('Required'),
});
