import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getgalleryItemsServices } from 'src/features/services/booking.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import ErrorPopup from '../ErrorPopup';
import PreviewService from '../ServiceDetail/PreviewService';
import FullGalleryView from '../ServiceDetail/FullGalleryView';

const DetailedService = ({ handleAddToCart, showFullView, setShowFullView, uuid, closeServiceDetail, showServiceDetail, handleErrorPopup }) => {

  const { bookingError, selectedBookingService } = useAppSelector((state) => state.bookingServices);
  const [galleryItems, setGalleryItems] = useState([]);
  const handleFullGalleryView = () => {
    setShowFullView(!showFullView)
  };
  const dispatch = useAppDispatch();
  const darkMode = useAppSelector( state => state.theme.darkMode )

  useEffect(() => {
    if (selectedBookingService) {
      dispatch(getgalleryItemsServices({ uuid, service_id: selectedBookingService.id }))
        .unwrap().then((response) => { setGalleryItems(response) })
    }
  }, [selectedBookingService])

  return (
    <>
      <FullGalleryView
        handleAddToCart={handleAddToCart}
        addButtonDisable={false}
        showView={showFullView}
        closeView={handleFullGalleryView}
        isPropertyInfoUpdated={true}
        selectedBookingService={selectedBookingService}
        selectedServices={[]}
        handleErrorPopup={handleErrorPopup}
        galleryItems={galleryItems}
      />
      <Modal
        backdrop="static"
        keyboard={false}
        dialogClassName="medium-modal"
        show={showServiceDetail}
        fullscreen={'sm-down'}
        contentClassName="pt-0"
        onHide={closeServiceDetail}
      >
        <Modal.Header closeButton>
          <div
            className="py-2 px-3 fw-medium fs-14 go-back"
            role="button"
            onClick={closeServiceDetail}
          >
            <img src={ darkMode ? "/icon/backLight.svg" : "/icon/back.svg" } alt="" />
            &nbsp; Back
          </div>
        </Modal.Header>

        <Modal.Body className="pt-0">
          {bookingError && <ErrorPopup message={bookingError} />}
          <PreviewService
            handleAddToCart={handleAddToCart}
            handleRemove={() => { }}
            addButtonDisable={false}
            service={selectedBookingService}
            galleryItems={galleryItems}
            selectedServices={[]}
            handleFullGalleryView={handleFullGalleryView}
            handleErrorPopup={handleErrorPopup}
            propertyInfoFilled={true}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DetailedService;
