import React, { useState, useCallback } from 'react';
import { Card, CardContent, Typography, IconButton, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import NoteForm from './NoteForm';
import { Note } from 'src/features/notes/notes.slice';

interface NoteItemProps {
  note: Note;
  onUpdate: (noteId: string, updatedData: any) => void;
  onDelete: (noteId: string) => void;
}

const NoteItem: React.FC<NoteItemProps> = ({ note, onUpdate, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleUpdate = async (updatedNote: any) => {
    await onUpdate(note.id, updatedNote);
    setIsEditing(false);
  };

  const handleCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  return (
    <Card style={{ marginBottom: '1rem' }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">{note.subject}</Typography>
          <Box>
            <IconButton onClick={() => setIsEditing(!isEditing)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => onDelete(note.id)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
        <Typography variant="body2" color="textSecondary">
          {new Date(note.created_at).toLocaleString()}
        </Typography>
        {!isEditing ? (
          <Typography variant="body1" style={{ marginTop: '1rem' }}>
            {note.body}
          </Typography>
        ) : (
          <NoteForm
            note={note}
            onSave={handleUpdate}
            onCancel={handleCancelEdit}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default NoteItem;
