// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Link } from 'react-router-dom';
import GoBackDashboard from './GoBackDashboard';

interface Props {
  activePage: string;
  screenWidth?: number;
  className?: string;
}

const SideBar = ({ screenWidth, activePage }: Props) => {
  let threshold = 750;
  return (
    <div className="col-md-3 col-lg-2 d-md-block bg-light border-right pl-5">
      <div className="d-flex align-items-start flex-column">
        <div className=" mt-4">
          <GoBackDashboard />
        </div>

        {(!screenWidth || screenWidth > threshold) && (
          <div>
            <div className="fs-18 pt-4 fw-medium">
              {/*
              <SettingsOutlinedIcon sx={{ fontSize: 30 }} className="pr-1" />
            */}
              Booking Settings
            </div>
            <div className="sidebar-sticky pt-3 pl-3">
              <ul className="nav d-flex align-items-start flex-column">
                <Link to="/account" className="removeLinkClass">
                  <li
                    className={
                      activePage && activePage === 'account'
                        ? 'activePage fs-16 fw-medium-light'
                        : 'fs-16 fw-medium-light removeLinkClass'
                    }
                  >
                    Account
                  </li>
                </Link>
                <Link to="/location" className="removeLinkClass">
                  <li
                    className={
                      activePage && activePage === 'location'
                        ? 'activePage pt-3 fs-16 fw-medium-light'
                        : 'pt-3 fs-16 fw-medium-light'
                    }
                  >
                    Location
                  </li>
                </Link>
                <Link to="/travel" className="removeLinkClass">
                  <li
                    className={
                      activePage && activePage === 'travel'
                        ? 'activePage pt-3 fs-16 fw-medium-light'
                        : 'pt-3 fs-16 fw-medium-light'
                    }
                  >
                    Travel Preferences
                  </li>
                </Link>
                <Link to="/confirmation" className="removeLinkClass">
                  <li
                    className={
                      activePage && activePage === 'confirmation'
                        ? 'activePage pt-3 fs-16 fw-medium-light'
                        : 'pt-3 fs-16 fw-medium-light'
                    }
                  >
                    Confirmation Message
                  </li>
                </Link>
                <Link to="/availability" className="removeLinkClass">
                  <li
                    className={
                      activePage && activePage === 'availability'
                        ? 'activePage pt-3 fs-16 fw-medium-light'
                        : 'pt-3 fs-16 fw-medium-light'
                    }
                  >
                    Booking Availability
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideBar;
