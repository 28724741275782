import React, { useState, useRef, useEffect } from 'react';
import { TextField, Divider, Box, Typography } from '@mui/material';

export const OtpField = ({
  length = 6,
  error = '',
  loading = false,
  phoneNumber,
  onSubmit = (otp, phone) => {},
  onSendAgain = (phone) => {},
}) => {
  const [otp, setOtp] = useState(Array(length).fill(''));
  const inputRefs = useRef(Array(length).fill(null));
  const [errors, setErrors] = useState(error);
  const [showSendAgain, setShowSendAgain] = useState(true);
  const [countdown, setCountdown] = useState(60);

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  useEffect(() => {
    setErrors(error);
  }, [error]);

  useEffect(() => {
    if (otp.join('').length === length) {
      onSubmit(otp.join(''), phoneNumber);
    }
  }, [otp]);

  const handleInput = (e, index) => {
    const value = e.target.value;
    if (!Number.isInteger(Number(value))) return;
    setErrors(null);
    const newOtp = [...otp];

    if (value === '' && index > 0) {
      newOtp[index] = '';
      inputRefs.current[index - 1].focus();
      inputRefs.current[index - 1].select();
    } else {
      newOtp[index] = value;
      if (value !== '' && index < length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }

    setOtp(newOtp);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && e.target.value === '') {
      e.preventDefault();
      inputRefs.current[index - 1].focus();
      inputRefs.current[index - 1].select();
    }
  };

  const handlePaste = (e, index) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    if (pastedData) {
      const newOtp = [...otp];
      let currentIndex = index;
      for (let i = 0; i < pastedData.length && currentIndex < length; i++) {
        newOtp[currentIndex] = pastedData[i];
        currentIndex++;
      }
      setOtp(newOtp);
    }
  };

  const handleSendAgain = () => {
    if (showSendAgain) {
      onSendAgain(phoneNumber);
      setShowSendAgain(false);
      setOtp(Array(length).fill(''));

      let timer = countdown;
      const countdownInterval = setInterval(() => {
        if (timer === 0) {
          setShowSendAgain(true);
          clearInterval(countdownInterval);
          setCountdown(60);
        } else {
          setCountdown((prevCountdown) => prevCountdown - 1);
          timer--;
        }
      }, 1000);
    }
  };

  return (
    <>
      <Box style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        {otp.map((value, index) => (
          <React.Fragment key={index}>
            <div className="hideArrows">
              <TextField
                error={errors?.length > 0}
                type='number'
                variant='outlined'
                value={value}
                inputRef={(ref) => (inputRefs.current[index] = ref)}
                onChange={(e) => handleInput(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onPaste={(e) => handlePaste(e, index)}
                inputProps={{
                  maxLength: 1,
                }}
                sx={{
                  width: '40px',
                  '& fieldset': {
                    borderRadius: '4px',
                    textAlign: 'center',
                  },
                  '& input': {
                    color: errors?.length > 0 ? 'text.error' : 'text.primary',
                    height: '17px',
                    fontSize: '20px',
                    fontWeight: 400,
                    outlineWidth: 10,
                    letterSpacing: '0.15px',
                    textAlign: 'center',
                  },
                }}
              />
            </div>
            {index === length / 2 - 1 && <Divider sx={{ width: 5, border: 1 }} />}
          </React.Fragment>
        ))}
      </Box>
      {errors?.length > 0 && (
        <Typography variant='subtitle1' color='error' sx={{ cursor: 'pointer' }}>
          {errors}
        </Typography>
      )}
      {showSendAgain ? (
        <Typography
          variant='subtitle2'
          color='primary'
          sx={{ cursor: 'pointer' }}
          onClick={handleSendAgain}
        >
          Send again
        </Typography>
      ) : (
        <Typography variant='subtitle1' color='text.secondary'>
          {loading ? 'Sending...' : `Send again in ${countdown}s`}
        </Typography>
      )}
    </>
  );
};
