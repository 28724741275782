import { Card } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { useAppDispatch } from 'src/store';
import { setSelectedBookingService } from 'src/features/services/booking.slice';
import NumberFormat from 'react-number-format';
import ServiceGalleryItem from '../ServiceDetail/ServiceGalleryItem';

const SuggestedServiceItem = ({
  firstGalleryItem,
  service,
  setShowServiceDetail,
  addServiceToBooking,
}) => {
  const dispatch = useAppDispatch();

  const handelServiceDetail = () => {
    dispatch(setSelectedBookingService(service));
    setShowServiceDetail(true);
  };

  return (
    <>
      <Card className="service-card">
        <ServiceGalleryItem
          galleryItem={firstGalleryItem}
          service={service}
          handleServiceDetail={handelServiceDetail}
        />
        <Card.Body>
          <div className="service-title-subtitle" onClick={handelServiceDetail}>
            <span className="fw-bold fs-14 text-truncate">
              {service.name}
            </span>
            <p className="fs-12 fw-medium-light lh-1 text-secondary">
              {service.tagline}
            </p>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="fs-12" style={{ overflowWrap: 'anywhere' }}>
              <span>
                <span className="fw-bold"> <NumberFormat value={service.price} displayType={'text'} thousandSeparator={true} prefix={'$'} /></span>
              </span>
            </div>
            <Button
              className="add-btn fw-medium px-3"
              size="sm"
              variant="outline-dark"
              onClick={() => addServiceToBooking(service)}
            >
              <span>Add</span>
            </Button>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default SuggestedServiceItem;
