import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssistantIcon from '../ThemeElements/AssistantIcon';
import { useSnackbar } from 'notistack';
import TranscriptionMessage from './TranscriptionMessage';
import { useAppSelector } from 'src/store';

const LiveTranscriptionComponent = ({ latestTranscriptionChunk }) => {
  const [callState, setCallState] = useState('ringing');
  const [callDuration, setCallDuration] = useState(0);
  const [expanded, setExpanded] = useState(true);
  const { user } = useAppSelector((state) => state.user);
  const stringifiedUser = JSON.stringify(user);
  const systemMessage = {
    role: 'system',
    content: `<user_profile> ${stringifiedUser} </user_profile> You are a helpful assistant. You are currently on a call with the user in the profile. Help them with their questions and needs. Keep in mind, this is a phone transcript so it may not be 100% accurate and you should assume the users message for their phonetic values more than the absolute text. Ask for clarification if needed I.E something sounds strange or it sounds like they're saying or based on context you feel like they meant something else by saying I didn't catch that or I didn't get that or I was having trouble hearing but I think you said this so here's the result. Talk to them like a best friend who is also a helpful assistant.`,
  };

  const [loadingAssistant, setLoadingAssistant] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const messagesRef = useRef([]);
  const currentUserMessageRef = useRef({
    speaker: 'User',
    text: '',
    isFinal: false,
    startTime: new Date(),
  });

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => setCallState('transcribing'), 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    let interval;
    if (callState === 'transcribing') {
      interval = setInterval(() => setCallDuration((prev) => prev + 1), 1000);
    }
    return () => clearInterval(interval);
  }, [callState]);

  // Handle incoming transcript chunks
  useEffect(() => {
    if (!latestTranscriptionChunk) return;

    // Update currentUserMessageRef
    if (currentUserMessageRef.current.isFinal) {
      currentUserMessageRef.current = {
        speaker: 'User',
        text: latestTranscriptionChunk,
        isFinal: false,
        startTime: new Date(),
      };
    } else {
      currentUserMessageRef.current.text = currentUserMessageRef.current.text
        ? `${currentUserMessageRef.current.text} ${latestTranscriptionChunk}`
        : latestTranscriptionChunk;
    }

    // Log updated user message
    console.log('Current User Message:', currentUserMessageRef.current);
  }, [latestTranscriptionChunk]);

  // Monitor messages and trigger assistant response when user's message is finalized
  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage && lastMessage.speaker === 'User' && lastMessage.isFinal) {
      fetchAssistantResponse([...messages]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  // Function to fetch assistant response
  const fetchAssistantResponse = useCallback(
    async (allMessages) => {
      setLoadingAssistant(true);
      const combinedMessages = allMessages.map((msg) => ({
        role: msg.speaker === 'Assistant' ? 'assistant' : 'user',
        content: msg.text,
      }));

      try {
        const requestBody = {
          model: 'gpt-4o',
          messages: [systemMessage, ...combinedMessages],
        };

        const response = await fetch('https://api.openai.com/v1/chat/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`, // Securely handle API key
          },
          body: JSON.stringify(requestBody),
        });

        const data = await response.json();

        if (response.ok) {
          const assistantMessage = {
            speaker: 'Assistant',
            text: data.choices[0].message.content,
            isFinal: true,
            startTime: new Date(),
          };
          messagesRef.current = [...messagesRef.current, assistantMessage];
          setMessages([...messagesRef.current]);
        } else {
          enqueueSnackbar(`Error: ${data.error.message}`, { variant: 'error' });
        }
      } catch (error: any) {
        enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
      } finally {
        setLoadingAssistant(false);
      }
    },
    [systemMessage, enqueueSnackbar]
  );

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  // Update messages when TranscriptionMessage finalizes
  const handleMessageFinalized = (finalizedMessage) => {
    messagesRef.current = [...messagesRef.current, finalizedMessage];
    setMessages([...messagesRef.current]);

    // Clear current user message ref
    currentUserMessageRef.current = {
      speaker: 'User',
      text: '',
      isFinal: false,
      startTime: new Date(),
    };
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={{ width: '100%', maxWidth: 'lg' }}
    >
      <AccordionSummary
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <AssistantIcon />
          <Typography variant="body1" sx={{ ml: 1, fontWeight: 500 }}>
            {callState === 'transcribing' ? 'Transcribing call...' : 'Live Transcription'}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
            <AccessTimeIcon sx={{ mr: 0.5 }} />
            <Typography variant="body2">{formatTime(callDuration)}</Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 3,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 3,
            height: '32rem',
            overflow: 'hidden',
          }}
        >
          <Paper sx={{ width: '100%', overflowY: 'auto', flexGrow: 1, p: 2 }}>
            {/* Display messages */}
            {[...messages, currentUserMessageRef.current.text ? currentUserMessageRef.current : null]
              .filter(Boolean)
              .map((item, index) => (
                <TranscriptionMessage
                  key={index}
                  speaker={item.speaker}
                  text={item.text}
                  isFinal={item.isFinal}
                  startTime={item.startTime}
                  endTime={item.endTime}
                  {...(item.isFinal ? {} : { onMessageFinalized: handleMessageFinalized })}
                />
              ))}
            {/* Loading indicator for assistant message */}
            {loadingAssistant && (
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <CircularProgress size={24} />
                <Typography variant="body2" sx={{ ml: 1 }}>
                  Assistant is typing...
                </Typography>
              </Box>
            )}
          </Paper>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default LiveTranscriptionComponent;
