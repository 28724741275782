import React, { useEffect, useState } from 'react';
import {
  Modal,
} from 'react-bootstrap';
import { trackBookingAnalytics } from 'src/features/analytics/analytics.slice';
import { addSelectedServices, removeSelectedServices } from 'src/features/services/booking.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import ErrorPopup from '../ErrorPopup';
import FullGalleryView from './FullGalleryView';
import PreviewService from './PreviewService';

const ServiceDetail = ({ setShowServiceDetail, showFullView, setShowFullView,closeServiceView, uuid, addButtonDisable, propertyInfoFilled, showServiceDetail, selectedServices, handleErrorPopup }) => {

  const darkMode = useAppSelector( state => state.theme.darkMode )
  const { bookingError, selectedBookingService, galleryItems } = useAppSelector((state) => state.bookingServices);
  const handleFullGalleryView = () => {
    setShowFullView(!showFullView);
    // setShowServiceDetail(!showServiceDetail)
  };
  const dispatch = useAppDispatch();

  const handleAddToCart = () => {
    dispatch(trackBookingAnalytics({action_name: "add_to_cart", payload: { description: `user added service with id ${selectedBookingService.id} to cart`, service_id: selectedBookingService.id, uuid: uuid }}))
    dispatch(addSelectedServices(selectedBookingService))
  };

  const handleRemove = () => {
    dispatch(trackBookingAnalytics({action_name: "remove_from_cart", payload: { description: `user removed service with id ${selectedBookingService.id} from cart`, service_id: selectedBookingService.id, uuid: uuid }}))
    dispatch(removeSelectedServices(selectedBookingService))
  };

  return (
    <>
      <FullGalleryView
        handleAddToCart={handleAddToCart}
        handleRemove={handleRemove}
        addButtonDisable={addButtonDisable}
        showView={showFullView}
        closeView={closeServiceView}
        isPropertyInfoUpdated={propertyInfoFilled}
        selectedBookingService={selectedBookingService}
        selectedServices={selectedServices}
        handleErrorPopup={handleErrorPopup}
        galleryItems={selectedBookingService?.galleryItems || []}
      />
      <Modal
        backdrop="static"
        keyboard={false}
        dialogClassName="medium-modal"
        show={showServiceDetail}
        fullscreen={'sm-down'}
        contentClassName="pt-0"
        onHide={closeServiceView}
      >
        <Modal.Header closeButton>
          <div
            className="py-2 px-3 fw-medium fs-14 go-back"
            role="button"
            onClick={closeServiceView}
          >
            <img src={ darkMode ? "/icon/backLight.svg" : "/icon/back.svg" } alt="" />
            &nbsp; All Services
          </div>
        </Modal.Header>

        <Modal.Body className="pt-0">
          {bookingError && <ErrorPopup message={bookingError}/>}
          <PreviewService
            handleAddToCart={handleAddToCart}
            handleRemove={handleRemove}
            addButtonDisable={addButtonDisable}
            service={selectedBookingService}
            galleryItems={galleryItems}
            selectedServices={selectedServices}
            handleFullGalleryView={handleFullGalleryView}
            handleErrorPopup={handleErrorPopup}
            propertyInfoFilled={propertyInfoFilled}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ServiceDetail;
