import { API_ROUTES } from 'src/constants/routes';
import httpClient from 'src/libs/axios';

const resetChat = (conversation_id: string, embed_key: string) => {
  return httpClient.post(API_ROUTES.chat_reset, { conversation_id: conversation_id, embed_key: embed_key });
}

const ChatService = {
  resetChat
};

export default ChatService;
