import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { CircularProgress, IconButton, InputAdornment, Link, TextField, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useAppSelector } from 'src/store';
import useDebounce from 'src/hooks/useDebounce';
import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';
import Edit from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { enqueueSnackbar } from 'notistack';


const EmbedKeyInput = ({ style = {}, channelId, initialEmbedKey, parentWrapperStyle = {}, textFieldStyle = {}, linkWrapperStyle = {} }) => {
  const [showInput, setShowInput] = useState(false);
  const [error, setError] = useState(false);
  const [label, setLabel] = useState('');
  const [value, setValue] = useState(initialEmbedKey);
  const debouncedValue = useDebounce(value, 700);
  const [loading, setLoading] = useState(false);
  const [currentEmbedKey, setCurrentEmbedKey] = useState(initialEmbedKey);

  useEffect(() => {
    setValue(initialEmbedKey);
    setCurrentEmbedKey(initialEmbedKey)
  }, [channelId]);

  useEffect(() => {
    if (debouncedValue) {
      handleChange(debouncedValue);
    }
  }, [debouncedValue]);

  const handleChange = async (val) => {
    if (val.length < 3) {
      setError(true);
      setLabel('Url must be at least 3 characters long.');
      return;
    }

    if (debouncedValue === currentEmbedKey) {
      setError(false);
      setLabel('');
      return;
    }

    const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.channel_url_available}?url_slug=${debouncedValue}`;

    try {
      const res = await httpClient.get(url);

      if (res && res.data && res.data.available) {
        setError(false);
        setLabel(`app.oppy.pro/s/${debouncedValue} is available!"`);
      } else {
        setError(true);
        setLabel(`app.oppy.pro/s/${debouncedValue} is not available!"`);
      }
    } catch (e) {
      console.error(e);

      setError(true);
      setLabel('Failed to check URL availability.');
    }
  }

  const handleInputChange = (event) => {
    const val = event.target.value;
    setValue(val);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(false);
    const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_channel_update_embed_key(channelId)}`;
    try {
      const res = await httpClient.post(url, { embed_key: debouncedValue });
      setCurrentEmbedKey(res.data.embed_key);
      setShowInput(false);
      setError(false);
      setLabel('');
      enqueueSnackbar(`Share link updated to app.oppy.pro/s/${res.data.embed_key}`, { variant: 'success' });
    } catch (e) {
      console.error(e);
      setError(true);
      setLabel('Failed to update URL.');
      setCurrentEmbedKey(initialEmbedKey);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Box sx={parentWrapperStyle}>
        {/* button goes here */}
        {showInput ? 
        <TextField 
          disabled={loading} 
          onChange={handleInputChange}
          error={error}
          helperText={label}
          onBlur={()=> {
            setValue(currentEmbedKey)
            setError(false)
            setLabel('')
            setShowInput(false)
          }
          }
          label='Public Link To This Oppy'
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ color: 'gray' }}>
                app.oppy.pro/s/
              </InputAdornment>
            ),
            endAdornment:
              <>
                {/* MUI IconButton with Save icon */}
                <IconButton disabled={debouncedValue === currentEmbedKey} onMouseDown={(e) => e.preventDefault()} onClick={()=>{handleSubmit();}}>
                  {loading ? <CircularProgress sx={{fontSize:'22px'}} /> : <SaveIcon sx={{fontSize:'22px'}} />}
                </IconButton>
              </>
          }}
          value={value}
          sx={textFieldStyle}
          />
          :
          // make flexbox put all icons in the same row
        <Box sx={linkWrapperStyle} >
            <Link sx={{ ...style, margin: 1 }} href={`https://app.oppy.pro/s/${currentEmbedKey}`} target="_blank" variant='body1'>
            {`app.oppy.pro/s/${currentEmbedKey}`}
          </Link>
          {/* <IconButton onClick={() => {
            navigator.clipboard.writeText(`https://app.oppy.pro/s/${currentEmbedKey}`);
          }}>
            <ContentCopyIcon />
          </IconButton> */}
          <IconButton onClick={() => setShowInput(true)}>
            <Edit sx={{ ...style, fontSize: "16px" }} />
          </IconButton>
        </Box>
        }
      </Box>
    </>
  );
};

export default EmbedKeyInput;

// END Parent Component frontend/src/components/Channels/PagesDialog.tsx
