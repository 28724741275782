import React, { useState } from 'react';
import { Modal, Card, Col, Row, Container } from 'react-bootstrap';

const Templates = () => {
  const [show, setShow] = useState(true);

  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        size="xl"
        show={show}
        contentClassName="large-width px-3"
        fullscreen={'sm-down'}
        centered
      >
        <Modal.Header closeButton>
          <div>
            <div className="fs-36 fw-medium">Templates</div>
            <div className="fs-18 fw-medium">
              Pick a template or start from scratch
            </div>
          </div>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex justify-content-between align-items-center">
            <Container fluid>
              <Row className="justify-content-between">
                <Col className="px-0 d-none d-lg-block text-end" role="button">
                  <span className="border-bottom border-dark Col-7 text-end"></span>
                </Col>
                <div className="px-0 mt-2 d-lg-none" role="button">
                  <span className="border-bottom border-dark"></span>
                </div>
              </Row>
            </Container>
          </div>

          <Row xs={1} sm={2} md={2} lg={3} className="g-4">
            {Array.from({ length: 6 }).map((_, idx) => (
              <Col key={idx}>
                <Card className="service-card">
                  <Card.Img
                    className=""
                    width={224}
                    height={136}
                    variant="top"
                    src="./images/Rectangle19.png"
                  />
                  <Card.Body>
                    <div className="d-flex justify-content-between">
                      <span className="mr-auto fw-bold fs-14">
                        Exterior Photos Only
                      </span>
                      <img src="./icon/image3.png" alt="" />
                    </div>
                    <p className="fs-12 fw-medium-light lh-1 text-secondary">
                      Tagline
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Modal.Body>

        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default Templates;
