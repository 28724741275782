import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from 'src/store';
import { selectChannelById } from 'src/features/account/channels.slice';
import PromptPicker from '../PromptPicker';
import { Divider, Typography, Button, Dialog } from '@mui/material';
import AdvancedChannelCreate from './AdvancedChannelCreate';

const SimpleChannelCreate = () => {
  const [newChannelConfig, setNewChannelConfig] = useState({});
  const [openAdvanced, setOpenAdvanced] = useState(false);

  const handlePromptSelect = (selectedPrompt) => {
    setNewChannelConfig(prevChannelConfig => ({
      ...prevChannelConfig,
      system_message: selectedPrompt
    }));
  };

  return (
    <>
      <PromptPicker
        onPromptSelect={handlePromptSelect}
        initialPrompt=""
        channel={newChannelConfig}
        chatbotName=""
        variant="cards"
      />
      <Divider sx={{ my: 2 }}>
        <Typography variant="body2" color="text.secondary">
          - or -
        </Typography>
      </Divider>
      <Button variant="outlined" onClick={() => setOpenAdvanced(true)}>
        Advanced Builder
      </Button>
      <Dialog
        open={openAdvanced}
        onClose={() => setOpenAdvanced(false)}
        fullWidth
        maxWidth = "xl"
      >
        <AdvancedChannelCreate />
      </Dialog>
    </>
  );
};

export default SimpleChannelCreate;
