import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserService from 'src/services/user.services';
import {
  BookingAvailabilityRes,
  BookingAvailabilityType,
  BookingSettingsType,
  PaymentCollectionType,
  ResPasswordType,
  TravelAndLocationType,
  TravelPreferencesType,
  UpdateUserType,
  UserCalendarReq,
  UserCalendarType,
  UserType,
} from 'src/types/user';
import { serializeErrorMessage } from 'src/utils';
import { logout } from '../auth/auth.slice';
import { httpClient } from 'src/libs';
import GoogleService from 'src/services/googleCalendar.services';

interface UserState {
  user: null | UserType;
  userLoading: boolean;
  userError: null | string;
  travelPreferences?: TravelAndLocationType;
  bookingSettings?: BookingSettingsType;
  bookingAvailability?: BookingAvailabilityRes;
  userCalendar?: UserCalendarType[];
  paymentCollection?: PaymentCollectionType;
  subscriptionPlan?: string;
  account?: { uuid: string; name: string }; 
}

// TODO validate if we want to save user profile in the localstorage
const initialState: UserState = {
  user: null,
  userLoading: false,
  userError: null,
  travelPreferences: null,
  bookingSettings: null,
  userCalendar: [],
};

export const getUser = createAsyncThunk('user/getUser', async (_, thunkAPI) => {
  try {
    const {
      data: { user },
    } = await UserService.getUser();

    return { user };
  } catch (error) {
    const message = serializeErrorMessage(error);

    thunkAPI.dispatch(logout());
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (data: UpdateUserType, thunkAPI) => {
    try {
      if (data?.photo_attributes?.file) {
        await UserService.updateUserPhoto(data);
      }

      const {
        data: { user },
      } = await UserService.updateUser(data);

      return { user };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteUserPhoto = createAsyncThunk(
  'user/deletePhoto',
  async (_, thunkAPI) => {
    try {
      const {
        data: { user },
      } = await UserService.deleteUserPhoto();
      return { user };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUserTravelPreferences = createAsyncThunk(
  'user/getTravelPreferences',
  async (_, thunkAPI) => {
    try {
      const { data } = await UserService.getTravelPreferences();
      return { data };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateUserTravelPreferences = createAsyncThunk(
  'user/updatgeTravelPreferences',
  async (travelPreferences: TravelPreferencesType, thunkAPI) => {
    try {
      const { data } = await UserService.putTravelPreferences(
        travelPreferences
      );
      return { data };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUserBookingSettings = createAsyncThunk(
  'user/getBookingSettings',
  async (_, thunkAPI) => {
    try {
      const { data } = await UserService.getBookingSettings();
      return { data };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateUserBookingSettings = createAsyncThunk(
  'user/updateBookingSettings',
  async (bookingSettings: BookingAvailabilityType, thunkAPI) => {
    try {
      const { data } = await UserService.putBookingSettings(bookingSettings);
      return { data };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUserBookingAvailability = createAsyncThunk(
  'user/getBookingAvailability',
  async (_, thunkAPI) => {
    try {
      const { data } = await UserService.getBookingAvailability();
      return { data };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateUserBookingAvailability = createAsyncThunk(
  'user/updateBookingAvailability',
  async (bookingAvailability: BookingAvailabilityType, thunkAPI) => {
    try {
      const { data } = await UserService.putBookingAvailability(
        bookingAvailability
      );
      return { data };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUserCalendar = createAsyncThunk(
  'user/getUserCalendar',
  async (_, thunkAPI) => {
    try {
      const { data } = await UserService.getUserCalendar();
      return { data };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const checkUserCalendar = createAsyncThunk(
  'user/checkUserCalendar',
  async (_, thunkAPI) => {
    try {
      const { data } = await UserService.getUserCalendar();
      return { data };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateUserCalendar = createAsyncThunk(
  'user/updateUserCalendar',
  async (userCalendar: UserCalendarReq, thunkAPI) => {
    try {
      const { data } = await UserService.putUserCalendar(
        userCalendar
      );
      return { data };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const changePassword = createAsyncThunk(
  'user/changePassword',
  async (data: ResPasswordType, thunkAPI) => {
    try {
      const {  } = await UserService.changePassword(data);
      return {  };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const mailVerificationEmail = createAsyncThunk(
  'user/mailVerificationEmail',
  async (_, thunkAPI) => {
    try {
      const { data } = await UserService.sendVerificationEmail();
      return { data };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateEmail = createAsyncThunk(
  'user/updateEmail',
  async (data: {email: string, email_token: string}, thunkAPI) => {
    try {
      const { data: response } = await UserService.updateEmail(data);
      return { response };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const connectPaymentProcessing = createAsyncThunk(
  'user/connectPaymentProcessing',
  async (_, thunkAPI) => {
    try {
      const { data: response } = await UserService.connectPaymentProcessing();
      return { response };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPaymentCollection = createAsyncThunk(
  'user/getPaymentCollection',
  async (_, thunkAPI) => {
    try {
      const { data } = await UserService.getPaymentCollection();
      return { data };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePaymentCollection = createAsyncThunk(
  'user/updatePaymentCollection',
  async ( user: { payment_collection_type: string }, thunkAPI) => {
    try {
      const { data } = await UserService.updatePaymentCollection(user);
      return { data };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const disconnectGoogleCalendar = createAsyncThunk(
  'user/disconnectGoogleCalendar',
  async (_, thunkAPI) => {
    try {
      await UserService.disconnectGoogleCalendar();
      return { google_calendar_connected: false };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const setGoogleCalendarToken = createAsyncThunk(
  'google/setToken',
  async (data: { authorization_code: string, redirect_uri?: string }, thunkAPI) => {
    try {
      const response = await GoogleService.setGoogleCalendarToken(data);
      console.log('response', response)
      // set user data
      thunkAPI.dispatch(setUser(response.data));
      return { data: response.data };
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Define the action
// export const setGoogleCalendarToken = createAsyncThunk(
//   'user/setGoogleCalendarToken',
//   async (payload: { authorization_code: string; redirect_uri: string }, { dispatch }) => {
//     try {
//       console.log('Sending authorization code:', payload.authorization_code); // Debugging log
//       console.log('Using redirect URI:', payload.redirect_uri); // Debugging log

//       const response = await httpClient.post('/api/google-calendar/token', {
//         authorization_code: payload.authorization_code,
//         redirect_uri: payload.redirect_uri,
//       });

//       // Assuming the response contains user data
//       dispatch(setUser(response.data));
//       return response.data;
//     } catch (error) {
//       console.error('Error in setGoogleCalendarToken:', error); // Log error details
//       throw new Error('Failed to set Google Calendar token');
//     }
//   }
// );

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = { ...state.user, ...action.payload.user };
    },
    clearUser: (state) => {
      state.user = null
    },
    setSubscriptionPlan: (state, action) => {
      state.subscriptionPlan = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.fulfilled, (state, action) => {
        state.userLoading = false;
        state.user = { ...state.user, ...action.payload.user };
        state.userError = null
      })
      .addCase(getUser.pending, (state, _) => {
        state.userLoading = true;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.userLoading = false;
        state.user = null;
        state.userError = action.payload as string;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.userLoading = false;
        state.user = { ...state.user, ...action.payload.user };
        state.userError = null
      })
      .addCase(updateUser.pending, (state, _) => {
        state.userLoading = true;
        state.userError = null
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = action.payload as string;
      })
      .addCase(deleteUserPhoto.fulfilled, (state, action) => {
        state.userLoading = false;
        state.user = { ...state.user, ...action.payload.user };
        state.userError = null
      })
      .addCase(deleteUserPhoto.pending, (state, _) => {
        state.userLoading = true;
      })
      .addCase(deleteUserPhoto.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = action.payload as string;
      })
      .addCase(getUserTravelPreferences.fulfilled, (state, action) => {
        state.userLoading = false;
        state.travelPreferences = { ...action.payload.data };
        state.userError = null
      })
      .addCase(getUserTravelPreferences.pending, (state, _) => {
        state.userLoading = true;
        state.userError = null
      })
      .addCase(getUserTravelPreferences.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = action.payload as string;
      })
      .addCase(updateUserTravelPreferences.fulfilled, (state, action) => {
        state.userLoading = false;
        state.travelPreferences = { ...action.payload.data };
        state.userError = null
      })
      .addCase(updateUserTravelPreferences.pending, (state, _) => {
        state.userLoading = true;
        state.userError = null
      })
      .addCase(updateUserTravelPreferences.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = action.payload as string;
      })
      .addCase(getUserBookingSettings.fulfilled, (state, action) => {
        state.userLoading = false;
        state.bookingSettings = { ...action.payload.data };
        state.userError = null
      })
      .addCase(getUserBookingSettings.pending, (state, _) => {
        state.userLoading = true;
        state.userError = null
      })
      .addCase(getUserBookingSettings.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = action.payload as string;
      })
      .addCase(updateUserBookingSettings.fulfilled, (state, action) => {
        state.userLoading = false;
        state.bookingSettings = { ...action.payload.data };
        state.userError = null
      })
      .addCase(updateUserBookingSettings.pending, (state, _) => {
        state.userLoading = true;
        state.userError = null
      })
      .addCase(updateUserBookingSettings.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = action.payload as string;
      })
      .addCase(getUserBookingAvailability.fulfilled, (state, action) => {
        state.userLoading = false;
        state.bookingAvailability = { ...action.payload.data };
        state.userError = null
      })
      .addCase(getUserBookingAvailability.pending, (state, _) => {
        state.userLoading = true;
        state.userError = null
      })
      .addCase(getUserBookingAvailability.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = action.payload as string;
      })
      .addCase(updateUserBookingAvailability.fulfilled, (state, action) => {
        state.userLoading = false;
        state.bookingAvailability = { ...action.payload.data };
        state.userError = null
      })
      .addCase(updateUserBookingAvailability.pending, (state, _) => {
        state.userLoading = true;
        state.userError = null
      })
      .addCase(updateUserBookingAvailability.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = action.payload as string;
      })
      .addCase(getUserCalendar.fulfilled, (state, action) => {
        state.userLoading = false;
        state.userCalendar = action.payload.data.calendar_list;
        state.userError = null
      })
      .addCase(getUserCalendar.pending, (state, _) => {
        state.userLoading = true;
        state.userError = null
      })
      .addCase(getUserCalendar.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = action.payload as string;
      })
      .addCase(updateUserCalendar.fulfilled, (state, action) => {
        state.userLoading = false;
        state.userError = null
      })
      .addCase(updateUserCalendar.pending, (state, _) => {
        state.userLoading = true;
        state.userError = null
      })
      .addCase(updateUserCalendar.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = action.payload as string;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.userLoading = false;
        state.userError = null
      })
      .addCase(changePassword.pending, (state, _) => {
        state.userLoading = true;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = action.payload as string;
      })
      .addCase(updateEmail.fulfilled, (state, action) => {
        state.userLoading = false;
        state.userError = null
      })
      .addCase(updateEmail.pending, (state, _) => {
        state.userLoading = true;
        state.userError = null;
      })
      .addCase(updateEmail.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = action.payload as string;
      })
      .addCase(mailVerificationEmail.fulfilled, (state, action) => {
        state.userLoading = false;
        state.userError = null
      })
      .addCase(mailVerificationEmail.pending, (state, _) => {
        state.userLoading = true;
        state.userError = null;
      })
      .addCase(mailVerificationEmail.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = action.payload as string;
      })
      .addCase(connectPaymentProcessing.fulfilled, (state, action) => {
        state.userLoading = false;
        state.userError = null
      })
      .addCase(connectPaymentProcessing.pending, (state, _) => {
        state.userLoading = true;
        state.userError = null;
      })
      .addCase(connectPaymentProcessing.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = action.payload as string;
      })
      .addCase(getPaymentCollection.fulfilled, (state, action) => {
        state.paymentCollection = action.payload.data
        state.userLoading = false;
        state.userError = null
      })
      .addCase(getPaymentCollection.pending, (state, _) => {
        state.userLoading = true;
        state.userError = null;
      })
      .addCase(getPaymentCollection.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = action.payload as string;
      })
      .addCase(updatePaymentCollection.fulfilled, (state, action) => {
        state.userLoading = false;
        state.userError = null
      })
      .addCase(updatePaymentCollection.pending, (state, _) => {
        state.userLoading = true;
        state.userError = null;
      })
      .addCase(updatePaymentCollection.rejected, (state, action) => {
        state.userLoading = false;
        state.userError = action.payload as string;
      })
      .addCase(disconnectGoogleCalendar.fulfilled, (state) => {
        if (state.user) {
          state.user.google_calendar_connected = false;
          state.user.calendar_id = null; // Ensure this line doesn't throw an error
        }
        state.userError = null;
      })
      .addCase(disconnectGoogleCalendar.rejected, (state, action) => {
        state.userError = action.payload as string;
      });
  },
});

export const { setUser, clearUser, setSubscriptionPlan } = userSlice.actions

const userReducer = userSlice.reducer;

export default userReducer;
