import { useState } from 'react';
import { Button, Col, Container, Row, Image } from 'react-bootstrap';
import GoBackDashboard from '../../BookingSettings/GoBackDashboard';
import Header from '../../BookingSettings/Header';

export default function Empty({ screenWidth }) {
  const [menu, setMenu] = useState(false);

  return (
    <>
      <Header screenWidth={screenWidth} setMenu={setMenu} />
      <Container className="bg-light" fluid>
        <Container className="text-start" fluid>
          <Row lg={'auto'} className="p-4">
            <Col lg={8} md={6} sm={12}>
              <GoBackDashboard />
              <div className="fs-4 fw-medium d-flex align-items-center px-0">
                Services
                <div className="service-badge border border-3 text-black fw-medium-light fs-5 mx-3">
                  0
                </div>
              </div>
            </Col>
            <Col lg={2} sm={12} md={3}>
              <div className="fs-18 fw-medium ">Embed Code</div>
            </Col>
            <Col lg={2} sm={12} md={3}>
              <div className="fs-18 fw-medium ">View Booking Page</div>
            </Col>
          </Row>
          <Row
            lg={'auto'}
            className="mt-5 p-5 d-flex flex-column justify-content-center"
          >
            <div className="d-flex justify-content-center">
              <img
                src="./images/serviceBackground.png"
                alt=""
                height={322}
                width={524}
                className="pb-4"
              />
            </div>
            <div className="d-flex flex-column align-items-center">
              <p className="text-black-50 fs-18 text-align-center">
                You do not have services yet. Add your first
                <br /> service to allow people booking you.
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <Button variant="primary" type="submit">
                <Image src="icon/plus.svg" className="me-2" />
                Add Service
              </Button>
            </div>
          </Row>
        </Container>
      </Container>
    </>
  );
}
