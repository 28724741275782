import { Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'src/store';

interface Props {
  text?: string;
  path?: string;
  className?: string;
}

export default function GoBack({ text, path, className }: Props) {
  const darkMode = useAppSelector( state => state.theme.darkMode )
  return (
    <Col className={className}>
      <Link to={path} className="unstyled text-dark d-flex">
        <Image className="me-2" src={ darkMode ? "/icon/backChevronLight.svg" : "/icon/backChevron.svg" }/>
        <span>{text ? `${text}` : 'Go to dashboard'}</span>
      </Link>
    </Col>
  );
}
