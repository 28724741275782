import PaymentProcessingForm from "src/components/profile/PaymentProcessingForm";
import ProfileLayout from "src/components/profile/ProfileLayout";

const PaymentProcessing = () => {
    return (
      <ProfileLayout activeKey="payment_processing" >
          <PaymentProcessingForm />
      </ProfileLayout>
    );
  };

export default PaymentProcessing;
