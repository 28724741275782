import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { firstGalleryItemSerializer } from 'src/libs/utils';
import PhotographService from 'src/services/photograph.services';
import { GalleryItem } from 'src/types/bookings';
import { ReqGalleryItemType, ResServicesType, ServiceGalleryItem, ServicesType } from 'src/types/services';
import { serializeErrorMessage } from 'src/utils';

interface photographerState {
  serviceList?: ResServicesType[];
  serviceError?: string;
  serviceSuccessMessage?: string;
  draftService?: ServicesType;
  servicesAmount?: number;
  hasServices: boolean;
  isLoading: boolean;
  currentService: ResServicesType;
  currentGalleryItems: ServiceGalleryItem[];
  firstGalleryItems: GalleryItem[];
}


const initialState: photographerState = {
  serviceList: null,
  serviceError: null,
  serviceSuccessMessage: null,
  servicesAmount: 0,
  hasServices: false,
  isLoading: false,
  currentService: null,
  currentGalleryItems: [],
  firstGalleryItems: [],
};

export const createPhotographerService = createAsyncThunk(
  'services/photograph/create',
  async (data: ServicesType, thunkAPI) => {
    try {
      const res = await PhotographService.create(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePhotographerService = createAsyncThunk(
  'services/photograph/update',
  async ({ id, data }: { id: string; data: ServicesType }, thunkAPI) => {
    try {
      const res = await PhotographService.update(id, data);
      return res.data;
    } catch (error) {
      // TODO handle error }
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllPhotographerServices = createAsyncThunk(
  'services/photograph/getAll',
  async (_, thunkAPI) => {
    try {
      const res = await PhotographService.getAll();
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPhotographerServiceById = createAsyncThunk(
  'services/photograph/getOne',
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const res = await PhotographService.get(id);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteProviderServiceById = createAsyncThunk(
  'services/photograph/delete',
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const res = await PhotographService.deleteService(id);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPhotographerServiceGallery = createAsyncThunk(
  'services/photograph/gallery/get',
  async ({ serviceId }: { serviceId: string }, thunkAPI) => {
    try {
      const res = await PhotographService.getServicePhotos(serviceId);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const uploadPhotographerServiceImage = createAsyncThunk(
  'services/photograph/gallery/add',
  async ( data: ReqGalleryItemType, thunkAPI ) => {
    try {
      const res = await PhotographService.uploadServicePhoto(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletePhotographerServiceImage = createAsyncThunk(
  'services/photograph/gallery/delete',
  async (
    { serviceId, photoId }: { serviceId: string; photoId: number },
    thunkAPI
  ) => {
    try {
      const res = await PhotographService.deleteServicePhoto(
        serviceId,
        photoId
      );
      return photoId;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const setgalleryItems = createAsyncThunk(
  'services/photograph/setgalleryItems',
  async (data: {serviceId: string, order: {id: number, order: number}[]}, thunkAPI) => {
    try {
      const res = await PhotographService.bulk_reorder(data.serviceId, data.order);
      return res
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getFirstGalleryItems = createAsyncThunk(
  'services/firstGalleryItems',
  async (_, thunkAPI) => {
    try {
      const res = await PhotographService.getFirstGalleryItems();
      return res.data
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const reorderServices = createAsyncThunk(
  'services/reorder',
  async (services_order: {id: number, order: number}[], thunkAPI) => {
    try {
      const res = await PhotographService.reorderServices(services_order);
      return res.data
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const photoGrapherSlice = createSlice({
  name: 'photographerServices',
  initialState,
  reducers: {
    setServiceImages: (state, action) => {
      state.currentGalleryItems = action.payload
    },
    clearServiceImages: (state) => {
      state.currentGalleryItems = []
    },
    setServiceList: (state, action) => {
      state.serviceList = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPhotographerService.fulfilled, (state, action) => {
        // state.serviceDraft = action.payload.photographic;
        state.serviceSuccessMessage = "Service saved successfully"
        state.isLoading = false;
        state.serviceError = null
      })
      .addCase(createPhotographerService.pending, (state, action) => {
        state.isLoading = true;
        state.serviceError = null
        state.serviceSuccessMessage = null
      })
      .addCase(createPhotographerService.rejected, (state, action) => {
        // state.service.description = '';
        state.isLoading = false;
        state.serviceError = action.payload as string
        state.serviceSuccessMessage = null
      })
      .addCase(updatePhotographerService.fulfilled, (state, action) => {
        // state.serviceDraft = action.payload.photographic;
        state.serviceSuccessMessage = "Service saved successfully"
        state.currentService = action.payload
        state.isLoading = false;
        state.serviceError = null
      })
      .addCase(updatePhotographerService.pending, (state, action) => {
        state.isLoading = true;
        state.serviceSuccessMessage = null
        state.serviceError = null
      })
      .addCase(updatePhotographerService.rejected, (state, action) => {
        state.serviceSuccessMessage = null
        state.isLoading = false;
        state.serviceError = action.payload as string
      })
      .addCase(getAllPhotographerServices.fulfilled, (state, action) => {
        state.serviceList = action.payload;
        state.servicesAmount = action.payload.length;
        state.hasServices = action.payload.length > 0;
        state.isLoading = false;
        state.serviceSuccessMessage = null
      })
      .addCase(getAllPhotographerServices.pending, (state, action) => {
        state.isLoading = true;
        state.serviceSuccessMessage = null
      })
      .addCase(getAllPhotographerServices.rejected, (state, action) => {
        // gratefully fallback
        state.serviceList = [];
        state.servicesAmount = 0;
        state.hasServices = false;
        state.isLoading = false;
        state.serviceSuccessMessage = null
        state.serviceError = action.payload as string
      })
      .addCase(getPhotographerServiceById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentService = action.payload
        state.serviceError = null
      })
      .addCase(getPhotographerServiceById.pending, (state, action) => {
        state.isLoading = true;
        state.serviceError = null
      })
      .addCase(getPhotographerServiceById.rejected, (state, action) => {
        state.isLoading = false;
        state.serviceError = action.payload as string
      })
      .addCase(getPhotographerServiceGallery.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentGalleryItems = action.payload
        state.serviceError = null
      })
      .addCase(getPhotographerServiceGallery.pending, (state, action) => {
        state.isLoading = true;
        state.serviceError = null
      })
      .addCase(getPhotographerServiceGallery.rejected, (state, action) => {
        state.isLoading = false;
        state.serviceError = action.payload as string
        state.currentGalleryItems = []
      })
      .addCase(uploadPhotographerServiceImage.fulfilled, (state, action) => {
        state.isLoading = false;
        let flag = false
        state.currentGalleryItems = state.currentGalleryItems.filter((img) => {
          if (img.id > 0 || flag){ return true }
          flag = true
          return false
        })
        state.currentGalleryItems.push(action.payload)
        state.serviceError = null
      })
      .addCase(uploadPhotographerServiceImage.pending, (state, action) => {
        state.isLoading = true;
        state.serviceError = null
      })
      .addCase(uploadPhotographerServiceImage.rejected, (state, action) => {
        state.isLoading = false;
        let flag = false
        state.currentGalleryItems = state.currentGalleryItems.filter((img) => {
          if (img.id > 0 || flag){ return true }
          flag = true
          return false
        })
        state.serviceError = action.payload as string
      })
      .addCase(deletePhotographerServiceImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentGalleryItems = state.currentGalleryItems.filter((item) => (
          action.payload != item.id
        ))
        state.serviceError = null
      })
      .addCase(deletePhotographerServiceImage.pending, (state, action) => {
        state.isLoading = true;
        state.serviceError = null
      })
      .addCase(deletePhotographerServiceImage.rejected, (state, action) => {
        state.isLoading = false;
        state.serviceError = action.payload as string
      })
      .addCase(setgalleryItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.serviceError = null
      })
      .addCase(setgalleryItems.pending, (state, action) => {
        state.isLoading = true;
        state.serviceError = null
      })
      .addCase(setgalleryItems.rejected, (state, action) => {
        state.isLoading = false;
        state.serviceError = action.payload as string
      })
      .addCase(getFirstGalleryItems.fulfilled, (state, action) => {
        state.firstGalleryItems = firstGalleryItemSerializer(action.payload)
        state.isLoading = false;
        state.serviceError = null
      })
      .addCase(getFirstGalleryItems.pending, (state, action) => {
        state.isLoading = true;
        state.serviceError = null
      })
      .addCase(getFirstGalleryItems.rejected, (state, action) => {
        state.isLoading = false;
        state.serviceError = action.payload as string
      })
      .addCase(deleteProviderServiceById.fulfilled, (state, action) => {
        state.serviceError = null
      })
      .addCase(deleteProviderServiceById.pending, (state, action) => {
        state.serviceError = null
      })
      .addCase(deleteProviderServiceById.rejected, (state, action) => {
        state.serviceError = action.payload as string
      })
      .addCase(reorderServices.fulfilled, (state, action) => {
      })
      .addCase(reorderServices.pending, (state, action) => {
      })
      .addCase(reorderServices.rejected, (state, action) => {
        state.serviceError = action.payload as string
      });
  },
});

export const {clearServiceImages, setServiceImages, setServiceList} = photoGrapherSlice.actions;

const photographerReducer = photoGrapherSlice.reducer;
export default photographerReducer;
