import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { trackBookingAnalytics } from "src/features/analytics/analytics.slice";
import { cancelBooking } from "src/features/services/booking.slice";
import { useAppDispatch, useAppSelector } from "src/store";
import ErrorPopup from "../ErrorPopup";

const BookingCancelation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { cancel_booking_token } = useParams();
  const { bookingError } = useAppSelector((state) => state.bookingServices)
  const [show, setShow] = useState(true)

  const handleGoBack = () => {
    navigate('/')
  }

  const handleCancelBooking = () => {
    dispatch(cancelBooking(cancel_booking_token)).unwrap().then((response)=>{
      dispatch(trackBookingAnalytics({action_name: "cancel_booking", payload: { description: `Cancelled booking. User:${response.uuid}`, booking_uuid: response.uuid}}))
      navigate(`/c/${response.uuid}`);
    });
    setShow(false)
  }
  const darkMode = useAppSelector( state => state.theme.darkMode )

  return (
    <>
    {bookingError && <ErrorPopup message={bookingError}/>}
    <Modal
      show={show}
      fullscreen={'sm-down'}
      keyboard={false}
      centered
      onHide={handleGoBack}
      contentClassName="medium-width"
    >
      <Modal.Header>
        <div className="py-2 px-3 fw-medium fs-14 go-back">
          <img src={ darkMode ? "/icon/backLight.svg" : "/icon/back.svg" } onClick={handleGoBack} alt="" />
          &nbsp; Go back
        </div>
      </Modal.Header>
      <Modal.Body>
        {/* LargeScreen */}
        <div className="d-none d-sm-block">
          <p className="fs-4 px-3 fw-medium lh-sm">
            Are you sure you want to cancel this booking?
          </p>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={handleGoBack}>
              <span className="fw-medium">No</span>
            </Button>
            <Button variant="danger" onClick={handleCancelBooking}>
              <span className="fw-medium">Yes</span>
            </Button>
          </Modal.Footer>
        </div>
      </Modal.Body>
    </Modal>
    </>
  );
};

export default BookingCancelation;
