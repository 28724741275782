import React, { useState } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import Header from '../Header';
import SideBar from '../SideBar';

const Account = ({ screenWidth }) => {
  const [menu, setMenu] = useState(true);

  return (
    <>
      <Header screenWidth={screenWidth} setMenu={setMenu} />
      {menu ? (
        <div className="row">
          <SideBar activePage={'account'} />
        </div>
      ) : (
        <Container fluid>
          <div className="row">
            <SideBar
              className="col"
              screenWidth={screenWidth}
              activePage={'account'}
            />
            <Navbar
              expand="lg"
              variant="light"
              bg="light"
              className="col px-lg-5"
            >
              <Container className="px-lg-5 mx-lg-5 ">
                <Container className="text-start px-lg-5 mx-lg-5">
                  <form>
                    <div className="">
                      <div className="pt-4 pb-3 fw-medium fs-48">Account</div>
                      <div className="fw-medium fs-24">Profile</div>
                      <p className="text-black-50 fs-12">
                        This information will be displayed publicly in your
                        booking page
                      </p>

                      <div className="row pt-3 pb-3">
                        <div className="col-12 col-lg-6">
                          <h6 className="fs-14 fw-medium required">
                            First Name
                          </h6>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control ht-48"
                              aria-describedby="basic-addon2"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <h6 className="fs-14 fw-medium required">
                            Last Name
                          </h6>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control ht-48"
                              aria-label="Amount (to the nearest dollar)"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <h6 className="fs-14 fw-medium">Photo</h6>
                          <div className="d-flex flex-row align-items-center">
                            <img
                              src="./images/Ellipse4.png"
                              className=""
                              alt=""
                              height={48}
                              width={48}
                            />
                            <div className="mx-3 text-blue-added fw-medium">
                              Change
                            </div>
                            <div className="fw-medium text-red-added">
                              Remove
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="fw-medium fs-24 mt-4">
                        Personal Information
                      </div>
                      <p className="text-black-50 fs-12">
                        This information will be displayed publicly in your
                        booking page
                      </p>

                      <div className="row pt-3 pb-3">
                        <div className="col-12 col-lg-6">
                          <h6 className="fs-14 fw-medium required">
                            Email Address
                          </h6>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control ht-48"
                              aria-describedby="basic-addon2"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6">
                          <h6 className="fs-14 fw-medium required">
                            Cell Phone Number
                          </h6>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control ht-48"
                              aria-label="Amount (to the nearest dollar)"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="border-top row justify-content-end mx-1">
                        <button
                          type="button"
                          className="col-xl-2 order-xl-2 mt-3 ml-3 btn btn-primary btn-sm"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="col-xl-2 order-xl-1 mt-3 ml-3 btn btn-sm btn-light"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </Container>
              </Container>
            </Navbar>
          </div>
        </Container>
      )}
    </>
  );
};

export default Account;
