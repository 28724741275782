import { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EnableServiceRequest from 'src/components/EnableServiceRequest';
import ErrorPopup from 'src/components/ErrorPopup';
import Layout from 'src/components/Layout';
import Loading from 'src/components/Loading';
import Successful from 'src/components/Onboarding/Gcalendar/Successful';
import EmptyState from 'src/components/Services/EmptyState';
import ServicesNavigation from 'src/components/Services/ServicesNavigation';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import { createPhotographerService, setServiceList } from 'src/features/services/photographer.slice';
import usePhotographerServices from 'src/hooks/usePhotographerServices';
import { networkError } from 'src/libs/utils';
import { useAppDispatch, useAppSelector } from 'src/store';
import AddService from './AddService';
import WaitlistPopup from 'src/components/WaitlistPopup'
import DeleteServiceConfirmation from 'src/components/ExitConfirmation/DeleteServiceConfirmation';
import { deleteProviderServiceById } from 'src/features/services/photographer.slice';
// import ServiceItemDragAndDrop from './service/ServiceItemDragAndDrop';

const ServicesList = () => {
  const dispatch = useAppDispatch();
  const { firstGalleryItems, serviceList, hasServices, servicesAmount, isLoading } = usePhotographerServices();
  const [showDeleteServiceConfirmation, setShowDeleteServiceConfirmation] = useState(false);
  const [showCalendarSync, setShowCalendarSync] = useState(false);
  const [showAddService, setShowAddService] = useState(false);
  const [showEnableService, setShowEnableService] = useState(false);
  const [selectedService, setSelectedService] = useState(null)
  const [enableErrors, setEnableErrors] = useState({});

  const { serviceError } = useAppSelector((state) => state.photographerServices);

  const navigate = useNavigate();

  const onClickServiceItem = (serviceId: number) => {
    dispatch(trackAnalytics({action_name: "service_clicked", payload: { description: `photographer selected service with id ${serviceId}`, service_id: serviceId }}))
    navigate(`/service-edit/${serviceId}`);
  };

  const addAService = () => {
    dispatch(trackAnalytics({action_name: "add_service_clicked", payload: { description: "user initiated a new service"}}))
    setShowAddService(true)
  };

  const closeAddService = () => {
    setShowAddService(false)
  };

  const addService = (data) => {
    dispatch(createPhotographerService(data))
    .unwrap()
    .then((response) => {
      dispatch(trackAnalytics({ action_name: "user_created_service", payload: { description: `user created service with id ${response.id}`, service_id: response.id }}))
      navigate(`/service-edit/${response.id}`)
    })
  };

  const closeEnableService = () => {
    setShowEnableService(false)
  };

  useEffect(() => {
    if (!hasServices) {
      setShowCalendarSync(true);
    }
    if (!isLoading && serviceList?.length === 0 && serviceError != networkError){
      setShowAddService(true)
    }
    return () => {setShowAddService(false)}
  }, [hasServices, serviceList]);

  const updateServiceList = (id, hidden) => {
    const updatedServiceList = serviceList.map((service) => {
      if(service.id == id){
        return {...service, hidden: hidden}
      }
      return service
    })
    dispatch(setServiceList(updatedServiceList))
  }

  const closeDeleteServiceConfirmation = () => {
    setShowDeleteServiceConfirmation(false)
  }

  const deleteSelectedService = () => {
    let tempServices = serviceList.filter((service) => service.id != selectedService.id)
    const currentServices = [...serviceList]

    dispatch(setServiceList(tempServices))

    dispatch(deleteProviderServiceById({id: selectedService.id}))
      .unwrap().catch(() => dispatch(setServiceList(currentServices)))

    setShowDeleteServiceConfirmation(false)
  }

  return (
    <>
      {showDeleteServiceConfirmation && <DeleteServiceConfirmation
        show={showDeleteServiceConfirmation}
        handleGoBack={closeDeleteServiceConfirmation}
        handleDeleteService={deleteSelectedService}
        service={selectedService}
      />}
      {showEnableService && (<EnableServiceRequest
        show={showEnableService}
        handleGoBack={closeEnableService}
        handleAddDetail={() => onClickServiceItem(selectedService?.id)}
        values={selectedService}
        initialValues={selectedService}
        enableErrors={enableErrors}
      />)}
      {showAddService && (<AddService
        onSubmit={addService}
        show={showAddService}
        closeAddService={closeAddService}
        serviceList={serviceList}
      />)}
      <Layout>
        {/* <BookingManager/> */}
        <Container fluid className="with-navbar base-container">
          {serviceError && <ErrorPopup message={serviceError}/>}
          <Container>
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <ServicesNavigation
                  servicesAmount={servicesAmount}
                  serviceList={serviceList}
                />
                {hasServices ? (
                  <>
                    <Row className="mt-4">
                      {/* <ServiceItemDragAndDrop
                        setShowDeleteServiceConfirmation={setShowDeleteServiceConfirmation}
                        serviceList={serviceList}
                        firstGalleryItems={firstGalleryItems}
                        onClickServiceItem={onClickServiceItem}
                        setSelectedService={setSelectedService}
                        setEnableErrors={setEnableErrors}
                        setShowEnableService={setShowEnableService}
                        updateServiceList={updateServiceList}
                        addAService={addAService}
                      /> */}
                    </Row>
                  </>
                ) : (
                  <EmptyState onClick={addAService} />
                )}
              </>
            )}
          </Container>
        </Container>
        <Successful isShown={showCalendarSync} />
      </Layout>
      <WaitlistPopup />
    </>
  );
};

export default ServicesList;
