import { memo } from "react";
import CloudinaryVideoTag from "src/components/CloudinaryVideoTag";

const GalleryItem = ({ item }) => {
  switch (item.itemable_type) {
    case "Video":
      return <div key="video" className='w-100'><CloudinaryVideoTag cloudinary_public_id={item?.video_public_id} /></div>
    case "EmbedCode":
      return <iframe
        key="embedcode"
        src={item.embed_params.url}
        title={item.embed_params.title}
        className="w-100 embed-code"
        loading="lazy"
      />
    default:
      return <img key="image" src={item.itemable_url} alt="" className="item-image" />
  }
};

export default memo(GalleryItem)
