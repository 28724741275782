import React, { useState, useEffect } from 'react';
import { TextField, Typography, InputAdornment, Box } from '@mui/material';
import { debounce } from 'lodash';
import { httpClient } from "src/libs";
import { API_ROUTES } from 'src/constants/routes';
function UrlInput({ setUrlValid, handleUrlInputChange, inputValue ='' }) {
  const [value, setValue] = useState(inputValue);
  const [error, setError] = useState(false);
  const [label, setLabel] = useState('');

  const handleChange = debounce(async (val) => {
    if (val.length < 3) {
      setError(true);
      setLabel('Url must be at least 3 characters long.');
      setUrlValid(false);
      return;
    }

    const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.channel_url_available}?url_slug=${val}`;

    try {
      const res = await httpClient.get(url);

      if (res && res.data && res.data.available) {
        setError(false);
        setLabel(`app.oppy.pro/s/${val} is available!"`);
        setUrlValid(true);
      } else {
        setError(true);
        setLabel(`app.oppy.pro/s/${val} is not available!"`);
        setUrlValid(false);
      }
    } catch (e) {
      console.error(e);

      setError(true);
      setLabel('Failed to check Slug availability.');
      setUrlValid(false);
    }
  }, 500);

  const handleInputChange = (event) => {
    const val = event.target.value;
    setValue(val);
    handleUrlInputChange(val);
    handleChange(val);
  };


  return (
    <>
      <Box>
        <TextField
          name="embed_key"
          sx={{ margin: 1 }}
          label='Public Link To This Oppy'
          error={error}
          value={value}
          onChange={handleInputChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ color: 'gray' }}>
                https://oppy.pro/s/
              </InputAdornment>
            )
          }}
        />
        <Typography variant="body2" sx={{ ml: 1, mt:-1, mb: 2 }} color={error ? 'error' : 'success'}>
          {label}
        </Typography>
      </Box>
    </>
  );
}

export default UrlInput;
