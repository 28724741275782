import { httpClient } from 'src/libs';

const setGoogleCalendarToken = async (data: { authorization_code: string, redirect_uri?: string }) => {
  const API_URL = `${process.env.REACT_APP_API_V3_URL}/scheduler/google_calendar/`;
  return httpClient.post(API_URL, {
    ...data,
  });
};

const GoogleService = {
  setGoogleCalendarToken,
};

export default GoogleService;
