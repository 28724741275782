import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface Props {
  isShown?: boolean;
}

const CalenderConnectedSuccess = ({ isShown = false }: Props) => {
  const [show, setShow] = useState(isShown);

  return (
    <>
      <Modal backdrop="static" keyboard={false} size="lg" show={show} centered>
        <Modal.Body>
          <div className="p-4 d-flex flex-column justify-content-center">
            <div className="d-flex justify-content-center">
              <img
                src="./images/Google_Calendar.png"
                alt=""
                height={85}
                width={85}
                className="pb-3 h-100"
              />
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="fw-medium fs-36">Your calendar is synced!</div>
              <p className="text-black-50 fs-18 text-align-center">
                For best results, remember to:
              </p>
            </div>

            <div className="mt-4">
              <div className="fw-medium fs-16">
                Keep your calendar up to date
              </div>
              <div>
                This tool will scan your exisiting calendar to find your
                availabilities, so it must be up to date. Remember, you can
                change your available time in your booking settings.
              </div>
            </div>
            <div className="mt-4">
              <div className="fw-medium fs-16">Add appointment locations</div>
              <div>
                We use your location to calculate how long it will take you to
                get from point A to point B, taking into account traffic and
                time of day. For example, if you have a dentist appointment, be
                sure to include the address in your calendar event so that it
                will be considered should you need to leave for an appointment
                after your teeth cleanning!
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <Button
                variant="primary"
                type="button"
                onClick={() => setShow(false)}
              >
                Add a Service
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CalenderConnectedSuccess;
