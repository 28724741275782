import CloudinaryVideoTag from "src/components/CloudinaryVideoTag";
import { useAppDispatch, useAppSelector } from 'src/store';
import DownloadButton from "src/components/GalleryV2/components/DownloadButton";
import { deleteGalleryItem, setGalleryItems } from 'src/features/services/gallery.slice';
import React, { useState } from 'react';
import Lottie from 'react-lottie'
import * as animationData from 'src/assets/lottie/trash-icon.json'
import ZoomInIcon from "src/components/GalleryV2/components/ZoomInIcon";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import Swal from 'sweetalert2'
import { finalizeInvoiceMail } from "src/features/services/booking.slice";
import { useParams } from "react-router-dom";

const GalleryItem = ({ item, isPaid, watermarkedUrl }) => {
  switch (item.itemable_type) {
    case "Video":
      return <div className="w-100 h-100" key="service-video" >
        <CloudinaryVideoTag
          cloudinary_public_id={item.video_public_id}
        />
      </div>
    case "EmbedCode":
      return <iframe
        key="service-embedcode"
        src={item.embed_params.url}
        title={item.embed_params.title}
        className="w-100"
        style={{ height: `250px` }}
        loading="lazy"
      />
    case "Attachment":
      return <a href={isPaid ? item.itemable_url : null} target="_blank" rel="noreferrer" className="w-100">
        <div>
          <img
            src="/icon/PDF_file_icon.svg.png"
            style={{
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
              display: 'block',
              maxWidth: '100%',
              margin: 'auto'
            }} />
          <p className="attachment-file-name"> {item.filename}</p>
        </div>
      </a>
    default:
      return <img
        src={(isPaid || item.id < 0) ? item.itemable_url : watermarkedUrl(item)}
        style={{
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          display: 'block',
          width: '100%'
        }}
      />
  }
}

const GalleryItemV2 = ({ permissions, item, currentGalleryItems, isPaid, setLightBoxItem, setOpenLightBox, setShowEmbedDialogue }) => {
  let { uuid } = useParams();
  const dispatch = useAppDispatch();
  const { booking } = useAppSelector((state) => state.bookingServices);
  const handleItemDelete = async (item) => {
    let tempItems = currentGalleryItems.filter((i) => i.id != item.id)
    const currentImages = [...currentGalleryItems]
    dispatch(setGalleryItems(tempItems))
    dispatch(deleteGalleryItem({ itemId: item.id }))
      .unwrap().catch(() => dispatch(setGalleryItems(currentImages)))
  }
  const [isHovering, setIsHovering] = useState(false);

  const watermarkedUrl = ({ flexible_photo_url }) => (
    flexible_photo_url?.replace("CUSTOM_REPLACE", "t_unlicensedwatermark,c_limit,w_1600,h_1600,cs_srgb,f_jpg")
  )

  const setLightBox = () => {
    setLightBoxItem(<GalleryItem item={item} isPaid={isPaid} watermarkedUrl={watermarkedUrl} />)
    setOpenLightBox(true)
  }

  const showEmbedDialogue = () => {
    if (booking.payment_collection_type == 'never_charge' || isPaid) {
      setLightBoxItem(item)
      setShowEmbedDialogue(true)
    } else {
      Swal.fire({
        showCancelButton: true,
        confirmButtonText: 'Pay Now',
        icon: 'error',
        title: 'Unlicensed',
        text: 'Please pay invoice to view Embed code and URL.'
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(finalizeInvoiceMail(uuid))
          Swal.fire('Email sent!', 'Check your email for Payment link', 'success')
        }
      })
    }
  }

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const DeleteButtons = ({ item }) => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    return (
      <div className={`overlay delete-button ${permissions != 10 ? 'hidden' : ""}`} onClick={() => handleItemDelete(item)}>
        <Lottie
          options={defaultOptions}
          height={25}
          width={25}
        />
      </div>
    )
  }

  return (
    <div
      className="d-flex"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ position: 'relative' }}
    >
      {(isHovering && item.id > 0) &&
        <>
          <DeleteButtons item={item} />
          {item.itemable_type != "EmbedCode" ?
            <DownloadButton item={item} isPaid={isPaid} /> :
            <div className="overlay download-button" onClick={showEmbedDialogue} >
              <InsertLinkIcon />
            </div>
          }
          {!(["EmbedCode", "Attachment"].includes(item.itemable_type)) &&
            <div className="overlay zoom-button" onClick={setLightBox}>
              <ZoomInIcon />
            </div>
          }
        </>}
      <GalleryItem item={item} isPaid={isPaid} watermarkedUrl={watermarkedUrl} />
    </div>
  );
};

export default GalleryItemV2;
