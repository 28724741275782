import React, { useState, useEffect } from 'react';
import {
  Card as MuiCard,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  Collapse,
  Typography,
} from '@mui/material';
import {
  ExpandMore,
  ExpandLess,
  Star,
  StarBorder,
  Mail,
  Phone,
  Business,
  LocationOn,
  Person,
  AttachMoney,
} from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from 'src/store';
import { updateContact, selectContactById } from 'src/features/account/contacts.slice';
import ContactDialog from './ContactDialog';

interface ContactCardV2Props {
  contactId: string;
  expanded: boolean;
  selected: boolean;
  onSelect: () => void;
}

const ContactCardV2: React.FC<ContactCardV2Props> = React.memo(({ contactId, expanded, selected, onSelect }) => {
  const contact = useAppSelector(state => selectContactById(state, contactId));
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded);
  const [isFavorite, setIsFavorite] = useState<boolean>(contact?.tags?.includes('isfavorite'));
  const dispatch = useAppDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    if (contact) {
      setIsFavorite(contact?.tags?.includes('isfavorite'));
    }
  }, [contact]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    console.log(`Toggled expand for contact ${contactId}: ${!isExpanded}`);
  };

  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
    const updatedTags = isFavorite
      ? (contact.tags || []).filter(tag => tag !== 'isfavorite')
      : [...(contact.tags || []), 'isfavorite'];
    console.log(`Updating contact ${contactId} with tags:`, updatedTags);
    dispatch(updateContact({ id: contactId, tags: updatedTags }));
  };

  const getFirstLine = () => {
    if (contact.first_name || contact.last_name) {
      return `${contact.first_name || ''} ${contact.last_name || ''}`;
    }
    if (contact.email) {
      return contact.email;
    }
    if (contact.phone_number) {
      return contact.phone_number;
    }
    return 'No contact information available';
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short'
    }).format(date);
  };

  const renderContactDetails = () => {
    const addressParts = [
      contact.street_address_1,
      contact.street_address_2,
      contact.locality, // Use locality instead of city
      contact.state,
      contact.country_code,
    ].filter(Boolean).join(', '); // Filter out falsy values and join with a comma

    const details = [
      { icon: <Business />, label: contact.company_name ? `${contact.company_name} - ${contact.company_size} employees` : null, key: 'company_name' },
      { icon: <LocationOn />, label: addressParts || null, key: 'location' }, // Use addressParts
      { icon: <AttachMoney />, label: contact.deal_size ? `Deal size: ${contact.deal_size}` : null, key: 'deal_size' },
      { icon: <Person />, label: contact.assigned_to ? `Assigned to: ${contact.assigned_to}` : null, key: 'assigned_to' },
      { icon: null, label: contact.last_contacted ? `Last contacted: ${formatDate(contact.last_contacted)}` : null, key: 'last_contacted' },
      { icon: null, label: contact.shared_notes, key: 'shared_notes', tooltip: true },
      { icon: null, label: contact.admin_notes, key: 'admin_notes', tooltip: true }
    ];

    return details
      .filter(detail => detail.label) // Filter out null, undefined, or empty labels
      .map(detail => (
        <Typography
          variant="body2"
          color="textSecondary"
          key={detail.key}
          sx={{ maxHeight: detail.tooltip ? 50 : 'auto', overflow: 'hidden', position: 'relative' }}
          title={detail.tooltip ? String(detail.label) : ''} // Convert to string
        >
          {detail.icon && React.cloneElement(detail.icon, { fontSize: 'inherit', sx: { verticalAlign: 'middle', mr: 0.5 } })}
          {detail.label}
        </Typography>
      ));
  };

  const handleContactUpdate = (updatedContact) => {
    console.log('Contact updated:', updatedContact);
    // Update local state or perform additional actions if needed
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  if (!contact) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <>
      <MuiCard sx={{ mb: 2 }}>
        <CardHeader
          avatar={
            <Avatar
              src={contact.picture_url || '/api/placeholder/48/48'}
              alt={getFirstLine()}
              sx={{ width: 24, height: 24 }}
            />
          }
          action={
            <IconButton onClick={toggleFavorite}>
              {isFavorite ? <Star color="warning" /> : <StarBorder />}
            </IconButton>
          }
          title={
            <>
              {getFirstLine()}
              {contact.email && contact.email !== getFirstLine() && (
                <>
                  <br />
                  
                  {contact.email}
                </>
              )}
              {contact.phone_number && contact.phone_number !== getFirstLine() && (
                <>
                  <br />
                  {contact.phone_number}
                </>
              )}
            </>
          }
          subheader={contact.company_name || ''}
          onClick={() => {
            toggleExpand();
            handleDialogOpen(); // Open dialog on name click
          }}
          sx={{ cursor: 'pointer' }}
        />
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <CardContent>
            {renderContactDetails()}
            {console.log(`Expanded details for contact ${contactId}`)}
          </CardContent>
        </Collapse>
      </MuiCard>
      <ContactDialog
        contactId={contactId}
        open={dialogOpen}
        onClose={handleDialogClose}
        handleContactUpdate={handleContactUpdate} // Pass the update handler
      />
    </>
  );
}, (prevProps, nextProps) => {
  return prevProps.contactId === nextProps.contactId && prevProps.expanded === nextProps.expanded;
});

// Set display name for debugging
ContactCardV2.displayName = 'ContactCardV2';

export default ContactCardV2;
