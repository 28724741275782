import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, CircularProgress, TextField, Typography, Link,Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import { login } from 'src/features/auth/auth.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { loginSchema } from 'src/utils/formSchemas';
import { useSnackbar } from 'notistack';

const initialValues = {
  email: '',
  password: '',
};

const EmailLogin = ({ smsLogin, setSmsLogin, setDisplayedAuthScreen }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { authError } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [error, setError] = useState(false)
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setError(authError)

  }, [authError])

  // notistack notification for authSuccessMessage
  useEffect(() => {
    if (authError && authError.length > 0) {
      enqueueSnackbar(authError, { variant: 'error' });
    }
  }, [authError]);

  const onSubmit = (values) => {
    setLoading(true);
    dispatch(login({ ...values }))
      .unwrap()
      .then(() => {
        dispatch(trackAnalytics({ action_name: "user_logged_in", payload: { description: `user with email ${values.email} logged in` } }))
        navigate('/channels')
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <>
        <Formik
          initialValues={initialValues}
          validationSchema={loginSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <form noValidate onSubmit={handleSubmit}>
            {authError && <Typography color="error" textAlign="center" sx={{ mb: 2 }}>{authError}</Typography>}
              <TextField
                fullWidth
                autoComplete="email"
                type="email"
                name="email"
                label="Email"
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                margin="dense"
                required
                sx={{ 
                  mb: 2,
                  width: '100%'
                }}
                autoFocus
              />

              <TextField
                fullWidth
                autoComplete="current-password"
                type="password"
                name="password"
                label="Password"
                value={values.password}
                onChange={handleChange}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                margin="dense"
                required
                sx={{
                  mb: 2,
                  width: '100%'
                }}
              />

              <Link
                component={RouterLink}
                to="/forgot_password"
                onClick={() => setDisplayedAuthScreen('forgot_password')}
              color="secondary"
                sx={{ 
                textDecoration: 'none',
                mt:-1.5,
                justifyContent: 'flex-end',
                display: 'flex',
              }}
              >
                Forgot password?
              </Link>

                <Button
                  type="submit"
                  variant="contained"
                  disabled={loading}
                  sx={{ 
                    mt: 3, 
                    mb: 2, 
                    width: '100%',
                    p:2.5,
                    fontSize: '18px',
                  }}
                >
                  {loading && <CircularProgress size={24} sx={{ color: 'white' }} />}
                  Log In
                </Button>
            </form>
          )}
        </Formik>
      </>
  );
};

export default EmailLogin;
