import Layout from 'src/components/Layout';
// import 'src/styles/components/_dashboard.scss'
import ChannelManager from '../../components/Channels/ChannelManager';

const ChannelsPage = () => {

  return (
    <Layout>
      <ChannelManager />
    </Layout>
  );
};

export default ChannelsPage;
