import Lottie from 'react-lottie'
import * as zoomIn from 'src/assets/lottie/zoom-in.json'

const ZoomInIcon = () => {
 
    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: zoomIn,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };



    return (
            <Lottie 
                options={defaultOptions}
                height={25}
                width={25}
                isStopped={true}
                isPaused={true}
              />
      
    );
  }


export default ZoomInIcon;
