import React from 'react';
import { Box, Card, Typography, Link } from '@mui/material';
import { getBranding } from 'src/utils/branding';

const NotFound = () => {
  const oppyExpressionDrunk = getBranding('oppy_expressions_drunk');

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      p={3}
    >
      <Card
        sx={{
          maxWidth: 480,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: 3,
        }}
      >
        <Box
          component="img"
          src={oppyExpressionDrunk}
          alt="Lost and confused character"
          sx={{ width: '100%', maxWidth: 200, mb: 2 }}
        />
        <Typography variant="h1" component="h1" gutterBottom textAlign="center">
          We all get lost sometimes...
        </Typography>
        <Typography variant="body1" textAlign="center" mb={2}>
          This is a 404 error which means "Page not found", as in, I couldn't find the page you were looking for.
          Keep trying and I believe you will find what you need. Maybe you'd like to start back at{' '}
          <Link href="/" underline="hover">
            home
          </Link>
          . If you still can't find what you need, please don't hesitate to reach out. Our support team is standing by.
        </Typography>
      </Card>
    </Box>
  );
};

export default NotFound;
