import { Button, Modal, Row } from 'react-bootstrap';
import { useAppSelector } from 'src/store';

const AddServiceToExistingBookingConfirmation = ({
  showAddServiceConfirmation,
  handleGoBack,
  service,
  booking,
  checkAvailabilityAndUpdateBooking
}) => {
  const { square_feet, isLoading } = useAppSelector((state) => state.bookingServices)
  const darkMode = useAppSelector( state => state.theme.darkMode )

  return (
    <Modal
      show={showAddServiceConfirmation}
      fullscreen={'sm-down'}
      keyboard={false}
      centered
      onHide={handleGoBack}
      contentClassName="medium-width"
    >
      <Modal.Header>
        <div className="py-2 px-3 fw-medium fs-14 go-back">
          <img src={ darkMode ? "/icon/backLight.svg" : "/icon/back.svg" } onClick={handleGoBack} alt="" />
          &nbsp; Go back
        </div>
      </Modal.Header>
      <Modal.Body>
        {/* LargeScreen */}
        <div>
          <p className="fs-4 px-3 fw-medium lh-sm">
            Are you sure you want to add {service?.name} to this appointment?
          </p>
          <p className="px-3 fs-14">you will have to pay $ {service?.price} additionally for this service</p>
          <p className="px-3 fs-14">your new total will be $ {booking?.price + service?.price} <br />
            and now your appointment will take {booking.estimated_duration + (service?.fixed_time + (square_feet/1000) * service?.time_per_k)} minutes!
          </p>
          <Modal.Footer>
            {isLoading && <div className='animate__animated animate__pulse animate__infinite	infinite' style={{justifyContent: 'center'}}>
              <b>Checking the provider's calendar to make sure the new appointment length will work</b>
            </div>}
            <Button variant="outline-dark" onClick={handleGoBack} disabled={isLoading}>
              <span className="fw-medium">Close</span>
            </Button>
            <Button variant="danger" onClick={() => checkAvailabilityAndUpdateBooking(service)} disabled={isLoading}>
              <span className="fw-medium">Confirm</span>
            </Button>
          </Modal.Footer>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddServiceToExistingBookingConfirmation;
