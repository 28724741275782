import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import analyticsService from 'src/services/analytics';
import { serializeErrorMessage } from 'src/utils';

interface analyticsState {
    analyticsError: string;
    analyticsMessage: string;
}

const initialState: analyticsState = {
    analyticsError: null,
    analyticsMessage: null,
};

export const trackAnalytics = createAsyncThunk(
  'analytics/trackAnalytics',
  async (data: {action_name: string, payload: object } , thunkAPI) => {
  try {
      const { } = await analyticsService.sendEvent(data);
      return { };
  } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
  }
});

export const trackBookingAnalytics = createAsyncThunk(
  'analytics/trackBookingAnalytics',
  async (data: {action_name: string, payload: object } , thunkAPI) => {
  try {
      const { } = await analyticsService.sendBookingEvent(data);
      return { };
  } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
  }
});


const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(trackAnalytics.fulfilled, (state, action) => {
        state.analyticsMessage = action.payload as string;
      })
      .addCase(trackAnalytics.pending, (state, _) => {

      })
      .addCase(trackAnalytics.rejected, (state, action) => {
        state.analyticsError = action.payload as string;
      })
      .addCase(trackBookingAnalytics.fulfilled, (state, action) => {
        state.analyticsMessage = action.payload as string;
      })
      .addCase(trackBookingAnalytics.pending, (state, _) => {

      })
      .addCase(trackBookingAnalytics.rejected, (state, action) => {
        state.analyticsError = action.payload as string;
      });
  },
});

export const {} = analyticsSlice.actions

const analyticsReducer = analyticsSlice.reducer;

export default analyticsReducer;
