export const acceptableFileTypes = ["image/svg+xml", "image/svg", "image/jpeg", "image/jpg", "image/png", "video/mp4", ".mov", "video/quicktime", "video/mp", "application/pdf"]

let current = 0

export const generateUniqueId = (prefix) => `${prefix || 'id'}-${current++}`

export const resetIdCounter = () => current = 0

export const isEmpty = (obj) => {
  return obj === null || obj === '' || Number.isNaN(obj) || obj === undefined
}

export const firstGalleryItemSerializer = (firstGalleryItems) => (
  firstGalleryItems.reduce((hash, galleryItem) => (
    { ...hash, [galleryItem.galleriable_id]: { ...galleryItem } }
  ), {})
);

export const removeNulls = (obj) => {
  const isArray = Array.isArray(obj);
  for (const k of Object.keys(obj)) {
    if (isEmpty(obj[k])) {
      if (isArray) {
        obj.splice(parseInt(k), 1)
      } else {
        delete obj[k];
      }
    } else if (typeof obj[k] === "object") {
      removeNulls(obj[k]);
    }
    if (isArray && obj.length === parseInt(k)) {
      removeNulls(obj);
    }
  }
  return obj;
}

export const serviceValuesConversion = (apiService) => {
  return {
    name: apiService?.name || '',
    tagline: apiService?.tagline || '',
    description: apiService?.description || '',
    icon: apiService?.icon || '',
    slug: apiService?.slug || '',
    fixed_time: apiService?.fixed_time === null ? '' : apiService?.fixed_time,
    time_per_k: apiService?.time_per_k === null ? '' : apiService?.time_per_k,
    turnaround_time_hours: apiService?.turnaround_time_hours || '',
    turnaround_time_string: apiService?.turnaround_time_string || '',
    service_pricing_attributes: {
      pricing_methodology: apiService?.service_pricing?.pricing_methodology || '',
      amount: apiService?.service_pricing?.amount || '',
      min_price: apiService?.service_pricing?.min_price === null ? '' : apiService?.service_pricing?.min_price,
      max_price: apiService?.service_pricing?.max_price === null ? '' : apiService?.service_pricing?.max_price,
      service_pricing_tier_attributes: apiService?.service_pricing?.service_pricing_tier || [],
    },
    hidden: apiService?.hidden,
  }
}

export const getValidationErrors = (schema, data) => {
  const formFields = removeNulls(JSON.parse(JSON.stringify((data))))
  try{
    schema.validateSync(formFields, {abortEarly: false, context: {}})
    return null
  } catch (errors){
    return errors.inner
  }
}

export const compareChanges = (initialValues, data) => {
  let payload = {}
  const keys = Object.keys(data)
  keys.forEach((key, index) => {
    if (typeof data[key] === 'object'){
      if (!(JSON.stringify((initialValues[key])) === JSON.stringify((data[key])))){
        payload[key] = data[key]
      }
    }
    else{
      if (initialValues[key] != data[key]){ payload[key] = data[key] }
    }
    });
  return { updated_fields: payload }
}

export const meta = {
  title: "Oppy",
  description: "Hands Off Booking - Ultimate Control",
  meta: {
    itemProp: {
      name: "Oppy",
      description: "Hands Off Booking - Ultimate Control",
      image: "https://x.remark.re/image/upload/dpr_auto,q_auto/v1644444222/Remark/landing_page_screengrab.webp",
    },
    property: {
      'og:title': "Oppy",
      'og:description': "Hands Off Booking - Ultimate Control",
      'og:image': "https://x.remark.re/image/upload/dpr_auto,q_auto/v1644444222/Remark/landing_page_screengrab.webp",
    }
  },
}

export const getChatbotTheme = (theme) => {
  switch (theme) {
    case 1:
      return "light"
    case 2:
      return "dark"
    case 3:
      return "multicolored"
  }
}

// based on if the cookie  rmk_sign_in_redirect is onboarding_multi or if it's null redirect to on-boarding-multi or on-boarding
export const getOnboardingPath = () :string => {
  const signInRedirectCookie = document.cookie.replace(/(?:(?:^|.*;\s*)rmk_sign_in_redirect\s*\=\s*([^;]*).*$)|^.*$/, "$1");
  // return signInRedirectCookie == "onboarding_multi" ? "/on-boarding-multi" : "/on-boarding"
  return signInRedirectCookie == "onboarding_multi" ? "/channels" : "/channels"

}

export const networkError = "Error connecting with server!"
