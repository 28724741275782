import { Button, Modal } from 'react-bootstrap';
import { useAppSelector } from 'src/store';

const NoEnableService = ({ show, handleGoBack }) => {
  const darkMode = useAppSelector( state => state.theme.darkMode )
  return (
    <Modal
      show={show}
      fullscreen={'sm-down'}
      keyboard={false}
      centered
      onHide={handleGoBack}
    >
      <Modal.Header>
        <div className="py-2 px-3 fw-medium fs-14 go-back">
          <img src={ darkMode ? "/icon/backLight.svg" : "/icon/back.svg" } onClick={handleGoBack} alt="" />
          &nbsp; Go back
        </div>
      </Modal.Header>
      <Modal.Body>
        {/* LargeScreen */}
        <div className="text-center">
          Enable at least one service to get your booking page!
        </div>
        <Modal.Footer>
          <Button className="fw-medium" onClick={handleGoBack} >
            Got it
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default NoEnableService;
