import { useEffect, useState } from 'react';
import GoogleCalendar from 'src/libs/googleCalendar';

interface ResponseOfflineAccess {
  code: string;
}

const gCal = GoogleCalendar.getInstance();

const useGoogleAuth = () => {
  const [isSignIn, setIsSignIn] = useState(gCal.isSignIn);
  const [authData, setAuthData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [authorizationCode, setAuthorizationCode] = useState(null);

  const handleSignInRes = (res) => {
    setAuthData(() => res.getAuthResponse());
  };

  const handleOfflineAccess = (res: ResponseOfflineAccess) => {
    setAuthorizationCode(res.code);
  };

  const handleSignInError = (_error) => {
    setAuthData(() => null);
    setIsLoading(() => false);
  };

  const getOfflineAccess = () => {
    setIsSignIn(() => false);
    setIsLoading(true);
    gCal.grantOfflineAccess().then(handleOfflineAccess, handleSignInError);
  };

  const googleSignIn = () => {
    setIsLoading(true);
    gCal.signIn().then(handleSignInRes, handleSignInError);
  };

  const googleSignOut = () => {
    gCal.signOut();
  };

  useEffect(() => {
    if (authData || authorizationCode) {
      setIsSignIn(() => true);
    } else {
      setIsSignIn(() => false);
    }
    setIsLoading(() => false);
  }, [authData, authorizationCode]);

  return {
    isSignIn,
    authData,
    isLoading,
    googleSignIn,
    googleSignOut,
    getOfflineAccess,
    authorizationCode,
  };
};

export default useGoogleAuth;
