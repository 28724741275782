import React from 'react';
import { Box, Button, Typography, Skeleton, Tooltip, Avatar } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "src/store";
import { selectAllChannels, selectChannelById } from 'src/features/account/channels.slice';
import { toggleChannelsList, toggleCreateChannelDialog } from 'src/features/theme/theme.slice';
import { useDispatch } from 'react-redux';
import { Mail, Phone } from '@mui/icons-material';

const styles = {
  card: {
    position: 'relative',
    cursor: 'pointer',
    height: '150px',
    minWidth: '300px',
    pl: 2,
    pr: 2,
    pb: 2,
    pt: 1.5,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    alignItems: 'flex-start',
    gap: '10px',
    background: 'initial',
    borderRadius: '10px',
    '&:hover': { background: '#f5f5f5' },
  },
  list: {
    position: 'relative',
    cursor: 'pointer',
    height: '70px',
    width: '100%',
    minWidth: '30px',
    pl: 2,
    pr: 2,
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    background: 'grey.900',
    borderRadius: '5px',
    transition: 'all 0.3s ease',
    '&:hover': { 
      background: 'grey.800',
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    },
    mb: 2, // Increased margin bottom for more gap between items
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    p: 2,
    borderRadius: 3,
    background: 'rgb(139 145 167 / 3%);',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    overflowY: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
};

const ChannelsList = ({ variant = 'card' }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const channels = useAppSelector(selectAllChannels);
  const focusedChannel = useAppSelector(state => selectChannelById(state, searchParams.get('channel_id')));
  const { channelsLoading } = useAppSelector(state => state.channels);
  const channelDispatch = useDispatch();

  const handleChannelClick = (channelId: string) => {
    setSearchParams({ channel_id: channelId });
    navigate(`/channels?channel_id=${channelId}`);
    dispatch(toggleChannelsList(false));
  };

  function snakeToTitle(snakeString: string) {
    if (!snakeString) {
      return '';
    }
    return snakeString.split('_').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
  }

  const renderChannel = (channel) => {
    if (variant === 'list') {
      return (
        <Box onClick={() => handleChannelClick(channel.id)} key={channel.embed_key} 
          sx={{
            ...styles.list,
            background: focusedChannel && channel.id === focusedChannel.id ? 'grey.800' : 'grey.900',
            '&:hover': { 
              background: 'grey.700', // Slightly different background color on hover
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
            },
          }}>
          <Box sx={{ width: '40px', height: '40px', backgroundImage: `url(${channel.avatar_url})`, backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '50%' }} />
          <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '200px', overflow: 'hidden' }}>
            <Typography sx={{ fontSize: '14px', fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip', color: 'grey.300' }}>{channel.chatbot_name}</Typography>
            <Typography sx={{ width: '100%', maxWidth: '200px', fontSize: '12px', color: 'grey.500', whiteSpace: 'nowrap', overflowY: 'scroll', '&::-webkit-scrollbar': { display: 'none' }, msOverflowStyle: 'none', scrollbarWidth: 'none' }}>{channel.description}</Typography>
            <Box sx={{ display: 'flex', gap: '10px', mt: '4px' }}>
              {channel.email_address && (
                <Tooltip title={channel.email_address}>
                  <a href={`mailto:${channel.email_address}`} onClick={(e) => e.stopPropagation()}>
                    <Mail sx={{ fontSize: 16, color: 'grey.500' }} />
                  </a>
                </Tooltip>
              )}
              {channel.twilio_phone_number && (
                <Tooltip title={channel.twilio_phone_number}>
                  <a href={`sms:${channel.twilio_phone_number}`} onClick={(e) => e.stopPropagation()}>
                    <Phone sx={{ fontSize: 16, color: 'grey.500' }} />
                  </a>
                </Tooltip>
              )}
            </Box>
          </Box>
        </Box>
      );
    }
    return (
      <Box onClick={() => handleChannelClick(channel.id)} key={channel.embed_key} 
        sx={{
          ...styles.card,
          background: focusedChannel && channel.id === focusedChannel.id ? '#f5f5f5' : '#ffffff'
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', height: '100%', justifyContent: 'center' }}>
          {/* avatar, chatbot name and contact line in same box */}
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
            <Avatar sx={{ width: 40, height: 40 }} src={channel.avatar_url} />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', height: '100%', justifyContent: 'center' }}>
              <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{channel.chatbot_name}</Typography>
              <Typography sx={{ fontSize: '12px', color: '#555' }}>{channel?.twilio_phone_number || channel?.email_address}</Typography>
            </Box>
          </Box>
          <Typography sx={{ width:'100%', maxWidth: '250px', fontSize: '12px', color: '#828282' }}>{channel.description}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={styles.container}>
      {variant === 'card' && (
        <Typography variant="h4">Oppies</Typography>
      )}
      {channelsLoading ? (
        <>
          <Typography variant='caption'>Loading Oppies...</Typography>
          <Skeleton variant="rectangular" width="100%" sx={{ background: 'rgb(139 145 167 / 3%)' }} height={65} />
          <Skeleton variant="rectangular" width="100%" sx={{ background: 'rgb(139 145 167 / 3%)' }} height={65} />
          <Skeleton variant="rectangular" width="100%" sx={{ background: 'rgb(139 145 167 / 3%)' }} height={65} />
          <Skeleton variant="rectangular" width="100%" sx={{ background: 'rgb(139 145 167 / 3%)' }} height={65} />
          <Skeleton variant="rectangular" width="100%" sx={{ background: 'rgb(139 145 167 / 3%)' }} height={65} />
        </>
      ) : channels.length === 0 ? (
        <Box sx={styles.emptyState}>
          <Typography sx={{ fontSize: '24px', fontWeight: 'bold', mb: 2 }}>
            Make your first assistant in a few clicks!
          </Typography>
          <Button onClick={() => dispatch(toggleCreateChannelDialog())} variant="outlined" color="secondary">+ New Oppy</Button>
        </Box>
      ) : (
        channels.map(renderChannel)
      )}
    </Box>
  );
};

export default ChannelsList;
