const LavaLampBackground: React.FC = () => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    viewBox="0 0 1000 1000" 
    style={{ 
      // minHeight: '100vh',
      minHeight: '-webkit-fill-available',
      width: '100%',
      maxWidth: '100vw'
    }}
  >
    <style>
      {`
      @keyframes moveCircle1 {
        0%, 100% { transform: translate(0, 0); }
        50% { transform: translate(700px, 200px); }
      }
      @keyframes moveCircle2 {
        0%, 100% { transform: translate(0, 0); }
        50% { transform: translate(-700px, 200px); }
      }
      @keyframes moveCircle3 {
        0%, 100% { transform: translate(0, 0); }
        50% { transform: translate(-300px, -900px); }
      }
      @keyframes moveCircle4 {
        0%, 100% { transform: translate(0, 0); }
        50% { transform: translate(700px, 150px); }
      }
      .circle1 { animation: moveCircle1 300s infinite; }
      .circle2 { animation: moveCircle2 330s infinite; }
      .circle3 { animation: moveCircle3 360s infinite; }
      .circle4 { animation: moveCircle4 390s infinite; }
      .circle1, .circle2, .circle3, .circle4 {
        filter: blur(20px);
        transition: filter 0.3s ease;
      }
      `}
    </style>
    <rect width="100%" height="100%" fill="#000000" />
    <g>
      <circle className="circle4" cx="200" cy="800" r="200" fill="#ceff1a" opacity="0.9" />
      <circle className="circle1" cx="300" cy="300" r="250" fill="#bbb5ff" opacity="0.5" />
      <circle className="circle2" cx="700" cy="500" r="220" fill="#8b91a7" opacity="0.5" />
      <circle className="circle3" cx="500" cy="700" r="280" fill="#4949c8" opacity="0.7" />
    </g>
  </svg>
);

export default LavaLampBackground;
