// import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './App.scss';
// import './index.scss';
import reportWebVitals from './reportWebVitals';
import store from './store';

const ReactApp = () => (
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate( <ReactApp/>, rootElement);
} else {
  render( <ReactApp/>, rootElement);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
