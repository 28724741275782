import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box, Button, Dialog, DialogContent, DialogTitle, Fade, Paper, Popper } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useAppDispatch, useAppSelector } from 'src/store';
import { useSnackbar } from 'notistack';
import { phonePlans } from 'src/libs/products';
import { ReactComponent as ExclamationIcon } from 'src/assets/svg/exclamation.svg';
import { ReactComponent as DarkCircleCheckIcon } from 'src/assets/svg/Dark-circle-check.svg';


const PhoneNumberPurchaseDialog = ({ isOpen, onClose, handlePurchasePhoneNumber, subAccountStatus, a2pApproved, channelId }) => {
  const theme = useTheme();
  const { user } = useAppSelector((state) => state.user);
  const { channel } = useAppSelector((state) => state.channels.channels.find(channel => channel.id === channelId));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [billedAnnually, setBilledAnnually] = useState(false);
  const enqueueSnackbar = useSnackbar();
  const filteredPlanData = phonePlans(a2pApproved, subAccountStatus); 
  const [dlcPopperOpen, setDlcPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleBilledAnnualToggle = () => {
    setBilledAnnually((prevChecked) => !prevChecked);
  };

  const subscribe = (tier) => {
    // enqueueSnackbar(`Error while updating assistant. Please try again in a moment.`, {
    //   variant: 'error',
    //   autoHideDuration: 6000
    // });
    handlePurchasePhoneNumber();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      sx={{
        textAlign: "center",
        "& .MuiDialog-container": {
          "& .MuiDialog-paperWidthSm": {
            width: "100%",
            maxWidth: "750px",
            borderRadius: "10px",
            padding: "16px",
            maxHeight: '100%'
          },
        },
      }}
    >
      <DialogTitle>

        <Typography
            gutterBottom
            variant="h2"
            component="div"
            sx={{
              fontSize: '36px',
              fontWeight: 600,
              lineHeight: '42px',
              letterSpacing: '-0.02em',
              textAlign: 'center',
            }}
          >
            Phone Number Costs
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              paddingBottom: 3,
              lineHeight: '19px',
              letterSpacing: '0em',
              textAlign: 'center',
            }}
          >
            Simple, transparent pricing that grows with you.
          </Typography>
        </DialogTitle>
      <DialogContent>
       {/* phone plans would go here  */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Box
            sx={{ width: '32px', height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            {subAccountStatus == "verification_not_started" ? <ExclamationIcon style={{ fontSize: '24px' }} /> : <DarkCircleCheckIcon style={{ fontSize: '24px' }} />}
          </Box>

          <Button variant="text" onClick={(e) => {
            setDlcPopperOpen(!dlcPopperOpen);
            setAnchorEl(anchorEl ? null : e.currentTarget);
          }}>
            {!dlcPopperOpen ? `What is 10DLC?` : `Close`}
          </Button>
          <Popper open={dlcPopperOpen} anchorEl={anchorEl} transition style={{ zIndex: 2000, maxWidth: '500px' }}>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <Typography variant="h6" sx={{ p: 2, pb:0 }}>
                    What is 10DLC?
                  </Typography>
                  <Typography sx={{ p: 2 }}>
                    10DLC, or 10-Digit Long Code, is a long telephone number used by businesses to send SMS messages. It's not used exclusively for marketing, but can also send alerts such as customer service notifications or appointment reminders. With a 10DLC number, businesses can send SMS messages more affordably and effectively than the traditional 5-6 digit short codes used for advertising. Plus, with the right 10DLC approval, businesses can use their existing phone numbers to send messages. This makes the switch to 10DLC easier and more cost-effective.
                  </Typography>
                </Paper>
              </Fade>
            )}
          </Popper>

        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PhoneNumberPurchaseDialog;
