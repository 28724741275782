import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';
import { Contact } from 'src/features/account/contacts.slice'; // Corrected import statement for type

const fetch_contacts = (params: Partial<Contact> = {}) => {
  // **Exclude deal_size and score if they are 0**
  if (params.deal_size === 0) {
    console.log('Excluding deal_size from queryParams because it is 0'); // Debug log
    delete params.deal_size;
  }
  if (params.score === 0) {
    console.log('Excluding score from queryParams because it is 0'); // Debug log
    delete params.score;
  }

  // Process the 'stage' parameter
  if (params.stage) {
    let stage = params.stage;
    // Remove leading numbers and hyphens
    stage = stage.replace(/^\s*\d+-?\s*/, '');
    // Convert to snake_case
    stage = stage.trim().toLowerCase().replace(/\s+/g, '_');
    params = { ...params, stage };
    console.log('Transformed stage:', stage); // Log the transformed stage
  }
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.contacts}`
  return httpClient.get(url, { params });
}

const show = (contactId) => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.contacts}/${contactId}`
  return httpClient.get(url);
}

const update = (data) => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.contacts}/${data.id}`
  return httpClient.put(url, {"contact": data});
};

const destroy = (contactId) => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.contacts}/${contactId}`
  return httpClient.delete(url);
};

const create = (data) => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.contacts}`
  return httpClient.post(url, { contact: data }); 
}

const syncWithFub = () => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.contacts}/sync_with_fub`;
  return httpClient.post(url);
};

const ContactsService = {
  update,
  destroy,
  fetch_contacts,
  create,
  show,
  syncWithFub // Add syncWithFub to the exported service
};

export default ContactsService;
