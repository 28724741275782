import { Image } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setGalleryItems, uploadGalleryItem } from 'src/features/services/gallery.slice';
import FilestackUpload from 'src/components/FilestackUpload';
import { useState } from 'react';
import UploadEmbedGalleryItemV2 from './UploadEmbedGalleryItemV2';
import LoadingV2 from 'src/components/LoadingV2';
import ErrorPopup from 'src/components/ErrorPopup';

const GalleryItemUploaderV2 = ({ galleriableId, galleriableType, currentGalleryItems }) => {
  const dispatch = useAppDispatch();
  const [openFilestack, setOpenFilestack] = useState(false);
  const [showEmbedForm, setShowEmbedForm] = useState(false);
  const { isLoading, serviceError} = useAppSelector((state) => state.galleryServices);

  const setItemableType = (file) => {
    switch(file.mimetype.split('/')[0]) {
      case "image":
        return "Photo"
      case "video":
        return "Video"
      case "application":
        return "Attachment"
      default:
        return null
    }
  }

  const saveMedia = (file) => {
    dispatch(uploadGalleryItem({
      galleriable_id: galleriableId,
      galleriable_type: galleriableType,
      itemable_type: setItemableType(file),
      file: file.url,
    }))
  }

  const uploadedFiles = (files) => {

    let loading = [...currentGalleryItems]
    files.map((file, index) => {
      loading = [...loading, {id: -1 * (index+1), itemable_url: "/icon/loading.gif"}]
      saveMedia(file)
    })
    dispatch(setGalleryItems(loading))
  };

  const closeEmbedForm = () => {
    setShowEmbedForm(false)
  };

  return (
    <>
      {isLoading && <h1>LOADING</h1> }
      { serviceError && <ErrorPopup message={serviceError} /> }
      <div id="fullscreen-drop-pane"></div>
      <UploadEmbedGalleryItemV2
        galleriableId={galleriableId}
        galleriableType={galleriableType}
        show={showEmbedForm}
        close={closeEmbedForm}
      />
      <div
        className="add-item-button border align-items-center justify-content-center d-flex item item-empty"
        onClick={() => setOpenFilestack(true)}
      >
        <label htmlFor="image-upload-input">
          <Image
            src="/icon/addImageIcon.svg"
            className="mr-3 text-blue-added "
          />
        </label>
        <Image
          src="/icon/threeDotsVertical.svg"
          className="three-dots"
          onClick={(e) => {
            e.stopPropagation()
            setShowEmbedForm(true)
          }}
        />
        {openFilestack &&
          <FilestackUpload
            uploadedFiles={uploadedFiles}
            max_file_size_in_mb={150}
            onClose={() => setOpenFilestack(false)}
          />
        }
      </div>
    </>
  );
};

export default GalleryItemUploaderV2;
