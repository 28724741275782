import { Formik } from 'formik';
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import { useParams } from 'react-router-dom';
import { availabilityAlert } from 'src/features/services/booking.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { availabilityAlertSchema } from 'src/utils/formSchemas';
import AlertConfirmation from './AlertConfirmation';

const AvailabilityAlert = ({
  showAvailabilityAlert,
  handleClose,
  handleBookingClose,
  handleBookingShow,
  bookingDate,
}) => {
  const initialValues = {
    name: "",
    phone_number: "",
    email: "",
  }
  const [showAlertConfirmation, setShowAlertConfirmation] = useState(false);
  const { photographer, isLoading, selectedServices, address, square_feet } = useAppSelector((state) => state.bookingServices);
  let { uuid } = useParams();
  const dispatch = useAppDispatch();
  const darkMode = useAppSelector( state => state.theme.darkMode )

  const onSubmit = (data) => {
    data = {...data, address: address, service_ids: selectedServices, date: bookingDate, uuid: uuid, square_feet: square_feet}
    dispatch(availabilityAlert(data)).unwrap().then(() => {
      setShowAlertConfirmation(true);
    })
  };

  const closeAlertConfirmation = () => {
    setShowAlertConfirmation(false);
    handleBookingShow();
    handleClose();
  };

  const formattedDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).replace(',', '');
  }

  return (
    <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={availabilityAlertSchema}
      >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        touched,
        errors,
      }) => {
        return(
        <>
          <AlertConfirmation
            show={showAlertConfirmation}
            onClose={closeAlertConfirmation}
            photographer={photographer}
          />
          <Modal
            backdrop="static"
            show={showAvailabilityAlert}
            fullscreen={'sm-down'}
            keyboard={false}
            centered
            contentClassName="medium-width"
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <div
                className="py-2 px-3 fw-medium fs-14 go-back"
                role="button"
                onClick={handleClose}
              >
                <img src={ darkMode ? "/icon/backLight.svg" : "/icon/back.svg" } alt="" />
                &nbsp; Go back
              </div>
            </Modal.Header>

            <Modal.Body>
              <div className="d-flex flex-column align-items-center text-center px-3">
                <p className="fs-4 px-3 fw-medium lh-sm">Availability Alert</p>
                <p className="fs-14 lh-1 fw-medium">For {formattedDate(bookingDate)}</p>
              </div>

              <div className="mx-4">
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-medium-light">Contact</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Full Name"
                      className="mb-2 fs-14"
                      value={values.name}
                      id="name"
                      name="name"
                      onChange={handleChange}
                      isValid={touched.name && !errors.name}
                      isInvalid={touched.name && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                     <>{errors.name}</>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <PhoneInput
                      value={values.phone_number}
                      enableSearch={true}
                      onlyCountries={['us']}
                      disableDropdown={true}
                      country="us"
                      placeholder="Phone Number"
                      inputProps={{
                        id: "phone_number",
                        name: "phone_number",
                        className: "mb-2 fs-14 ht-46 w-100 form-control",
                      }}
                      isValid={touched.phone_number && !errors.phone_number}
                      onChange={(e) => {
                        if (!e.startsWith("1")) { e = "1" + e }
                        setFieldValue("phone_number",e)}
                      }
                    />
                    <div className="text-danger">
                      <>{errors.phone_number}</>
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Control
                      type="email"
                      placeholder="Email Address"
                      className="mb-2 fs-14"
                      name="email"
                      id="email"
                      value={values.email}
                      onChange={handleChange}
                      isValid={touched.email && !errors.email}
                      isInvalid={touched.email && !!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      <>{errors.email}</>
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button
                    className="w-100 mb-3"
                    variant="primary"
                    type="submit"
                    disabled={isLoading}
                  >
                    <span className="fw-medium">Set Alert</span>
                  </Button>
                </Form>

                <div className="d-flex flex-column align-items-center text-center px-3">
                  <p className="px-3 fs-14">
                    We will be in touch if {photographer?.name} becomes available for this time.
                  </p>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}}
    </Formik>
  );
};

export default AvailabilityAlert;
