import React from 'react';
import { Drawer, Accordion, AccordionSummary, AccordionDetails, Typography, Dialog } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../store';

const DebugDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const conversations = useAppSelector(state => state.conversations);
  const contacts = useAppSelector(state => state.contacts);
  const channels = useAppSelector(state => state.channels);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.metaKey && event.key === 'k') {
        setIsOpen(!isOpen);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, conversations, contacts, channels]);

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} sx={{ maxWidth: '100vw' }}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ color: 'black' }}>Conversations</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="pre" style={{ color: 'black' }}>{JSON.stringify(conversations, null, 2)}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ color: 'black' }}>Contacts</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="pre" style={{ color: 'black' }}>{JSON.stringify(contacts, null, 2)}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ color: 'black' }}>Channels</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="pre" style={{ color: 'black' }}>{JSON.stringify(channels, null, 2)}</Typography>
        </AccordionDetails>
      </Accordion>
    </Dialog>
  );
};

export default DebugDrawer;
