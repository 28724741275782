import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import GalleryService from 'src/services/gallery.services';
import { GalleryItem, ReqGalleryItemType } from 'src/types/gallery'
import { serializeErrorMessage } from 'src/utils';

interface galleryState {
  serviceError?: string;
  serviceSuccessMessage?: string;
  isLoading: boolean;
  currentGalleryItems: GalleryItem[];
}


const initialState: galleryState = {
  serviceError: null,
  serviceSuccessMessage: null,
  isLoading: false,
  currentGalleryItems: [],
};



export const getGalleryItems = createAsyncThunk(
  'gallery/get',
  async ({ galleriableType, galleriableId }: { galleriableType: string, galleriableId: string }, thunkAPI) => {
    try {
      const res = await GalleryService.getGalleryPhotos(galleriableType,galleriableId);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const uploadGalleryItem = createAsyncThunk(
  'services/gallery/add',
  async ( data: ReqGalleryItemType, thunkAPI ) => {
    try {
      const res = await GalleryService.uploadGalleryItem(data);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteGalleryItem = createAsyncThunk(
  'services/gallery/delete',
  async ({itemId}: {itemId: number}, thunkAPI) => {
    try {
      const res = await GalleryService.removeGalleryItem(
        itemId
      );
      return itemId;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);


const gallerySlice = createSlice({
  name: 'galleryServices',
  initialState,
  reducers: {
    setGalleryItems: (state, action) => {
      state.currentGalleryItems = action.payload
    },
    clearGalleryItems: (state) => {
      state.currentGalleryItems = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGalleryItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.serviceError = null
        state.currentGalleryItems = action.payload
      })
      .addCase(getGalleryItems.pending, (state, action) => {
        state.isLoading = true;
        state.serviceError = null
      })
      .addCase(getGalleryItems.rejected, (state, action) => {
        state.isLoading = false;
        state.serviceError = action.payload as string
        state.currentGalleryItems = []
      })
      .addCase(uploadGalleryItem.fulfilled, (state, action) => {
        state.isLoading = false;
        let flag = false
        state.currentGalleryItems = state.currentGalleryItems.filter((img) => {
          if (img.id > 0 || flag){ return true }
          flag = true
          return false
        })
        state.currentGalleryItems.push(action.payload)
        state.serviceError = null
      })
      .addCase(uploadGalleryItem.pending, (state, action) => {
        state.isLoading = true;
        state.serviceError = null
      })
      .addCase(uploadGalleryItem.rejected, (state, action) => {
        state.isLoading = false;
        let flag = false
        state.currentGalleryItems = state.currentGalleryItems.filter((img) => {
          if (img.id > 0 || flag){ return true }
          flag = true
          return false
        })
        state.serviceError = action.payload as string
      })
      .addCase(deleteGalleryItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentGalleryItems = state.currentGalleryItems.filter((item) => (
          action.payload != item.id
        ))
        state.serviceError = null
      })
      .addCase(deleteGalleryItem.pending, (state, action) => {
        state.isLoading = true;
        state.serviceError = null
      })
      .addCase(deleteGalleryItem.rejected, (state, action) => {
        state.isLoading = false;
        state.serviceError = action.payload as string
      })
  },
});

export const { clearGalleryItems, setGalleryItems} = gallerySlice.actions;

const galleryReducer = gallerySlice.reducer;
export default galleryReducer;
