import { useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

export const delayTime = 10000

const ToastMessage = ({ message, type }) => {
  const [show, setShow] = useState(true);
  const toggleShow = () => setShow(!show);

  return (
    <>
      { message &&
        <ToastContainer className="p-3 fixed-bottom position-fixed" position="top-center">
          <Toast bg={type} onClose={() => setShow(false)} show={show} delay={delayTime} autohide>
          <Toast.Header>
            <strong className="me-auto">{type == 'danger' ? "Error" : "Success"}</strong>
          </Toast.Header>
            <Toast.Body className="text-white">{message}</Toast.Body>
          </Toast>
        </ToastContainer>
      }
    </>
  );
};

export default ToastMessage;
