import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { getUserTravelPreferences, updateUserTravelPreferences } from 'src/features/user/user.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { locationAndTravelSchema } from 'src/utils/formSchemas';
import ErrorPopup from '../ErrorPopup';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress } from 'react-google-places-autocomplete';
import SuccessPopup from '../SuccessPopup';
import NumberFormat from 'react-number-format';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import { compareChanges } from 'src/libs/utils';
import CustomMap, { calculateRadiusInMiles, calculateZoom } from '../BookingConfirmation/CustomMap';
import { delayTime } from '../ToastMessage';
import subscriptionPopup from '../Subscription/SubscriptionPopup';
import Box from '@mui/material/Box';

const TravelForm = () => {
  const { subscriptionFeatures } = useAppSelector((state) => state.auth);
  const { travelPreferences, userError, userLoading } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(null)
  const [successMessage, setSuccessMessage] = useState("")

  const initialValues = {
    formatted_address: travelPreferences?.formatted_address || '',
    appointment_buffer: travelPreferences?.appointment_buffer || '',
    photographer_surcharge_travel_distance: travelPreferences?.photographer_surcharge_travel_distance || '',
    photographer_base_travel_distance: travelPreferences?.photographer_base_travel_distance || '',
    photographer_travel_surcharge: travelPreferences?.photographer_travel_surcharge || '',
    photographer_willing_to_surchage_travel: travelPreferences?.photographer_willing_to_surchage_travel || false,
    max_drive_time: travelPreferences?.max_drive_time || 120,
    snappiness_filter_max: travelPreferences?.snappiness_filter_max || '',
  };

  useEffect(() => {
    dispatch(getUserTravelPreferences())
      .unwrap()
      .then((response)=> {
        geocodeByAddress(response.data.formatted_address)
        .then(results => {
          if(results[0]){
            setValue({value: results[0], label: results[0].formatted_address})
          }
        })
      })
  }, []);

  const onSubmit = (data) => {
    let payload = compareChanges(initialValues, data)
    dispatch(updateUserTravelPreferences(data))
    .unwrap()
    .then((response) => {
      dispatch(trackAnalytics({action_name: "travel_preference_saved", payload: {...payload, description: "user updated travel form"}}))
      setSuccessMessage("Successfully Updated")
      setTimeout(() => {
        setSuccessMessage("")
      }, delayTime)
    })
  };

  return (
    <>
      <h3 className="mb-4">Advanced Scheduling Preferences</h3>
      {userError && <ErrorPopup message={userError}/>}
      {successMessage && <SuccessPopup message={successMessage}/>}

      <Formik
        initialValues={initialValues}
        validationSchema={locationAndTravelSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          resetForm,
          values,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <h4 className="mb-2">Buffer</h4>
            <p className="mb-4">
              The minimum amount of buffer time between appointments (before drive time buffers are added if applicable)
            </p>

            <Form.Label htmlFor="appointment_buffer">
              Minimum Appointment Buffer
            </Form.Label>
            <InputGroup className="mb-4">
              <FormControl
                type="number"
                id="appointment_buffer"
                name="appointment_buffer"
                min={0}
                disabled={userLoading}
                isInvalid={
                  touched.appointment_buffer &&
                  !!errors.appointment_buffer
                }
                onChange={handleChange}
                value={values.appointment_buffer}
                aria-describedby="minutes-travel"
              />

              <InputGroup.Text id="minutes-travel">minutes</InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                <>{errors.appointment_buffer}</>
              </Form.Control.Feedback>
            </InputGroup>

            <h4 className="mb-2">Filter Time Slots</h4>

            <Form.Label>
              Maximum time willing to drive round trip (based on forecasted traffic at the proposed appointment time) *beta
            </Form.Label>
            <InputGroup className='mb-4'>
              <FormControl
                type="number"
                placeholder="Time in minutes"
                aria-describedby="minutes"
                id="max_drive_time"
                name="max_drive_time"
                disabled={userLoading}
                min={0}
                isValid={touched.max_drive_time && !errors.max_drive_time}
                isInvalid={touched.max_drive_time && !!errors.max_drive_time}
                onChange={handleChange}
                value={values.max_drive_time}
              />
              <InputGroup.Text id="minutes">minutes</InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                <>{errors.max_drive_time}</>
              </Form.Control.Feedback>
            </InputGroup>

            <Form.Label>
              Snappiness Threshold - "Snappiness" filters and shows the most efficient time slots based on your set percentage.
            </Form.Label>
            <InputGroup className='mb-4'>
              <FormControl
                type="number"
                aria-describedby="minutes"
                id="snappiness_filter_max"
                name="snappiness_filter_max"
                disabled={userLoading}
                min={0}
                max={60}
                isValid={touched.snappiness_filter_max && !errors.snappiness_filter_max}
                isInvalid={touched.snappiness_filter_max && !!errors.snappiness_filter_max}
                onChange={handleChange}
                value={values.snappiness_filter_max}
              />
              <Form.Control.Feedback type="invalid">
                <>{errors.snappiness_filter_max}</>
              </Form.Control.Feedback>
            </InputGroup>

            <h4 className="mb-2">Distances</h4>
            <p className="mb-4">
              Tell us how far you are willing to travel from your home address,
              and how much you charge for longer distances. We use historical
              traffic data to make your schedule more efficient.
            </p>
            <Form.Label htmlFor="photographer_surcharge_travel_distance" className="mb-2">
              Maximum Distance
              <span className="text-danger"> *</span>
            </Form.Label>
            <InputGroup className="mb-3">
              <FormControl
                type="number"
                placeholder="Distance"
                aria-describedby="miles"
                id="photographer_surcharge_travel_distance"
                name="photographer_surcharge_travel_distance"
                disabled={userLoading}
                min={6}
                isValid={
                  touched.photographer_surcharge_travel_distance && !errors.photographer_surcharge_travel_distance
                }
                isInvalid={
                  touched.photographer_surcharge_travel_distance && !!errors.photographer_surcharge_travel_distance
                }
                onChange={handleChange}
                value={values.photographer_surcharge_travel_distance}
              />
              <InputGroup.Text id="miles">miles</InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                <>{errors.photographer_surcharge_travel_distance}</>
              </Form.Control.Feedback>
            </InputGroup>
            <p className="form-text">
              Maximum distance willing to travel even if paid extra (Straight
              line distance “As the crow flies”)
            </p>

            <div className="mb-3">
              <Form.Label htmlFor="formatted_address">
                Address <span className="text-danger">*</span>
              </Form.Label>

              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                selectProps={{
                  value,
                  onChange: (e) => {
                    setValue(e)
                    setFieldValue('formatted_address', e.label)
                  },
                  styles: {
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: 'var(--bs-secondary-bg)',
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: 'var(--body-text)',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      backgroundColor: 'var(--bs-secondary-bg)',
                    }),
                    option: (provided, {isFocused, isSelected}) => ({
                      ...provided,
                      color: 'var(--body-text)',
                      backgroundColor: (isFocused || isSelected) ? 'var(--bs-body-bg)' : 'var(--bs-secondary-bg)',
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: 'var(--body-text)',
                    }),
                  },
                }}
              />

              {values.formatted_address &&
                <Col style={{height: '300px', position: "relative"}}>
                  <CustomMap
                    address={values.formatted_address}
                    radius={calculateRadiusInMiles(values.photographer_surcharge_travel_distance)}
                    zoom={calculateZoom(values.photographer_surcharge_travel_distance)}
                  />
                </Col>}
            </div>

            <Box className="mb-3" sx={{display:'none'}}>
              <Form.Label className="mb-2">
                Add A Fee For Drives Over A Certain Length?
                <span className="text-danger"> *</span>
              </Form.Label>
              <Form.Check
                type="radio"
                label="yes"
                id="yes"
                value="true"
                name="photographer_willing_to_surchage_travel"
                disabled={userLoading}
                onChange={(e) => {
                  if (subscriptionFeatures?.drive_time_travel_fees?.permitted) { setFieldValue('photographer_willing_to_surchage_travel', true) }
                  else { subscriptionPopup(subscriptionFeatures, "Travel fees not included", { feature_id: "drive_time_travel_fees", description: "Provider can not use travel fees" }, dispatch) }
                }}
                checked={values.photographer_willing_to_surchage_travel === true}
              />
              <Form.Check
                type="radio"
                name="photographer_willing_to_surchage_travel"
                label="no"
                value="false"
                id="no"
                disabled={userLoading}
                onChange={(e) =>
                  setFieldValue(
                    'photographer_willing_to_surchage_travel',
                    false
                  )
                }
                checked={values.photographer_willing_to_surchage_travel === false}
              />
              {
                <Form.Control.Feedback type="invalid">
                  <>{errors.photographer_willing_to_surchage_travel}</>
                </Form.Control.Feedback>
              }
            </Box>

            <Box sx={{ display: 'none' }}>
              <Col sm={12} lg={6} className="mb-2">
                <Form.Label className="mb-2" htmlFor="photographer_base_travel_distance">
                  Travel Time Included In Service Fees
                  <span className="text-danger"> *</span>
                </Form.Label>
                <InputGroup className="mb-2">
                  <FormControl
                    type="number"
                    id="photographer_base_travel_distance"
                    min={0}
                    value={values.photographer_base_travel_distance}
                    name="photographer_base_travel_distance"
                    disabled={userLoading || !subscriptionFeatures?.drive_time_travel_fees?.permitted}
                    onChange={handleChange}
                    isValid={
                      touched.photographer_base_travel_distance &&
                      !errors.photographer_base_travel_distance
                    }
                    isInvalid={
                      touched.photographer_base_travel_distance &&
                      !!errors.photographer_base_travel_distance
                    }
                  />
                  <InputGroup.Text id="">minutes</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    <>{errors.photographer_base_travel_distance}</>
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
              <Col sm={12} lg={6}>
                <Form.Label htmlFor="photographer_travel_surcharge" className="mb-2">
                  Extra Charge Above Travel Time Included
                  <span className="text-danger"> *</span>
                </Form.Label>

                <InputGroup className="mb-3">
                  <InputGroup.Text>$</InputGroup.Text>
                  <NumberFormat
                    aria-label="Amount (to the nearest dollar)"
                    thousandSeparator={true}
                    className={`form-control ${ touched.photographer_travel_surcharge && !!errors.photographer_travel_surcharge ? "is-invalid" : ""}`}
                    id="photographer_travel_surcharge"
                    name="photographer_travel_surcharge"
                    allowNegative={false}
                    value={values.photographer_travel_surcharge}
                    disabled={userLoading || !subscriptionFeatures?.drive_time_travel_fees?.permitted}
                    onValueChange={(e) => {setFieldValue("photographer_travel_surcharge", e.value)}}
                  />
                  <InputGroup.Text>USD</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    <>{errors.photographer_travel_surcharge}</>
                  </Form.Control.Feedback>
                </InputGroup>
                  {/* <Form.Label>
                    Maximum time willing to drive round trip (based on forecasted traffic at the proposed appointment time)
                  </Form.Label>
                  <InputGroup>
                    <FormControl
                      type="number"
                      placeholder="Time in minutes"
                      aria-describedby="minutes"
                      id="max_drive_time"
                      name="max_drive_time"
                      disabled={userLoading}
                      min={0}
                      isValid={
                        touched.max_drive_time && !errors.max_drive_time
                      }
                      isInvalid={
                        touched.max_drive_time && !!errors.max_drive_time
                      }
                      onChange={handleChange}
                      value={values.max_drive_time}
                    />
                    <InputGroup.Text id="minutes">minutes</InputGroup.Text>
                    <Form.Control.Feedback type="invalid">
                      <>{errors.max_drive_time}</>
                    </Form.Control.Feedback>
                  </InputGroup> */}
              </Col>
            </Box>

            <hr className="mt-5 mb-4" />
            <div className="action-buttons">
              <Button variant="primary" type="submit" disabled={userLoading}>
                Save
              </Button>
              <Button variant="outline-secondary" className="me-lg-3" onClick={() => resetForm()}>
                Cancel
              </Button>{' '}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default TravelForm;
