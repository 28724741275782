import { PickerOverlay } from 'filestack-react'
import { acceptableFileTypes } from 'src/libs/utils';

const FilestackUpload = ({ uploadedFiles, max_file_size_in_mb, onClose }) => {


const options = {
  accept: acceptableFileTypes,
  maxSize: max_file_size_in_mb*1024*1024,
  onClose: onClose,
  maxFiles: 100
        
}
  return (
    <div>
        <PickerOverlay
          apikey={process.env.REACT_APP_FILESTACK_API_KEY}
          onUploadDone={(result) => uploadedFiles(result.filesUploaded)}
          onError={(res) => console.log(res)}
          pickerOptions={options}
        />
    </div>
  );
};

export default FilestackUpload;
