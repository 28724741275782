import { useState } from 'react';
import { Modal, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import PriceSelector from 'src/components/Services/forms/PriceSelector';
import TestPriceModal from './TestPriceModal';

const PricingModal = ({ isLoading, showView, closeView, handleChange, values, touched, errors, setFieldValue }) => {
  const [showTestPricingModal, setShowTestPricingModal] = useState(false);
  const [showError, setShowError] = useState(false);

  const closeTestPricingModal = () => {
    setShowTestPricingModal(false);
  };

  const testPricing = () => {
    setShowTestPricingModal(true);
    closeView();
  };

  const onSave = () => {
    setShowError(true)
    if (!errors.service_pricing_attributes) { closeView() }
  };

  return (
    <>
      <Modal
        backdrop="static"
        keyboard={false}
        show={showView}
        onHide={closeView}
        centered
        size={'lg'}
        contentClassName="large-width px-3"
        fullscreen={'sm-down'}
      >
        <Modal.Header closeButton className="fs-32 fw-medium">
          Pricing
        </Modal.Header>

        <Modal.Body className="pt-0">
          <PriceSelector
            showError={showError}
            handleChange={handleChange}
            values={values}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
          />
          {/* <div className="d-flex justify-content-center border-top p-4">
            <Button size="sm" onClick={testPricing}>
              Test Pricing
            </Button>
          </div>
          <div className="fs-14 text-align-center border-bottom pb-4">
            Your price would be $103 after the 2.9% card fee. You keep roughly
            $80
          </div> */}
          <div className="pt-3 ">
            <div className="row d-flex justify-content-end">
              <Button
                type="submit"
                size="sm"
                className="col-lg-2 fw-medium ml-2 order-xl-2 m-2"
                disabled={isLoading}
                onClick={onSave}
              >
                {isLoading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                Save
              </Button>
              <Button
                className="col-lg-2 fw-medium btn-light order-xl-1 m-2"
                variant="secondary"
                onClick={closeView}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <TestPriceModal
        showView={showTestPricingModal}
        closeView={closeTestPricingModal}
      />
    </>
  );
};

export default PricingModal;
