import React from 'react';
import { Formik, Form } from 'formik';
import { TextField, Button, Box } from '@mui/material';
import { Note } from 'src/features/notes/notes.slice';

interface NoteFormProps {
  note?: Note;
  onSave: (noteData: any) => Promise<any>;
  onCancel: () => void;
  initialValues?: any;
}

const NoteForm: React.FC<NoteFormProps> = ({ note, onSave, onCancel, initialValues }) => {
  const isEditMode = !!note;

  const initialFormValues = React.useMemo(() => initialValues || {
    subject: note?.subject ?? '',
    body: note?.body ?? '',
    owner_id: note?.owner_id ?? '',
    owner_type: note?.owner_type ?? '',
    status: note?.status ?? 'active',
    order: note?.order ?? 0,
    metadata: note?.metadata ?? {},
    tags: note?.tags ?? [],
  }, [initialValues, note]);

  console.log('Initial Form Values:', initialFormValues);

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        console.log('Form Values on Submit:', values);

        if (!values.owner_id || !values.owner_type) {
          alert('Owner ID and Owner Type are required.');
          setSubmitting(false);
          return;
        }

        try {
          await onSave(values);
          console.log('Note saved successfully:', values);
          resetForm();
        } catch (error: any) {
          console.error('Error saving note:', error);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting, handleChange, values, errors }) => (
        <Form>
          {console.log('Rendering NoteForm with values:', values)}
          <Box mb={2}>
            <TextField
              name="subject"
              label="Subject"
              value={values.subject}
              onChange={handleChange}
              fullWidth
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              name="body"
              label="Body"
              value={values.body}
              onChange={handleChange}
              multiline
              rows={4}
              fullWidth
              required
            />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={onCancel} disabled={isSubmitting} style={{ marginRight: '1rem' }}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
              {isEditMode ? 'Update Note' : 'Create Note'}
            </Button>
          </Box>

          <input type="hidden" name="owner_id" value={values.owner_id} />
          <input type="hidden" name="owner_type" value={values.owner_type} />
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(NoteForm); 
