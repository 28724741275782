import { useEffect, useState } from 'react';
import Geocode from "react-geocode";
import { Map, GoogleApiWrapper, Marker, Circle } from 'google-maps-react';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);

export const calculateZoom = (travel_distance) => {
  return travel_distance? 12 - Math.round(Math.log2(travel_distance/3)) : 12
}

export const calculateRadiusInMiles = (travel_distance) => {
  return travel_distance * 1611
}

const CustomMap = ({ address, google, radius=0, zoom=16 }) => {
  const [latLong, setLatLong] = useState(null);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    setReload(false)
  }, [address]);

  useEffect(() => {
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setLatLong({lat, lng})
        setReload(true)
      },
      (error) => {
        console.error(error);
      }
    );
  }, [address, google]);

  return (
    <>
      {latLong && reload ?
        <Map
          google={google}
          zoom={zoom}
          initialCenter={latLong}
        >
          <Marker
            label={address}
          />
          { radius && <Circle
            radius={radius}
            center={latLong}
            strokeColor='transparent'
            strokeOpacity={1}
            strokeWeight={5}
            fillColor='#FF0000'
            fillOpacity={0.2}
          />}
        </Map>
      :
        <div>Loading...</div>
      }
    </>
  )
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
})(CustomMap);
