import { createSlice } from '@reduxjs/toolkit';

interface MessageState {
  message: string | null;
}

const initialState: MessageState = {
  message: null,
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessage: (_state, action) => {
      return {
        message: action.payload,
      };
    },
    clearMessage: () => {
      return { message: '' };
    },
  },
});

export const { setMessage, clearMessage } = messageSlice.actions;

const messageReducer = messageSlice.reducer;
export default messageReducer;
