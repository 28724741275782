import React, { useMemo } from 'react';
import SimpleMDEEditor from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';
// import '../styles/_dark_mode_markdown_editor.scss'; // Include custom styles

// Define prop types
interface MarkdownEditorProps {
    value: string;
    onChange: (value: string) => void;
    maxLength: number;
    options?: object; // Add options prop
}

const MarkdownEditor: React.FC<MarkdownEditorProps> = React.memo((props) => {
    const { value, onChange, maxLength, options } = props;

    // Memoize options to prevent re-initialization
    const editorOptions = useMemo(() => ({
        autofocus: true,
        autoDownloadFontAwesome: true,
        spellChecker: false,
        status: false,
        ...options,
    }), [options]);

    return (
        <SimpleMDEEditor
            value={value}
            onChange={onChange}
            options={editorOptions}
        />
    );
});

// Assign displayName to the memoized component
MarkdownEditor.displayName = 'MarkdownEditor';

export default MarkdownEditor;
