import { Card } from 'react-bootstrap';
import { isEmpty } from 'src/libs/utils';
import { GreenLeaf } from './GreenLeaf';

interface Props {
  time: string;
  meridian: string;
  onClick: () => void;
  discount?: number;
}

export const BookingTime = ({ time, meridian, onClick, discount }: Props) => {
  return (
    <Card
      className="mb-2 p-2 fs-18 fw-medium-light lh-sm w-100"
      onClick={onClick}
    >
      <div className="d-flex justify-content-between w-100 px-1">
        <div className="d-flex">
          {time}{' '}
          {!isEmpty(discount) && !!discount && (
            <span className="text-blue-added fw-medium-light d-inline-flex align-items-center fs-14 ms-2">
              <GreenLeaf />
              save {discount}%
            </span>
          )}
        </div>
        <div>{meridian}</div>
      </div>
    </Card>
  );
};
