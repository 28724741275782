import { Button, Modal } from 'react-bootstrap';

const EnableServiceRequest = ({ show, handleGoBack, handleAddDetail, values, initialValues, enableErrors }) => {
  const nameConversion = {
    'name': 'Name',
    'tagline': 'Subtitle',
    'description': 'Description',
    'icon': 'Small Icon',
    'fixed_time': 'Fixed Service Time',
    'time_per_k': 'Variable Service Time',
    'turnaround_time_hours': 'Service Turnaround Time',
    'pricing_methodology': 'Pricing Method',
    'amount': 'Charge per Square Foot or Per Project',
    'min_price': 'Minimum Charge Per Project',
    'max_price': 'Maximum Charge Per Project',
    'lower_limit': 'Upper Limit',
  }

  const getErrorKeyName = path => (
    nameConversion[path.split('.').pop()] ||
      path.split('.').pop().split('_')
        .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
        .join(' ')
  )

  const errors = enableErrors.reduce((acc, error) => {acc[getErrorKeyName(error.path)]= error.message; return acc}, {})
  return (
    <Modal
      show={show}
      keyboard={false}
      centered
      onHide={handleGoBack}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Missing Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* LargeScreen */}
        <div >
          To enable <strong >{values?.name || initialValues?.name}</strong>, add the missing details (listed below):
          <ul>
           {Object.keys(errors).map((error, index) => (
              <li key={index} >
                {error} <b>{errors[error]}</b>
              </li>
            ))}
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
          <Button variant="outline-dark" onClick={handleGoBack}>
            <span className="fw-medium">Keep service disabled</span>
          </Button>
          <Button className="fw-medium" onClick={handleAddDetail}>
            Add Missing Info
          </Button>
        </Modal.Footer>
    </Modal>
  );
};

export default EnableServiceRequest;
