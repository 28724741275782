import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Image, Button, Container, Row, Form, FormCheck, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Gallery from 'src/components/Services/forms/Gallery';
import GeneralServiceInfo from 'src/components/Services/forms/GeneralServiceInfo';
import TimeService from 'src/components/Services/forms/TimeService';
import { useAppSelector } from 'src/store';
import { addServiceSchema } from 'src/utils/formSchemas';
import PricingModal from './PricingModal';
import TestTimeModal from './TestTimeModal';
import ErrorPopup from 'src/components/ErrorPopup';
import SuccessPopup from 'src/components/SuccessPopup';
import PreviewService from 'src/components/ServiceDetail/PreviewService';
import ExitEditServiceConfirmation from 'src/components/ExitConfirmation/ExitEditServiceConfirmation';
import EnableServiceSuggestion from 'src/components/EnableServiceSuggestion';
import EnableServiceRequest from 'src/components/EnableServiceRequest';
import { getValidationErrors } from 'src/libs/utils';
import 'animate.css';
import { handleWindowClose, removeHandleWindowCloseListner } from 'src/hooks/handleWindowClose';


const Service = ({ exitEditService, initialValues, isEdit, id, onSubmit, showEnableSuggestion, setShowEnableSuggestion }) => {
  const [pricingModalShow, setPricingModalShow] = useState(false);
  const [testTimeModalShow, setTestTimeModalShow] = useState(false);
  const { isLoading, serviceError, serviceSuccessMessage, currentGalleryItems } = useAppSelector((state) => state.photographerServices);
  const [editorState, setEditorState] = useState('')
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const [enableErrors, setEnableErrors] = useState({});
  const [showEnableService, setShowEnableService] = useState(false);
  const darkMode = useAppSelector( state => state.theme.darkMode )
  let pageHasChanges = false;
  let pageHasErrors = false;
  let canDisableButton = false;

  useEffect(() => {
    setEditorState(initialValues.description)
  }, [initialValues.description]);

  useEffect(() => {
    const callback = (ev) => {
      ev.preventDefault();
      return ev.returnValue = ''
    }
    handleWindowClose(callback)

    return () => removeHandleWindowCloseListner(callback)
  }, [])

  const closePricingModal = () => {
    setPricingModalShow(false);
  };
  const testTimeDuration = () => {
    setTestTimeModalShow(true);
  };
  const closeTestTimeModal = () => {
    setTestTimeModalShow(false);
  };

  const closeEnableServiceSuggestion = () => {
    setShowEnableSuggestion(false)
  };

  const closeExitConfirmation = () => {
    setShowExitConfirmation(false)
  };

  const handleServiceExit = (values) => {
    if (!pageHasChanges) {
      exitEditService()
    } else {
      setShowExitConfirmation(true)
    }
  }

  const closeEnableService = () => {
    setShowEnableService(false)
  };

  const handleEnable = (values, callingSource) => {
    const data = { ...values, hidden: false }
    const errors = getErrors(data)
    if (!errors) {
      onSubmit(data, null, callingSource)
    } else {
      setEnableErrors(errors)
      setShowEnableService(true)
    }
  }

  const getErrors = (data) => {
    return getValidationErrors(addServiceSchema, data)
  }

  const handleEnableService = (values) => {
    handleEnable(values, "enableButton")
    closeEnableServiceSuggestion()
  }

  const disableCheck = (data) => {
    if (data.hidden) {
      return false
    } else {
      return !!getErrors({ ...data, hidden: true })
    }
  }

  return (
    <>
      {showExitConfirmation && <ExitEditServiceConfirmation
        showExitConfirmation={showExitConfirmation}
        handleGoBack={closeExitConfirmation}
        exitEditService={exitEditService}
      />}

      {testTimeModalShow && <TestTimeModal
        showView={testTimeModalShow}
        closeView={closeTestTimeModal}
      />}
      {serviceSuccessMessage && <SuccessPopup message={serviceSuccessMessage} />}
      {serviceError && <ErrorPopup message={serviceError} />}
      <Container fluid>
        <div className="row vh-100">


          <Formik
            initialValues={initialValues}
            validationSchema={addServiceSchema}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              values,
              touched,
              errors,
            }) => {
              pageHasChanges = !(JSON.stringify((initialValues)) === JSON.stringify((values)))
              pageHasErrors = Object.keys(errors).length > 0
              canDisableButton = disableCheck(values)
              return (
                <>
                  {showEnableService && <EnableServiceRequest
                    show={showEnableService}
                    handleGoBack={closeEnableService}
                    handleAddDetail={closeEnableService}
                    values={values}
                    initialValues={initialValues}
                    enableErrors={enableErrors}
                  />}
                  {showEnableSuggestion && <EnableServiceSuggestion
                    show={showEnableSuggestion}
                    handleGoBack={closeEnableServiceSuggestion}
                    service={initialValues}
                    handleEnableService={() => handleEnableService(values)}
                    exitEditService={exitEditService}
                  />}
                  <div className="col col-lg-6 col-sm-12 bg-light p-0 pb-5">
                    <Form noValidate onSubmit={handleSubmit}>
                      <PricingModal
                        showView={pricingModalShow}
                        closeView={closePricingModal}
                        handleChange={handleChange}
                        values={values}
                        touched={touched}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        isLoading={isLoading}
                      />
                      <Container fluid>
                        <div className="row p-3 justify-content-end">
                          <Col xs={"auto"} className="me-auto my-auto">
                            <a className={"unstyled text-dark d-flex"}>
                              <Image onClick={() => handleServiceExit(values)} style={{ cursor: 'pointer' }} className="me-2" src={ darkMode ? "/icon/backChevronLight.svg" : "/icon/backChevron.svg" } />
                              <span style={{ cursor: 'pointer' }} onClick={() => handleServiceExit(values)}>Services</span> &nbsp;/ {values.name}
                            </a>
                          </Col>
                          <Col xs={"auto"} className="ms-auto mt-1 d-flex">
                            <Form.Group className="my-auto">
                              <div title={canDisableButton ? "There is some error" : ""}>
                                <FormCheck
                                  className="btn-custom fs-14 fw-medium ml-auto me-4 outline-secondary"
                                  id="hidden"
                                  type="switch"
                                  name="hidden"
                                  checked={!values.hidden}
                                  disabled={isLoading || canDisableButton}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      handleEnable(values, "toggleButton")
                                    } else {
                                      onSubmit({ ...values, hidden: true }, null, "toggleButton")
                                    }
                                  }}
                                  label={!values.hidden ? "Enabled" : "Disabled"}
                                />
                              </div>
                            </Form.Group>
                            <Button
                              variant="outline-dark"
                              size="sm"
                              className="btn-custom fs-14 fw-medium ml-auto me-4 outline-secondary"
                              onClick={() => setPricingModalShow(true)}
                            >
                              $ Pricing
                              {Object.keys(errors).includes("service_pricing_attributes") && (<span className="badge-pricing bg-primary badge">!</span>)}
                            </Button>
                            <Button
                              title={pageHasErrors ? "There is some error" : ""}
                              type="submit"
                              size="sm"
                              className={`fs-14 fw-medium ${!pageHasErrors && pageHasChanges ? 'animate__animated animate__pulse animate__infinite	infinite' : ''}`}
                              style={{ pointerEvents: 'auto' }}
                              disabled={isLoading || pageHasErrors}
                            >
                              {isLoading && (
                                <span className="spinner-border spinner-border-sm"></span>
                              )}
                              Save & Close
                            </Button>
                          </Col>
                        </div>
                      </Container>
                      <Row className="border-bottom border-top">
                        <div>
                          <div className="d-flex p-4 align-items-center">
                            <Image className="me-2" src="/icon/editIcon.svg" />
                            <h4 className="m-0 ms-2 text-truncate">{values.name}</h4>
                          </div>
                        </div>
                      </Row>

                      <Accordion className="custome-accordion" defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <Image
                              src="/icon/articleIcon.svg"
                              className="me-3 text-blue-added"
                            />
                            General
                            {['name', 'tagline', 'description'].some(el => Object.keys(errors).includes(el)) && (
                              <span className="bg-danger ms-1 badge">!</span>
                            )}
                          </Accordion.Header>
                          <Accordion.Body className="p-3">
                            <GeneralServiceInfo
                              editorState={editorState}
                              setEditorState={setEditorState}
                              handleChange={handleChange}
                              values={values}
                              touched={touched}
                              errors={errors}
                              setFieldValue={setFieldValue}
                            />
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>
                            <Image
                              src="/icon/clockIcon.svg"
                              className="me-3 text-blue-added"
                            />
                            Time
                            {['fixed_time', 'time_per_k', 'turnaround_time_hours'].some(el => Object.keys(errors).includes(el)) && (
                              <span className="bg-danger ms-1 badge">!</span>
                            )}
                          </Accordion.Header>
                          <Accordion.Body className="p-4">
                            <TimeService
                              handleChange={handleChange}
                              values={values}
                              touched={touched}
                              errors={errors}
                            />
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                          <Accordion.Header>
                            <Image
                              src="/icon/galleryIcon.svg"
                              className="me-3 text-blue-added"
                            />
                            Gallery
                          </Accordion.Header>
                          <Accordion.Body className="p-4">
                            {isEdit && (
                              <Gallery serviceId={id} values={values} />
                            )}

                            {!isEdit && (
                              <div>
                                You will be able to add images after saving the Service
                              </div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Form>
                  </div>
                  <div
                    className="col col-lg-6 "
                    style={{ background: '#2E937E' }}
                  >
                    <Container className="text-start">
                      <div className="d-flex justify-content-center align-items-center">
                        <div style={{ width: '25rem' }} className="m-5">
                          <h2 className="text-center">Service Preview</h2>
                          <p className="card-subtitle text-center">How your service will appear to customers</p><br />
                          <PreviewService
                            service={values}
                            galleryItems={currentGalleryItems}
                          />
                        </div>
                      </div>
                    </Container>
                  </div>
                </>
              )
            }}
          </Formik>
        </div>
      </Container>
    </>
  );
};

export default Service;
