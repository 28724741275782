const authToken = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user && user.access_token) {
    return user.access_token;
  }

  return '';
};

export default authToken;
