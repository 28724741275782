import ServiceTier from "./ServiceTier";

const TieredFeeSq = ({ showError, handleChange, values, touched, errors, setFieldValue }) =>
  (<ServiceTier
    showError={showError}
    handleChange={handleChange}
    values={values}
    touched={touched}
    errors={errors}
    setFieldValue={setFieldValue}
    tierType="sqft"
  />);

export default TieredFeeSq;
