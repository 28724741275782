import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { checkPaymentStatus } from 'src/features/services/booking.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import Loading from '../Loading';

const CheckoutCallback = ({ }) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { bookingError } = useAppSelector((state) => state.bookingServices);

  useEffect(() => {
    const ss = searchParams.get("session_secret")
    setSearchParams({})
    dispatch(checkPaymentStatus({
      session_secret: ss
    })).unwrap().then((response) => {
      navigate(`/bookings/${response.booking_uuid}`);
    })
  }, []);

  if ( bookingError ) {
    return <p className='text-danger text-center'>{bookingError}</p>
  }

  return (
    <Loading />
  );
};

export default CheckoutCallback;
