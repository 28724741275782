import { Button, Modal, Row } from 'react-bootstrap';
import { useAppSelector } from 'src/store';

const EnableServiceSuggestion = ({
  show,
  exitEditService,
  handleGoBack,
  handleEnableService,
  service,
}) => {

  const darkMode = useAppSelector( state => state.theme.darkMode )
  return (
    <Modal
      show={show}
      fullscreen={'sm-down'}
      keyboard={false}
      centered
      onHide={handleGoBack}
    >
      <Modal.Header>
        <div className="py-2 px-3 fw-medium fs-14 go-back">
          <img src= { darkMode ? "/icon/backLight.svg" : "/icon/back.svg" } onClick={handleGoBack} alt="" />
          &nbsp; Go back
        </div>
      </Modal.Header>
      <Modal.Body>
        {/* LargeScreen */}
        <div className="d-none d-sm-block">
          <Row className="px-3 lh-sm">
            <div>Your work is safe but your service is not yet enabled for booking!
            Would you like to enable <strong>{service.name}</strong> on your booking page?</div>
          </Row>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={exitEditService}>
              <span className="fw-medium">Keep disabled & Close</span>
            </Button>
            <Button className="fw-medium" onClick={handleEnableService}>
              Enable! & Close
            </Button>
          </Modal.Footer>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EnableServiceSuggestion;
