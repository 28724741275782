import { Formik } from 'formik';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { addServiceSchema } from 'src/utils/formSchemas';

const AddService = ( { show, closeAddService, serviceList, onSubmit } ) => {
  const initialValues = {
    name: '',
  };

  return (
    <Modal
      backdrop="static"
      show={show}
      fullscreen={'sm-down'}
      size="lg"
      keyboard={false}
      centered
      contentClassName="medium-width"
      onHide={closeAddService}
    >
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={addServiceSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          handleSubmit,
          handleChange,
          setFieldValue,
          values,
          touched,
          errors,
        }) => {
          return (
            <Form noValidate onSubmit={handleSubmit} className="">
              <Modal.Header closeButton>
                <Modal.Title>
                {serviceList?.length === 0? "Create your first service" : "Create a service" }
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Form.Group className="mb-3">
                    <Form.Label htmlFor="name" className="required">
                    Give your service a name
                    </Form.Label>
                    <Form.Control
                      placeholder="Service name"
                      id="name"
                      type="text"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isValid={touched.name && !errors.name}
                      isInvalid={touched.name && !!errors.name}
                      className=""
                      autoFocus
                    />
                    <Form.Control.Feedback type="invalid">
                      <>{errors.name}</>
                    </Form.Control.Feedback>
                  </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-dark"
                  className=""
                  onClick={closeAddService}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className=""
                >
                  Add Details
                </Button>
              </Modal.Footer>

          </Form>
          )}}
      </Formik>
    </div>
    </Modal>
  );
};

export default AddService;
