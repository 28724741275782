import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from 'src/services/auth.service';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@mui/material';

const Masquerade = ({ open, onClose, userId, userName }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleMasquerade = async () => {
    setLoading(true);
    try {
      const response = await AuthService.masquerade(userId);
      if (response.success) {
        console.log('Masquerade successful:', response);
        window.location.href = '/channels';
        window.location.reload();
      }
    } catch (error) {
      console.error('Masquerade failed', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Masquerade</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to masquerade as {userName}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleMasquerade} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Masquerade; 
