import { Button, Modal } from 'react-bootstrap';
import { useAppSelector } from 'src/store';

const Confirmation = ({
  showConfirmation,
  handleGoBack,
  handleConfirmation,
  message
}) => {

  const darkMode = useAppSelector( state => state.theme.darkMode )
  return (
    <Modal
      backdrop="static"
      show={showConfirmation}
      fullscreen={'sm-down'}
      keyboard={false}
      centered
      onHide={handleGoBack}
      contentClassName="medium-width"
    >
      <Modal.Header>
        <div className="py-2 px-3 fw-medium fs-14 go-back">
          <img src= { darkMode ? "/icon/backLight.svg" : "/icon/back.svg" } onClick={handleGoBack} alt="" />
          &nbsp; Go back
        </div>
      </Modal.Header>
      <Modal.Body>
        {/* LargeScreen */}
        <div>
          <p className="fs-4 px-3 fw-medium lh-sm">
            {message}
          </p>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={handleGoBack}>
              <span className="fw-medium">No</span>
            </Button>
            <Button variant="danger" onClick={handleConfirmation}>
              <span className="fw-medium">Yes</span>
            </Button>
          </Modal.Footer>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Confirmation;
