import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';

const getChannels = () => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_channel}`;
  return httpClient.get(url);
};

const getChannelConversations = (channelId) => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_conversations}?channel_id=${channelId}`;
  return httpClient.get(url);
};

const updateChannel = (channelData) => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_channel}/${channelData.id}`;
  return httpClient.patch(url, channelData);
};

const releasePhoneNumber = (channelId) => {
  const url = `${API_ROUTES.release_phone_number}`;
  return httpClient.post(url, { "channel_id": channelId })
    .then((response) => {
      if (response.data.success) {
        return response.data.channel;
      } else {
        throw new Error('Failed to release phone number.');
      }
    });
};

const getUsage = (id) => {
  return httpClient.get(`${API_ROUTES.usages}/${id}`);
};

const updateChannelPrompt = (channelId, prompt) => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_channel_update_prompt(channelId)}`;
  return httpClient.post(url, { "prompt": prompt });
}

const updateBccAndFallbackPhone = (channelId, smsNotificationsEnabled, fallbackPhone) => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_channel_update_bcc_and_fallback_phone(channelId)}`;
  return httpClient.post(url, { "sms_notifications_enabled": smsNotificationsEnabled, "fallback_phone_number": fallbackPhone });
}

const updateChannelAdminSettings = (channelId, data) => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_channel_update_admin_settings(channelId)}`;
  return httpClient.patch(url, data)
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.error);
      }
    });
};

const updateDelivery = (channelId, data) => {
  return httpClient.patch(`${API_ROUTES.channels}/${channelId}/update_channel_delivery`, data);
}

const destroy = (channelId) => {
  return httpClient.delete(`${API_ROUTES.channels}/${channelId}`);
}

const updatePublicFunctions = (channelId: string, publicFunctions: string[]) => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_channel_update_public_functions(channelId)}`;
  return httpClient.patch(url, { public_functions: publicFunctions });
};

const updateAdminFunctions = (channelId: string, adminFunctions: string[]) => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_channel_update_admin_functions(channelId)}`;
  return httpClient.patch(url, { admin_functions: adminFunctions });
};

const updateAutocompleteSchedule = (channelId, schedule) => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_channel_update_autocomplete_schedule(channelId)}`;
  return httpClient.patch(url, { autocomplete_schedule: schedule });
};

const getChannel = (channelId: string) => {
  const url = `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_channel}/${channelId}`;
  return httpClient.get(url);
};

const ChannelsService = {
  getChannels,
  getChannelConversations,
  updateChannel,
  releasePhoneNumber,
  getUsage,
  updateChannelPrompt,
  updateChannelAdminSettings,
  updateBccAndFallbackPhone,
  updateDelivery,
  destroy,
  updatePublicFunctions,
  updateAdminFunctions,
  updateAutocompleteSchedule,
  getChannel
};

export default ChannelsService;
