import { useEffect } from "react";
import Loading from "../Loading";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store';
import { createSubscription } from 'src/features/auth/auth.slice';

const SubscriptionCallback = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { authError } = useAppSelector((state) => state.auth);
  useEffect(() => {
    const cs = searchParams.get("checkout_session_id")
    setSearchParams({})
    dispatch(createSubscription({
      checkout_session_id: cs
    })).unwrap().then(() => navigate('/channels'))
  }, []);

  if (authError) {
    return <p className='text-danger text-center'>{authError}</p>
  }

  return (
    <Loading />
  );
};

export default SubscriptionCallback;
