import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export default function TestPriceModal({ showView, closeView }) {
  return (
    <Modal
      backdrop="static"
      keyboard={false}
      show={showView}
      onHide={closeView}
      centered
      contentClassName="large-width px-4"
      fullscreen={'sm-down'}
    >
      <Modal.Header closeButton className="fs-32 fw-medium">
        Test Pricing
      </Modal.Header>

      <Modal.Body className="pt-0">
        <div className="fs-14 ">
          Add the property info below to test the price of the service with the
          current settings.
        </div>
        <div className="row pt-3 pb-2">
          <div className="col-12">
            <h6 className="fs-14 fw-medium required">Square Ft</h6>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control ht-48"
                aria-describedby="basic-addon2"
              />
              <div className="input-group-append ht-48">
                <span
                  className="input-group-text fs-14 fw-medium"
                  id="basic-addon2"
                >
                  Sq. Ft.
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col">
            <h6 className="fs-14 fw-medium required ">USD Price</h6>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text ">$</span>
              </div>
              <input
                type="text"
                className="form-control ht-48"
                aria-label="Amount (to the nearest dollar)"
              />
              <div className="input-group-append ht-48">
                <span className="input-group-text fs-14 fw-medium">USD</span>
              </div>
            </div>
          </div>
          <div className="col-12 col">
            <h6 className="fs-14 fw-medium required ">Unit For the Service</h6>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control ht-48"
                aria-label="Amount (to the nearest dollar)"
              />
            </div>
          </div>
        </div>
        <Button className="col-12 mb-2">Save</Button>
      </Modal.Body>
    </Modal>
  );
}
