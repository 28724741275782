import { Formik } from 'formik';
import {  Box, Typography, TextField, Button, Link, Grid, CircularProgress } from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { resetPasswordSchema } from 'src/utils/formSchemas';
import { useAppDispatch, useAppSelector } from 'src/store';
import { resetPassword } from 'src/features/auth/auth.slice';
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

const ResetPassword = ({ displayedAuthScreen, setDisplayedAuthScreen }) => {
  const initialValues = {
    password: '',
    passwordConfirmation: '',
  };

  const dispatch = useAppDispatch();
  const { authError, authSuccessMessage } = useAppSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // notistack notification for authSuccessMessage
  useEffect(() => {
    if(authSuccessMessage && authSuccessMessage.length > 0){
      enqueueSnackbar(authSuccessMessage, {
        variant: 'success'
      });
    }
  }, [authSuccessMessage]);


  const onSubmit = (values, { setSubmitting }) => {
    const formData = {
      ...values,
      resetPasswordToken: searchParams.get("token")
    };

    dispatch(resetPassword(formData))
      .unwrap()
      .then(() => {
        setDisplayedAuthScreen('login')
        navigate('/login');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h3" sx={{
        opacity: 0.9,
      }}>
        Reset Password
      </Typography>
      {authError && <Typography color="error">{authError}</Typography>}
      {authSuccessMessage && <Typography color="success.main">{authSuccessMessage}</Typography>}
      <Typography>
        Already have login and password?
        <Link 
          component={RouterLink} 
          to="/login"
          onClick={() => setDisplayedAuthScreen('login')}
        >
          Login
        </Link>
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={resetPasswordSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, handleChange, touched, errors, isSubmitting }) => (
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, width: '100%' }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              onChange={handleChange}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="passwordConfirmation"
              label="Confirm Password"
              type="password"
              onChange={handleChange}
              error={touched.passwordConfirmation && Boolean(errors.passwordConfirmation)}
              helperText={touched.passwordConfirmation && errors.passwordConfirmation}
            />
            <Box sx={{ mt: 3, mb: 2 }}>
              {/* add helper text saying to check their spam folder if they don't see the email at first. The subject should look like "Reset password instructions" */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting && <CircularProgress size={24} />}
                {isSubmitting ? "Email Sent" : "Reset Password"}

              </Button>
              <Typography variant='caption' sx={{ opacity: 0.7 }}>Please check your email for a reset password link. If you don't see it, please check your spam folder.</Typography>
            </Box>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default ResetPassword;
