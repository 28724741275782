import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';

const EmbedDialogue = ({ show, close, item }) => {
  const [coppiedUrl, setCoppiedUrl] = useState(false)

  const copyUrl = () => {
    navigator.clipboard.writeText(item.embed_params.url)
    setCoppiedUrl(true)
    setTimeout(() => {
      setCoppiedUrl(false)
    }, 3000);
  }

  return (
    <Modal
      show={show}
      fullscreen={'sm-down'}
      keyboard={false}
      centered
      onHide={close}
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body >
        <div className='row'>
          <p>
            &lt;iframe <br />
            <div style={{ paddingLeft: "8px" }}>src="{item.embed_params.url}" </div>
            /&gt;
          </p>
          <Button variant="outline-secondary" onClick={() => copyUrl()} className="col fw-medium my-2">
            {coppiedUrl ?
              <><img src="/icon/clipboard-check-fill.svg" /> Embed URL Copied!</>
              : <><img src="/icon/clipboard.svg" /> Copy Embed URL</>
            }
          </Button>
        </div>
      </Modal.Body>
    </Modal >
  );
};

export default EmbedDialogue;
