import { Formik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { useAppSelector } from 'src/store';
import { changePasswordSchema } from 'src/utils/formSchemas';
import { getBranding } from 'src/utils/branding';
const ChangePassword = ({
  show,
  onClose,
  onSubmit,
  userLoading,
}) => {
  const initialValues = {
    current_password: '',
    password: '',
    password_confirmation: '',
  };
  const darkMode = useAppSelector( state => state.theme.darkMode )
  const branding_logo = getBranding('logo');
  return (
    <Modal
      backdrop="static"
      show={show}
      fullscreen={'sm-down'}
      keyboard={false}
      centered
      onHide={onClose}
      contentClassName="medium-width"
    >
      <Modal.Header closeButton>
        <>
        <div className="me-auto d-flex align-items-center fs-12 d-none d-sm-block ms-3">
          Powered by 
          <img src={branding_logo} className="me-2" alt="" />
        </div>
        <div className="py-2 px-3 fw-medium fs-14 go-back d-sm-none">
          <img src={ darkMode ? "/icon/backLight.svg" : "/icon/back.svg" } onClick={onClose} alt="" />
          &nbsp; Go back
        </div>
      </>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center text-center">
          <p className="fs-4 px-3 fw-medium lh-sm">Change Password</p>

          <div className='w-100'>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize
              validationSchema={changePasswordSchema}
            >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              resetForm,
              values,
              touched,
              errors,
            }) => {
              return (
              <Form noValidate onSubmit={handleSubmit}>
                <div className="mb-32">
                  <Form.Label htmlFor="formatted_address">
                    Old Password <span className="text-danger">*</span>
                  </Form.Label>

                  <Form.Control
                    value={values.current_password}
                    id="current_password"
                    name="current_password"
                    type="password"
                    className="mb-4"
                    onChange={handleChange}
                    isValid={touched.current_password && !errors.current_password}
                    isInvalid={touched.current_password && !!errors.current_password}
                  />

                  <Form.Control.Feedback type="invalid">
                    <>{errors.current_password}</>
                  </Form.Control.Feedback>
                </div>

                <div className="mb-32">
                  <Form.Label htmlFor="formatted_address">
                    New Password <span className="text-danger">*</span>
                  </Form.Label>

                  <Form.Control
                    value={values.password}
                    id="password"
                    name="password"
                    type="password"
                    className="mb-4"
                    onChange={handleChange}
                    isValid={touched.password && !errors.password}
                    isInvalid={touched.password && !!errors.password}
                  />

                  <Form.Control.Feedback type="invalid">
                    <>{errors.password}</>
                  </Form.Control.Feedback>
                </div>

                <div className="mb-32">
                  <Form.Label htmlFor="formatted_address">
                    Confirm Password <span className="text-danger">*</span>
                  </Form.Label>

                  <Form.Control
                    value={values.password_confirmation}
                    id="password_confirmation"
                    name="password_confirmation"
                    type="password"
                    className="mb-4"
                    onChange={handleChange}
                    isValid={touched.password_confirmation && !errors.password_confirmation}
                    isInvalid={touched.password_confirmation && !!errors.password_confirmation}
                  />

                  <Form.Control.Feedback type="invalid">
                    <>{errors.password_confirmation}</>
                  </Form.Control.Feedback>
                </div>

                <Button
                  className="w-100 mb-3"
                  variant="primary"
                  type="submit"
                  disabled={userLoading}
                >
                  <span className="fw-medium">Update Password</span>
                </Button>
              </Form>
            )}}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePassword;
