import { useEffect } from 'react';
import { Formik } from 'formik';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';
import { useAppSelector } from 'src/store';
import { propertyInfoSchema } from 'src/utils/formSchemas';
import NumberFormat from 'react-number-format';
import ErrorPopup from '../ErrorPopup';
import { CircularProgress } from '@mui/material';
import { getBranding } from 'src/utils/branding';

const PropertyInfo = ({
  showPropertyInfo,
  closePropertyInfo,
  onSubmit,
  value,
  setValue,
  showErrorPopup,
  showAddressField=true
}) => {
  const darkMode = useAppSelector( state => state.theme.darkMode )
  const { address, square_feet, listing_price, bookingError, showListingPrice, showSquareFeet, isLoading } = useAppSelector((state) => state.bookingServices);
  const initialValues = {
    address: address || '',
    listing_price: listing_price || '',
    show_listing_price: showListingPrice || false,
    square_feet: square_feet || '',
    show_square_feet: showSquareFeet || false,
  };
  const branding_logo = getBranding('logo');
  useEffect(() => {
    if (address) {
      geocodeByAddress(address)
      .then(results => {
        if(results[0]){
          setValue({value: results[0], label: results[0].formatted_address})
        }
      })
    } else { setValue(null) }
  }, [showPropertyInfo]);

  return (
    <Modal
      backdrop="static"
      show={showPropertyInfo}
      fullscreen={'sm-down'}
      keyboard={false}
      centered
      onHide={closePropertyInfo}
      contentClassName="medium-width"
    >
      {bookingError && <ErrorPopup message={bookingError}/>}
      <Modal.Header closeButton>
        <>
        <div className="d-flex remark-label fs-12 flex-grow-1 align-items-center">
          <span>Powered by </span>
          <img src={branding_logo} alt="Oppy.pro icon" style={{ maxHeight: '30px', marginLeft:'4px', marginBottom:"-4px", maxWidth: '35px' }} />
        </div>
        <div className="py-2 px-3 fw-medium fs-14 go-back d-sm-none">
          <img src={ darkMode ? "/icon/backLight.svg" : "/icon/back.svg" } onClick={closePropertyInfo} alt="" />
          &nbsp; Go back
        </div>
      </>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center text-center">
          <p className="fs-4 px-3 fw-medium lh-sm">Add Property Info</p>
          <p className="px-3 fs-14">
            We require this information to estimate prices and times for the
            services
          </p>
          <div className='w-100'>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              enableReinitialize
              validationSchema={propertyInfoSchema}
            >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              resetForm,
              values,
              touched,
              errors,
            }) => {
              return (
              <Form noValidate onSubmit={handleSubmit}>
                { showAddressField && <div className="mb-32">
                  <Form.Label htmlFor="formatted_address">
                    Address <span className="text-danger">*</span>
                  </Form.Label>

                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                    selectProps={{
                      value,
                      onChange: (e) => {
                        setValue(e)
                        setFieldValue('address',e.label)
                      },
                    }}
                  />
                  <p className='text-danger'>
                    <>{errors.address}</>
                  </p>
                  {/* { values.address && <Row>
                    <Col style={{height: '200px'}}>
                      <CustomMap
                        address={values.address}
                      />
                    </Col>
                  </Row>} */}
                </div>}

                { showSquareFeet &&
                <Form.Group>
                  <InputGroup className="mb-2">
                    <NumberFormat
                      thousandSeparator={true}
                      placeholder="Square feet (required)"
                      className={`mb-2 fs-14 form-control ${ touched.square_feet && !!errors.square_feet ? "is-invalid" : ""}`}
                      style={{zIndex: 0}}
                      name="square_feet"
                      allowNegative={false}
                      value={values.square_feet}
                      onValueChange={(e) => {setFieldValue("square_feet", e.value)}}
                    />
                    <InputGroup.Text className="mb-2 fs-14">Sq. Ft.</InputGroup.Text>
                    <Form.Control.Feedback type="invalid">
                      <>{errors.square_feet}</>
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>}

                { showListingPrice &&
                <Form.Group>
                  <InputGroup className="mb-2">
                    <InputGroup.Text className="mb-2 fs-14">$</InputGroup.Text>
                    <NumberFormat
                      thousandSeparator={true}
                      placeholder="Listing Price (required)"
                      className={`mb-2 fs-14 form-control ${ touched.listing_price && !!errors.listing_price ? "is-invalid" : ""}`}
                      style={{zIndex: 0}}
                      name="listing_price"
                      allowNegative={false}
                      value={values.listing_price}
                      onValueChange={(e) => {setFieldValue("listing_price", e.value)}}
                    />
                    <Form.Control.Feedback type="invalid">
                      <>{errors.listing_price}</>
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>}

                <Button
                  className={isLoading ? 'w-100 mb-3 btn btn-disabled' : 'w-100 mb-3 btn btn-primary'}
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading ? <CircularProgress color="inherit" size="1rem"/> : <span className="fw-medium">Save Information</span> }
                </Button>
              </Form>
            )}}
            </Formik>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PropertyInfo;
