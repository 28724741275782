import React, { useState } from 'react';
import { Button, Chip, Stack, Autocomplete, TextField, IconButton, Popover } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { fetchConversationsWithMessages } from "src/features/account/conversations.slice";
import { useAppDispatch } from 'src/store';
import ContactsSearchAutocomplete from '../Contacts/ContactsSearchAutocomplete'; // Import the component

const statusOptions = [
  "Closed",
  "Error",
  "Awaiting Oppy",
  "Awaiting User",
  "Processing Reply",
  "Standby",
  "Needs Attention"
];

const ConversationsListFilterBar = () => {
  const dispatch = useAppDispatch();
  const [selectedStatus, setSelectedStatus] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedContactId, setSelectedContactId] = useState("");
  const [subject, setSubject] = useState("");
  const [queryString, setQueryString] = useState("");
  const [latestDeliveryBefore, setLatestDeliveryBefore] = useState(null);
  const [latestMessageCreatedBefore, setLatestMessageCreatedBefore] = useState(null);
  const [lastUpdatedBefore, setLastUpdatedBefore] = useState(null);

  const handleFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusSelect = (event, newValue) => {
    setSelectedStatus(newValue);
    handleSubmit(newValue, selectedContactId);
    handleClose();
  };

  const handleSubmit = (status, contactId) => {
    const statusFilter = (() => {
      switch (status) {
        case "Closed": return "closed";
        case "Error": return "error";
        case "Awaiting Oppy": return "pending_assistant_reply";
        case "Awaiting User": return "pending_user_reply";
        case "Processing Reply": return "processing_autocomplete";
        case "Standby": return "standby";
        case "Needs Attention": return "needs_attention";
        default: return "";
      }
    })();
    dispatch(fetchConversationsWithMessages({
      status: statusFilter,
      contactId,
      subjectContains: subject,
      queryString,
      conversationLatestDeliveryAtBefore: latestDeliveryBefore,
      conversationLatestMessageCreatedAtBefore: latestMessageCreatedBefore,
      conversationLastUpdatedBefore: lastUpdatedBefore
    }));
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
      <IconButton onClick={handleFilterClick}>
        <FilterListIcon />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack spacing={2} sx={{ p: 2 }}>
          <Autocomplete
            id="status-filter"
            options={statusOptions}
            getOptionLabel={(option) => option}
            onChange={handleStatusSelect}
            value={selectedStatus || null}
            isOptionEqualToValue={(option, value) => option === value}
            sx={{ width: 200 }}
            renderInput={(params) => (
              <TextField {...params} variant="standard" label="Filter by Status" placeholder="Select Status" />
            )}
          />
          <TextField
            label="Filter by Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            variant="standard"
            placeholder="Enter Subject"
          />
          <TextField
            label="Filter by Query String"
            value={queryString}
            onChange={(e) => setQueryString(e.target.value)}
            variant="standard"
            placeholder="Enter Query String"
          />
          {/* <DateTimePicker
            label="Latest Delivery Before"
            value={latestDeliveryBefore}
            onChange={setLatestDeliveryBefore}
            renderInput={(params) => <TextField {...params} variant="standard" />}
          />
          <DateTimePicker
            label="Latest Message Created Before"
            value={latestMessageCreatedBefore}
            onChange={setLatestMessageCreatedBefore}
            renderInput={(params) => <TextField {...params} variant="standard" />}
          />
          <DateTimePicker
            label="Last Updated Before"
            value={lastUpdatedBefore}
            onChange={setLastUpdatedBefore}
            renderInput={(params) => <TextField {...params} variant="standard" />}
          /> */}
          <ContactsSearchAutocomplete onSelectContact={(contact) => setSelectedContactId(contact.id)} />
          <Button variant="contained" color="primary" onClick={() => handleSubmit(selectedStatus, selectedContactId)}>
            Apply
          </Button>
        </Stack>
      </Popover>
    </Stack>
  );
};

export default ConversationsListFilterBar;
