import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';
import { plainHttpClient } from 'src/libs/axios';

interface AnalyticsEvent {
  action_name: string;
  payload?: any;
}

const sendEvent = ({ action_name, payload }: AnalyticsEvent) => {
  httpClient.post(`${API_ROUTES.analytics}`, { action_name, payload });
};

const sendBookingEvent = ({ action_name, payload }: AnalyticsEvent) => {
  plainHttpClient.post(`${API_ROUTES.analytics}`, { action_name, payload });
};

const analyticsService = {
  sendEvent,
  sendBookingEvent,
};

export default analyticsService;
