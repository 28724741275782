import React, { useState, useEffect } from 'react';
import { httpClient } from 'src/libs';
import {
  Button,
  Alert,
  AlertTitle,
  LinearProgress,
  Typography,
  Box,
  Paper,
  Input,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InfoIcon from '@mui/icons-material/Info';
import { API_ROUTES } from 'src/constants/routes';
import Papa from 'papaparse';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});



const ContactsUploader = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [initializationResult, setInitializationResult] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isInitializing, setIsInitializing] = useState(false);
  const [parsedContacts, setParsedContacts] = useState([]);
  const [error, setError] = useState<string | null>(null);
  const [csvData, setCsvData] = useState(null);

  useEffect(() => {
    let timer;
    if (uploadStatus) {
      timer = setTimeout(() => {
        setUploadStatus(null);
      }, 10000);
    }
    return () => clearTimeout(timer);
  }, [uploadStatus]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isUploading) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isUploading]);

  const csvTemplateDownload = () => {
    const csvTemplate = `id,phone_number,email,first_name,last_name,address,metadata,picture_url,status,preferred_contact_method,company_name,company_size,company_industry,company_website,company_address,home_address,tags,timezone,locality,zip,state,country_code,street_address_1,street_address_2,shared_notes,admin_notes,last_contacted
    1,123-456-7890,art.vandelay@vandelay.com,Art,Vandelay,123 Fake St,{"key":"value"},http://example.com/pic.jpg,active,email,Vandelay Industries,100,Import/Export,http://vandelay.com,456 Industry Rd,789 Home St,"tag1,tag2",PST,New York,10001,NY,US,123 Main St,456 Side St,Shared notes here,Admin notes here,2021-01-01T00:00:00Z`;
    const blob = new Blob([csvTemplate], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'contacts_template.csv';
    link.click();
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setInitializationResult(null);
    setProgress(0);
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setCsvData(e.target.result);
      const parsed = Papa.parse(e.target.result, {
        header: true,
        skipEmptyLines: true,
        transformHeader: header => header.trim().toLowerCase(),
      }).data.map(row => ({
        id: row.id,
        phone_number: row.phone_number,
        email: row.email,
        first_name: row.first_name,
        last_name: row.last_name,
        address: row.address,
        metadata: row.metadata,
        picture_url: row.picture_url,
        status: row.status,
        preferred_contact_method: row.preferred_contact_method,
        company_name: row.company_name,
        company_size: row.company_size,
        company_industry: row.company_industry,
        company_website: row.company_website,
        company_address: row.company_address,
        home_address: row.home_address,
        tags: row.tags ? row.tags.split(',').map(tag => tag.trim()) : [],
        timezone: row.timezone,
        locality: row.locality,
        zip: row.zip,
        state: row.state,
        country_code: row.country_code,
        street_address_1: row.street_address_1,
        street_address_2: row.street_address_2,
        shared_notes: row.shared_notes,
        admin_notes: row.admin_notes,
        last_contacted: row.last_contacted
      }));
      setParsedContacts(parsed);
    };
    reader.readAsText(file);
  };

  const initializeUpload = () => {
    if (!csvData) {
      setUploadStatus('Please select a CSV file first.');
      return;
    }

    setIsInitializing(true);
    setUploadStatus(null);

    httpClient.post(`${process.env.REACT_APP_API_V3_URL}${API_ROUTES.contacts}/bulk_initialize`, { contacts: parsedContacts })
      .then(response => {
        if (response.status === 200) {
          setInitializationResult(response.data);
        } else {
          handleErrorResponse(response.data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setUploadStatus('An error occurred. Please try again.');
      })
      .finally(() => {
        setIsInitializing(false);
      });
  };

  const handleErrorResponse = (errorData) => {
    if (errorData.error && errorData.error.includes('contacts[0][tags] is invalid')) {
      setUploadStatus('Error: Invalid tags format. Please ensure tags are in a valid format (e.g., comma-separated).');
    } else {
      setUploadStatus('Error initializing upload. Please check your CSV format and try again.');
    }
  };

  const uploadBatch = (batch) => {
    return httpClient.post(`${process.env.REACT_APP_API_V3_URL}${API_ROUTES.contacts}/bulk_create`, { contacts: batch })
      .then(response => {
        if (!response.data.success || response.data.error || response.data.message) {
          throw new Error(response.data.error || response.data.message || 'Failed to upload batch');
        }
        return response.data;
      })
      .catch(error => {
        console.error('Batch upload error:', error);
        handleErrorResponse(error.response?.data || error);
        throw error;
      });
  };

  const handleUpload = () => {
    if (!file || !initializationResult) {
      setUploadStatus('Please initialize the upload first.');
      return;
    }

    setIsUploading(true);
    setProgress(0);

    const batchSize = 100;
    const batches = [];
    for (let i = 0; i < parsedContacts.length; i += batchSize) {
      batches.push(parsedContacts.slice(i, i + batchSize));
    }

    let successfulBatches = 0;
    let errorMessage = '';

    const processBatch = (index) => {
      if (index >= batches.length) {
        finishUpload();
        return;
      }

      uploadBatch(batches[index])
        .then(() => {
          successfulBatches++;
          setProgress((successfulBatches / batches.length) * 100);
          processBatch(index + 1);
        })
        .catch(error => {
          errorMessage = error.message;
          finishUpload();
        });
    };

    const finishUpload = () => {
      if (errorMessage) {
        setUploadStatus(`Error: ${errorMessage}`);
      } else {
        setUploadStatus(`Upload completed. ${successfulBatches} out of ${batches.length} batches were successful.`);
      }
      setIsUploading(false);
    };

    processBatch(0);
  };

  return (
    <Paper elevation={3} sx={{ maxWidth: 400, mx: 'auto', mt: 4, p: 3 }}>
      <Typography variant="h4" component="h2" gutterBottom>
        Upload Contacts CSV
      </Typography>
      <Button onClick={csvTemplateDownload}>Download CSV Template</Button>
      <Box sx={{ mb: 2 }}>
        <Button
          component="label"
          variant="contained"
          startIcon={<CloudUploadIcon />}
          sx={{ mb: 2 }}
          disabled={isUploading}
        >
          Select CSV File
          <VisuallyHiddenInput type="file" accept=".csv" onChange={handleFileChange} />
        </Button>
        {file && <Typography variant="body2">{file.name}</Typography>}
        <Button
          onClick={initializeUpload}
          variant="outlined"
          startIcon={<InfoIcon />}
          fullWidth
          sx={{ mb: 2, mt: 2 }}
          disabled={!file || isInitializing}
        >
          {isInitializing ? (
            <CircularProgress size={24} />
          ) : (
            'Initialize Upload'
          )}
        </Button>
        {initializationResult && (
          <Alert severity="info" sx={{ mb: 2 }}>
            <AlertTitle>Upload Information</AlertTitle>
            Existing contacts to be merged: {initializationResult.matched_count}<br />
            New contacts to be created: {initializationResult.new_count}
          </Alert>
        )}
        <Button
          onClick={handleUpload}
          variant="contained"
          startIcon={<CloudUploadIcon />}
          fullWidth
          disabled={!initializationResult || isUploading}
        >
          Upload Contacts
        </Button>
      </Box>
      {isUploading && (
        <Box sx={{ width: '100%', mb: 2 }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}
      {uploadStatus && (
        <Alert severity={uploadStatus.includes('completed') ? 'success' : 'error'}>
          {uploadStatus}
        </Alert>
      )}
    </Paper>
  );
};

export default ContactsUploader;
