import ToastMessage from "./ToastMessage";

const ErrorPopup = ({ message }) => {

  return (
    <ToastMessage message={message} type="danger"/>
  );
};

export default ErrorPopup;
