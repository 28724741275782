import React, { useState } from 'react';
import { Container, Dropdown, DropdownButton, Navbar } from 'react-bootstrap';
import Header from '../Header';
import SideBar from '../SideBar';

const Availability = ({ screenWidth }) => {
  const [menu, setMenu] = useState(true);
  const [WeeksDropDown, setWeeksDropDown] = useState({
    Monday: true,
    Tuesday: true,
    Wednesday: true,
    Thursday: true,
    Friday: true,
    Saturday: false,
    Sunday: false,
  });

  let weeks = [];

  Object.keys(WeeksDropDown).forEach((day, index) => {
    weeks.push(
      !WeeksDropDown[day] ? (
        <div className="row pt-3" key={index}>
          <div className="col fw-medium fs-24">{day}</div>
          <div className="col d-flex justify-content-end ">
            <label className="switch ">
              <input
                type="checkbox"
                checked={WeeksDropDown[day]}
                onChange={() => {
                  setWeeksDropDown((prev) => ({
                    ...prev,
                    [day]: !prev[day],
                  }));
                }}
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
      ) : (
        <div key={index}>
          <div className="row pt-3">
            <div className="col fw-medium fs-24">{day}</div>
            <div className="col d-flex justify-content-end ">
              <label className="switch ">
                <input
                  type="checkbox"
                  checked={WeeksDropDown[day]}
                  onChange={() => {
                    setWeeksDropDown((prev) => ({
                      ...prev,
                      [day]: !prev[day],
                    }));
                  }}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div>
            <div className="row pt-3 pb-3">
              <div className="col-6">
                <h6 className="fs-14 fw-medium required">Start Time</h6>
                <DropdownButton
                  id="dropdown-basic-button"
                  size="sm"
                  variant="light"
                  title="Select Start Time"
                  align="end"
                  className="dropdown "
                  style={{ fontSize: 14 }}
                >
                  <Dropdown.Item href="#/action-1">8 a.m</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">9 a.m</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">10 a.m</Dropdown.Item>
                </DropdownButton>
              </div>
              <div className="col-6">
                <h6 className="fs-14 fw-medium required">End Time</h6>
                <DropdownButton
                  id="dropdown-basic-button"
                  size="sm"
                  variant="light"
                  title="Select End Time"
                  align="end"
                  className="dropdown"
                >
                  <Dropdown.Item href="#/action-1">6 p.m</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">7 p.m</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">8 p.m</Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
          </div>
        </div>
      )
    );
  });

  return (
    <>
      <Header screenWidth={screenWidth} setMenu={setMenu} />
      {menu ? (
        <div className="row">
          <SideBar activePage={'availability'} />
        </div>
      ) : (
        <Container fluid>
          <div className="row">
            <SideBar
              className="col"
              screenWidth={screenWidth}
              activePage={'availability'}
            />
            <Navbar
              expand="lg"
              variant="light"
              bg="light"
              className="col px-lg-5"
            >
              <Container className="px-lg-5 mx-lg-5 ">
                <Container className="text-start px-lg-5 mx-lg-5">
                  <form>
                    <div className="">
                      <div className="pt-4 pb-3 fw-medium fs-48">
                        Booking Availability
                      </div>
                      {weeks}
                      <div className="border-top row justify-content-end mx-1">
                        <button
                          type="button"
                          className="col-xl-2 order-xl-2 mt-3 ml-3 btn btn-primary btn-sm"
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className="col-xl-2 order-xl-1 mt-3 ml-3 btn btn-sm btn-light"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </Container>
              </Container>
            </Navbar>
          </div>
        </Container>
      )}
    </>
  );
};

export default Availability;
