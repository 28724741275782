import Swal from "sweetalert2";
import { trackAnalytics } from "src/features/analytics/analytics.slice";

const subscriptionPopup = (subscriptionFeatures, featureTitle, payload, dispatch) => {

  Swal.fire({
    showCancelButton: true,
    confirmButtonText: 'Subscribe Now',
    icon: 'error',
    title: `${featureTitle}`,
    text: 'Pro Feature!'
  }).then((result) => {
    if (result.isConfirmed) {
      window.open(subscriptionFeatures?.higher_tier_payment_details.pro.payment_link)
      Swal.fire('Subscription link now open', 'Upgrade your account by filling in details here', 'success')
    }
  })

  dispatch(trackAnalytics({action_name: "paywall_limit_reacted", payload: { feature_id: `${payload.feature_id}`, description: `${payload.description}`}}))
};

export default subscriptionPopup;
