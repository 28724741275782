// pulsatingBorderStyle.js

/**
 * Checks if a given date string is valid.
 * @param {string} dateString - The date string to check.
 * @returns {boolean} True if valid, false otherwise.
 */
const isValidDate = (dateString) => {
  const date = new Date(dateString);
  return date instanceof Date && !isNaN(date.getTime());
}
/**
 * Injects keyframe animation and returns dynamic style for a pulsating border based on the time left until a specified future date.
 * If the future date is invalid, returns a default static style.
 * @param {string} futureDateTime - The future date and time until which the countdown is measured.
 * @param {string} baselineColor = 'darkgrey' - The baseline color for the border, defaults to dark grey.
 * @returns {Object} Style object to be applied to an element.
 */
const getPulsatingBorderStyle = (futureDateTime, baselineColor = 'darkgrey') => {
  if (!isValidDate(futureDateTime)) {
    // Return a default static style if the date is invalid
    return { border: `3px solid ${baselineColor}` };
  }

  const getTimeLeft = () => {
    const endTime = new Date(futureDateTime).getTime();
    const now = new Date().getTime();
    return (endTime - now) / 1000; // seconds left
  };

  const timeLeft = getTimeLeft();
  if (timeLeft <= 0) {
    // If the countdown is over, return a static style
    return { border: `3px solid ${baselineColor}` };
  }

  const animationDuration = Math.max(0.5, timeLeft / 5); // Faster as time decreases, minimum 0.5 seconds
  const animationName = `pulsate-${Math.random().toString(36).substr(2, 9)}`;

  const style = document.createElement('style');
  document.head.appendChild(style);
  style.sheet.insertRule(`
    @keyframes ${animationName} {
      0% { border-color: ${baselineColor}; }
      50% { border-color: transparent; }
      100% { border-color: ${baselineColor}; }
    }
  `, style.sheet.cssRules.length);

  return {
    animation: `${animationName} ${animationDuration}s infinite ease-in-out`,
    border: `3px solid ${baselineColor}`
  };
};

export default getPulsatingBorderStyle;
