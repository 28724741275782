import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from 'src/components/Layout';
import Service from 'src/components/Onboarding/Services/Index';
import { trackAnalytics } from 'src/features/analytics/analytics.slice';
import { getPhotographerServiceById, updatePhotographerService } from 'src/features/services/photographer.slice';
import { compareChanges, getValidationErrors, serviceValuesConversion } from 'src/libs/utils';
import { useAppDispatch, useAppSelector } from 'src/store';
import { addServiceSchema } from 'src/utils/formSchemas';

const ServiceEdit = () => {

  let { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentService } = useAppSelector((state) => state.photographerServices);
  const [showEnableSuggestion, setShowEnableSuggestion] = useState(false);

  const initialValues = serviceValuesConversion(currentService)

  useEffect(() => {
    dispatch(getPhotographerServiceById({id}));
  }, []);

  const exitEditService = () => {
    navigate('/services-list');
  };

  const checkEnableService = (values, callingSource) => {
    if(values.hidden && initialValues.hidden){
      const data = {...values, hidden: false}
      const errors = getValidationErrors(addServiceSchema, data)
      !errors? setShowEnableSuggestion(true) : exitEditService()
    }
    else if(callingSource === "saveButton" || callingSource === "enableButton"){
      exitEditService()
    }
  }

  const onSubmit = (data, _, callingSource="saveButton") => {
    let payload = compareChanges(initialValues, data)
    dispatch(updatePhotographerService({id, data})).unwrap().then(() => {
      checkEnableService(data, callingSource)
      dispatch(trackAnalytics({action_name: "save_service_clicked", payload: {...payload, description: `service with id ${id} updated`}}))
    }
    )
  };

  return (
    <Layout>
      <Service exitEditService={exitEditService} showEnableSuggestion={showEnableSuggestion} setShowEnableSuggestion={setShowEnableSuggestion} initialValues={initialValues} isEdit id={id} onSubmit={onSubmit}/>
    </Layout>
  );
};

export default ServiceEdit;
