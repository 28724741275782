import {
  PhotographUser,
  BookingSettingsType,
  BookingAvailabilityAttr,
  BookingAvailabilityType,
} from 'src/types/user';

export const PhotographMapper = (user: PhotographUser) => {
  return {
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    password: user.password,
    contact_phone: user.phone,
    phone_verification: user.phone_verification,
    timezone: user.timezone,
    //category: user.category,
    ref: user.ref,
    account_invite: user.account_invite,
  };
};

// Return an object with days as key and their information
// {
//  'monday': {
//      'start': '1'
//      'end': '1'
//      'enabled': true
//  }
// }
export const bookingAvailabilitySerializer = (items) => {
  if (items.length === 0) return {};

  return items.reduce((acc, item) => {
    return { ...acc, [item.day]: { ...item } };
  }, {});
};

export const bookingAvailabilityMapper = (items): BookingAvailabilityType => {
  const bookingAvailability = Object.keys(items).reduce((acc, objKey) => {
    if (items[objKey].start_time !== '') {
      acc.push({ ...items[objKey] });
    }
    return acc;
  }, []);

  const data: BookingAvailabilityType = {
    booking_availabilities_attributes:
      bookingAvailability as BookingAvailabilityAttr[],
  };

  return data;
};

export const weekDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const getBookingAvailabilityEmptyState = () => {
  return weekDays.reduce((acc, curr) => {
    acc[curr] = { start_time: '', end_time: '', day: curr, enabled: false };
    return acc;
  }, {});
};
