import Layout from 'src/components/Layout';
// import 'src/styles/components/_dashboard.scss'
import ChannelManager from '../../components/Channels/ChannelManager';
import { useEffect, useState } from 'react';
import { httpClient } from 'src/libs';
import { useSearchParams } from 'react-router-dom';
import { API_ROUTES } from 'src/constants/routes';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import ConfettiExplosion from "react-confetti-explosion";


const PhonePurchaseSuccess = () => {
  // if the url has /channels then /phone_purchase_success
  const [searchParams, setSearchParams] = useSearchParams();
  const {enqueueSnackbar} = useSnackbar();
  const cs = searchParams.get("checkout_session_id")
  const [channel, setChannel] = useState(null); 
  const [loading, setLoading] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [error, setError] = useState(null); // Add error state
  const navigate = useNavigate();

  const quotes = [
  "Remember when? Dial-up took at least 13 minutes to download a 3-minute song. A whole album could take hours!",
  "Dial-up was the first form of internet access. It was slow, but it was a start.",
  "\"Palm Before The Storm (1990s - 2010)\": Palm was iPhone before iPhone.It lost grip, but for a while, those Palm PDAs were peak tech swag!",
  "MySpace's Friendlier Times (2003 - 2008)\": MySpace was our first digital hangout long before Facebook. It might be quiet now, but let's \"Tom\" will forever be our first social media friend.",
  "'Winamp: it really whips the llama's ass!' - was a much-loved, customizable MP3 player developed in 1997."
];
  const [currentQuote, setCurrentQuote] = useState(quotes[0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentQuote(quotes[Math.floor(Math.random() * quotes.length)]);
    }, 4000); // Change this to the number of seconds you want.

    return () => clearInterval(intervalId); // This clears the interval on unmount.
  }, []);

  useEffect(() => {
    handlePurchasePhoneNumber()
  }, [cs]);

  useEffect(() => {
    if (!loading && (channel || error)) {
      if (channel?.twilio_phone_number) {
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 3000);
        enqueueSnackbar(`Your phone number ${channel.twilio_phone_number} has been purchased and is ready for use!`, { variant: 'success' });
      }
      if (channel && channel.id && error) {
        enqueueSnackbar(error.message, { variant: 'error' });
        navigate(`/channels`)
      } else if (channel && channel.id) {
        navigate(`/channels?channel_id=${channel?.id}`)
      } else {
        navigate(`/channels`)
      }
    }
  }, [channel, loading, error]);

  const handlePurchasePhoneNumber = async () => {
    const url = `${process.env.REACT_APP_API_URL}${API_ROUTES.phone_purchase_success}`;
    try {
      const response = await httpClient.post(url, { checkout_session_id: cs });
      if (response.data.success) {
        setChannel(response.data.channel);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setError(error);
      navigate(`/channels?channel_id=${channel?.id}`);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <Layout>
      {/* mui Box which uses flex and centers everything vertically and horizontally */}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', width:'100%' }}>
          <Typography variant="h2" component="h1" gutterBottom>
            Dialing up your new number...
          </Typography>
          <CircularProgress />
          {/* write a fun fact about dialup */}
        <Typography sx={{ maxWidth: 500, display: 'flex',textAlign:'center', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} variant="caption" component="h1" gutterBottom>
            {currentQuote}
          </Typography>
      </Box>
      {showConfetti && <ConfettiExplosion />}
    </Layout>
  );
};

export default PhonePurchaseSuccess;
