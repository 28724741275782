import { Formik } from 'formik';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Form,
  InputGroup,
} from 'react-bootstrap';
import { createBooking, createStripeAccount } from 'src/features/services/booking.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { userCheckoutSchema } from 'src/utils/formSchemas';
import ErrorPopup from '../ErrorPopup';
import { trackBookingAnalytics } from 'src/features/analytics/analytics.slice';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import { getBranding } from 'src/utils/branding';
const Checkout = ({ uuid, show, onClose, showConfirmation, slotDetail, selectedServices, handleShowPropertyInfo }) => {
  const darkMode = useAppSelector( state => state.theme.darkMode )
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { subscriptionFeatures, photographer, bookingError, bookingSettings, square_feet, listing_price, address, bookingServices, isLoading } = useAppSelector((state) => state.bookingServices);
  const initialValues = {
    created_by_first_name: '',
    created_by_last_name: '',
    created_by_phone: '',
    created_by_email: '',
    client_notes: '',
  };
  const branding_logo = getBranding('logo');
  const onSubmit = (data) => {
    const info = {
      uuid: uuid,
      address: address,
      square_feet: square_feet,
      listing_price: listing_price,
      service_ids: selectedServices,
      selected_time: slotDetail?.start_time,
      booking: data }
    dispatch(createBooking(info)).unwrap().then((response) => {
      dispatch(trackBookingAnalytics({
        action_name: "booking_created",
        payload: {
          description: `user with ${data.created_by_email} booked services`,
          client_details: data,
          booked_services: selectedServices,
          address: address,
          date_time: slotDetail?.start_time,
          square_feet: square_feet,
          listing_price: listing_price,
          uuid: response.uuid
        }
      }))

      response?.payment_collection_type === "never_charge" ?
      navigate(`/c/${response.uuid}`) :
      dispatch(createStripeAccount({
        booking_uuid: response.uuid,
      })).unwrap().then((res) => {
        window.location.href = res.url
      })
      // showConfirmation();
    })
  };

  const totalPrice =
    bookingServices.filter((service) => (selectedServices.includes(service.id)))
      .reduce((sum,service) =>  sum = sum + parseFloat(service.price) , 0 )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={ onSubmit }
      enableReinitialize
      validationSchema={userCheckoutSchema}
    >
      {({
      handleSubmit,
      handleChange,
      setFieldValue,
      resetForm,
      values,
      touched,
      errors,
      }) => {
        const travel_cost = slotDetail?.travel_cost ? slotDetail.travel_cost : 0
      return(
      <Modal
        show={show}
        fullscreen={'sm-down'}
        keyboard={false}
        centered
        size="xl"
        onHide={onClose}
        dialogClassName="pb-5"
        contentClassName="large-width overflow-auto"
      >
       <Form noValidate onSubmit={handleSubmit} className="mt-3">
        <Modal.Header closeButton>
          <div
            className="py-2 fw-medium fs-14 go-back"
            role="button"
            onClick={onClose}
          >
            <img src={ darkMode ? "/icon/backLight.svg" : "/icon/back.svg" } alt="" />
            &nbsp; Back
          </div>
        </Modal.Header>

        <Modal.Body>
          {bookingError && <ErrorPopup message={bookingError}/>}
          <Container>
              <Row>
              <Col
                className="d-flex flex-column align-items-center"
                lg={6}
                md={12}
                sm={12}
              >
                <div className="fw-medium fs-4">Checkout</div>
                  <div className='mt-3'>
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-medium-light fs-14">
                        Contact
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        className="mb-2 fs-14"
                        name="created_by_first_name"
                        value={values.created_by_first_name}
                        onChange={handleChange}
                        isValid={touched.created_by_first_name && !errors.created_by_first_name}
                        isInvalid={touched.created_by_first_name && !!errors.created_by_first_name}
                      />
                      <Form.Control.Feedback type="invalid">
                        <>{errors.created_by_first_name}</>
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        className="mb-2 fs-14"
                        name="created_by_last_name"
                        value={values.created_by_last_name}
                        onChange={handleChange}
                        isValid={touched.created_by_last_name && !errors.created_by_last_name}
                        isInvalid={touched.created_by_last_name && !!errors.created_by_last_name}
                      />
                       <Form.Control.Feedback type="invalid">
                        <>{errors.created_by_last_name}</>
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                    <PhoneInput
                        value={values.created_by_phone}
                        enableSearch={true}
                        onlyCountries={['us']}
                        disableDropdown={true}
                        country="us"
                        placeholder="Enter phone number"
                        inputProps={{
                          id: "created_by_phone",
                          name: "created_by_phone",
                          className: "mb-2 fs-14 ht-46 w-100 form-control",
                        }}
                        isValid={touched.created_by_phone && !errors.created_by_phone}
                        onChange={(e) => {
                          if (!e.startsWith("1")) { e = "1" + e }
                          setFieldValue("created_by_phone",e)
                        }}
                      />
                      <div className="text-danger">
                        <>{errors.created_by_phone}</>
                      </div>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Control
                        type="email"
                        placeholder="Email Address"
                        className="mb-2 fs-14"
                        name="created_by_email"
                        value={values.created_by_email}
                        onChange={handleChange}
                        isValid={touched.created_by_email && !errors.created_by_email}
                        isInvalid={touched.created_by_email && !!errors.created_by_email}
                      />
                       <Form.Control.Feedback type="invalid">
                        <>{errors.created_by_email}</>
                      </Form.Control.Feedback>
                    </Form.Group>
{/*
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-medium-light fs-14">
                        Payment
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Card Number"
                        className="mb-2 fs-14"
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Cardholder Name"
                        className="mb-2 fs-14"
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="MM / YY"
                        className="fs-14 w-25 me-2"
                        onChange={handleChange}
                      />
                      <Form.Control
                        type="text"
                        placeholder="CVV"
                        className="fs-14 w-45"
                        onChange={handleChange}
                      />
                    </InputGroup>

                    <Form.Group className="mb-4">
                      <Form.Check
                        type="checkbox"
                        label="Lorem ipsum dolor sit amet elit."
                        className="fs-14"
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder="CVV"
                        className="mb-1 fs-14 me-2"
                        value="BF2021"
                        required
                        onChange={handleChange}
                      />
                      <Button variant="secondary" type="submit">
                        <span className="fw-medium">Apply</span>
                      </Button>
                    </InputGroup>
                    <div className="text-blue-added fs-12 fw-medium-light mb-4">
                      Applied
                    </div> */}
                  </div>
              </Col>
              {/* <div className="vr p-0 m-0 d-none d-lg-block" /> */}
              <Col
                className="d-flex flex-column align-items-stretch text-center"
                lg={6}
                md={12}
                sm={12}
              >
                <div className="fw-medium fs-4">Booking Details</div>
                <div className="mt-4">
                  <div className="fw-medium-light fs-18">
                    <img src="/icon/smallCalendar.svg" alt="" className="me-2" />
                    {slotDetail?.detailed_start_time} <br /> ({slotDetail?.duration} Minutes)
                  </div>
                  <div onClick={onClose} role="button" className="text-blue-added fw-medium fs-14 mb-3">Edit</div>

                  <div className="fw-medium-light fs-18">
                    <img src="/icon/location.svg" alt="" className="me-2" />
                    {address}
                  </div>
                  <div onClick={handleShowPropertyInfo} role="button" className="text-blue-added fw-medium fs-14 mb-3">Edit</div>

                  <div className="d-flex justify-content-between fs-14 fw-medium-light">
                    Summary
                  </div>

                  <hr />

                  {bookingServices.filter((service) => (selectedServices.includes(service.id))).map((service) =>
                  (<div
                    className="d-flex justify-content-between fs-14 mb-2"
                    key={`service-${service.id}`}
                  >
                    <div className='text-truncate'>{service.name}</div>
                    <div>${service.price}</div>
                  </div>)
                  )}

                  <hr />
                  {slotDetail?.travel_cost > 0 &&
                    <>
                    <div className="d-flex justify-content-between fs-14">
                      <div>
                        Fee{' '}
                        <Tooltip title={`A travel fee may be charged for drives beyond a certain length. Depending on traffic forecasts and ${photographer.name}'s future location(s), you may find varying travel fees.`}>
                          <img
                            src="/icon/questionCircle.svg"
                            alt=""
                            className="ms-1"
                          />
                        </Tooltip>
                      </div>
                      <div>$ {slotDetail?.travel_cost}</div>
                    </div>
                    <hr />
                    </>
                  }

                  <div className="d-flex justify-content-between fs-4 fw-medium">
                    <div>Total</div>
                    <div>$ {totalPrice + travel_cost}</div>
                  </div>
                  <div className="text-start fs-14 fw-medium-light mt-3 mb-2">
                    Notes
                  </div>
                  <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    placeholder={`Leave instructions for this booking here. Please be as detailed as possible.`}
                    style={{ height: '104px', resize: 'none' }}
                    className="fs-14 px-3 py-2"
                    name="client_notes"
                    value={values.client_notes}
                    onChange={handleChange}
                    isValid={touched.client_notes && !errors.client_notes}
                    isInvalid={touched.client_notes && !!errors.client_notes}
                  />
                  <Form.Control.Feedback type="invalid">
                    <>{errors.client_notes}</>
                  </Form.Control.Feedback>
                  </Form.Group>

                  { bookingSettings?.cancellation_policy &&
                    <>
                      <div className="text-start fs-16 fw-medium black-medium text-secondary mb-1 mt-4">
                        Cancellations
                      </div>
                      <div className="fs-12 text-start text-secondary">
                        <Markdown>{bookingSettings?.cancellation_policy}</Markdown>
                      </div>
                    </>
                  }

                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex fs-14 flex-grow-1 d-none d-sm-block">
            <span>Powered by </span>
            <img src={branding_logo} className="me-2" style={{ maxHeight: '30px', maxWidth: '30px' }} alt="" />
          </div>
          <Button
            disabled={isLoading}
            type="submit"
          >
          {isLoading && (
            <span className="spinner-border spinner-border-sm"></span>
          )}
          Confirm Booking {(photographer?.payment_collection_type === "never_charge" || !subscriptionFeatures?.invoicing_paywalls_and_watermarks?.permitted) ? "" : "and Pay"}
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>
    )}}
    </Formik>
  );
};

export default Checkout;
