import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import Confirmation from "src/components/Confirmation";
import Lottie from 'react-lottie';
import * as animationData from 'src/assets/lottie/trash-icon.json';

const ServiceTier = ({ showError, handleChange, values, touched, errors, setFieldValue, tierType }) => {
  const totalTier = values.service_pricing_attributes.service_pricing_tier_attributes.length
  const service_pricing_tier_attributes_values = values.service_pricing_attributes.service_pricing_tier_attributes
  const type = tierType === 'sqft' ? 'Sq. Ft.' : 'USD'
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [tierIndex, setTierIndex] = useState(null)

  const defaultOptions = {
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    if( totalTier < 2){
      const initialTier = [
        {lower_limit: 0, upper_limit: 1000, amount: 100},
        {lower_limit: 1001, amount: 200},
      ]
      setFieldValue('service_pricing_attributes.service_pricing_tier_attributes', initialTier)
    } else {
      setFieldValue(`service_pricing_attributes.service_pricing_tier_attributes[${totalTier-1}].upper_limit`, null)
    }
  }, [values])

  const handleAddTier = () => {
    let lastTier = {...service_pricing_tier_attributes_values[totalTier-1]}
    lastTier.lower_limit = service_pricing_tier_attributes_values[totalTier-2].upper_limit && parseFloat(service_pricing_tier_attributes_values[totalTier-2].upper_limit) + 1001

    const newTier = [
      ...service_pricing_tier_attributes_values.slice(0, totalTier-1),
      {lower_limit: service_pricing_tier_attributes_values[totalTier-2].upper_limit && parseFloat(service_pricing_tier_attributes_values[totalTier-2].upper_limit) + 1,
       upper_limit: service_pricing_tier_attributes_values[totalTier-2].upper_limit && parseFloat(service_pricing_tier_attributes_values[totalTier-2].upper_limit) + 1000,
       amount: totalTier * 100},
       lastTier
  ];
  setFieldValue('service_pricing_attributes.service_pricing_tier_attributes', newTier)
  }

  const handleRemoveTier = (index) => {
    let lastTiers = [...service_pricing_tier_attributes_values.slice(index+1)]
    lastTiers[0] = { ...lastTiers[0], lower_limit: service_pricing_tier_attributes_values[index-1].upper_limit && parseFloat(service_pricing_tier_attributes_values[index-1].upper_limit) + 1}

    const newTier = [
      ...service_pricing_tier_attributes_values.slice(0, index),
      ...lastTiers
  ];
  setFieldValue('service_pricing_attributes.service_pricing_tier_attributes', newTier)
  }

  const removeTierConfirmation = (index) => {
    setShowConfirmation(true)
    setTierIndex(index)
  }

  const closeAndRemoveTier = () => {
    setShowConfirmation(false)
    handleRemoveTier(tierIndex)
  }

  const service_pricing_tier_attributes = errors.service_pricing_attributes?.service_pricing_tier_attributes
  return (
    <>
      <Confirmation
        showConfirmation={showConfirmation}
        handleConfirmation={closeAndRemoveTier}
        handleGoBack={() => setShowConfirmation(false)}
        message={"Are you sure you want to remove this Tier?"}
      />
      {service_pricing_tier_attributes_values.map((_,index) => {
        // last tier
        if(index === totalTier-1){
          return(
            <div key={`service_pricing_attributes.service_pricing_tier_attributes[${index}]`}>
              <div className="fs-18 fw-medium text-blue-added pb-3" >
                <div
                  className="tier"
                  role="button"
                  onClick={() => {handleAddTier()}}
                >
                  Add Tier
                </div>
              </div>
            <Row className="pt-3 pb-2">
            <Col lg={6}>
              <Form.Group>
                <Form.Label
                  htmlFor="lower_limit"
                  className="fw-medium fs-14"
                >
                  Last Tier
                </Form.Label>

                <InputGroup className="mb-3">
                  <NumberFormat
                    thousandSeparator={true}
                    readOnly={true}
                    className={"form-control"}
                    aria-label="Amount (to the nearest dollar)"
                    id={`service_pricing_attributes.service_pricing_tier_attributes[${index}].lower_limit`}
                    name={`service_pricing_attributes.service_pricing_tier_attributes[${index}].lower_limit`}
                    allowNegative={false}
                    value={service_pricing_tier_attributes_values[index].lower_limit}
                    onValueChange={(e) => {setFieldValue(`service_pricing_attributes.service_pricing_tier_attributes[${index}].lower_limit`,e.value)}}
                    required
                  />
                  <InputGroup.Text >{type}</InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label
                  htmlFor="lower_limit"
                  className="fw-medium fs-14"
                >
                  Amount You Charge
                </Form.Label>

                <InputGroup className="mb-3">
                  <InputGroup.Text >$</InputGroup.Text>
                  <NumberFormat
                    thousandSeparator={true}
                    className={`form-control ${ showError && service_pricing_tier_attributes && !!service_pricing_tier_attributes[index]?.amount ? "is-invalid" : ""}`}
                    aria-label="Amount (to the nearest dollar)"
                    id={`service_pricing_attributes.service_pricing_tier_attributes[${index}].amount`}
                    name={`service_pricing_attributes.service_pricing_tier_attributes[${index}].amount`}
                    allowNegative={false}
                    value={service_pricing_tier_attributes_values[index].amount}
                    onValueChange={(e) => {setFieldValue(`service_pricing_attributes.service_pricing_tier_attributes[${index}].amount`,e.value)}}
                    required
                  />
                  <InputGroup.Text >USD</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    <>{service_pricing_tier_attributes && service_pricing_tier_attributes[index]?.amount}</>
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          </div>
          )
        }

        return(
          <Row key={`service_pricing_attributes.service_pricing_tier_attributes[${index}]`} className="pt-1 pb-2 mt-1">
            {index != 0 &&
            <Col xs={{span: 1, offset: 11}}>
              <div onClick={() => removeTierConfirmation(index)} className="trash-icon-bg">
                <Lottie
                  options={defaultOptions}
                  height={25}
                  width={25}
                />
              </div>
            </Col>}
            <Col lg={4}>
              <Form.Group>
                <Form.Label
                  htmlFor="lower_limit"
                  className="fw-medium fs-14"
                >
                  Lower Limit
                </Form.Label>

                <InputGroup className="mb-3">
                  <NumberFormat
                    thousandSeparator={true}
                    readOnly={true}
                    className={"form-control"}
                    aria-label="Amount (to the nearest dollar)"
                    aria-describedby="basic-addon2"
                    id={`service_pricing_attributes.service_pricing_tier_attributes[${index}].lower_limit`}
                    name={`service_pricing_attributes.service_pricing_tier_attributes[${index}].lower_limit`}
                    allowNegative={false}
                    value={service_pricing_tier_attributes_values[index].lower_limit}
                    onValueChange={(e) => {setFieldValue(`service_pricing_attributes.service_pricing_tier_attributes[${index}].lower_limit`,e.value)}}
                    required
                  />
                  <InputGroup.Text id="basic-addon2">{type}</InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group>
                <Form.Label
                  htmlFor="lower_limit"
                  className="fw-medium fs-14"
                >
                  Upper Limit
                </Form.Label>

                <InputGroup className="mb-3">
                  <NumberFormat
                    thousandSeparator={true}
                    className={`form-control ${ showError && service_pricing_tier_attributes &&
                        (
                          !!service_pricing_tier_attributes[index]?.upper_limit ||
                          !!service_pricing_tier_attributes[index+1]?.lower_limit
                        ) ? "is-invalid" : ""}`}
                    aria-label="Amount (to the nearest dollar)"
                    aria-describedby="basic-addon2"
                    id={`service_pricing_attributes.service_pricing_tier_attributes[${index}].upper_limit`}
                    name={`service_pricing_attributes.service_pricing_tier_attributes[${index}].upper_limit`}
                    allowNegative={false}
                    value={service_pricing_tier_attributes_values[index].upper_limit}
                    onValueChange={(e) => {
                      setFieldValue(`service_pricing_attributes.service_pricing_tier_attributes[${index}].upper_limit`, e.value)
                      setFieldValue(`service_pricing_attributes.service_pricing_tier_attributes[${index+1}].lower_limit`, parseFloat(e.value) + 1)
                    }}
                    required
                  />
                  <InputGroup.Text id="basic-addon2">{type}</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    <>{ service_pricing_tier_attributes &&
                        (
                          service_pricing_tier_attributes[index]?.upper_limit ||
                          service_pricing_tier_attributes[index+1]?.lower_limit
                        )
                    }</>
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col lg={4}>
              <Form.Group>
                <Form.Label
                  htmlFor="lower_limit"
                  className="fw-medium fs-14"
                >
                  Amount You Charge
                </Form.Label>

                <InputGroup className="mb-3">
                  <InputGroup.Text >$</InputGroup.Text>
                  <NumberFormat
                    thousandSeparator={true}
                    className={`form-control ${ showError && service_pricing_tier_attributes &&
                      !!service_pricing_tier_attributes[index]?.amount ? "is-invalid" : ""
                    }`}
                    aria-label="Amount (to the nearest dollar)"
                    id={`service_pricing_attributes.service_pricing_tier_attributes[${index}].amount`}
                    name={`service_pricing_attributes.service_pricing_tier_attributes[${index}].amount`}
                    allowNegative={false}
                    value={service_pricing_tier_attributes_values[index].amount}
                    onValueChange={(e) => {setFieldValue(`service_pricing_attributes.service_pricing_tier_attributes[${index}].amount`, e.value)}}
                    required
                  />
                  <InputGroup.Text >USD</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    <>{service_pricing_tier_attributes && service_pricing_tier_attributes[index]?.amount}</>
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
            </Row>
        )
      })}
    </>
  );
};

export default ServiceTier;
