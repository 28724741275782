import { Menu, MenuItem, ListItemIcon, ListItemText, Paper } from '@mui/material';
import { Sms, Phone } from '@mui/icons-material';

const OutboundOptionsMenu = ({ anchorEl, open, onClose, onPlaceCallDialogOpen, onCreateDialogOpen, position }) => (
  <Menu
    anchorEl={anchorEl}
    open={open}
    onClose={onClose}
    anchorReference="anchorPosition"
    anchorPosition={position}
  >
    <MenuItem onClick={onCreateDialogOpen}>
      <ListItemIcon>
        <Sms fontSize="small" />
      </ListItemIcon>
      <ListItemText>Send SMS/Email</ListItemText>
    </MenuItem>
    <MenuItem onClick={onPlaceCallDialogOpen}>
      <ListItemIcon>
        <Phone fontSize="small" />
      </ListItemIcon>
      <ListItemText>Place Call</ListItemText>
    </MenuItem>
  </Menu>
);

export default OutboundOptionsMenu;
