import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, Typography, Card, CardContent, Grid, Tooltip } from '@mui/material';
import { dateTimeFormatter } from "src/libs/dateTimeFormatter";
import Markdown from "markdown-to-jsx";
import ConversationStatusIndicator from "./ConversationStatusIndicator";
import ConversationOptionsMenu from "./ConversationOptionsMenu";
import CountdownTimerButton from "../CountdownTimerButton";
import CloseConversationButton from "./CloseConversationButton";
import ConversationContacts from "./ConversationContacts";
import { useAppDispatch } from "src/store";
import { useNavigate } from "react-router-dom";
import { toggleOpenConversation, conversationAutocompleteNow } from "src/features/account/conversations.slice";
import pulsatingBorderStyle from "src/libs/pulsatingBorderStyle";

interface ConversationCardProps {
  conversation: any;
  handleConversationsListToggleClick?: () => void;
  variant?: "full" | "small";
}

const ConversationCard: React.FC<ConversationCardProps> = React.memo(({ conversation, handleConversationsListToggleClick = () => {}, variant = "full" }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const subjectRef = useRef(null);

  const showPausedIndicator = conversation?.delivery_state === 'pause' &&
    conversation?.messages.some((msg: any) => msg.status === 'pending_delivery');

  const setBorder = useCallback(() => {
    const borderColor = conversation?.delivery_state === 'pause' ? '#f00' : '#000';
    const time = conversation?.latest_delivery_at || conversation?.next_autocomplete_scheduled_at || conversation?.created_at;
    return pulsatingBorderStyle(time, borderColor);
  }, [conversation]);

  const unreadAnimation = useCallback(() => {
    if (!conversation?.read) {
      return "pulse 1s ease infinite";
    }
    return "none";
  }, [conversation]);

  const handleScroll = useCallback(() => {
    setIsUserScrolling(true);
    clearTimeout(subjectRef.current.scrollTimer);
    subjectRef.current.scrollTimer = setTimeout(() => {
      setIsUserScrolling(false);
      console.log('User stopped scrolling');
    }, 2000);
    console.log('User is scrolling');
  }, []);

  const handleConversationClick = useCallback(() => {
    handleConversationsListToggleClick();
    dispatch(toggleOpenConversation(conversation.id));
    navigate(`?conversation_id=${conversation.id}&channel_id=${conversation.channel_id}`);
    console.log('Navigating to conversation:', conversation.id);
  }, [conversation.id, conversation.channel_id, dispatch, handleConversationsListToggleClick, navigate]);

  useEffect(() => {
    const subject = subjectRef.current;
    if (subject) {
      setIsOverflowing(subject.scrollWidth > subject.clientWidth);
    }
  }, [conversation?.subject]);

  if (!conversation) {
    return null;
  }

  const styles = {
    card: {
      position: 'relative',
      cursor: 'pointer',
      height: variant === "small" ? '150px' : '500px',
      maxHeight: variant === "small" ? '150px' : '400px',
      width: '350px',
      maxWidth: '100vw',
      pl: .5,
      pr: .5,
      pb: 0,
      pt: 1.5,
      display: 'flex',
      flexDirection: 'column',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
      background: '#ffffff',
      borderRadius: '0.5rem',
      border: '2px solid #e5e7eb',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      animation: unreadAnimation(),
      transition: 'all 300ms',
      overflow: 'hidden',
      '&:hover': {
        background: '#f5f5f5',
        transform: 'scale(1.02)',
        maxWidth: '500px',
        zIndex: 999,
        position: 'relative',
      },
      ...(conversation.call_status === 'active' && {
        animation: 'pulse-green 2s infinite',
        '@keyframes pulse-green': {
          '0%, 100%': { borderColor: 'rgba(0, 200, 0, 0.3)' },
          '50%': { borderColor: 'rgba(0, 255, 0, 0.6)' },
        },
      }),
      ...(conversation.call_status === 'ringing' && {
        animation: 'ring-glow 1s infinite',
        '@keyframes ring-glow': {
          '0%, 100%': { borderColor: 'rgba(255, 165, 0, 0.5)' },
          '50%': { borderColor: 'rgba(255, 165, 0, 1)' },
        },
      }),
    },
    contactInfo: {
      backgroundColor: '#f3f4f6',
      padding: '0.5rem',
      borderRadius: '0.25rem',
      mb: 1,
      width: '100%',
    },
    topRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      mb: 1,
    },
    statusIndicator: {
      position: 'absolute',
      top: -10,
      right: -10,
      zIndex: 2,
    },
    time: {
      fontSize: '0.875rem',
      color: '#6b7280',
      mb: 0.5,
    },
    summaryTitle: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      mb: 0.5,
      position: 'relative',
      overflow: 'hidden',
    },
    summaryTitleContent: {
      whiteSpace: 'nowrap',
      overflowX: 'auto',
      paddingBottom: '5px',
      paddingRight: '30px',
      '&::-webkit-scrollbar': { display: 'none' },
      scrollbarWidth: 'none',
    },
    summaryTitleGradient: {
      position: 'absolute',
      right: 0,
      top: 0,
      height: '100%',
      width: '30px',
      pointerEvents: 'none',
    },
    summaryContainer: {
      height: variant === "small" ? 'calc(100% - 80px)' : '212px',
      overflowY: 'auto',
      position: 'relative',
      paddingRight: '0',
      paddingLeft: '0',
      paddingBottom: '60px',
    },
    summaryContent: {
      paddingBottom: '20px',
    },
    messageTypography: {
      fontSize: '14px',
      color: '#374151',
    },
    callStatus: {
      position: 'absolute',
      bottom: '1rem',
      right: '1rem',
      display: 'flex',
      alignItems: 'center',
      fontSize: '0.875rem',
      fontWeight: 'bold',
      zIndex: 12, // Ensure it's above the content but below the gradient
      color: conversation.call_status === 'active' ? '#10b981' : '#fbbf24',
      padding: '0.25rem 0.5rem',
      borderRadius: '0.25rem',
      backgroundColor: 'white',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    cornerDecoration: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: '2rem',
      height: '2rem',
      backgroundColor: '#3b82f6',
      borderBottomLeftRadius: '0.5rem',
    },
    optionsMenu: {
      position: 'absolute',
      zIndex: 99,
      top: 5,
      right: 14,
      opacity: 0,
    },
    cardFooter: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 2,
      height: '100px',
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        height: '100px',
        background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
        pointerEvents: 'none',
      },
    },
    closeButton: {
      position: 'absolute',
      bottom: '1rem',
      left: '1rem',
      color: '#ef4444',
      padding: '0.25rem',
      borderRadius: '0.25rem',
      transition: 'opacity 300ms, transform 300ms',
      opacity: isHovered ? 1 : 0,
      transform: isHovered ? 'translateY(0)' : 'translateY(10px)',
      zIndex: 20,
      backgroundColor: 'white',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    contentWrapper: {
      position: 'relative',
      height: '100%',
    },
    callStatusButton: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f3f4f6',
      },
    },
    pausedIndicator: {
      position: 'absolute',
      top: 5,
      right: 5,
      zIndex: 2,
    },
  };

  function snakeToTitle(snakeString: string) {
    if (!snakeString) {
      return '';
    }
    return snakeString.split('_').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }).join(' ');
  }

  return (
    <>
      <Card
        sx={{ ...styles.card, border: setBorder() }}
        onMouseDown={(e) => e.preventDefault()}
        onClick={handleConversationClick}
        onMouseEnter={() => {
          setIsHovered(true);
          console.log('Card hovered');
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          console.log('Card unhovered');
        }}
      >
        <Box sx={styles.cornerDecoration} />
        <Box sx={styles.statusIndicator}>
          <ConversationStatusIndicator conversationStatus={conversation.status} variant="circle" />
        </Box>
        {showPausedIndicator && (
          <Box sx={styles.pausedIndicator}>
            <Tooltip title="This conversation is paused. Messages are pending delivery.">
              <ConversationStatusIndicator conversationStatus="paused_pending" variant="circle" />
            </Tooltip>
          </Box>
        )}
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={styles.contactInfo}>
                <ConversationContacts conversation_id={conversation.id} />
              </Box>
            </Grid>
          </Grid>
          <Box sx={styles.topRow}>
            <Typography sx={styles.time}>
              {dateTimeFormatter(conversation.updated_at, "day_month_ordinal_humanized_time_period")}
            </Typography>
            <ConversationOptionsMenu sx={styles.optionsMenu} conversation={conversation} />
            <CountdownTimerButton
              currentTime={new Date().toISOString()}
              futureTime={conversation.next_autocomplete_scheduled_at}
              onClick={() => {
                dispatch(conversationAutocompleteNow({ channelId: conversation.channel_id, conversationId: conversation.id }));
              }}
            />
          </Box>
          <Typography component="div" sx={styles.summaryTitle}>
            <Box
              ref={subjectRef}
              sx={styles.summaryTitleContent}
              onScroll={handleScroll}
            >
              {isOverflowing ? `${conversation.subject || "Messages"} • ` : conversation.subject || "Messages"}
            </Box>
            <Box sx={styles.summaryTitleGradient} />
          </Typography>
          <Box sx={styles.contentWrapper}>
            <Box sx={styles.summaryContainer}>
              <Box sx={styles.summaryContent}>
                {conversation && conversation.messages && conversation.messages.length > 0 ? (
                  <Typography sx={styles.messageTypography}>
                    <Markdown>
                      {conversation.messages[conversation.messages.length - 1].content}
                    </Markdown>
                  </Typography>
                ) : (
                  <Typography sx={styles.messageTypography}>
                    {snakeToTitle(conversation.status)}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        </CardContent>
        <Box sx={styles.cardFooter}>
          <Box sx={styles.closeButton}>
            <CloseConversationButton conversation={conversation} />
          </Box>
        </Box>
      </Card>
    </>
  );
});

ConversationCard.displayName = "ConversationCard";

export default ConversationCard;
