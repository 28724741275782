import Swal from 'sweetalert2'
import { finalizeInvoiceMail } from 'src/features/services/booking.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { useParams } from 'react-router-dom';


const DownloadAsZipButton = ({ galleriableType, galleriableId, isPaid, authorizedToPay=false, sendFinalizeInvoice=() => {} }) => {
  const dispatch = useAppDispatch();
  let { uuid } = useParams();
  const { booking } = useAppSelector((state) => state.bookingServices);

  const downloadClicked = () => {
    if (booking.payment_collection_type == 'never_charge' || isPaid) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-primary swal-button',
          cancelButton: 'btn btn-default swal-button'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Download all Files',
        text: "Simply, pick the size you'd like and click download",
        input: 'select',
        inputOptions: {
          'mls': 'Small (1600px) - Good for MLS & Websites',
          'broker': 'Medium (2300px) - Good for modern websites',
          'original': 'Large/Print - Great for printing & editing',
        },
        inputValue: '&style=broker',
        confirmButtonText: '<i class="fa fa-cloud-download fa-fw" aria-hidden="true"></i> Download',
        showCancelButton: true,
        cancelButtonText: '<i class="fa fa-thumbs-down"></i> Cancel',
        allowEnterKey: false
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let url = `${process.env.REACT_APP_API_URL}/downloads/${galleriableType}/${galleriableId}/${result.value}`
          window.open(url, '_blank').focus();
          Swal.fire('Downloading now!', 'Check your downloads folder for the zip file containing all your images. Like the weather in New England, download times vary.', 'success')
        } else if (result.isDenied) {

        }
      })
    } else {
      Swal.fire({
        showCancelButton: true,
        confirmButtonText: 'Pay Now',
        icon: 'error',
        title: 'Unlicensed',
        text: 'Please pay invoice to download files.'
      }).then((result) => {
        if (result.isConfirmed) {
          if(authorizedToPay){
            sendFinalizeInvoice()
          } else {
            dispatch(finalizeInvoiceMail(uuid)).then(() => {
              Swal.fire('Email sent!', 'Check your email for Payment link', 'success')
            })
          }
        }
      })
    }

  }
  return (
    <button className="btn btn-primary btn-sm" onClick={downloadClicked}>
      <i className="fa fa-cloud-download fa-fw" aria-hidden="true"></i>
      &nbsp;
      Download All
    </button>
  );
}
export default DownloadAsZipButton;
