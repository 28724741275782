import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { httpClient } from 'src/libs';
import { RootState } from 'src/store';
import { API_ROUTES } from 'src/constants/routes';

interface FAQ {
  id: number;
  channel_id: number;
  question: string;
  answer: string;
  faq_type: string; // Use 'faq_type'
  created_at: string;
  updated_at: string;
}

const faqsAdapter = createEntityAdapter<FAQ>({
  selectId: (faq) => faq.id,
  sortComparer: (a, b) => b.created_at.localeCompare(a.created_at),
});

const initialState = faqsAdapter.getInitialState({
  loading: false,
  error: null,
});

// Fetch FAQs
export const fetchFaqs = createAsyncThunk<FAQ[]>(
  'faqs/fetchFaqs',
  async (_, thunkAPI) => {
    try {
      const response = await httpClient.get(
        `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.faqs}`
      );
      console.log('Fetched FAQs:', response.data); // Log response
      return response.data;
    } catch (error: any) {
      console.error('Error fetching FAQs:', error); // Log error
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Create FAQ
export const createFaq = createAsyncThunk<FAQ, Omit<FAQ, 'id' | 'created_at' | 'updated_at'>>(
  'faqs/createFaq',
  async (faqData, thunkAPI) => {
    try {
      const response = await httpClient.post(
        `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.faqs}`,
        { faq: faqData }
      );
      console.log('Created FAQ:', response.data); // Log response
      return response.data;
    } catch (error: any) {
      console.error('Error creating FAQ:', error); // Log error
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Update FAQ
export const updateFaq = createAsyncThunk<FAQ, FAQ>(
  'faqs/updateFaq',
  async (faqData, thunkAPI) => {
    try {
      const response = await httpClient.put(
        `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.faqs}/${faqData.id}`,
        { faq: faqData }
      );
      console.log('Updated FAQ:', response.data); // Log response
      return response.data;
    } catch (error: any) {
      console.error('Error updating FAQ:', error); // Log error
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// Delete FAQ
export const deleteFaq = createAsyncThunk<number, number>(
  'faqs/deleteFaq',
  async (faqId, thunkAPI) => {
    try {
      await httpClient.delete(
        `${process.env.REACT_APP_API_V3_URL}${API_ROUTES.faqs}/${faqId}`
      );
      console.log(`Deleted FAQ with ID: ${faqId}`); // Log deletion
      return faqId;
    } catch (error: any) {
      console.error('Error deleting FAQ:', error); // Log error
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const faqSlice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFaqs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFaqs.fulfilled, (state, action) => {
        state.loading = false;
        faqsAdapter.setAll(state, action.payload);
      })
      .addCase(fetchFaqs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(createFaq.fulfilled, (state, action) => {
        faqsAdapter.addOne(state, action.payload);
      })
      .addCase(updateFaq.fulfilled, (state, action) => {
        faqsAdapter.updateOne(state, {
          id: action.payload.id,
          changes: action.payload,
        });
      })
      .addCase(deleteFaq.fulfilled, (state, action) => {
        faqsAdapter.removeOne(state, action.payload);
      });
  },
});

export const {
  selectById: selectFaqById,
  selectIds: selectFaqIds,
  selectEntities: selectFaqEntities,
  selectAll: selectAllFaqs,
  selectTotal: selectTotalFaqs,
} = faqsAdapter.getSelectors<RootState>((state) => state.faqs);

export default faqSlice.reducer;

// Example usage:
// dispatch(fetchFaqs());
// dispatch(createFaq({ channel_id: 1, question: 'Q?', answer: 'A', faq_type: 'General' }));
