import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'; // Added import

const Problem: React.FC = () => {
  return (
    <Box sx={{
      /* Remove fixed height and justifyContent */
      // height: '100%',
      display: 'flex',
      flexDirection: 'column',
      color: 'white',
      padding: 4,
      // justifyContent: 'center',
    }}>
      <Typography variant="h3" align="center" sx={{ mb: 4 }}>The Problem</Typography>
      <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
        <List>
          {/* Updated ListItems with big numbers outside the text blocks */}
          <ListItem>
            <ListItemIcon>
              <CancelOutlinedIcon sx={{ color: 'red' }} />
            </ListItemIcon>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ListItemText
                primary="Human assistants cost $3k-$6k/month"
                secondary="A tightening economy has operators searching for cheaper and more efficient options"
                primaryTypographyProps={{ color: 'white', fontSize: '2rem', fontWeight: 'bold' }}
                secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '1.5rem' }}
              />
            </Box>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CancelOutlinedIcon sx={{ color: 'red' }} />
            </ListItemIcon>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ListItemText 
                primary="NAR Settlement: Compliance Burden Post-August 17, 2024" 
                secondary="Increased urgency: MLSs, brokers & agents who don't increase documentation & transparency face legal action"
                primaryTypographyProps={{ color: 'white', fontSize: '2rem', fontWeight: 'bold' }}
                secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '1.5rem' }}
              />
            </Box>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <CancelOutlinedIcon sx={{ color: 'red' }} />
            </ListItemIcon>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ListItemText 
                primary="Poor service & lost leads: 47% of leads unanswered. 8 hour avg response time" 
                secondary="5 minutes is all a service provider has to respond to a lead before they're 80% more likely to go to another agent"
                primaryTypographyProps={{ color: 'white', fontSize: '2rem', fontWeight: 'bold' }}
                secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '1.5rem' }}
              />
            </Box>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <CancelOutlinedIcon sx={{ color: 'red' }} />
            </ListItemIcon>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ListItemText 
                primary="Email is dead. 70% of consumers prefer text messages" 
                secondary="Only 35% of businesses offer SMS options"
                primaryTypographyProps={{ color: 'white', fontSize: '2rem', fontWeight: 'bold' }}
                secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '1.5rem' }}
              />
            </Box>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <CancelOutlinedIcon sx={{ color: 'red' }} />
            </ListItemIcon>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ListItemText 
                primary="TC's overwhelmed with emails" 
                secondary="Hundreds of emails daily at small brokerages, thousands at larger firms. ~5.5 emails/day per agent to TC alone"
                primaryTypographyProps={{ color: 'white', fontSize: '2rem', fontWeight: 'bold' }}
                secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '1.5rem' }}
              />
            </Box>
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <CancelOutlinedIcon sx={{ color: 'red' }} />
            </ListItemIcon>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ListItemText 
                primary="MLS and brokerages swamped with support and recruiting emails" 
                secondary="MLS and brokerages receive hundreds of support and recruiting emails daily"
                primaryTypographyProps={{ color: 'white', fontSize: '2rem', fontWeight: 'bold' }}
                secondaryTypographyProps={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '1.5rem' }}
              />
            </Box>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default Problem;
