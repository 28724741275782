import React, { useState, useEffect } from 'react';
import { useAppSelector } from 'src/store';
import { Drawer, TextField, Typography, Box, SwipeableDrawer } from '@mui/material';
import { selectConversationById } from 'src/features/account/conversations.slice';
import ConversationMemorySummary from './ConversationMemorySummary';
import ConversationMemoryFollowUp from './ConversationMemoryFollowUp';

interface Props {
  conversationId: string;
}

const AdvancedConversationDetailsDrawer: React.FC<Props> = ({ conversationId }) => {
  const [open, setOpen] = useState(false);
  const conversation = useAppSelector(state => selectConversationById(state, conversationId));

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'm') {
        setOpen(!open);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);

  if (!conversation) return null;

  return (
    <SwipeableDrawer
      anchor={"bottom"}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      sx={{ width: '100px', zIndex: 9999 }}
    >
      {/* dont render this box if there is no memory
      {conversation?.memory && (
        <Box p={2}>
          <Typography variant="h6">Advanced Conversation Details</Typography>
          <ConversationMemorySummary summary={conversation?.memory} />
          <ConversationMemoryFollowUp followUpData={conversation?.memory?.follow_up} />
        </Box>
      )} */}
    </SwipeableDrawer>
  );
};

export default AdvancedConversationDetailsDrawer;
