import Swal from 'sweetalert2'
import Lottie from 'react-lottie';
import * as animationData from 'src/assets/lottie/download-icon.json'
import { useEffect } from 'react';
import { finalizeInvoiceMail } from 'src/features/services/booking.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { useParams } from 'react-router-dom';


const DownloadButton = ({ item, isPaid }) => {
  let { uuid } = useParams();
  const dispatch = useAppDispatch();
  const { booking } = useAppSelector((state) => state.bookingServices);

  useEffect(() => {
    // Update the document title using the browser API
    generatedInputOptions
  });

  const generatedInputOptions = (item): {} => {
    return {
      "Video": {
        'original': 'Full Resolution'
      },
      "Photo": {
        'c_limit,w_1600,h_1600,cs_srgb,f_jpg,fl_attachment:FILENAME': 'Small (1600px) - Good for MLS & Websites',
        'c_limit,w_2300,h_2300,cs_srgb,f_jpg,fl_attachment:FILENAME': 'Medium (2300px) - Good for modern websites',
        'fl_attachment:FILENAME': 'Large/Print - Great for printing & editing',
        'f_pdf': 'PDF - Great for printing'
      }
    }[item.itemable_type] || { 'nothing': '' }
  }
  const downloadUrl = (item_params) => {
    return item_params === "original"
      ? item.original_file_url
      : item.flexible_photo_url.replace('CUSTOM_REPLACE', item_params.replace("FILENAME", encodeURIComponent(item.filename.split('.')[0])))
  }

  const downloadClicked = (item) => {
    if (booking.payment_collection_type == 'never_charge' || isPaid) {
      if (item.itemable_type == "Video" || item.itemable_type == "Attachment") {
        return window.open(item.original_file_url)
      }
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-primary swal-button',
          cancelButton: 'btn btn-default swal-button'
        },
        buttonsStyling: false
      })
      swalWithBootstrapButtons.fire({
        title: 'Download Single File',
        text: "Simply pick the format you'd like and click download",
        input: 'select',
        imageUrl: item.medium_url,
        imageWidth: 350,
        inputOptions: generatedInputOptions(item),
        // inputOptions: {item.itemable_type == "Video" ? videoInputOptions : photoInputOptions},
        inputValue: 'c_limit,w_2300,h_2300,cs_srgb,f_jpg',
        confirmButtonText: 'Download',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        allowEnterKey: false
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let url = downloadUrl(result.value)
          window.open(url, 'download')
          Swal.fire('Downloading now!', 'Check your downloads folder', 'success')
        } else if (result.isDenied) {

        }
      })
    } else {
      Swal.fire({
        showCancelButton: true,
        confirmButtonText: 'Pay Now',
        icon: 'error',
        title: 'Unlicensed',
        text: 'Please pay invoice to download files.'
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(finalizeInvoiceMail(uuid))
          Swal.fire('Email sent!', 'Check your email for Payment link', 'success')
        }
      })
    }
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="overlay download-button">
      <a onClick={() => downloadClicked(item)} download>
        <Lottie
          options={defaultOptions}
          height={25}
          width={25}
          isStopped={true}
          isPaused={true}
        />
      </a>
    </div>
  );
}


export default DownloadButton;
