import { Image } from 'react-bootstrap'
import { useAppSelector } from 'src/store';

export default function GoBackDashboard() {
  const darkMode = useAppSelector( state => state.theme.darkMode )
  return (
    <div className="fs-14 fw-medium-light ">
      <Image className="me-2" src={ darkMode ? "/icon/backChevronLight.svg" : "/icon/backChevron.svg" } />
      Go to dashboard
    </div>
  );
}
