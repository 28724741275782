// BEGIN frontend/src/components/Channels/ImportWebsitesInputArray.tsx

import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import ImportWebsiteInput from './ImportWebsiteInput';
import { FormControl, FormGroup } from '@mui/material';
import { useAppSelector } from 'src/store';
import { enqueueSnackbar } from 'notistack';
import UpgradePlanPopup from '../Nav/UpgradePlanPopup';
import { useAppDispatch } from 'src/store';


type Page = {
  tracking_id: string;
  page_url: string;
}

// Define the props for the component
interface ImportWebsitesInputArrayProps {
  channelId?: string;
  incomingPages?: Page[];  // now it can be an optional prop
  maxItems?: number;  // make it optional and give it a default value later
  onUpdatePages: (updatedPages: Page[]) => void;  // the function onUpdatePages should accept a parameter of Page array
}

const ImportWebsitesInputArray: React.FC<ImportWebsitesInputArrayProps> = ({ 
  incomingPages = [{ tracking_id: '', page_url: '' }], 
  maxItems = Infinity,
  onUpdatePages,
  channelId
}) =>{

  const { subscriptionFeatures } = useAppSelector((state) => state.auth);
  const [openUpgradePlanPopup, setOpenUpgradePlanPopup] = useState(false);
  const showAddMoreButton = incomingPages.length <= maxItems;
  const dispatch = useAppDispatch();
  
  const handleAddPage = (index) => {
    
    if (subscriptionFeatures?.higher_tiers.length > 0 && index >= maxItems) {
      // popup UpgradePlanPopup 
      setOpenUpgradePlanPopup(true);
      enqueueSnackbar('Add up to 100 pages per Oppy with Pro!', { variant: 'info' });
      return;
    }
    // add a new page to the incomingPages array right after the index
    let updatedPages = [...incomingPages];
    updatedPages.splice(index + 1, 0, { tracking_id: '', page_url: '' });
    onUpdatePages(updatedPages);
  };

  const handleRemovePage = (index) => {

    if (incomingPages.length > 0) {
      // remove the page at the index from the incomingPages array
      let updatedPages = [...incomingPages];
      updatedPages.splice(index, 1);
      if (updatedPages.length === 0) {
        updatedPages = [{ tracking_id: '', page_url: '' }];
      }
      onUpdatePages(updatedPages);
    } else {
      onUpdatePages([{ tracking_id: '', page_url: '' }]);
    }
  };

  const handleResetPage = (index) => {
    // find the page at the index and reset it on incomingPages
    let updatedPages = [...incomingPages];
    updatedPages[index] = { tracking_id: '', page_url: '' };
    onUpdatePages(updatedPages);
  }

  const handleUpdatePages = (updatedPage,index) => {
    let updatedPages = [...incomingPages]; // Create a copy of the current pages array
    updatedPages[index] = updatedPage ; // Fix: spread the object to avoid deleting other keys/values // Update the specific page at the index
    onUpdatePages(updatedPages);
  }

  return (
    <>
    <FormGroup sx={{ marginTop: 3 }}>
      <FormControl variant="outlined">
        {incomingPages.map((page, index) => (
          <Box key={index} display="flex" alignItems="center" justifyContent={"space-between"} width="100%">
            <ImportWebsiteInput
              handleUpdatePage={handleUpdatePages}
              pages={incomingPages}
              incomingPage={page}
              handleAddPage={() => handleAddPage(index)}
              handleRemovePage={() => handleRemovePage(index)}
              handleResetPage={() => handleResetPage(index)}
              index={index}
              showAddButton={index <= maxItems && incomingPages.length === index + 1 && page.page_url !== ''}
              showRemoveButton={index > 0 || incomingPages.length > 1}
              showResetButton={index === 0}
              channelId={channelId}
            />
          </Box>
        ))}
      </FormControl>
    </FormGroup>
          {
      openUpgradePlanPopup && <UpgradePlanPopup
        isOpen={openUpgradePlanPopup}
        onClose={() => {
          setOpenUpgradePlanPopup(false);
        }}
        subscriptionFeatures={subscriptionFeatures}
      />
    }
    </>
  );
};

export default ImportWebsitesInputArray;

// END Parent Component frontend/src/components/Channels/ImportWebsitesInputArray.tsx
