import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ChatService from "src/services/chat_services";
import { serializeErrorMessage } from "src/utils";

interface ChatState {
  isLoading: boolean;
  chatError: string;
}

const initialState: ChatState = {
  isLoading: false,
  chatError: null,
}

export const closeConversation = createAsyncThunk(
  'chat/conversation/close',
  async ({ conversation_id, embed_key }: { conversation_id: string, embed_key: string }, thunkAPI) => {
    try {
      const res = await ChatService.resetChat(conversation_id, embed_key);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(closeConversation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.chatError = null;
      })
      .addCase(closeConversation.pending, (state, action) => {
        state.isLoading = true;
        state.chatError = null;
      })
      .addCase(closeConversation.rejected, (state, action) => {
        state.isLoading = false;
        state.chatError = action.payload as string;
      });
  },
});

const chatReducer = chatSlice.reducer;
export default chatReducer;
