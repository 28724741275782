import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ProgressSlide: React.FC = () => {
  const theme = useTheme();

  // Default offsets for top and bottom events
  const topEventOffset = { xOffset: 130, yOffset: 12 };
  const bottomEventOffset = { xOffset: 130, yOffset: -3 };

  // Width per event
  const eventWidth = 230;

  // Events array without individual offsets
  const events = [
    {
      // begins as an idea for a scheduling via sms tool 
      "date": "May 2023",
      "title": "",
      "description": "Begins as an idea for a scheduling via sms tool. Patent filed for Human in Loop dashboard and traffic aware instant booking.",
      "image": "https://res.cloudinary.com/kw/image/upload/v1728676551/oppy/Screenshot_2024-10-11_at_12.52.50_PM.png"
    },
    {
      "date": "Nov 2023",
      "title": "Initial Alpha Launch",
      "description": "Launched at NAR NXT conference in Anaheim, California. Made numerous connections.",
      "image": "https://res.cloudinary.com/kw/image/upload/v1728675416/oppy/Screenshot_2024-10-11_at_12.34.56_PM.png"
    },
    {
      "date": "Jan 2024",
      "title": "User Onboarding",
      "description": "Began onboarding users, testing use cases, and gathered the first positive review."
    },
    {
      "date": "Feb 2024",
      "title": "Inman News Review",
      "description": "Received a four-star review from Inman News."
    },
    {
      "date": "Spring 2024",
      "title": "Product Improvement",
      "description": "GPT-4o released; Enhanced product reliability and AI response times; Large brokerage onboarded with multi agent workflow.",
    },
    {
      "date": "Jun 2024",
      "title": "Feature Launch",
      "description": "Launched multi-agent workflow and AI website readers capable of looking up website content on the fly, reaching out to their human team when help is needed and more...",
      "image": "https://res.cloudinary.com/kw/image/upload/v1728673161/oppy/Screenshot_2024-10-11_at_9.37.16_AM.png" // Test image URL
    },
    {
      "date": "Jul 2024",
      "title": "Inman Connect",
      "description": "Exhibited at Inman Connect, pitched on stage, secured new brokerage customers, and initiated talks with MLSs and tech platforms.",
      "image": "https://res.cloudinary.com/kw/image/upload/v1728675415/oppy/Screenshot_2024-10-11_at_12.34.40_PM.png"
    },
    {
      "date": "Aug 2024",
      "title": "Call Transcription Beta Release",
      "description": "Began rolling out call transcription to select users",
      "image": "https://res.cloudinary.com/kw/image/upload/v1728675759/oppy/Screenshot_2024-10-11_at_12.41.52_PM.png"
    },
    {
      "date": "Sep 2024",
      "title": "Blueprint Conference",
      "description": "Networked with influencers, lined up MLS meetings, and continued talks with tech partners."
    },
    {
      "date": "Sep 2024",
      "title": "T360 Pitch Battle New Orleans",
      "description": "Pitched Oppy to a live audience of top real estate influencers at the T360 Pitch Battle in New Orleans. 25+ executives joined waitlist. Further traction with MLSs.",
      "image": "https://res.cloudinary.com/kw/image/upload/v1728675415/oppy/Screenshot_2024-10-11_at_12.34.48_PM.png"
    },
    {
      "date": "Oct 2024",
      "title": "Press Recognition",
      "description": "Received additional press coverage from Inman News and began talks with two large MLSs.",
      "image": "https://res.cloudinary.com/kw/image/upload/v1728675052/oppy/Screenshot_2024-10-10_at_6.16.19_AM.png"
    },
    {
      "date": "Oct 2024",
      "title": "Best AI Startup in Real Estate",
      "description": `Inman news selects Oppy as "Best AI Startup in Real Estate"`,
      "image": "https://res.cloudinary.com/kw/image/upload/v1728675052/oppy/Screenshot_2024-10-10_at_6.16.19_AM.png"
    },
    {
      "date": "Oct 2024",
      "title": "Oppy Joins T360",
      "description": `Oppy engages T360, the top RE tech consulting and networking agency, with focus on triaging wait list, road map and GTM strategy`,
      "image": "https://res.cloudinary.com/kw/image/upload/v1728675052/oppy/Screenshot_2024-10-10_at_6.16.19_AM.png"
    },
    {
      "date": "Now",
      "title": "Current Status",
      "description": "Patent pending for Human in Loop dashboard",
      "isNow": true,
      "xOffset": 1800,
      "yOffset": -15,
      "bulletOffsetX": 0,
      "bulletOffsetY": 0
    },
    // working through waiting list, building team and launching live transcriptions ahead of NAR
    {
      "date": "Oct 2024",
      "title": "Onboard, build team, launch live transcriptions",
      "description": "Working through waiting list, building team and launching live transcriptions ahead of NAR."
    },
    {
      "date": "Nov 2024",
      "title": "NAR NXT Conference",
      "description": "Exhibiting with live demos and possibly live calling.",
      "isFuture": true
    },
    {
      "date": "Dec 2024",
      "title": "MLS Forum Pitch",
      "description": "Pitching to an MLS forum via live webinar.",
      "isFuture": true
    },
    // launching AI voice assistants depending on legal 
    {
      "date": "Jan 2025",
      "title": "Launch AI Voice Assistants",
      "description": "Launching AI voice assistants depending on legal and regulatory approval.",
      "isFuture": true
    },
    {
      "date": "Jan 2025",
      "title": "Inman Connect NYC",
      "description": "Speaking at Inman Connect, continuing expansion into MLSs.",
      "isFuture": true
    },
    {
      "date": "Q1/Q2 2025",
      "title": "Major MLS Marketplace Entry",
      "description": "Anticipated integration with one of the largest MLSs.",
      "isFuture": true
    }
  ];

  // Total width based on number of events
  const totalWidth = events.length * eventWidth;

  return (
    <Box
      sx={{
        height: 'calc(100vh - 200px)',
        color: 'white',
        overflow: 'hidden', // Prevent horizontal overflow
      }}
    >
      {/* Title Bar */}
      <Box
        sx={{
          padding: 2,
        }}
      >
        <Typography variant="h4" sx={{ mb: 1 }}>
          Timeline & Road Ahead
        </Typography>
      </Box>

      {/* Scrollable Content */}
      <Box
        sx={{
          height: '110vh', // Adjust height for remaining space
          overflowX: 'auto', // Enable horizontal scrolling
          overflowY: 'scroll',
          whiteSpace: 'nowrap',
          pt: 2,
          mt: 2,
          position: 'relative',
        }}
      >
        {/* Container for events */}
        <Box
          sx={{
            position: 'relative',
            width: `${totalWidth}px`, // Set container width based on total events
            height: '100%',
          }}
        >
          {/* Horizontal Line */}
          <Box
            sx={{
              position: 'absolute',
              top: '50%', // Centered vertically
              left: '0',
              width: '100%', // Extend line to full width
              height: '8px',
              backgroundColor: theme.palette.secondary.main,
            }}
          />

          {/* Render Events */}
          {events.map((event, index) => {
            // Determine if the event is on top or bottom
            const isTopEvent = index % 2 === 0;

            // Calculate positions using offsets
            const xOffset = index * eventWidth + (isTopEvent ? topEventOffset.xOffset : bottomEventOffset.xOffset);
            const yOffset = isTopEvent
              ? topEventOffset.yOffset
              : bottomEventOffset.yOffset;

            const leftPosition = `${xOffset}px`;
            const topPosition = `calc(50% + ${yOffset}px)`;

            // Log positions for debugging
            console.log(
              `Rendering event "${event.title}" at (${leftPosition}, ${topPosition})`
            );

            return (
              <Box
                key={index}
                sx={{
                  position: 'absolute',
                  left: leftPosition,
                  top: topPosition,
                  transform: isTopEvent ? 'translate(-50%, -100%)' : 'translate(-50%, 0)',
                  display: 'flex',
                  flexDirection: isTopEvent ? 'column-reverse' : 'column',
                  alignItems: 'center',
                  justifyContent: isTopEvent ? 'flex-start' : 'flex-end',
                  alignContent: 'space-between',
                }}
              >
                {/* Bullet */}
                <Box
                  sx={{
                    width: event.isNow ? '34px' : '16px',
                    height: event.isNow ? '34px' : '16px',
                    borderRadius: '50%',
                    backgroundColor: event.isNow
                      ? theme.palette.secondary.main
                      : 'white',
                  }}
                />

                {/* Event Content */}
                <Box
                  sx={{
                    textAlign: 'center',
                    maxWidth: '300px',
                    mt: isTopEvent ? 0 : '8px',
                    mb: isTopEvent ? '8px' : 0,
                    minHeight: '80px', // Ensure a minimum height
                  }}
                >
                  {/* Event Date */}
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: event.isNow ? 'bold' : 'normal',
                      opacity: event.isFuture ? 0.5 : 1,
                    }}
                  >
                    {event.date}
                  </Typography>

                  {/* Event Title */}
                  <Typography
                    variant="body1"
                    sx={{ opacity: event.isFuture ? 0.5 : 1 }}
                  >
                    {event.title}
                  </Typography>

                  {/* Event Description with Tooltip */}
                  <Tooltip title={event.description} arrow>
                    <Typography
                      variant="body1"
                      sx={{
                        opacity: event.isFuture ? 0.5 : 1,
                        overflowWrap: 'break-word',
                        whiteSpace: 'normal',
                        // maxHeight: '60px', // Limit height
                        overflowY: 'auto', // Enable vertical scrolling
                      }}
                    >
                      {event.description}
                    </Typography>
                  </Tooltip>
                </Box>
                {/* Render image if available */}
                {event.image && (
                  <Box
                    sx={{
                      mt: isTopEvent ? 0 : 2,
                      mb: isTopEvent ? 2 : 0,
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <img
                      src={event.image}
                      alt={event.title}
                      style={{
                        width: '250px',   // Fixed width
                        // height: '380px',  // Fixed height
                        objectFit: 'contain',
                      }}
                    />
                    {console.log(`Rendered image for event "${event.title}"`)}
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default ProgressSlide;
