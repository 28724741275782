export const timezonelist = [
  { label: "International Date Line West - Etc/GMT+12", value: "International Date Line West"},
  { label: "Midway Island - Pacific/Midway", value: "Midway Island"},
  { label: "American Samoa - Pacific/Pago_Pago - Etc/GMT-11", value: "American Samoa"},
  { label: "Hawaii - Pacific/Honolulu - Etc/GMT-10", value: "Hawaii"},
  { label: "Alaska - America/Juneau", value: "Alaska"},
  { label: "Pacific Time (US & Canada) - America/Los_Angeles - Etc/GMT-8", value: "Pacific Time (US & Canada)"},
  { label: "Tijuana - America/Tijuana", value: "Tijuana"},
  { label: "Mountain Time (US & Canada) - America/Denver", value: "Mountain Time (US & Canada)"},
  { label: "Arizona - America/Phoenix - Etc/GMT-7", value: "Arizona"},
  { label: "Chihuahua - America/Chihuahua", value: "Chihuahua"},
  { label: "Mazatlan - America/Mazatlan", value: "Mazatlan"},
  { label: "Central Time (US & Canada) - America/Chicago", value: "Central Time (US & Canada)"},
  { label: "Saskatchewan - America/Regina", value: "Saskatchewan"},
  { label: "Guadalajara - America/Mexico_City", value: "Guadalajara"},
  { label: "Mexico City - America/Mexico_City", value: "Mexico City"},
  { label: "Monterrey - America/Monterrey", value: "Monterrey"},
  { label: "Central America - America/Guatemala - Etc/GMT-6", value: "Central America"},
  { label: "Eastern Time (US & Canada) - America/New_York - Etc/GMT-5", value: "America/New_York"},
  { label: "Indiana (East) - America/Indiana/Indianapolis", value: "Indiana (East)"},
  { label: "Bogota - America/Bogota", value: "Bogota"},
  { label: "Lima - America/Lima", value: "Lima"},
  { label: "Quito - America/Lima", value: "Quito"},
  { label: "Atlantic Time (Canada) - America/Halifax - Etc/GMT-4", value: "Atlantic Time (Canada)"},
  { label: "Caracas - America/Caracas - Etc/GMT-4:30", value: "Caracas"},
  { label: "La Paz - America/La_Paz", value: "La Paz"},
  { label: "Santiago - America/Santiago", value: "Santiago"},
  { label: "Newfoundland - America/St_Johns - Etc/GMT-3:30", value: "Newfoundland"},
  { label: "Brasilia - America/Sao_Paulo", value: "Brasilia"},
  { label: "Buenos Aires - America/Argentina/Buenos_Aires", value: "Buenos Aires"},
  { label: "Montevideo - America/Montevideo", value: "Montevideo"},
  { label: "Georgetown - America/Guyana", value: "Georgetown"},
  { label: "Puerto Rico - America/Puerto_Rico", value: "Puerto Rico"},
  { label: "Greenland - America/Godthab - Etc/GMT-3", value: "Greenland"},
  { label: "Mid-Atlantic - Atlantic/South_Georgia", value: "Mid-Atlantic"},
  { label: "Azores - Atlantic/Azores - Etc/GMT-1", value: "Azores"},
  { label: "Cape Verde Is. - Atlantic/Cape_Verde", value: "Cape Verde Is."},
  { label: "Dublin - Europe/Dublin", value: "Dublin"},
  { label: "Edinburgh - Europe/London", value: "Edinburgh"},
  { label: "Lisbon - Europe/Lisbon", value: "Lisbon"},
  { label: "London - Europe/London", value: "London"},
  { label: "Casablanca - Africa/Casablanca", value: "Casablanca"},
  { label: "Monrovia - Africa/Monrovia", value: "Monrovia"},
  { label: "UTC - Etc/UTC", value: "UTC"},
  { label: "Belgrade - Europe/Belgrade", value: "Belgrade"},
  { label: "Bratislava - Europe/Bratislava", value: "Bratislava"},
  { label: "Budapest - Europe/Budapest", value: "Budapest"},
  { label: "Ljubljana - Europe/Ljubljana", value: "Ljubljana"},
  { label: "Prague - Europe/Prague", value: "Prague"},
  { label: "Sarajevo - Europe/Sarajevo", value: "Sarajevo"},
  { label: "Skopje - Europe/Skopje", value: "Skopje"},
  { label: "Warsaw - Europe/Warsaw", value: "Warsaw"},
  { label: "Zagreb - Europe/Zagreb", value: "Zagreb"},
  { label: "Brussels - Europe/Brussels", value: "Brussels"},
  { label: "Copenhagen - Europe/Copenhagen", value: "Copenhagen"},
  { label: "Madrid - Europe/Madrid", value: "Madrid"},
  { label: "Paris - Europe/Paris", value: "Paris"},
  { label: "Amsterdam - Europe/Amsterdam", value: "Amsterdam"},
  { label: "Berlin - Europe/Berlin - Etc/GMT+1", value: "Berlin"},
  { label: "Bern - Europe/Zurich", value: "Bern"},
  { label: "Zurich - Europe/Zurich", value: "Zurich"},
  { label: "Rome - Europe/Rome", value: "Rome"},
  { label: "Stockholm - Europe/Stockholm", value: "Stockholm"},
  { label: "Vienna - Europe/Vienna", value: "Vienna"},
  { label: "West Central Africa - Africa/Algiers", value: "West Central Africa"},
  { label: "Bucharest - Europe/Bucharest", value: "Bucharest"},
  { label: "Cairo - Africa/Cairo", value: "Cairo"},
  { label: "Helsinki - Europe/Helsinki", value: "Helsinki"},
  { label: "Kyiv - Europe/Kiev", value: "Kyiv"},
  { label: "Riga - Europe/Riga", value: "Riga"},
  { label: "Sofia - Europe/Sofia", value: "Sofia"},
  { label: "Tallinn - Europe/Tallinn", value: "Tallinn"},
  { label: "Vilnius - Europe/Vilnius", value: "Vilnius"},
  { label: "Athens - Europe/Athens", value: "Athens"},
  { label: "Istanbul - Europe/Istanbul", value: "Istanbul"},
  { label: "Minsk - Europe/Minsk", value: "Minsk"},
  { label: "Jerusalem - Asia/Jerusalem", value: "Jerusalem"},
  { label: "Harare - Africa/Harare", value: "Harare"},
  { label: "Pretoria - Africa/Johannesburg - Etc/GMT+2", value: "Pretoria"},
  { label: "Kaliningrad - Europe/Kaliningrad", value: "Kaliningrad"},
  { label: "Moscow - Europe/Moscow", value: "Moscow"},
  { label: "St. Petersburg - Europe/Moscow", value: "St. Petersburg"},
  { label: "Volgograd - Europe/Volgograd", value: "Volgograd"},
  { label: "Samara - Europe/Samara", value: "Samara"},
  { label: "Kuwait - Asia/Kuwait", value: "Kuwait"},
  { label: "Riyadh - Asia/Riyadh", value: "Riyadh"},
  { label: "Nairobi - Africa/Nairobi", value: "Nairobi"},
  { label: "Baghdad - Asia/Baghdad - Etc/GMT+3", value: "Baghdad"},
  { label: "Tehran - Asia/Tehran - Etc/GMT+3:30", value: "Tehran"},
  { label: "Abu Dhabi - Asia/Muscat", value: "Abu Dhabi"},
  { label: "Muscat - Asia/Muscat", value: "Muscat"},
  { label: "Baku - Asia/Baku - Etc/GMT+4", value: "Baku"},
  { label: "Tbilisi - Asia/Tbilisi", value: "Tbilisi"},
  { label: "Yerevan - Asia/Yerevan", value: "Yerevan"},
  { label: "Kabul - Asia/Kabul - Etc/GMT+4:30", value: "Kabul"},
  { label: "Ekaterinburg - Asia/Yekaterinburg - Etc/GMT+5", value: "Ekaterinburg"},
  { label: "Islamabad - Asia/Karachi", value: "Islamabad"},
  { label: "Karachi - Asia/Karachi", value: "Karachi"},
  { label: "Tashkent - Asia/Tashkent", value: "Tashkent"},
  { label: "Chennai - Asia/Kolkata - Etc/GMT+5:30", value: "Chennai"},
  { label: "Kolkata - Asia/Kolkata - Etc/GMT+5:30", value: "Kolkata"},
  { label: "Mumbai - Asia/Kolkata - Etc/GMT+5:30", value: "Mumbai"},
  { label: "New Delhi - Asia/Kolkata - Etc/GMT+5:30", value: "New Delhi"},
  { label: "Kathmandu - Asia/Kathmandu - Etc/GMT+5:45", value: "Kathmandu"},
  { label: "Astana - Asia/Dhaka - Etc/GMT+6", value: "Astana"},
  { label: "Dhaka - Asia/Dhaka - Etc/GMT+6", value: "Dhaka"},
  { label: "Sri Jayawardenepura - Asia/Colombo", value: "Sri Jayawardenepura"},
  { label: "Almaty - Asia/Almaty", value: "Almaty"},
  { label: "Novosibirsk - Asia/Novosibirsk", value: "Novosibirsk"},
  { label: "Rangoon - Asia/Rangoon - Etc/GMT+6:30", value: "Rangoon"},
  { label: "Bangkok - Asia/Bangkok", value: "Bangkok"},
  { label: "Hanoi - Asia/Bangkok", value: "Hanoi"},
  { label: "Jakarta - Asia/Jakarta", value: "Jakarta"},
  { label: "Krasnoyarsk - Asia/Krasnoyarsk - Etc/GMT+7", value: "Krasnoyarsk"},
  { label: "Beijing - Asia/Shanghai - Etc/GMT+8", value: "Beijing"},
  { label: "Chongqing - Asia/Chongqing", value: "Chongqing"},
  { label: "Hong Kong - Asia/Hong_Kong", value: "Hong Kong"},
  { label: "Urumqi - Asia/Urumqi", value: "Urumqi"},
  { label: "Kuala Lumpur - Asia/Kuala_Lumpur", value: "Kuala Lumpur"},
  { label: "Singapore - Asia/Singapore", value: "Singapore"},
  { label: "Taipei - Asia/Taipei", value: "Taipei"},
  { label: "Perth - Australia/Perth", value: "Perth"},
  { label: "Irkutsk - Asia/Irkutsk", value: "Irkutsk"},
  { label: "Ulaanbaatar - Asia/Ulaanbaatar", value: "Ulaanbaatar"},
  { label: "Seoul - Asia/Seoul", value: "Seoul"},
  { label: "Osaka - Asia/Tokyo", value: "Osaka"},
  { label: "Sapporo - Asia/Tokyo", value: "Sapporo"},
  { label: "Tokyo - Asia/Tokyo", value: "Tokyo"},
  { label: "Yakutsk - Asia/Yakutsk - Etc/GMT+9", value: "Yakutsk"},
  { label: "Darwin - Australia/Darwin - Etc/GMT+9:30", value: "Darwin"},
  { label: "Adelaide - Australia/Adelaide", value: "Adelaide"},
  { label: "Canberra - Australia/Melbourne", value: "Canberra"},
  { label: "Melbourne - Australia/Melbourne", value: "Melbourne"},
  { label: "Sydney - Australia/Sydney", value: "Sydney"},
  { label: "Brisbane - Australia/Brisbane - Etc/GMT+10", value: "Brisbane"},
  { label: "Hobart - Australia/Hobart", value: "Hobart"},
  { label: "Vladivostok - Asia/Vladivostok", value: "Vladivostok"},
  { label: "Guam - Pacific/Guam", value: "Guam"},
  { label: "Port Moresby - Pacific/Port_Moresby", value: "Port Moresby"},
  { label: "Magadan - Asia/Magadan", value: "Magadan"},
  { label: "Srednekolymsk - Asia/Srednekolymsk", value: "Srednekolymsk"},
  { label: "Solomon Is. - Pacific/Guadalcanal", value: "Solomon Is."},
  { label: "New Caledonia - Pacific/Noumea", value: "New Caledonia"},
  { label: "Fiji - Pacific/Fiji", value: "Fiji"},
  { label: "Kamchatka - Asia/Kamchatka - Etc/GMT+11", value: "Kamchatka"},
  { label: "Marshall Is. - Pacific/Majuro - Etc/GMT-12", value: "Marshall Is."},
  { label: "Auckland - Pacific/Auckland - Etc/GMT+12", value: "Auckland"},
  { label: "Wellington - Pacific/Auckland - Etc/GMT+12", value: "Wellington"},
  { label: "Nuku'alofa - Pacific/Tongatapu - Etc/GMT+13", value: "Nuku'alofa"},
  { label: "Tokelau Is. - Pacific/Fakaofo", value: "Tokelau Is."},
  { label: "Chatham Is. - Pacific/Chatham - Etc/GMT+12:45", value: "Chatham Is."},
  { label: "Samoa - Pacific/Apia", value: "Samoa"}
]
// export const timezonelist = [
//   "Africa/Abidjan",
//   "Africa/Accra",
//   "Africa/Addis_Ababa",
//   "Africa/Algiers",
//   "Africa/Asmara",
//   "Africa/Asmera",
//   "Africa/Bamako",
//   "Africa/Bangui",
//   "Africa/Banjul",
//   "Africa/Bissau",
//   "Africa/Blantyre",
//   "Africa/Brazzaville",
//   "Africa/Bujumbura",
//   "Africa/Cairo",
//   "Africa/Casablanca",
//   "Africa/Ceuta",
//   "Africa/Conakry",
//   "Africa/Dakar",
//   "Africa/Dar_es_Salaam",
//   "Africa/Djibouti",
//   "Africa/Douala",
//   "Africa/El_Aaiun",
//   "Africa/Freetown",
//   "Africa/Gaborone",
//   "Africa/Harare",
//   "Africa/Johannesburg",
//   "Africa/Juba",
//   "Africa/Kampala",
//   "Africa/Khartoum",
//   "Africa/Kigali",
//   "Africa/Kinshasa",
//   "Africa/Lagos",
//   "Africa/Libreville",
//   "Africa/Lome",
//   "Africa/Luanda",
//   "Africa/Lubumbashi",
//   "Africa/Lusaka",
//   "Africa/Malabo",
//   "Africa/Maputo",
//   "Africa/Maseru",
//   "Africa/Mbabane",
//   "Africa/Mogadishu",
//   "Africa/Monrovia",
//   "Africa/Nairobi",
//   "Africa/Ndjamena",
//   "Africa/Niamey",
//   "Africa/Nouakchott",
//   "Africa/Ouagadougou",
//   "Africa/Porto-Novo",
//   "Africa/Sao_Tome",
//   "Africa/Timbuktu",
//   "Africa/Tripoli",
//   "Africa/Tunis",
//   "Africa/Windhoek",
//   "America/Adak",
//   "America/Anchorage",
//   "America/Anguilla",
//   "America/Antigua",
//   "America/Araguaina",
//   "America/Argentina/Buenos_Aires",
//   "America/Argentina/Catamarca",
//   "America/Argentina/ComodRivadavia",
//   "America/Argentina/Cordoba",
//   "America/Argentina/Jujuy",
//   "America/Argentina/La_Rioja",
//   "America/Argentina/Mendoza",
//   "America/Argentina/Rio_Gallegos",
//   "America/Argentina/Salta",
//   "America/Argentina/San_Juan",
//   "America/Argentina/San_Luis",
//   "America/Argentina/Tucuman",
//   "America/Argentina/Ushuaia",
//   "America/Aruba",
//   "America/Asuncion",
//   "America/Atikokan",
//   "America/Atka",
//   "America/Bahia",
//   "America/Bahia_Banderas",
//   "America/Barbados",
//   "America/Belem",
//   "America/Belize",
//   "America/Blanc-Sablon",
//   "America/Boa_Vista",
//   "America/Bogota",
//   "America/Boise",
//   "America/Buenos_Aires",
//   "America/Cambridge_Bay",
//   "America/Campo_Grande",
//   "America/Cancun",
//   "America/Caracas",
//   "America/Catamarca",
//   "America/Cayenne",
//   "America/Cayman",
//   "America/Chicago",
//   "America/Chihuahua",
//   "America/Coral_Harbour",
//   "America/Cordoba",
//   "America/Costa_Rica",
//   "America/Creston",
//   "America/Cuiaba",
//   "America/Curacao",
//   "America/Danmarkshavn",
//   "America/Dawson",
//   "America/Dawson_Creek",
//   "America/Denver",
//   "America/Detroit",
//   "America/Dominica",
//   "America/Edmonton",
//   "America/Eirunepe",
//   "America/El_Salvador",
//   "America/Ensenada",
//   "America/Fort_Nelson",
//   "America/Fort_Wayne",
//   "America/Fortaleza",
//   "America/Glace_Bay",
//   "America/Godthab",
//   "America/Goose_Bay",
//   "America/Grand_Turk",
//   "America/Grenada",
//   "America/Guadeloupe",
//   "America/Guatemala",
//   "America/Guayaquil",
//   "America/Guyana",
//   "America/Halifax",
//   "America/Havana",
//   "America/Hermosillo",
//   "America/Indiana/Indianapolis",
//   "America/Indiana/Knox",
//   "America/Indiana/Marengo",
//   "America/Indiana/Petersburg",
//   "America/Indiana/Tell_City",
//   "America/Indiana/Vevay",
//   "America/Indiana/Vincennes",
//   "America/Indiana/Winamac",
//   "America/Indianapolis",
//   "America/Inuvik",
//   "America/Iqaluit",
//   "America/Jamaica",
//   "America/Jujuy",
//   "America/Juneau",
//   "America/Kentucky/Louisville",
//   "America/Kentucky/Monticello",
//   "America/Knox_IN",
//   "America/Kralendijk",
//   "America/La_Paz",
//   "America/Lima",
//   "America/Los_Angeles",
//   "America/Louisville",
//   "America/Lower_Princes",
//   "America/Maceio",
//   "America/Managua",
//   "America/Manaus",
//   "America/Marigot",
//   "America/Martinique",
//   "America/Matamoros",
//   "America/Mazatlan",
//   "America/Mendoza",
//   "America/Menominee",
//   "America/Merida",
//   "America/Metlakatla",
//   "America/Mexico_City",
//   "America/Miquelon",
//   "America/Moncton",
//   "America/Monterrey",
//   "America/Montevideo",
//   "America/Montreal",
//   "America/Montserrat",
//   "America/Nassau",
//   "America/New_York",
//   "America/Nipigon",
//   "America/Nome",
//   "America/Noronha",
//   "America/North_Dakota/Beulah",
//   "America/North_Dakota/Center",
//   "America/North_Dakota/New_Salem",
//   "America/Ojinaga",
//   "America/Panama",
//   "America/Pangnirtung",
//   "America/Paramaribo",
//   "America/Phoenix",
//   "America/Port-au-Prince",
//   "America/Port_of_Spain",
//   "America/Porto_Acre",
//   "America/Porto_Velho",
//   "America/Puerto_Rico",
//   "America/Punta_Arenas",
//   "America/Rainy_River",
//   "America/Rankin_Inlet",
//   "America/Recife",
//   "America/Regina",
//   "America/Resolute",
//   "America/Rio_Branco",
//   "America/Rosario",
//   "America/Santa_Isabel",
//   "America/Santarem",
//   "America/Santiago",
//   "America/Santo_Domingo",
//   "America/Sao_Paulo",
//   "America/Scoresbysund",
//   "America/Shiprock",
//   "America/Sitka",
//   "America/St_Barthelemy",
//   "America/St_Johns",
//   "America/St_Kitts",
//   "America/St_Lucia",
//   "America/St_Thomas",
//   "America/St_Vincent",
//   "America/Swift_Current",
//   "America/Tegucigalpa",
//   "America/Thule",
//   "America/Thunder_Bay",
//   "America/Tijuana",
//   "America/Toronto",
//   "America/Tortola",
//   "America/Vancouver",
//   "America/Virgin",
//   "America/Whitehorse",
//   "America/Winnipeg",
//   "America/Yakutat",
//   "America/Yellowknife",
//   "Antarctica/Casey",
//   "Antarctica/Davis",
//   "Antarctica/DumontDUrville",
//   "Antarctica/Macquarie",
//   "Antarctica/Mawson",
//   "Antarctica/McMurdo",
//   "Antarctica/Palmer",
//   "Antarctica/Rothera",
//   "Antarctica/South_Pole",
//   "Antarctica/Syowa",
//   "Antarctica/Troll",
//   "Antarctica/Vostok",
//   "Arctic/Longyearbyen",
//   "Asia/Aden",
//   "Asia/Almaty",
//   "Asia/Amman",
//   "Asia/Anadyr",
//   "Asia/Aqtau",
//   "Asia/Aqtobe",
//   "Asia/Ashgabat",
//   "Asia/Ashkhabad",
//   "Asia/Atyrau",
//   "Asia/Baghdad",
//   "Asia/Bahrain",
//   "Asia/Baku",
//   "Asia/Bangkok",
//   "Asia/Barnaul",
//   "Asia/Beirut",
//   "Asia/Bishkek",
//   "Asia/Brunei",
//   "Asia/Calcutta",
//   "Asia/Chita",
//   "Asia/Choibalsan",
//   "Asia/Chongqing",
//   "Asia/Chungking",
//   "Asia/Colombo",
//   "Asia/Dacca",
//   "Asia/Damascus",
//   "Asia/Dhaka",
//   "Asia/Dili",
//   "Asia/Dubai",
//   "Asia/Dushanbe",
//   "Asia/Famagusta",
//   "Asia/Gaza",
//   "Asia/Harbin",
//   "Asia/Hebron",
//   "Asia/Ho_Chi_Minh",
//   "Asia/Hong_Kong",
//   "Asia/Hovd",
//   "Asia/Irkutsk",
//   "Asia/Istanbul",
//   "Asia/Jakarta",
//   "Asia/Jayapura",
//   "Asia/Jerusalem",
//   "Asia/Kabul",
//   "Asia/Kamchatka",
//   "Asia/Karachi",
//   "Asia/Kashgar",
//   "Asia/Kathmandu",
//   "Asia/Katmandu",
//   "Asia/Khandyga",
//   "Asia/Kolkata",
//   "Asia/Krasnoyarsk",
//   "Asia/Kuala_Lumpur",
//   "Asia/Kuching",
//   "Asia/Kuwait",
//   "Asia/Macao",
//   "Asia/Macau",
//   "Asia/Magadan",
//   "Asia/Makassar",
//   "Asia/Manila",
//   "Asia/Muscat",
//   "Asia/Nicosia",
//   "Asia/Novokuznetsk",
//   "Asia/Novosibirsk",
//   "Asia/Omsk",
//   "Asia/Oral",
//   "Asia/Phnom_Penh",
//   "Asia/Pontianak",
//   "Asia/Pyongyang",
//   "Asia/Qatar",
//   "Asia/Qostanay",
//   "Asia/Qyzylorda",
//   "Asia/Rangoon",
//   "Asia/Riyadh",
//   "Asia/Saigon",
//   "Asia/Sakhalin",
//   "Asia/Samarkand",
//   "Asia/Seoul",
//   "Asia/Shanghai",
//   "Asia/Singapore",
//   "Asia/Srednekolymsk",
//   "Asia/Taipei",
//   "Asia/Tashkent",
//   "Asia/Tbilisi",
//   "Asia/Tehran",
//   "Asia/Tel_Aviv",
//   "Asia/Thimbu",
//   "Asia/Thimphu",
//   "Asia/Tokyo",
//   "Asia/Tomsk",
//   "Asia/Ujung_Pandang",
//   "Asia/Ulaanbaatar",
//   "Asia/Ulan_Bator",
//   "Asia/Urumqi",
//   "Asia/Ust-Nera",
//   "Asia/Vientiane",
//   "Asia/Vladivostok",
//   "Asia/Yakutsk",
//   "Asia/Yangon",
//   "Asia/Yekaterinburg",
//   "Asia/Yerevan",
//   "Atlantic/Azores",
//   "Atlantic/Bermuda",
//   "Atlantic/Canary",
//   "Atlantic/Cape_Verde",
//   "Atlantic/Faeroe",
//   "Atlantic/Faroe",
//   "Atlantic/Jan_Mayen",
//   "Atlantic/Madeira",
//   "Atlantic/Reykjavik",
//   "Atlantic/South_Georgia",
//   "Atlantic/St_Helena",
//   "Atlantic/Stanley",
//   "Australia/ACT",
//   "Australia/Adelaide",
//   "Australia/Brisbane",
//   "Australia/Broken_Hill",
//   "Australia/Canberra",
//   "Australia/Currie",
//   "Australia/Darwin",
//   "Australia/Eucla",
//   "Australia/Hobart",
//   "Australia/LHI",
//   "Australia/Lindeman",
//   "Australia/Lord_Howe",
//   "Australia/Melbourne",
//   "Australia/NSW",
//   "Australia/North",
//   "Australia/Perth",
//   "Australia/Queensland",
//   "Australia/South",
//   "Australia/Sydney",
//   "Australia/Tasmania",
//   "Australia/Victoria",
//   "Australia/West",
//   "Australia/Yancowinna",
//   "Brazil/Acre",
//   "Brazil/DeNoronha",
//   "Brazil/East",
//   "Brazil/West",
//   "CET",
//   "CST6CDT",
//   "Canada/Atlantic",
//   "Canada/Central",
//   "Canada/Eastern",
//   "Canada/Mountain",
//   "Canada/Newfoundland",
//   "Canada/Pacific",
//   "Canada/Saskatchewan",
//   "Canada/Yukon",
//   "Chile/Continental",
//   "Chile/EasterIsland",
//   "Cuba",
//   "EET",
//   "EST",
//   "EST5EDT",
//   "Egypt",
//   "Eire",
//   "Etc/GMT",
//   "Etc/GMT+0",
//   "Etc/GMT+1",
//   "Etc/GMT+10",
//   "Etc/GMT+11",
//   "Etc/GMT+12",
//   "Etc/GMT+2",
//   "Etc/GMT+3",
//   "Etc/GMT+4",
//   "Etc/GMT+5",
//   "Etc/GMT+6",
//   "Etc/GMT+7",
//   "Etc/GMT+8",
//   "Etc/GMT+9",
//   "Etc/GMT-0",
//   "Etc/GMT-1",
//   "Etc/GMT-10",
//   "Etc/GMT-11",
//   "Etc/GMT-12",
//   "Etc/GMT-13",
//   "Etc/GMT-14",
//   "Etc/GMT-2",
//   "Etc/GMT-3",
//   "Etc/GMT-4",
//   "Etc/GMT-5",
//   "Etc/GMT-6",
//   "Etc/GMT-7",
//   "Etc/GMT-8",
//   "Etc/GMT-9",
//   "Etc/GMT0",
//   "Etc/Greenwich",
//   "Etc/UCT",
//   "Etc/UTC",
//   "Etc/Universal",
//   "Etc/Zulu",
//   "Europe/Amsterdam",
//   "Europe/Andorra",
//   "Europe/Astrakhan",
//   "Europe/Athens",
//   "Europe/Belfast",
//   "Europe/Belgrade",
//   "Europe/Berlin",
//   "Europe/Bratislava",
//   "Europe/Brussels",
//   "Europe/Bucharest",
//   "Europe/Budapest",
//   "Europe/Busingen",
//   "Europe/Chisinau",
//   "Europe/Copenhagen",
//   "Europe/Dublin",
//   "Europe/Gibraltar",
//   "Europe/Guernsey",
//   "Europe/Helsinki",
//   "Europe/Isle_of_Man",
//   "Europe/Istanbul",
//   "Europe/Jersey",
//   "Europe/Kaliningrad",
//   "Europe/Kiev",
//   "Europe/Kirov",
//   "Europe/Lisbon",
//   "Europe/Ljubljana",
//   "Europe/London",
//   "Europe/Luxembourg",
//   "Europe/Madrid",
//   "Europe/Malta",
//   "Europe/Mariehamn",
//   "Europe/Minsk",
//   "Europe/Monaco",
//   "Europe/Moscow",
//   "Europe/Nicosia",
//   "Europe/Oslo",
//   "Europe/Paris",
//   "Europe/Podgorica",
//   "Europe/Prague",
//   "Europe/Riga",
//   "Europe/Rome",
//   "Europe/Samara",
//   "Europe/San_Marino",
//   "Europe/Sarajevo",
//   "Europe/Saratov",
//   "Europe/Simferopol",
//   "Europe/Skopje",
//   "Europe/Sofia",
//   "Europe/Stockholm",
//   "Europe/Tallinn",
//   "Europe/Tirane",
//   "Europe/Tiraspol",
//   "Europe/Ulyanovsk",
//   "Europe/Uzhgorod",
//   "Europe/Vaduz",
//   "Europe/Vatican",
//   "Europe/Vienna",
//   "Europe/Vilnius",
//   "Europe/Volgograd",
//   "Europe/Warsaw",
//   "Europe/Zagreb",
//   "Europe/Zaporozhye",
//   "Europe/Zurich",
//   "GB",
//   "GB-Eire",
//   "GMT",
//   "GMT+0",
//   "GMT-0",
//   "GMT0",
//   "Greenwich",
//   "HST",
//   "Hongkong",
//   "Iceland",
//   "Indian/Antananarivo",
//   "Indian/Chagos",
//   "Indian/Christmas",
//   "Indian/Cocos",
//   "Indian/Comoro",
//   "Indian/Kerguelen",
//   "Indian/Mahe",
//   "Indian/Maldives",
//   "Indian/Mauritius",
//   "Indian/Mayotte",
//   "Indian/Reunion",
//   "Iran",
//   "Israel",
//   "Jamaica",
//   "Japan",
//   "Kwajalein",
//   "Libya",
//   "MET",
//   "MST",
//   "MST7MDT",
//   "Mexico/BajaNorte",
//   "Mexico/BajaSur",
//   "Mexico/General",
//   "NZ",
//   "NZ-CHAT",
//   "Navajo",
//   "PRC",
//   "PST8PDT",
//   "Pacific/Apia",
//   "Pacific/Auckland",
//   "Pacific/Bougainville",
//   "Pacific/Chatham",
//   "Pacific/Chuuk",
//   "Pacific/Easter",
//   "Pacific/Efate",
//   "Pacific/Enderbury",
//   "Pacific/Fakaofo",
//   "Pacific/Fiji",
//   "Pacific/Funafuti",
//   "Pacific/Galapagos",
//   "Pacific/Gambier",
//   "Pacific/Guadalcanal",
//   "Pacific/Guam",
//   "Pacific/Honolulu",
//   "Pacific/Johnston",
//   "Pacific/Kiritimati",
//   "Pacific/Kosrae",
//   "Pacific/Kwajalein",
//   "Pacific/Majuro",
//   "Pacific/Marquesas",
//   "Pacific/Midway",
//   "Pacific/Nauru",
//   "Pacific/Niue",
//   "Pacific/Norfolk",
//   "Pacific/Noumea",
//   "Pacific/Pago_Pago",
//   "Pacific/Palau",
//   "Pacific/Pitcairn",
//   "Pacific/Pohnpei",
//   "Pacific/Ponape",
//   "Pacific/Port_Moresby",
//   "Pacific/Rarotonga",
//   "Pacific/Saipan",
//   "Pacific/Samoa",
//   "Pacific/Tahiti",
//   "Pacific/Tarawa",
//   "Pacific/Tongatapu",
//   "Pacific/Truk",
//   "Pacific/Wake",
//   "Pacific/Wallis",
//   "Pacific/Yap",
//   "Poland",
//   "Portugal",
//   "ROC",
//   "ROK",
//   "Singapore",
//   "Turkey",
//   "UCT",
//   "US/Alaska",
//   "US/Aleutian",
//   "US/Arizona",
//   "US/Central",
//   "US/East-Indiana",
//   "US/Eastern",
//   "US/Hawaii",
//   "US/Indiana-Starke",
//   "US/Michigan",
//   "US/Mountain",
//   "US/Pacific",
//   "US/Pacific-New",
//   "US/Samoa",
//   "UTC",
//   "Universal",
//   "W-SU",
//   "WET",
//   "Zulu"
// ]
