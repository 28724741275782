import React from 'react';
import Button from '@mui/material/Button';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import { CircularProgress } from '@mui/material';

type DeliveryStateButtonProps = {
  deliveryState: 'play' | 'pause' | 'loading' | 'stop';
  onClick: () => void;
};

const DeliveryStateButton: React.FC<DeliveryStateButtonProps> = ({ deliveryState, onClick }) => {
  const handleClick = () => {
    if (deliveryState === 'loading') {
      return;
    }
    onClick();
  }

  return (
    <Button 
      variant={deliveryState === 'play' ? 'contained' : 'outlined'} 
      color="primary" 
      onClick={handleClick}
      disabled={deliveryState === 'loading' || deliveryState === 'stop'}
      sx={{
        borderRadius:'0',
      }}
      >
      {deliveryState === 'play' && 'Delivery Enabled'}
      {deliveryState === 'pause' && 'Delivery Paused'}
      {deliveryState === 'loading' && 'Loading...'}
      {deliveryState === 'stop' && 'AI Stopped'}
      {deliveryState === 'play' && <PlayArrowIcon />}
      {deliveryState === 'pause' && <PauseIcon sx={{ animation: 'pulse 1s infinite' }} />}
      {deliveryState === 'loading' && <CircularProgress size={24} />}
      {deliveryState === 'stop' && <StopIcon />}
    </Button>
  );
};

export default DeliveryStateButton;
