import React, { useState, useEffect } from 'react';
import {
  Typography,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Button,
  LinearProgress,
  IconButton,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setGoogleCalendarToken, disconnectGoogleCalendar, getUser, getUserCalendar } from 'src/features/user/user.slice';
import { useLocation, useNavigate } from 'react-router-dom';
import { GoogleAuthorization } from 'src/libs/googleAuthorization';
import { CalendarToday, Sync, ErrorOutline } from '@mui/icons-material';

const Calendar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [error, setError] = useState(null);
  const { user, userCalendar } = useAppSelector((state) => state.user);
  const [selectedCalendar, setSelectedCalendar] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCalendarLoading, setIsCalendarLoading] = useState(false);

  useEffect(() => {
    const url = new URLSearchParams(location.search);
    const authorization_code = url.get('code');

    if (authorization_code) {
      console.log(`Authorization code: ${authorization_code}`);
      dispatch(
        setGoogleCalendarToken({ authorization_code: authorization_code, redirect_uri: `${window.location.origin}/calendar` })
      ).unwrap()
        .then((response) => {
          console.log('Calendar connected');
          console.log(response);
          
          dispatch(getUser()) // Refresh user after calendar connection
            .unwrap()
            .then(() => {
              console.log('User data refreshed');
            })
            .catch((error) => {
              console.error('Failed to refresh user data', error);
              setError(error);
            });
        })
        .catch((error) => {
          setError(error);
        });
    }

  }, [location, dispatch, navigate]);

  useEffect(() => {
    if (user.google_calendar_connected) {
      setIsCalendarLoading(true);
      dispatch(getUserCalendar())
        .unwrap()
        .finally(() => setIsCalendarLoading(false));
    }
  }, [dispatch, user.google_calendar_connected]);

  useEffect(() => {
    const initiallySelected = userCalendar.find((calendar) => calendar.selected);
    if (initiallySelected) {
      setSelectedCalendar(initiallySelected.id);
    }
  }, [userCalendar]);

  const handleConnect = (event) => {
    if (!selectedCalendar) return;
    // Trigger Google Authorization
    const googleAuth = new GoogleAuthorization(() => {
      console.log('Auth window closed');
    });
    googleAuth.authorizeUser(event);
  };

  const handleDisconnect = async () => {
    if (!window.confirm('Are you sure you want to disconnect your Google Calendar?')) return;
    setIsLoading(true);
    try {
      await dispatch(disconnectGoogleCalendar()).unwrap();
      console.log('Google Calendar disconnected');
      
      // Reload user and calendar list
      await dispatch(getUser()).unwrap();
    } catch (error) {
      console.error('Failed to disconnect Google Calendar:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4">Google Calendar Integration</Typography>
        {!user.google_calendar_connected && (
          <IconButton onClick={handleConnect} disabled={!selectedCalendar || isLoading}>
            <Sync />
          </IconButton>
        )}
      </Box>

      {isLoading || isCalendarLoading ? (
        <LinearProgress sx={{ mb: 2 }} />
      ) : (
        <Box sx={{ maxWidth: '600px', display: 'flex', flexDirection: 'column', gap: 2 }}>
          {userCalendar.length > 0 && user.google_calendar_connected && (
            <Typography variant="body1" gutterBottom>
              <CalendarToday sx={{ mr: 1 }} />
              Select a calendar to connect. You can only select one calendar at a time. If you wish to change the connected calendar, disconnect the current one first.
            </Typography>
          )}


          {user.google_calendar_connected ? 
            <>
              <FormControl component="fieldset">
                <RadioGroup
                  value={selectedCalendar}
                  onChange={(e) => setSelectedCalendar(e.target.value)}
                >
                  {userCalendar.map((calendar) => (
                    <FormControlLabel
                      key={calendar.id}
                      value={calendar.id}
                      control={<Radio />}
                      label={calendar.summary}
                      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </>
          : 
            <>
              <Typography variant="body1" gutterBottom>
                <ErrorOutline sx={{ mr: 1 }} />
                Connect your Google Calendar to unlock powerful features:
              </Typography>
              <ul>
                <li>Automate scheduling with AI-driven insights</li>
                <li>Maintain control over calendar access and permissions</li>
                <li>Seamlessly switch between calendars as needed</li>
              </ul>
              <Button
                onClick={handleConnect}
                variant="contained"
                disabled={!selectedCalendar || isLoading}
              >
                Connect Google Calendar
              </Button>
            </>
          }
          {user.google_calendar_connected && (
            <Button
              variant="outlined"
              color="warning"
              onClick={handleDisconnect}
              sx={{ mt: 4 }}
            >
              Disconnect Calendar
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Calendar;
