import BookingAvailabilityForm from "src/components/profile/BookingAvailabilityForm";
import ProfileLayout from "src/components/profile/ProfileLayout";

const BookingAvailability = () => {
    return (
        <ProfileLayout activeKey="booking">
            <BookingAvailabilityForm />
        </ProfileLayout>
      );
};

export default BookingAvailability;
