import { Image } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setServiceImages, uploadPhotographerServiceImage } from 'src/features/services/photographer.slice';
import FilestackUpload from 'src/components/FilestackUpload';
import { useState } from 'react';
import UploadEmbedGalleryItem from './UploadEmbedGalleryItem';
import subscriptionPopup from 'src/components/Subscription/SubscriptionPopup';
import Swal from "sweetalert2";


const GalleryItemUploader = ({ currentService, currentGalleryItems, serviceId }) => {
  const dispatch = useAppDispatch();
  const [openFilestack, setOpenFilestack] = useState(false);
  const [showEmbedForm, setShowEmbedForm] = useState(false);
  const { subscriptionFeatures } = useAppSelector((state) => state.auth);

  const setItemableType = (file) => {
    switch(file.mimetype.split('/')[0]) {
      case "image":
        return "Photo"
      case "video":
        return "Video"
      default:
        return null
    }
  }

  const limitReachedPopup = () => {
    Swal.fire({
      confirmButtonText: 'Ok',
      icon: 'error',
      title: 'Upgrade to Pro!',
      text: `And upload up to 100 items to each service!`
    })
  };

  const saveMedia = (file) => {
      dispatch(uploadPhotographerServiceImage({
        service_id: serviceId,
        itemable_type: setItemableType(file),
        file: file.url,
      }))
    }

  const uploadedFiles = (files) => {
    if (subscriptionFeatures?.higher_tiers?.length > 0 && files.length > (subscriptionFeatures?.service_specific_gallery?.quantity - currentGalleryItems.length)) {
      subscriptionPopup(subscriptionFeatures, `You can upload only ${subscriptionFeatures?.service_specific_gallery?.quantity} gallery item(s) `, { feature_id: "service_specific_gallery", description: `Provider can only upload ${subscriptionFeatures?.service_specific_gallery?.quantity} gallery item(s)` }, dispatch)
    } else if (subscriptionFeatures?.higher_tiers?.length == 0 && files.length > (subscriptionFeatures?.service_specific_gallery?.quantity - currentGalleryItems.length)) {
      limitReachedPopup()
    }
    else {
      let loading = [...currentGalleryItems]
      files.map((file, index) => {
        loading = [...loading, { id: -1 * (index + 1), itemable_url: "/icon/loading.gif" }]
        saveMedia(file)
      })
      dispatch(setServiceImages(loading))
    }

  };

  const closeEmbedForm = () => {
    setShowEmbedForm(false)
  };

  const limitExceeded = subscriptionFeatures?.higher_tiers?.length == 0 && currentGalleryItems.length >= subscriptionFeatures?.service_specific_gallery?.quantity
  const permitFunctionality = currentGalleryItems.length < subscriptionFeatures?.service_specific_gallery?.quantity
  return (
    <>
      <UploadEmbedGalleryItem
        serviceId={currentService?.id}
        show={showEmbedForm}
        close={closeEmbedForm}
      />
      <div
        className="border align-items-center justify-content-center d-flex item item-empty"
        onClick={() => {
          if (permitFunctionality) {
            setOpenFilestack(true)
          } else if (limitExceeded) {
            limitReachedPopup()
          }
          else {
            subscriptionPopup(subscriptionFeatures, `Upload up to 100 items to each service by upgrading your account to Pro!`, { feature_id:  "service_specific_gallery", description:  "Upload up to 100 items to each service by upgrading your account to Pro!" }, dispatch)
          }
        }}
      >
        <label htmlFor="image-upload-input">
          <Image
            src="/icon/addImageIcon.svg"
            className="mr-3 text-blue-added "
          />
        </label>
        <Image
          src="/icon/threeDotsVertical.svg"
          className="three-dots"
          onClick={(e) => {
            if (permitFunctionality) {
              e.stopPropagation()
              setShowEmbedForm(true)
            } else if (limitExceeded) {
              limitReachedPopup()
            }
            else {
              subscriptionPopup(subscriptionFeatures, `You can upload only ${subscriptionFeatures?.service_specific_gallery?.quantity} gallery item(s) `, { feature_id: "service_specific_gallery", description: `Provider can only upload ${subscriptionFeatures?.service_specific_gallery?.quantity} gallery item(s)` }, dispatch)
            }
          }}
        />
        {openFilestack &&
          <FilestackUpload
            uploadedFiles={uploadedFiles}
            max_file_size_in_mb={currentService.video_max_file_size_in_mb}
            onClose={() => setOpenFilestack(false)}
          />
        }
      </div>
    </>
  );
};

export default GalleryItemUploader;
