import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, List, ListItem, ListItemText } from '@mui/material';

const BrightMlsRevenueProjection: React.FC = () => {
  const [activeTooltip, setActiveTooltip] = useState(null);

  const totalListings = 522000;
  const monthlySubscriptionPrice = 39;
  
  const subscriptionDurations = [3, 3.5, 4, 6, 10];
  const penetrationRates = [0.04, 0.09, 0.12, 0.13, 0.15];
  const lowPenetrationRate = penetrationRates[0] * 100;
  const highPenetrationRate = penetrationRates[penetrationRates.length - 1] * 100;

  const calculateYearlyRevenue = (penetrationRate, subscriptionDuration) => {
    const subscribersPerYear = totalListings * penetrationRate;
    const averageMonthlySubscribers = subscribersPerYear * (subscriptionDuration / 12);
    return Math.round(averageMonthlySubscribers * monthlySubscriptionPrice * 12);
  };

  const data = penetrationRates.map((rate, index) => ({
    year: `Year ${index + 1}`,
    revenue: calculateYearlyRevenue(rate, subscriptionDurations[index]),
    penetrationRate: rate,
    subscriptionDuration: subscriptionDurations[index],
    subscribers: Math.round(totalListings * rate)
  }));

  const formatYAxis = (value) => {
    return `$${(value / 1_000_000).toFixed(0)}M`;
  };

  const customTooltip = ({ active, payload, label }) => {
    if (!active || !payload || !payload.length) return null;

    const { year, revenue, penetrationRate, subscriptionDuration, subscribers } = payload[0].payload;
    const yearNumber = parseInt(year.split(' ')[1]);

    const stories = [
      {
        title: "Launch and Early Traction",
        points: [
          `Listing concierge launched, reaching ${subscribers.toLocaleString()} listings subscribed`,
          `Penetration increases to ${(penetrationRate * 100).toFixed(1)}% of listings`,
          `Average subscription duration extends to ${subscriptionDuration} months`,
          `Revenue grows to $${revenue.toLocaleString()}`
        ]
      },
      {
        title: "Gaining Momentum",
        points: [
          `Subscriber base expands to ${subscribers.toLocaleString()} listings`,
          `Penetration rate climbs to ${(penetrationRate * 100).toFixed(1)}%`,
          `Average subscription length increases to ${subscriptionDuration} months`,
          `Revenue reaches $${revenue.toLocaleString()}`
        ]
      },
      {
        title: "Accelerated Growth",
        points: [
          `Strong marketing drives adoption to ${subscribers.toLocaleString()} listings subscribed`,
          `Total listing penetration hits ${(penetrationRate * 100).toFixed(1)}%`,
          `Subscription duration extends to ${subscriptionDuration} months`,
          `Revenue surges to $${revenue.toLocaleString()}`
        ]
      },
      {
        title: "Home Owner Retention",
        points: [
          `Oppy achieves ${(penetrationRate * 100).toFixed(1)}% penetration`,
          `${subscribers.toLocaleString()} active listings subscribed`,
          `Average subscription grows to ${subscriptionDuration} months`,
          `Annual revenue reaches $${revenue.toLocaleString()}`
        ]
      },
      {
        title: "Saturation",
        points: [
          `Penetration stabilizes at ${(penetrationRate * 100).toFixed(1)}%`,
          `${subscribers.toLocaleString()} listings consistently use Oppy`,
          `Subscription duration peaks at ${subscriptionDuration} months`,
          `Revenue stabilizes at $${revenue.toLocaleString()}`
        ]
      }
    ];

    if (isNaN(yearNumber) || yearNumber < 1 || yearNumber > stories.length) {
      return null;
    }

    const story = stories[yearNumber - 1];

    if (!story) {
      return null;
    }

    const avatarStyle = {
      backgroundColor: '#4CAF50',
      color: 'white',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 'bold',
      marginRight: '10px'
    };

    return (
      <Box sx={{ backgroundColor: '#1E1E1E', padding: '15px', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <Box sx={avatarStyle}>Y{yearNumber}</Box>
          <Typography variant="h6" sx={{ margin: 0, color: '#E0E0E0' }}>{story.title}</Typography>
        </Box>
        <List sx={{ padding: 0, margin: '0 0 10px 0', color: '#B0B0B0' }}>
          {story.points.map((point, index) => (
            <ListItem key={`${point}-${index}`} sx={{ marginBottom: '5px' }}>
              <ListItemText primary={`• ${point}`} />
            </ListItem>
          ))}
        </List>
        <Box sx={{ borderTop: '1px solid #333', paddingTop: '10px', color: '#D0D0D0' }}>
          <Typography>📈 Revenue: <strong>${revenue.toLocaleString()}</strong></Typography>
          <Typography>🎯 Penetration: <strong>{(penetrationRate * 100).toFixed(1)}%</strong></Typography>
          <Typography>⏳ Avg. Subscription: <strong>{subscriptionDuration} months</strong></Typography>
          <Typography>👥 Subscribers: <strong>{subscribers.toLocaleString()}</strong></Typography>
        </Box>
      </Box>
    );
  };

  const handleMouseMove = (props) => {
    if (props.activeTooltipIndex !== undefined) {
      setActiveTooltip(props.activeTooltipIndex);
    }
  };

  return (
    <Box sx={{ backgroundColor: 'black', padding: 2, height: '100%', display: 'flex', flexDirection: 'column', color: 'white', overflowY: 'auto' }}>
      <Typography variant="h4" align="center" gutterBottom>Oppy X MLS 24/7 AI Listing Concierge</Typography>
      <Typography variant="h6" align="center" gutterBottom>5-Year Revenue Projection (Monthly Subscriptions)</Typography>
      <Box sx={{ flexGrow: 1, minHeight: '50%', mb: 2 }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart 
            data={data} 
            margin={{ top: 20, right: 20, left: 20, bottom: 5 }}
            onMouseMove={handleMouseMove}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#333" />
            <XAxis dataKey="year" stroke="#fff" />
            <YAxis 
              stroke="#fff" 
              tickFormatter={formatYAxis}
              domain={[0, 'dataMax']}
              allowDataOverflow={true}
            />
            <Tooltip content={customTooltip} isAnimationActive={false} />
            <Legend wrapperStyle={{ color: '#fff' }} />
            <Line 
              type="monotone" 
              dataKey="revenue" 
              name="Projected Revenue" 
              stroke="#fff" 
              activeDot={{ r: 8 }} 
              dot={{ r: 4 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
      <Box>
        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>Projection Details:</Typography>
        <TableContainer component={Paper} sx={{ marginBottom: 2, backgroundColor: 'transparent' }}>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#1f2937' }}>
                <TableCell sx={{ color: 'white' }}>Year</TableCell>
                <TableCell sx={{ color: 'white' }}>Penetration Rate</TableCell>
                <TableCell sx={{ color: 'white' }}>Avg. Subscription Duration (months)</TableCell>
                <TableCell sx={{ color: 'white' }}>Projected Revenue</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow 
                  key={`${item.year}-${index}`}
                  sx={{
                    backgroundColor: item.year === data[activeTooltip]?.year ? '#2563eb' : index % 2 === 0 ? '#111827' : '#1f2937'
                  }}
                >
                  <TableCell sx={{ color: 'white' }}>{item.year}</TableCell>
                  <TableCell sx={{ color: 'white' }}>
                    {(item.penetrationRate * 100).toFixed(1)}% 
                    <br />
                    <Typography variant="caption" sx={{ opacity: 0.6, color: 'white' }}>({item.penetrationRate} * {totalListings.toLocaleString()} = {item.subscribers.toLocaleString()} subscribers)</Typography>
                  </TableCell>
                  <TableCell sx={{ color: 'white' }}>{item.subscriptionDuration}</TableCell>
                  <TableCell sx={{ color: 'white' }}>${item.revenue.toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>Proposed Integration:</Typography>
        <List dense>
          <ListItem>
            <ListItemText 
              primary="Each agent must list their property through the MLS" 
              sx={{ color: 'white' }} 
              primaryTypographyProps={{ lineHeight: 1.2 }}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="At the point of listing, the agent will be given the option to add the AI concierge" 
              sx={{ color: 'white' }} 
              primaryTypographyProps={{ lineHeight: 1.2 }}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="The agent will be presented with a pre-trained Oppy listing concierge they can customize" 
              sx={{ color: 'white' }} 
              primaryTypographyProps={{ lineHeight: 1.2 }}
            />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="The agent will be able to try it, secure a phone number, get a qr code and purchase a monthly subscription in a few clicks" 
              sx={{ color: 'white' }} 
              primaryTypographyProps={{ lineHeight: 1.2 }}
            />
          </ListItem>
        </List>
        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>Assumptions:</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
          <Box sx={{ flex: '1 1 200px', backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: 2, borderRadius: 1 }}>
            <Typography variant="body2">
              <strong>Market Size:</strong> {totalListings.toLocaleString()} listings
            </Typography>
          </Box>
          <Box sx={{ flex: '1 1 200px', backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: 2, borderRadius: 1 }}>
            <Typography variant="body2">
              <strong>Monthly Price:</strong> ${monthlySubscriptionPrice}
            </Typography>
          </Box>
          <Box sx={{ flex: '1 1 200px', backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: 2, borderRadius: 1 }}>
            <Typography variant="body2">
              <strong>Avg. Subscription:</strong> {subscriptionDurations.join(', ')} months
            </Typography>
          </Box>
          <Box sx={{ flex: '1 1 200px', backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: 2, borderRadius: 1 }}>
            <Typography variant="body2">
              <strong>Penetration:</strong> {lowPenetrationRate.toFixed(1)}% to {highPenetrationRate.toFixed(1)}%
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" sx={{ mb: 2 }}>
          We believe subscription duration can be extended by making Oppy a home concierge that the home buyer wants to keep after the transaction closes.
        </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
          This projection assumes a constant market size over 5 years.
        </Typography>
        <Typography variant="body2" color="rgba(255, 255, 255, 0.7)" sx={{ fontStyle: 'italic', borderTop: '1px solid rgba(255, 255, 255, 0.2)', paddingTop: 2 }}>
          Disclaimer: This projection is based on hypothetical growth rates and assumes stable market conditions.
          Actual results may vary significantly due to market changes, competition, and other factors.
          This chart should be used for illustrative purposes only in strategic planning.
        </Typography>
      </Box>
    </Box>
  );
};

export default BrightMlsRevenueProjection;
