import { Box } from '@mui/system';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from 'src/components/Loading';
import AssistantIcon from 'src/components/ThemeElements/AssistantIcon';
import LavaLampBackground from 'src/components/ThemeElements/LavaLampBackground';
import useAuth from 'src/hooks/useAuth';
import { useAppSelector } from 'src/store';
import { getBranding } from 'src/utils/branding';
import Lottie from 'react-lottie';

const Redirect = ({ isLoggedIn, authLoading }) => {
  const navigate = useNavigate();
  const { user } = useAppSelector(state => state.user)

  useEffect(() => {
    if (!isLoggedIn && !authLoading) {
      navigate('/login');
    } else if (isLoggedIn && !authLoading && user?.has_channels) {
      navigate('/conversations');
    } else if (isLoggedIn && !authLoading && !user?.has_channels) {
      navigate('/channels');
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn && !authLoading) {
      navigate('/login');
    } else if (isLoggedIn && !authLoading && user?.has_channels) {
      navigate('/conversations');
    } else if (isLoggedIn && !authLoading && !user?.has_channels) {
      navigate('/channels');
    }
  }, [isLoggedIn, authLoading, user]);

  // const lottieOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: getBranding('oppy_wave_animation'),
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice'
  //   }
  // };

  return (
    <>
      <Box sx={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection:'row' }}>
        <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: -1 }}>
          <LavaLampBackground />
        </Box>
        <Box sx={{
          display:'flex',
          justifyContent:'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}>
          {/* <Box sx={{ width: '150px', height: '150px', marginBottom: '16px' }}>
            <Lottie options={lottieOptions} />
          </Box> */}
          <Box component="img" src={getBranding('oppy_word_mark_yellow')} alt="Logo" sx={{ height: '32px', zIndex: 1 }} />
          <AssistantIcon size={75} movementSpeed={1.5} />
        </Box>
      </Box>
    </>
  );
};

export default Redirect;
