import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';

const setLocation = async (data: { formatted_address: string }) => {
  return httpClient.post(`${API_ROUTES.location}`, {
    ...data,
  });
};

const LocationService = {
  setLocation,
};

export default LocationService;
