import ConfirmationForm from "src/components/profile/ConfirmationForm";
import ProfileLayout from "src/components/profile/ProfileLayout";

const ConfirmationMessage = () => {
    return (
        <ProfileLayout activeKey="confirmation">
            <ConfirmationForm />
        </ProfileLayout>
        );
};

export default ConfirmationMessage;
