import { useEffect } from "react";
import { generateUniqueId } from "src/libs/utils";
import { Cloudinary } from "cloudinary-core";
import "cloudinary-video-player/dist/cld-video-player.min.js";
import "cloudinary-video-player/dist/cld-video-player.min.css";

const cld = new Cloudinary({
  secure: !!process.env.REACT_APP_CLOUDINARY_CNAME,
  secure_distribution: process.env.REACT_APP_CLOUDINARY_CNAME,
  private_cdn: !!process.env.REACT_APP_CLOUDINARY_CNAME,
  cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
})
const options = {
  sourceTypes: ["hls", "mp4"],
  muted: true,
  controls: true,
  sourceTransformation: {
    "hls": [{ streaming_profile: "full_hd" }],
    "mp4": [{ quality: "auto" }],
  },
}

const CloudinaryVideoTag = ({ cloudinary_public_id, onClick=()=>{} }) => {
  const videoTagId = generateUniqueId('cloudinary-player')

  useEffect(()=>{
    try {
      {/* @ts-ignore */}
      const videoPlayer = cld.videoPlayer(videoTagId, options);
      videoPlayer.source(cloudinary_public_id);
    } catch(e) { console.log(e) }
  }, [])

  return (
    <video
      onClick={onClick}
      id={videoTagId}
      className="cld-video-player cld-fluid h-100"
    />
  );
};

export default CloudinaryVideoTag;
