import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs';
import { ReqGalleryItemType } from 'src/types/gallery';


const getGalleryPhotos = async (galleriable_type: string, galleriable_id: string) => {
  // return httpClient.get(`http://localhost:3000/${API_ROUTES.gallery_items}?galleriable_type=${galleriable_type}&galleriable_id=${galleriable_id}`);
  return httpClient.get(`${API_ROUTES.gallery_items}/${galleriable_type}/${galleriable_id}`);
};


const uploadGalleryItem = async ( data: ReqGalleryItemType ) => {
  return httpClient.post(`${API_ROUTES.gallery_items}/${data.galleriable_type}/${data.galleriable_id}`, data);
};

const remove = (id) => {
  return httpClient.delete(`${API_ROUTES.services}/${id}`);
};

const removeGalleryItem = async (itemId) => {
  return httpClient.delete(`${API_ROUTES.gallery_items}/${itemId}`);
};


// const deleteGalleryItem = async (serviceId: string, photoId: number) => {
//   return httpClient.delete(`/services/${serviceId}/${API_ROUTES.gallery_items}/${photoId}`);
// };


// const bulk_reorder = (serviceId: string, order: Object[]): Promise<{data: { message: string }}> => {
//   return httpClient.put(`${API_ROUTES.services}/${serviceId}/gallery_items/bulk_reorder`, {
//     service: {
//       gallery_items_attributes: order
//     }
//   })
// };

// const create = (data: Type) => {
//   return httpClient.post(API_ROUTES.services, { service: data });
// };

// const update = (id: string, data) => {
//   return httpClient.put(`${API_ROUTES.services}/${id}`, { service: data });
// };

// const remove = (id) => {
//   return httpClient.delete(`${API_ROUTES.services}/${id}`);
// };

// const getGalleryItems = async (serviceId: string) => {
//   return httpClient.get(`/services/${serviceId}/${API_ROUTES.gallery_items}`);
// };


// const deleteGalleryItem = async (serviceId: string, photoId: number) => {
//   return httpClient.delete(`/services/${serviceId}/${API_ROUTES.gallery_items}/${photoId}`);
// };
const GalleryService = {
    getGalleryPhotos,
    uploadGalleryItem,
    removeGalleryItem,
};

export default GalleryService;
