import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import { updateChannelAdminSettings } from 'src/features/account/channels.slice';
import { Drawer, Button, TextField, Box, Typography, CircularProgress } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const UpdateAdminSettings = ({ channel, styles, open, setOpen }) => {
  const dispatch = useAppDispatch();
  const channelAdminSettings = useAppSelector(state => state.channels.channels.find(c => c.id === channel.id)?.admin_settings);
  const [drawerOpen, setDrawerOpen] = useState(open);
  const [adminEmail, setAdminEmail] = useState(channelAdminSettings?.admin_email?.email || '');
  const [adminPhone, setAdminPhone] = useState(channelAdminSettings?.admin_phone?.phone || '');
  const loading = channelAdminSettings?.loading || false;

  // useEffect(() => {
  //   debugger
  //   setAdminEmail(channelAdminSettings?.admin_email?.email || '');
  //   setAdminPhone(channelAdminSettings?.admin_phone?.phone || '');
  // }, [channelAdminSettings]);

  const handleUpdate = () => {
    const data: { admin_settings: { admin_email?: { email: string }, admin_phone?: { phone: string } } } = { admin_settings: {} };
    if (adminEmail) data.admin_settings.admin_email = { email: adminEmail };
    if (adminPhone) data.admin_settings.admin_phone = { phone: adminPhone };

    dispatch(updateChannelAdminSettings({
      channelId: channel.id,
      data
    }));
    setOpen(false);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setOpen(false)}
      >
        <Box p={2} width="300px">
          <Typography variant="h6">Update Admin Settings</Typography>
          <TextField
            label="Admin Email"
            value={adminEmail}
            onChange={(e) => setAdminEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Admin Phone"
            value={adminPhone}
            onChange={(e) => setAdminPhone(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdate}
            disabled={false}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? 'Updating...' : 'Update'}
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default UpdateAdminSettings;
