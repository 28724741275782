import React, { useState } from 'react';
import { Typography, Skeleton, Box } from '@mui/material';
import ContactDialog from './Contacts/ContactDialog'; // Adjust the import path as necessary
import theme from 'src/themes/oppy-default-theme';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

const ContactCard = ({ contact, name, styles }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleContactClick = (event) => {
    // prevent all default and propagation
    event.preventDefault();
    event.stopPropagation();
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Box sx={styles.wrapperBox}>
        { !name ? (
          <>
            <Skeleton variant="circular" width={24} height={24} style={{ marginRight: 8 }} />
            <Skeleton variant="text" width={100} height={24} />
          </>
        ) : (
          <>
            <Typography component="span" sx={styles.name} onClick={handleContactClick}>
              {name}
            </Typography>
            <Typography variant='caption' sx={styles.detailsRow}>
              {contact.phone_number && <><PhoneIcon sx={{ fontSize: 12, color: '#828282' }} /> {contact.phone_number}</>}
              {contact.email && <><EmailIcon sx={{ fontSize: 12, color: '#828282', ml: 1 }} /> {contact.email}</>}
            </Typography>
            <ContactDialog
              contactId={contact.id}
              open={isDialogOpen}
              onClose={handleCloseDialog}
              handleContactUpdate={null}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default ContactCard;
